import React, { Component } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import MetaTags from 'react-helmet'
import { Container, Row, Col, Button } from 'react-bootstrap'

import styles from './style.scss'

export default class Samples extends Component {
  static propTypes = {
    gotoURL: PropTypes.func.isRequired,
    loginWithRedirect: PropTypes.func.isRequired,
  }
  render() {
    const { gotoURL } = this.props
    return (
      <Container>
        <div>
          <MetaTags>
            <title>{'Free Resume Templates | CV Formats | Make Your Own Resume | Quick Resume Builder Free'}</title>
            <meta
            // eslint-disable-next-line max-len
              content={'First impression is the last impression! Make a great first impression by submitting an amazing resume that you can create here with numerous Templates.'}
              name="description" />
            <link href={'https://www.resumaker.co.in/free-resume-templates'} rel="canonical" />
          </MetaTags>
          <div>
            <Row className={classnames(styles.sample_page_header)}>
              <Col lg={7} md={7} sm={12} xl={7} xs={12}>
                <div className={classnames(styles.sample_page_header_margin)}>
                  <h1 className={classnames(styles.sample_page_header_heading)}>
                    Professional Resume Examples & Samples</h1>
                  <p>
                    Great resume composing is a fine art and can have the
                    effect of making you invited for an interview.
                    Nonetheless, many individuals don′t consider
                    it to be an artistic expression but a commitment.
                    Many people consider composing a resume as something
                    they need to do to find a new line of work.</p>
                </div>
              </Col>
              <Col lg={5} md={5} sm={12} xl={5} xs={12}>
                <img
                  alt="sample resume templates" className={classnames(styles.img_responsive, styles.img_center)}
                  src="/assets/images/sample-resume-landing.jpg" />
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} xl={12} xs={12}>
                <div className={classnames(styles.template_selection)}>
                  <h2>Free Resume Templates</h2>
                  <p className={classnames(styles.width_95)}>A simple resume template is the best methodology
                    for an assortment of positions.
                    Under this template, you focus on your achievements, experience, training, and many more.
                    Simple templates are adaptable and function admirably
                    across an assortment of fields and occupation titles.
                  </p>
                </div>
              </Col>
            </Row>
            <Row className={classnames(styles.template_selection_sample)}>
              <Col lg={10} md={9} sm={12} xl={10} xs={12}>
                <div
                  className={classnames(styles.resume_title)}
                  onClick={() => gotoURL('/free-resume-templates/best-resume-template-in-2022')}>
                  <h5 className={classnames(styles.resume_title)}>Best Resume Template in 2022</h5></div>
                <p className={classnames(styles.resume_text)}>
                  Writing the best resume can be challenging and very tricky.
                  If you don′t know how to write resume or feel that your CV isn′t good enough
                  to beat the competition, don′t worry we′ve got you covered.</p>
              </Col>
              <Col className={classnames(styles.resume_btn)} lg={2} md={3} sm={12} xl={2} xs={12}>
                <Button
                  className={classnames(styles.resume_readmore_btn)}
                  onClick={() => gotoURL('/free-resume-templates/best-resume-template-in-2022')}>
                  Show Me Sample </Button>
              </Col>
            </Row>
            <Row className={classnames(styles.template_selection_sample)}>
              <Col lg={10} md={9} sm={12} xl={10} xs={12}>
                <div
                  className={classnames(styles.resume_title)}
                  onClick={() => gotoURL('/free-resume-templates/customer-service-representative-resume-templates')}>
                  <h5 className={classnames(styles.resume_title)}>Customer Service Representative Resume</h5></div>
                <p className={classnames(styles.resume_text)}>
                  Customer service representatives matter a lot to the well-being of a company′s business.
                  It is crucial to bear in mind that great brands value excellent customer service and experience.
                  If you are looking for a customer service position,
                  it is essential to compose a top-notch resume that will enable you to get a perfect job.</p>
              </Col>
              <Col className={classnames(styles.resume_btn)} lg={2} md={3} sm={12} xl={2} xs={12}>
                <Button
                  className={classnames(styles.resume_readmore_btn)}
                  onClick={() => gotoURL('/free-resume-templates/customer-service-representative-resume-templates')}>
                  Show Me Sample </Button>
              </Col>
            </Row>
            <Row className={classnames(styles.template_selection_sample)}>
              <Col lg={10} md={9} sm={12} xl={10} xs={12}>
                <div
                  className={classnames(styles.resume_title)}
                  onClick={() => gotoURL('/free-resume-templates/fresher-resume-templates')}>
                  <h5 className={classnames(styles.resume_title)}>Fresher Resume</h5></div>
                <p className={classnames(styles.resume_text)}>
                  A simple and easy to use template is created for entry level jobs.
                  As a fresher you can write all the attributes about your academics,
                  projects or thesis work carried over the academics years to grab attention
                  from the hiring company.</p>
              </Col>
              <Col className={classnames(styles.resume_btn)} lg={2} md={3} sm={12} xl={2} xs={12}>
                <Button
                  className={classnames(styles.resume_readmore_btn)}
                  onClick={() => gotoURL('/free-resume-templates/fresher-resume-templates')}> Show Me Sample </Button>
              </Col>
            </Row>
            <Row className={classnames(styles.template_selection_sample)}>
              <Col lg={10} md={9} sm={12} xl={10} xs={12}>
                <div
                  className={classnames(styles.resume_title)}
                  onClick={() => gotoURL('/free-resume-templates/accounting-executive-resume-templates')}>
                  <h5 className={classnames(styles.resume_title)}>Accounting Executive Resume</h5></div>
                <p className={classnames(styles.resume_text)}>
                  Composing an Account Executive resume is a significant advance in your pursuit of
                  employment opportunities. When crafting your resume, make certain to reference
                  the expected set of responsibilities and highlight any skills, awards,
                  and accreditations that match your employer’s needs. You may likewise need to
                  incorporate a summary or objective synopsis that shows your qualifications
                  and capabilities.</p>
              </Col>
              <Col className={classnames(styles.resume_btn)} lg={2} md={3} sm={12} xl={2} xs={12}>
                <Button
                  className={classnames(styles.resume_readmore_btn)}
                  onClick={() => gotoURL('/free-resume-templates/accounting-executive-resume-templates')}>
                  Show Me Sample </Button>
              </Col>
            </Row>
            <Row className={classnames(styles.template_selection_sample)}>
              <Col lg={10} md={9} sm={12} xl={10} xs={12}>
                <div
                  className={classnames(styles.resume_title)}
                  onClick={() => gotoURL('/free-resume-templates/human-resource-manager-resume-templates')}>
                  <h5 className={classnames(styles.resume_title)}>Human Resource Manager Resume</h5></div>
                <p className={classnames(styles.resume_text)}>
                  Human Resources Managers deal with planning, directing, coordinating, and administering
                  the employee management function of the organization. Remember that as a Human Resources
                  Manager, you will do essential things like recruiting, planning, and managing payroll.
                  Therefore, you need a resume that attracts the attention of the recruiters.</p>
              </Col>
              <Col className={classnames(styles.resume_btn)} lg={2} md={3} sm={12} xl={2} xs={12}>
                <Button
                  className={classnames(styles.resume_readmore_btn)}
                  onClick={() => gotoURL('/free-resume-templates/human-resource-manager-resume-templates')}>
                  Show Me Sample </Button>
              </Col>
            </Row>
            <Row className={classnames(styles.template_selection_sample)}>
              <Col lg={10} md={9} sm={12} xl={10} xs={12}>
                <div
                  className={classnames(styles.resume_title)}
                  onClick={() => gotoURL('/free-resume-templates/supply-chain-manager-resume-templates')}>
                  <h5 className={classnames(styles.resume_title)}>Supply Chain Manager Resume</h5></div>
                <p className={classnames(styles.resume_text)}>
                  Supply chain managers are responsible for developing procurement strategies and
                  supervising all the supply chain life cycle stages. Employers are interested in
                  supply chain managers who are analytical, detail-oriented, and outgoing.
                  Your resume should highlight your ability to form reliable networks, use technology,
                  and handle multiple responsibilities.</p>
              </Col>
              <Col className={classnames(styles.resume_btn)} lg={2} md={3} sm={12} xl={2} xs={12}>
                <Button
                  className={classnames(styles.resume_readmore_btn)}
                  onClick={() => gotoURL('/free-resume-templates/supply-chain-manager-resume-templates')}>
                  Show Me Sample </Button>
              </Col>
            </Row>
            <Row className={classnames(styles.template_selection_sample)}>
              <Col lg={10} md={9} sm={12} xl={10} xs={12}>
                <div
                  className={classnames(styles.resume_title)}
                  onClick={() => gotoURL('/free-resume-templates/nurse-resume-templates')}>
                  <h5 className={classnames(styles.resume_title)}>Nurse Resume</h5></div>
                <p className={classnames(styles.resume_text)}>
                  Nursing is one of the best professions with immense opportunities in the job market,
                  and enrolled medical nurses are famous. There are many employment opportunities in
                  the nursing profession, yet getting one can be a challenge because of the competition.
                  It is advisable to optimize your resume prior to applying. An improved resume will
                  give you a favourable chance over your competitors. In this guide, you will get
                  bits of knowledge into a nurse resume summary, how to feature skills
                  and experience on a resume, how to list a nurse manager’s accomplishments,
                  and top tips for composing a nurse’s resume.</p>
              </Col>
              <Col className={classnames(styles.resume_btn)} lg={2} md={3} sm={12} xl={2} xs={12}>
                <Button
                  className={classnames(styles.resume_readmore_btn)}
                  onClick={() => gotoURL('/free-resume-templates/nurse-resume-templates')}> Show Me Sample </Button>
              </Col>
            </Row>
            <Row className={classnames(styles.template_selection_sample)}>
              <Col lg={10} md={9} sm={12} xl={10} xs={12}>
                <div
                  className={classnames(styles.resume_title)}
                  onClick={() => gotoURL('/free-resume-templates/operations-manager-resume-templates')}>
                  <h5 className={classnames(styles.resume_title)}>Operations Manager Resume</h5></div>
                <p className={classnames(styles.resume_text)}>
                  In any organization, operations managers are responsible for planning,
                  controlling, and quality affirmation of business processes.
                  The motivation behind the operations manager’s resume is to demonstrate
                  that you know how redesign business tasks, have business knowledge and
                  leadership skills, and are an effective problem solver.</p>
              </Col>
              <Col className={classnames(styles.resume_btn)} lg={2} md={3} sm={12} xl={2} xs={12}>
                <Button
                  className={classnames(styles.resume_readmore_btn)}
                  onClick={() => gotoURL('/free-resume-templates/operations-manager-resume-templates')}>
                  Show Me Sample </Button>
              </Col>
            </Row>
            <Row className={classnames(styles.template_selection_sample)}>
              <Col lg={10} md={9} sm={12} xl={10} xs={12}>
                <div
                  className={classnames(styles.resume_title)}
                  onClick={() => gotoURL('/free-resume-templates/ux-ui-designer-resume-templates')}>
                  <h5 className={classnames(styles.resume_title)}>UX/UI Designer Resume</h5></div>
                <p className={classnames(styles.resume_text)}>
                  Writing a UI/UX designer resume can be challenging if you don′t know how to go about it.
                  For one reason, you need to present your work history, summary, and skills in a manner
                  that meets an employer′s requirements. To enhance your chances of getting hired,
                  you must think of giving your resume a good shape. It is essential to bear in mind
                  that a resume is meant to sell the person that has it. Therefore, you have to think
                  of a way to craft your resume such that it would put you on a better chance of
                  being chosen by a recruiter.</p>
              </Col>
              <Col className={classnames(styles.resume_btn)} lg={2} md={3} sm={12} xl={2} xs={12}>
                <Button
                  className={classnames(styles.resume_readmore_btn)}
                  onClick={() => gotoURL('/free-resume-templates/ux-ui-designer-resume-templates')}>
                  Show Me Sample </Button>
              </Col>
            </Row>
            <Row className={classnames(styles.template_selection_sample)}>
              <Col lg={10} md={9} sm={12} xl={10} xs={12}>
                <div
                  className={classnames(styles.resume_title)}
                  onClick={() => gotoURL('/free-resume-templates/business-developmen-resume-templates')}>
                  <h5 className={classnames(styles.resume_title)}>Business Development Manager Resume
                    (Marketing Resume)</h5></div>
                <p className={classnames(styles.resume_text)}>
                  Every company focuses on employing a good sales representative to meet its marketing needs.
                  Whether you are a seasoned salesperson or newbie, you will need an excellent sales resume
                  to convince a potential employer to give you a job. In this guide, you will get insights
                  into a sales resume summary, how to highlight skills and experience in a resume, how to
                  capture a business development manager’s achievements and top tips for writing a sales
                  resume.</p>
              </Col>
              <Col className={classnames(styles.resume_btn)} lg={2} md={3} sm={12} xl={2} xs={12}>
                <Button
                  className={classnames(styles.resume_readmore_btn)}
                  onClick={() => gotoURL('/free-resume-templates/business-developmen-resume-templates')}>
                  Show Me Sample </Button>
              </Col>
            </Row>
            <Row className={classnames(styles.template_selection_sample, styles.template_selection_sample_lastdiv)}>
              <Col lg={10} md={9} sm={12} xl={10} xs={12}>
                <div
                  className={classnames(styles.resume_title)}
                  onClick={() => gotoURL('/free-resume-templates/software-engineer-resume-templates')}><h5
                  >
                    Software Engineer Resume (IT Resume)</h5></div>
                <p className={classnames(styles.resume_text)}>
                  Software engineers are adept at addressing most of the challenging assignments
                  in the technological world. However, the majority of them don’t know how to
                  compose compelling resumes for job applications. If you are looking for a
                  software engineer position, it is pivotal to acquaint yourself with making a resume.</p>
              </Col>
              <Col className={classnames(styles.resume_btn)} lg={2} md={3} sm={12} xl={2} xs={12}>
                <Button
                  className={classnames(styles.resume_readmore_btn)}
                  onClick={() => gotoURL('/free-resume-templates/software-engineer-resume-templates')}>
                  Show Me Sample </Button>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    )
  }
}
