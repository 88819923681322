import React, { Component, Fragment } from 'react'
import cs from 'classnames'
import { Container, Row, Col } from 'react-bootstrap'
import st from './style.scss'

export default class WhySection extends Component{
  render(){
    return (
      <Fragment>
        <section className={cs(st.why_resume_title)}>
          <Container className={cs(st.cc_fluid_pp)} fluid>
            <h2> Why Use The Resumaker Builder? </h2>
          </Container>
        </section>
        <section className={cs(st.why_resume)}>
          <Container className={cs(st.cc_fluid_pp)} fluid>
            <Row className={cs(st.why_resume_bg)}>
              <Col lg={4} md={12} sm={12} xl={4} xs={12}>
                <div className={cs(st.why_resume_bg)}>
                  <div>
                    <img alt="free" src={'/assets/images/why_resume_img_1.png'} />
                  </div>
                  <div className={cs(st.why_resume_content)}>
                    <div className={cs(st.why_resume_ttl)}> Edit In Real-Time </div>
                    <p> Change the text positions, fonts, color schemes and appearances while you create.
                      No hassle of alignments, margins - it&lsquo;s well taken care. </p>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={12} sm={12} xl={4} xs={12}>
                <div className={cs(st.why_resume_bg)}>
                  <div>
                    <img alt="real time" src={'/assets/images/why_resume_img_2.png'} />
                  </div>
                  <div className={cs(st.why_resume_content)}>
                    <div className={cs(st.why_resume_ttl)}>  Bundle of suggestions </div>
                    <p> Resumaker has abundant options to choose from for most of the job types in the industry.
                      You can get benefitted by these suggestions while writing objective, summary etc. Also you
                      have option to edit the suggestions and recreate your version.</p>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={12} sm={12} xl={4} xs={12}>
                <div className={cs(st.why_resume_bg)}>
                  <div>
                    <img alt="no hidden fee" src={'/assets/images/why_resume_img_3.png'} />
                  </div>
                  <div className={cs(st.why_resume_content)}>
                    <div className={cs(st.why_resume_ttl)}>  ATS - Friendly </div>
                    <p>Resumaker generates well-formatted resume with job matching keywords highlighting
                      the candidate&lsquo;s
                      work experience and skills. This will greatly improve the chance of being shortlisted. </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Fragment>
    )
  }
}
