import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { get } from 'lodash'
import classnames from 'classnames'
import { registerForGoogleAnalytics, getQueryStringComplete } from '../../constants'
import { LoginPage } from '../../components'

import { AppConstants } from '../../constants/application-constants'
import styles from './style.scss'

@inject('applicationModel')
@observer
class LoginContainer extends Component {
  static propTypes = {
    applicationModel: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }

  constructor(props){
    super(props)
    this.state = {
      selectedKey: '',
      ownUpdate: false,
    }
  }

  componentDidMount(){
    const { history } = this.props
    registerForGoogleAnalytics(history)
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if (prevState.ownUpdate){
      return {
        selectedKey: prevState.selectedKey,
        ownUpdate: false,
      }
    }
    const { location } = nextProps
    const pathname = get(location, 'pathname')
    const selectedKey = pathname.substring(pathname.lastIndexOf('/') + 1)
    if (selectedKey !== prevState.selectedKey) {
      return { selectedKey }
    }
    return null
  }

  login = ({ userName, password }) => {
    const { applicationModel } = this.props
    const callback = (profileId) => {
      this.onLogin()
    }
    applicationModel.login({ userName, password }, callback)
  }

  onLogin = () => {
    const { applicationModel } = this.props
    const redirectDetails = applicationModel.getRedirect()
    const redirectUrl = get(redirectDetails, 'redirectTo')
    if (redirectUrl){
      window.location.assign(`${AppConstants.dashboardHost}${redirectUrl}`)
    }
    else {
      window.location.assign(`${AppConstants.dashboardHost}/dashboard`)
    }
  }

  register = (payload, cb = () => {}) => {
    const { applicationModel } = this.props
    const callback = (response) => {
      cb(response)
    }
    applicationModel.registerUser(payload, callback)
  }

  updateSelectedKey = (selectedKey) => {
    this.setState({
      selectedKey,
      ownUpdate: true,
    })
  }

  getResetPasswordToken = (payload, cb = () => {}) => {
    const { profileModel } = this.props
    profileModel.getResetPasswordToken(payload, cb)
  }

  validateOTP = (payload, cb = () => {}) => {
    const { applicationModel } = this.props
    applicationModel.validateOTP(payload, cb)
  }

  changePassword = (payload, cb = () => {}) => {
    const { profileModel } = this.props
    profileModel.resetPassword(payload, cb)
  }

  openHome = (e) => {
    if (e) e.preventDefault()
    const { history } = this.props
    history.push('/')
  }

  openURL = (url) => {
    const { history } = this.props
    history.push(url)
  }

  redirectURL = (url) => {
    const { applicationModel } = this.props
    const redirectDetails = applicationModel.getRedirect()
    window.location.assign(`${AppConstants.dashboardHost}/${url}${getQueryStringComplete(redirectDetails)}`)
  }

  render() {
    const { selectedKey } = this.state
    return (
      <div className={classnames(styles.login_page)}>
        <LoginPage
          getResetPasswordToken={this.getResetPasswordToken}
          launchDashboard={this.launchDashboard}
          login={this.login}
          openURL={this.openURL}
          redirectURL={this.redirectURL}
          register={this.register}
          selectedKey={selectedKey}
          updatePassword={this.changePassword}
          updateSelectedKey={this.updateSelectedKey}
          validateOTP={this.validateOTP} />
      </div>
    )
  }
}
export default withRouter(LoginContainer)
