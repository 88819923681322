import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './style.scss'

export default class Download extends Component {
  static propTypes = {
    downloadURL: PropTypes.string.isRequired,
    thanksURL: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
  }

  onHREFClick = () => {
    const { history } = this.props
    history.push('/upgrade-to-premium-templates')
    return true
  }

  render() {
    const { downloadURL } = this.props
    return (
      <a
        ref={this.anchorRef}
        className={classnames(styles.resume_download)}
        href={downloadURL}
        onClick={this.onHREFClick}>
        <span className={classnames('sr-only')}>Download</span>
      </a>
    )
  }
}
