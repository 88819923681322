import React, { Component } from 'react'
import classnames from 'classnames'
import { Container, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import PromoCard from './PromoCard'
import Download from '../../common/Download'
import GetStartedButton from '../../MainContent/Home/GetStartedButton'
import { getDocUrl } from '../../../constants'
import styles from './style.scss'

export default class OperationsManager extends Component {
    static propTypes = {
      history: PropTypes.object.isRequired,
      loginWithRedirect: PropTypes.func.isRequired,
    }

    render() {
      const { loginWithRedirect, history } = this.props
      return (
        <div>
          <Container fluid>
            <div className={classnames('page-main-wrapper')}>
              <div className={classnames(styles.container_xl, 'mt-5', 'mb-5')}>
                <div className={classnames(styles.page_content_header, 'row', 'align-items-center')}>
                  <Col lg={12} md={12} xl={12} xs={12}>
                    <div className={classnames('text-center', 'mx-auto')}>
                      <img
                        alt="fresher resume" className={classnames(styles.resume_sample_vector, 'img-fluid', 'mx-auto')}
                        src="/assets/images/OPS_vector.jpg" />
                      <div
                        className={classnames('mt-md-5', 'text-center', 'mb-md-4')}>
                        <h1 className={classnames('mt-4', styles.size_heading)}>Operations Manager Resume</h1>
                        <p className={classnames('mb-0')}>In any organization, operations
                          managers are responsible for
                          planning, controlling, and quality
                          affirmation of business processes.
                          The motivation behind the operations manager&rsquo;s resume is to
                          demonstrate that you know how redesign business tasks,
                          have business knowledge and leadership skills, and are
                          an effective problem solver.Let&rsquo;s see how to compose an
                          operations manager resume.</p>
                      </div>
                      <div className={classnames(styles.btn_gs)}>
                        <GetStartedButton label="Build My Resume" loginWithRedirect={loginWithRedirect} size="lg" />
                      </div>
                    </div>
                  </Col>
                  <div className={classnames(styles.resume_sample_container, 'mt-5', 'pt-3', 'pt-lg-4')}>
                    <Row>
                      <Col lg={6}>
                        <div className={classnames('mb-5', 'p-xl-5', 'mb-xl-0')}>
                          <div className={classnames(styles.resume_sample)}>
                            <img
                              alt="Resume Sample" className={classnames(styles.resume_sample_img)}
                              src="/assets/images/Ops_resume.jpg" />
                            <Download
                              downloadURL={getDocUrl('assets/docs/orm-resume.docx')}
                              history={history} />
                          </div>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className={classnames('mb-5', 'p-xl-5', 'mb-xl-0')}>
                          <div className={classnames(styles.resume_sample)}>
                            <img
                              alt="Resume Sample" className={classnames(styles.resume_sample_img)}
                              src="/assets/images/Ops_cl.jpg" />
                            <Download
                              downloadURL={getDocUrl('assets/docs/orm-resume.docx')}
                              history={history} />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className={classnames(styles.resume_sample_header, 'text-center')}>
                      <div className={classnames(styles.container_xl)}>
                        <h2 className={classnames(styles.section_heading)}>
                          Operations Manager Resume (Text Format)
                        </h2>
                        <p className={classnames('mt-2', styles.section_paragraph)}>
                          Make sure you choose the right resume
                          format to suit your unique experience and the job you are applying.</p>
                      </div>
                    </div>
                    <div className={classnames(styles.resume_sample_wrapper)}>
                      <div className={classnames(styles.container_xl)}>
                        <Row>
                          <Col lg={8} xl={8} xs={12}>
                            <div className={classnames(styles.resume_sample_main)}>
                              <div className={classnames(styles.tab_content)}>
                                Text View
                              </div>
                            </div>
                            <div className={classnames(styles.resume_content_card,
                              styles.dz_card, styles.shadow_1, 'px-3', 'px-lg-5', 'py-5', 'tab-pane active')}>
                              <Row>
                                <h2 className={classnames(styles.section_heading, 'ml-2')}>
                                  Operations Manager Resume
                                </h2>
                              </Row>
                              <div className={classnames(styles.resume_content_title, 'mt-3')}>Summary</div>
                              <p className={classnames('mt-2', 'mb-2')}>
                                Results-oriented business
                                leader with more than 8 years of experience in day-to-day business
                                operations management. Effective liaison to key clients, accounts and
                                external business stakeholders. Offering the ability to leverage
                                trends across industries and markets to derive solutions and
                                approaches focused on creating value for customers and profitability.
                                Merger and acquisitions specialist adept in creating a seamless
                                integration of new operations and businesses.
                              </p>
                              <div className={classnames(styles.resume_content_title)}>
                                Work Experience</div>
                              <ul className={classnames(styles.interview_points, 'list-unstyled',
                                styles.content_title, 'mt-4')}>
                                <li className={classnames('mb-0')}>Operations Manager</li>
                                <li className={classnames('mb-0')}> XYZ Tech, Belas,
                                  US-Dec 2019 - till date</li>
                              </ul>
                              <ul className={classnames(styles.interview_points, 'mt-2')}>
                                <li>Conducted performance reviews each quarter, offering
                                  praise and recommendations for improvement.</li>
                                <li>Established and administered annual budget with effective
                                  controls to prevent overages, minimize burn rate and support
                                  sustainability objectives.</li>
                                <li>Strengthened performance metrics tracking and analysis to
                                  enhance tactical and strategic company plans.</li>
                                <li>Reviewed financial reports and investigated variances with
                                  relevant staff to keep records accurate.</li>
                                <li>Developed and submitted strategy and initiative reports to
                                  highlight current actions and proposed
                                  changes to corporate committee.</li>
                                <li>Reviewed financial reports and investigated variances
                                  with accounting staff to keep records accurate.</li>
                              </ul>
                              <ul className={classnames(styles.interview_points, 'list-unstyled',
                                styles.content_title, 'mt-4')}>
                                <li className={classnames('mb-0')}>Operations Assistant</li>
                                <li className={classnames('mb-0')}>Operations Coordinator,
                                  ABC Solutions Belas, US-2014 - 2019 </li>
                              </ul>
                              <ul className={classnames(styles.interview_points, 'mt-2')}>
                                <li>Assisted operations manager with the planning of
                                  routine operations and special projects.</li>
                                <li>Prepared and proofread reports
                                  detailing operations activities.</li>
                                <li>Developed and maintained relationships
                                  with club members and guests.</li>
                                <li>Assisted with day-to-day operations of facility, including
                                  scheduling and customer service.</li>
                                <li>Managed and maintained file system covering expenses, reports
                                  and support documentation.</li>
                                <li>Gathered, organized and modelled data to assist
                                  management in making key decisions.</li>
                                <li>Collected, arranged and input information
                                  into a database system.</li>
                                <li>Generated reports of findings to help
                                  management with making key decisions.</li>
                              </ul>
                              <div className={classnames(styles.resume_content_title)}>
                                Skills
                              </div>
                              <ul className={classnames(styles.interview_points, 'mt-4')}>
                                <li>Risk analysis and management</li>
                                <li>Supply Chain Management</li>
                                <li>SAP Expertise</li>
                                <li> Policies and Procedures Implementation </li>
                                <li> Team Leadership</li>
                                <li>Relationship Development</li>
                                <li> New product introductions strategies</li>
                                <li>Sales background</li>
                                <li>Negotiation</li>
                                <li>Business planning</li>
                              </ul>
                              <div className={classnames(styles.resume_content_title, 'mt-3')}>
                                Education</div>
                              <ul className={classnames(styles.interview_points, 'list-unstyled', 'mt-4')}>
                                <li> Master’s In Business Management (MBA)</li>
                              </ul>
                              <div className={classnames(styles.resume_content_title, 'mt-3')}>Certifications</div>
                              <ul className={classnames(styles.interview_points, 'mt-4')}>
                                <li>Six Sigma Master</li>
                                <li>MCSE: Cloud Platform and Infrastructure (Microsoft)</li>
                                <li> Certified Operations Manager</li>
                              </ul>
                              <div className={classnames(styles.resume_content_title)}>
                                Accomplishments
                              </div>
                              <ul className={classnames(styles.interview_points, 'mt-4')}>
                                <li> Used MS Excel to develop inventory
                                  tracking spreadsheets.</li>
                                <li>Resolved product issue through consumer testing.</li>
                                <li>Supervised team of 70+ staff members.</li>
                              </ul>
                              <div className={classnames(styles.resume_content_title)}>
                                Hobbies
                              </div>
                              <ul className={classnames(styles.interview_points, 'mt-4')}>
                                <li> Travelling</li><li> Video Games</li>
                              </ul>
                              <div className={classnames(styles.resume_content_title)}>
                                Language
                              </div>
                              <ul className={classnames(styles.interview_points, 'mt-4')}>
                                <li> English</li><li> Hindi</li><li> Spanish</li><li> French</li>
                              </ul>
                            </div>
                            <div className={classnames(styles.resume_sample_header, 'pb-5')}>
                              <h2 className={classnames(styles.section_heading, 'text-left', 'mt-2', 'mb-2')}>
                                How to Write Operations Manager Resume</h2>
                              <p>In this guide, you will get a ton of bits of
                                knowledge into how to compose
                                an operations manager resume.</p>
                            </div>
                            <div
                              aria-labelledby="resumeContentTab"
                              className={classnames(styles.resume_content_card,
                                styles.dz_card, styles.shadow_1, 'px-0', 'px-lg-5', 'py-5', 'tab-pane active')}
                              id="resumeContent" role="tabpanel">
                              <h3 className={classnames(styles.resume_content_heading, 'mt-2')}>
                                How to Write an Operations Manager’s Resume Summary</h3>
                              <p className={classnames('mb-0')}>A resume summary is a segment that goes on
                                top of your resume, simply under the contact data section. In the resume summary,
                                hiring managers begin to separate between a competent candidate and
                                somebody miserably sending generic mass messages
                                to many employers to get recruited.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2', styles.align)}>
                                Here are the strategies for
                                writing an operations manager resume summary</h5>
                              <ul className={classnames(styles.interview_points)}>
                                <li>Keep recruiters intrigued by your resume by
                                  giving them all that they require.</li>
                                <li>Make hiring managers interested in your
                                  resume by giving them all that they require.</li>
                                <li>Make your resume summary concise and clear. Keep recruiters
                                  interested in your resume by giving them what they’re looking for.</li>
                                <li> Highlight the experience, skills you have, and
                                  your career plans for the future</li>
                                <li>Avoid sending generic summaries because
                                  they will ruin your chances of getting recruited.</li>
                              </ul>
                              <p>Incorporate a couple of the keywords you
                                gathered from the job descriptions. Additionally,
                                feature tangible results with exact metrics
                                to highlight your greatest achievements.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>
                                Operations Manager Resume Summary Examples</h5>
                              <h5 className={classnames('ml-3', 'mt-3', 'font-weight-bold')}>Good Example</h5>
                              <blockquote><p className={classnames(styles.resume_rank, 'ml-0')}>
                                An Operations Manager with seven years of experience working for
                                Kilkin implementing and coordinating production processes.
                                Made crucial strategic decisions that improved profit margins
                                by 10% while cutting production costs by 14%. Demonstrated high
                                leadership and teamwork standards that reduced staff turnover by 30%. Currently
                                looking for a mid-level position at Enamel with
                                room for advancement within the department.
                              </p></blockquote>
                              <h5 className={classnames('ml-3', 'font-weight-bold')}>Bad Example</h5>
                              <blockquote><p className={classnames(styles.resume_rank, 'ml-0', 'mb-2')}>
                                An Operations Manager with more than five years of experience working
                                at a manufacturing firm. Helped with improving profits and
                                reducing business losses. Kept high leadership and
                                teamwork standards. Currently looking for a new operations
                                management position to advance my career.
                              </p>
                                <p className={classnames(styles.resume_rank, 'ml-0', 'mb-2')}>
                                  The above resume is wrong because of the following reasons:
                                </p>
                                <p className={classnames(styles.resume_rank, 'ml-0', 'mb-2')}>
                                  It&#8242;s generic and only features unspecific information about you.
                                  Additionally, it&#8242;s not showing your future career plans and goals.
                                </p>
                                <p className={classnames(styles.resume_rank, 'ml-0')}>
                                  It doesn&#8242;t feature your abilities and experience.
                                  Likewise, no strong measurements have been utilized.
                                  Anybody can imply to have strong skills. But how does
                                  your potential employer evaluate that?
                                </p>
                              </blockquote>
                              <div className={classnames('card', 'p-3', 'px-lg-4', 'mt-5',
                                styles.ProTip, styles.interview_blockquote)}>
                                <h5 className={classnames('ml-5', 'font-weight-bold')}>Pro Tips </h5>
                                <ul className={classnames(styles.interview_points, 'mt-4', 'ml-5')}>
                                  <li>Highlight accomplishments that are relevant
                                    to the job you are applying for.</li>
                                  <li>Analyze the job descriptions and note the keywords.
                                    Use the keywords to highlight skills
                                    related to the employer&#8242;s needs.</li>
                                </ul>
                              </div>
                              <h3 className={classnames(styles.resume_content_heading, 'mt-5', 'mb-4')}>
                                How to Highlight Skills in Operations Manager Resume
                              </h3>
                              <img
                                alt="Nurseimage" className={classnames('mt-2', 'mb-3')}
                                src="/assets/images/Ops_skill.jpg" />
                              <p className={classnames('mb-1')}>An operations manager resume should
                                exhibit a candidate&#8242;s capacity to coordinate and control
                                the company&#8242;s business operations. The manager ought to have
                                solid decision-making
                                and management skills to enable him/her to
                                manage and address operations issues.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>How to list skills</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Ensure you list skills shown in the
                                  operations manager&#8242;s job description.
                                  Although you may have many skills, the job descriptions
                                  reveal to you which ones matter most.</li>
                                <li>Demonstrate that you have used
                                  the skills to achieve business performance.</li>
                                <li>On your resume, show that you
                                  have strong operations management skills</li>
                                <li>Show how your skills have helped you to
                                  accomplish particular objectives.
                                  For example, &#8243;Used my operations management
                                  skills to reduce annual costs by 40%.&#8243;</li>
                                <li>Make sure to list your skills in the major
                                  sections of a resume, such as the summary,
                                  skill, and experience.</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold', 'mt-3', 'ml-2')}>
                                Operations Manager Resume Skills Examples</h5>
                              <h5 className={classnames('ml-3', 'mt-3', 'font-weight-bold')}>Good Example</h5>
                              <blockquote><p className={classnames(styles.resume_rank, 'ml-0')}>
                                <ul className={classnames(styles.interview_points, 'mt-3')}>
                                  <li>Reduced 20% from annual budget through
                                    Lean programs without sacrificing quality.</li>
                                  <li>Convinced suppliers to shift to Just-In-Time
                                    delivery, reducing inventory cost by 15%.</li>
                                  <li>Collaborated with sales and
                                    marketing teams to boost revenue by 50%.</li>
                                </ul>
                              </p></blockquote>
                              <h5 className={classnames('ml-3', 'font-weight-bold')}>Bad Example</h5>
                              <blockquote><p className={classnames(styles.resume_rank, 'ml-0', 'mt-3', 'mb-2')}>
                                Operations Manager Skills: Excellent correspondence,
                                sound leadership, teamwork,
                                Lean reasoning, budgeting, performance tracking,
                                Strategy Development, and Policy Deployment.
                              </p>
                                <p className={classnames(styles.resume_rank, 'ml-0', 'mb-0')}>
                                  The above sample isn&#8242;t right since it doesn&#8242;t
                                  show how precisely the skills were utilized
                                  to accomplish specific outcomes.
                                </p>
                              </blockquote>
                              <h5 className={classnames('font-weight-bold', 'mt-2', styles.align)}>
                                How to Highlight Hard and
                                Soft Skills on a Resume</h5>
                              <p className={classnames('mb-2')}>Hard skills entail the
                                qualifications and technical
                                abilities required for a particular task.
                                However, soft skills relate to your personality
                                or working style that are difficult to measure.</p>
                              <p>Ensure that you highlight relevant skills that
                                show that you can manage a business and
                                reduce production costs.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>
                                Examples of hard skills</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Strategy development</li>
                                <li>Budgeting</li>
                                <li>Lean thinking</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>
                                Examples of Soft skills</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Communication skills.</li>
                                <li>Critical thinking</li>
                                <li>Collaboration</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>
                                How to Highlight Operations
                                Manager Skills on a Resume</h5>
                              <p className={classnames('mb-2')}>
                                Here are the strategies on how to
                                list an operations manager&#8242;s skills on your resume</p>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Use both hard and soft skills to
                                  make your resume outstanding</li>
                                <li>Highlight skills based on the
                                  recruiter&#8242;s job descriptions</li>
                                <li>List skills that are relevant
                                  to the employer&#8242;s requirements</li>
                              </ul>
                              <div className={classnames('card', 'p-3', 'px-lg-4', 'mt-5',
                                styles.ProTip, styles.interview_blockquote)}>
                                <h5 className={classnames('ml-5', 'font-weight-bold')}>
                                  Pro Tip </h5>
                                <p className={classnames('ml-5')}>
                                  Analyze the job descriptions and include the most
                                  relevant skills to create a clear picture of
                                  how your skills have helped you achieve results.</p>
                              </div>
                              <h3 className={classnames(styles.resume_content_heading, 'mt-5')}>
                                How to Write Work Experience in Operations Manager Resume</h3>
                              <p className={classnames('mb-2')}>Operations management is a complex
                                role that not anybody can fit.
                                It requires a ton of exactness and time management
                                when it comes to running a company&#8242;s affairs.</p>
                              <p className={classnames('mb-2')}>A successful operations manager has
                                to be fast and consistent in the work he or she delivers.
                                They must be able to make quick, accurate
                                decisions to keep the company moving.
                                Hiring companies prefer to stick to candidates
                                with the best experience in the role.</p>
                              <p className={classnames('mb-0')}>Hiring managers need
                                to recruit candidates who
                                can rapidly adjust and understand
                                how to operate the business.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>Here is how to highlight
                                the work experience section</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Show the relevant experience you have in the job,
                                  along with the number of
                                  years you&#8242;ve been doing it.</li>
                                <li>Ensure you feature your most successful projects
                                  and past achievements. Use precise
                                  metrics and data to support your claims.</li>
                                <li>Make sure to list experience that is in
                                  alignment with the job offer.
                                  You need to understand the company&#8242;s
                                  needs and demonstrate how you are the solution.</li>
                                <li>Include keywords and exact terms the
                                  hiring company uses in
                                  the job application, then use
                                  them to describe your job experience.</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold', 'ml-2')}>
                                Operations Manager Resume Sample</h5>
                              <h5 className={classnames('ml-3', 'mt-3', 'font-weight-bold')}>Good Example</h5>
                              <blockquote><p className={classnames(styles.resume_rank, 'ml-0')}>
                                <ul className={classnames(styles.interview_points, 'list-unstyled', 'mt-3')}>
                                  <li>Operations Manager</li>
                                  <li>Enamel Ltd</li>
                                  <li>02/2015-05/2020</li>
                                </ul>
                                <ul className={classnames(styles.interview_points)}>
                                  <li>Recommended for customer service improvements
                                    that led to a $5M increase in annual revenue.</li>
                                  <li>Supervised all planning and budgeting
                                    procedures to keep expenses
                                    within budget guidelines and ensure
                                    compliance with company policies</li>
                                  <li>Increased staff productivity by 20% while reducing
                                    turnover rate staff by 35% through
                                    continuous teamwork and collaborative training.</li>
                                </ul>
                              </p></blockquote>
                              <h5 className={classnames('ml-3', 'font-weight-bold')}>Bad Example</h5>
                              <blockquote><p className={classnames(styles.resume_rank, 'ml-0', 'mb-0')}>
                                <ul className={classnames(styles.interview_points, 'list-unstyled', 'mt-3')}>
                                  <li>Operations Manager</li>
                                  <li>Enamel Ltd</li>
                                  <li>02/2015-05/2020</li>
                                </ul>
                                <ul className={classnames(styles.interview_points)}>
                                  <li>In charge of answering inquiries
                                    about company policies and services</li>
                                  <li>Assisted with running business’ operations.</li>
                                  <li>Increased staff productivity by 20% while reducing
                                    turnover rate staff by 35% through
                                    continuous teamwork and collaborative training.</li>
                                </ul>
                                The above example is wrong because it lacks solid examples
                                and precise metrics, which are important
                                in proving your experience to recruiters.
                              </p>
                              </blockquote>
                              <div className={classnames('card', 'p-3', 'px-lg-4', 'mt-5',
                                styles.ProTip, styles.interview_blockquote)}>
                                <h5 className={classnames('ml-5', 'font-weight-bold')}>Pro Tip</h5>
                                <p className={classnames('ml-5')}>Ensure to begin sentences
                                  with direct action verbs
                                  that enhance clear causality.
                                  Additionally, use metrics along with the exact
                                  job duties that lead to its achievement.</p>
                              </div>
                              <h3 className={classnames(styles.resume_content_heading, 'mt-5')}>
                                How to write Operations Manager&apos;s Achievements, Hobbies, Training,
                                and certifications on Resume</h3>
                              <p className={classnames('mb-1')}>Featuring your accomplishments,
                                certifications, and hobbies on your resume shows
                                the degree of your past performance.
                                This is intended to make your resume stand out,
                                henceforth expanding your odds of enlistment.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>How to Highlight Operations
                                Manager&#8242;s Achievement</h5>
                              <p className={classnames('mb-1')}>Feature your key achievements in a manner
                                that shows that you are fit for the position.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>
                                Examples</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Awarded the industry&#8242;s best operations
                                  award for two consecutive years</li>
                                <li>Reduced production expenses by 40% within
                                  the first year in office</li>
                                <li>Managed more than 200 employees across
                                  different branch networks</li>
                              </ul>
                              <div className={classnames('card', 'p-3', 'px-lg-4', 'mt-5',
                                styles.ProTip, styles.interview_blockquote)}>
                                <h5 className={classnames('ml-5', 'font-weight-bold')}>Pro Tip</h5>
                                <p className={classnames('ml-5')}>When highlighting
                                  your key achievements,
                                  always use metrics to quantify
                                  your accomplishments.</p>
                              </div>
                              <h5 className={classnames('font-weight-bold', 'mt-2', 'mt-5')}>
                                How to highlight hobbies
                                in operations manager&#8242;s resume</h5>
                              <p className={classnames('mb-1')}>You should list hobbies that align with
                                the content of the job descriptions. For example, if one of
                                your hobbies is socializing, adding it to your resume show
                                the hiring managers that you can cope well with
                                other employees; hence enhancing business growth.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>Here are the tips on
                                how to list hobbies on your resume</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>List hobbies that relate to
                                  the position you are applying</li>
                                <li>Restrict yourself to a maximum of three hobbies</li>
                                <li>Highlight hobbies as per the skills
                                  listed in the job description</li>
                                <li>Ensure you highlight hobbies
                                  at the bottom of your resume</li>
                              </ul>

                              <h5 className={classnames('font-weight-bold', 'mt-2')}>
                                Here are the hobbies you may
                                list on your operations manager resume</h5>

                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Making friends</li>
                                <li>Yoga</li>
                                <li>Reading</li>
                                <li>Traveling</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>How to List Certifications
                                on Operations Manager&#8242;s Resume</h5>
                              <p className={classnames('mb-2')}>Certifications show that
                                you are qualified for the role you are
                                applying for. Always analyze the job
                                descriptions and add certifications that
                                are relevant to the employer&#8242;s job requirement</p>
                              <div className={classnames('card', 'p-3', 'px-lg-4', 'mt-5',
                                styles.ProTip, styles.interview_blockquote)}>
                                <h5 className={classnames('ml-5', 'font-weight-bold')}>Pro Tips</h5>
                                <ul className={classnames(styles.interview_points, 'mt-4', 'ml-5')}>
                                  <li>Always list the certifying authority,
                                    date, and name of certification</li>
                                  <li>List your certifications in reverse
                                    chronological order, starting
                                    with the current ones</li>
                                  <li>Highlight certifications
                                    in a separate section of your resume</li>
                                </ul>
                              </div>
                              <h5 className={classnames('font-weight-bold', 'mt-5', styles.align)}>
                                Example of How to Add Certifications on
                                Operations Manager&#8242;s Resume</h5>
                              <h5 className={classnames('font-weight-bold', 'mt-2', 'mb-1')}>Certifications</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Certified Operations Manager (COM),
                                  California Business School, California-January 2015.</li>
                                <li>Product Development Manager (PDM),
                                  Nickel College, Japan-February 2013</li>
                                <li>Cost Management (CM), Racecourse College,
                                  China-February 2008</li>
                              </ul>
                              <h3 className={classnames('font-weight-bold', 'mt-5', styles.align)}>
                                Top Professional Tips for Writing Operations Manager Resume</h3>
                              <h5 className={classnames('font-weight-bold', 'mt-3', 'mb-4')}>
                                Here are the tips for
                                writing an operations manager resume.</h5>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>
                                Relevant Experience
                              </h5>
                              <p className={classnames('mb-2')}>Ensure that experience
                                and accolades that you
                                highlight on your resume are relevant
                                to the position you are applying for.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>
                                Use relevant skills</h5>
                              <p className={classnames('mb-2')}>Analyze the job description to
                                identify the keywords. Use those keywords
                                to describe or highlight your skills.</p>

                              <h5 className={classnames('font-weight-bold', 'mt-2')}>
                                Quantifiable Achievements</h5>
                              <p className={classnames('mb-2')}>Use metrics, such as percentages
                                and numbers to quantify your achievements.
                                Example: &#8243;created process and procedures that
                                improved in-stock rates from 20% to over 50%.&#8243;</p>
                              <p className={classnames('mb-2')}>Note that achievements
                                and awards relevant
                                to the job speak louder than a high qualification,
                                especially if you can quantify them with a number.</p>

                              <h5 className={classnames('font-weight-bold', 'mt-2')}>
                                Demonstrate Your Unique Qualities</h5>
                              <p className={classnames('mb-2')}>Note that hiring managers deal
                                with numerous resumes. Therefore, ensure that your CV stands
                                out and avoid sounding non-appealing.
                                Example: &#8243;Managed hospitality, innovation, and personal
                                logistics between our firms and our suppliers.&#8243;</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>
                                Highlight your key skills</h5>
                              <p className={classnames('mb-2')}>Operations managers ought to
                                carry out numerous duties. They should be prepared to manage
                                costs and ensure that the company operates optimally.
                                Additionally, recruiters want to see a mixture
                                of hard and soft skills on your resume.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>
                                List your technical experience</h5>
                              <p className={classnames('mb-2')}>As an operation manager,
                                you should have technical knowledge. No matter the kind
                                of company you intend to work for, it is crucial
                                to possess some technical expertise.
                                Having skills in computer or information systems
                                will set you apart from your competitors.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>
                                List your interpersonal skills</h5>
                              <p className={classnames('mb-2')}>Business growth is achieved
                                through relationship management. Interpersonal skills are crucial
                                for relating to customers and colleagues at your workplace.
                                Recruiters value interpersonal skills
                                when selecting suitable candidates for the job.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>
                                Management experience</h5>
                              <p className={classnames('mb-2')}>An operations manager&#8242;s work
                                entails administrative roles. This encompasses managing business
                                operations or motivating staff. Therefore, the manager&#8242;s
                                experience is ideal for the operation management role.
                                It also shows your ability to work with others
                                to improve business performance.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>
                                Proofread and edit your resume</h5>
                              <p className={classnames('mb-2')}>Once you have crafted your
                                resume, proofread it to correct any mistakes.
                                You may refer your resume to a companion for
                                further check and editing.
                                This is a better way of being aware of any
                                errors that might have escaped your attention.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>
                                List your hobbies and interests</h5>
                              <p className={classnames('mb-2')}>Although many people argue
                                that hobbies and interests aren&#8242;t pertinent to your job,
                                this isn&#8242;t true. Hobbies and interests can help you
                                stand out and get recruited depending
                                on the various circumstances.
                                They can also show your ability to socialize
                                with clients and make them feel appreciated.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>
                                List genuine references</h5>
                              <p className={classnames('mb-2')}>Legitimate referees matter
                                a lot when it comes to job applications.
                                Referees are your witnesses – organizations need workers
                                that will drive clients to give them great reviews.
                                Adding reliable references on your resume
                                shows the recruiter that you can inspire people.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>
                                Highlight appropriate keywords</h5>
                              <p className={classnames('mb-2')}>Using the right keywords will
                                help you with getting found through the Applicant
                                Tracking System (ATS). Operations manager
                                keywords include employment titles,
                                such as client assistance, relationship management,
                                managers, and cost management.</p>
                              <p className={classnames('mb-2')}>It is advisable to optimize
                                your resume for the keywords search.
                                This will make your CV to be effortlessly
                                found whenever it is entered into
                                the information database.</p>

                            </div>
                          </Col>
                          <Col lg={4} xl={4} xs={12}>
                            <PromoCard loginWithRedirect={loginWithRedirect} />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      )
    }
}
