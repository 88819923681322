import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { get } from 'lodash'
import { getBlogImageUrl, getBlogVideoUrl } from '../../constants'
import styles from './style.scss'

class BlogRecentPosts extends Component {
static propTypes = {
  gotoBlogDetails: PropTypes.func.isRequired,
  recentBlogs: PropTypes.object.isRequired,
}

render() {
  const { recentBlogs, gotoBlogDetails } = this.props
  return (
    <div className={classnames(styles.recent_div)}>
      <div className={classnames(styles.siebar_title)}>
        <h3> Recent Posts </h3>
      </div>
      {recentBlogs && recentBlogs.length > 0 && recentBlogs.map((blog, index) => (
        <div className={classnames(styles.blog_recent_list)}>
          <div className={classnames(styles.blog_recent_post_thumb)}>
            <span onClick={() => gotoBlogDetails(get(blog, 'blogId'))}>
              {get(blog, 'media.type') === 'images' && (
              <img
                alt={'imageurl'}
                className={classnames(styles.blog_image_responsive)}
                src={getBlogImageUrl(get(blog, 'media.url'))} />
              )}
              {get(blog, 'media.type') === 'videos' && (
              <video
                className={classnames(styles.blog_image_responsive)}
                src={getBlogVideoUrl(get(blog, 'media.url'))} />
              )}
            </span>
          </div>
          <div className={classnames(styles.post_info)}>
            <p className={classnames(styles.post_info_recent_date)}>
              { get(blog, 'createdMonth') } { get(blog, 'createdDay') }, 20{ get(blog, 'createdYear') } </p>
            <p className={classnames(styles.post_info_recent_content)}> {get(blog, 'title')} </p>
          </div>
        </div>
      ))}
    </div>
  )
}
}
export default withRouter(BlogRecentPosts)
