/* eslint-disable react/prop-types */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { inject } from 'mobx-react'
import classnames from 'classnames'
import { get } from 'lodash'

import { registerForGoogleAnalytics } from '../../constants'
import { AppConstants } from '../../constants/application-constants'
import MainHeader from '../../components/common/MainHeader'
import ResumeListing from '../../components/ResumeListing'
import MainFooter from '../../components/common/MainFooter'
import Templates from '../../components/ResumeListing/Templates'

import styles from './style.scss'

@inject('mainModel', 'applicationModel')
class SEOContainer extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    mainModel: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    selectedKey: PropTypes.string,
  }

  static defaultProps = {
    selectedKey: 'sample',
  }

  componentDidMount(){
    const { history } = this.props
    registerForGoogleAnalytics(history)
  }

  loginWithRedirect = (redirectDetails) => {
    const { applicationModel, history } = this.props
    applicationModel.setRedirect(redirectDetails)
    if (applicationModel.getLogin()){
      const url = get(redirectDetails, 'redirectTo')
      window.location.assign(`${AppConstants.dashboardHost}${url}`)
    }
    else {
      history.push('/login')
    }
  }

  subscribe = (email, cb) => {
    const { mainModel } = this.props
    mainModel.subscribeNewSLetter(email, cb)
  }

  gotoURL = (url) => {
    const { history } = this.props
    history.push(url)
    window.scrollTo(0, 0)
  }

  getRenderComponent() {
    const { selectedKey, match, history } = this.props
    const selectedSubKey = get(match, 'params.key', '')
    switch (selectedKey) {
      case 'sample':
        return (
          <ResumeListing
            gotoURL={this.gotoURL} history={history}
            loginWithRedirect={this.loginWithRedirect}
            selectedSubKey={selectedSubKey} />
        )
      case 'templates':
        return <Templates loginWithRedirect={this.loginWithRedirect} selectedSubKey={selectedSubKey} />
      default:
        return <div>Default</div>
    }
  }

  render() {
    const { applicationModel } = this.props
    const isLoggedIn = applicationModel.getLogin()
    return (
      <div className={classnames(styles.main)}>
        <MainHeader
          gotoURL={this.gotoURL} isLoggedIn={isLoggedIn}
          loginWithRedirect={this.loginWithRedirect} logout={this.logout} />
        { this.getRenderComponent() }
        <MainFooter gotoURL={this.gotoURL} subscribe={this.subscribe} />
      </div>
    )
  }
}

export default withRouter(SEOContainer)
