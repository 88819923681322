import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Container, Row, Col, Button } from 'react-bootstrap'
import MetaTags from 'react-helmet'
import classnames from 'classnames'
import { get, set } from 'lodash'
import { SEO } from '../../../constants/constants'
import { Icon, IconType } from '../../common'
import styles from './style.css'

class ContactUs extends Component {
  static propTypes = {
    mainModel: PropTypes.object.isRequired,
  }

  constructor(props){
    super(props)
    this.state = {
      captchaImage: '',
      requestId: '1',
      details: {},
    }
  }

  componentDidMount(){
    this.getCaptcha()
  }

  getCaptcha = () => {
    const { mainModel } = this.props
    const callback = (captcha) => {
      this.setState({
        captchaImage: get(captcha, 'image').trim(),
      })
    }
    mainModel.generateCaptcha(callback)
  }

  contactUs = () => {
    const { mainModel } = this.props
    const { details, requestId } = this.state
    set(details, 'requestId', requestId)
    mainModel.postContactDetails(details)
  }

  updateDetailsField = (key, value) => {
    const { details } = this.state
    set(details, key, value)
    this.setState({
      details,
    })
  }

  render() {
    const { MAIL, PHONE, WHATSAPP } = IconType.COMMUNICATION
    const { REFRESH } = IconType.ACTION
    const { captchaImage, details } = this.state
    return (
      <Fragment>
        <MetaTags>
          <title>Contact | Your Resume queries answered | Resumaker</title>
          <meta content={SEO.DESCRIPTION} name="description" />
        </MetaTags>
        <div>
          <div className={classnames(styles.contact_banner)}>
            <Container className={classnames(styles.h1_heading)}>
              <h1 className={classnames(styles.contact_title)}> We are easy to find </h1>
              {/* <p className={classnames(styles.h1_heading_content)}>
                Resumaker gives highiest priority to customer satisfaction.
                We are in here to address your queries at earliest possible.
                Just drop us a note or call our customer support team,
                you will recieve response with much more details that asswers your query..!</p> */}
              <p className={classnames(styles.h1_heading_content)}>
                Resumaker gives high priority to customer satisfaction.
                We are here to address your queries at the earliest possible.
                Email us or call our customer support team, you will receive a response within 2 working days.</p>
            </Container>
          </div>
          <div className={classnames(styles.contact_form)}>
            <Container className={classnames(styles.contact_container)}>
              <h3> We&apos;d love to hear from you. please send us your query...! </h3>
              <Row>
                <Col className={classnames(styles.mt_20)} lg={6} md={6} sm={12} xl={6} xs={12}>
                  <input
                    id="name" name="name"
                    onChange={(e) => this.updateDetailsField('firstName', e.target.value)}
                    placeholder="Your Name" type="text" value={details.firstName} /></Col>
                <Col className={classnames(styles.mt_20)}>
                  <input
                    id="email" name="email"
                    onChange={(e) => this.updateDetailsField('email', e.target.value)}
                    placeholder="Your Email Here" type="email" value={details.email} />
                </Col>
              </Row>
              <Row>
                <Col className={classnames(styles.mt_20)} lg={3} md={3} sm={12} xl={3} xs={12}>
                  <input
                    id="phone" name="phone"
                    onChange={(e) => this.updateDetailsField('phone', e.target.value)}
                    placeholder="Phone" type="text" value={details.phone} /></Col>
                <Col className={classnames(styles.mt_20)} lg={3} md={3} sm={12} xl={3} xs={12}>
                  <input
                    id="city" name="city"
                    onChange={(e) => this.updateDetailsField('city', e.target.value)}
                    placeholder="City" type="text" value={details.city} /></Col>
                <Col
                  className={classnames(styles.mt_20)}
                  lg={6} md={6} sm={12} xl={6} xs={12}>
                  <input
                    id="subject" name="subject"
                    onChange={(e) => this.updateDetailsField('subject', e.target.value)}
                    placeholder="Your Subject Here" type="text" value={details.subject} /></Col>
              </Row>

              <Row>
                <Col className={classnames(styles.mt_20)} lg={6} md={6} sm={12} xl={6} xs={12}>
                  <div>
                    <textarea
                      className={classnames(styles.text_area)} id="comment"
                      onChange={(e) => this.updateDetailsField('message', e.target.value)}
                      rows="5" value={details.message} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className={classnames(styles.mt_20)} lg={2} md={2} sm={5} xl={2} xs={4}>
                  <div>
                    <input
                      id="name" name="captcha"
                      onChange={(e) => this.updateDetailsField('capcha', e.target.value)}
                      placeholder="Captcha" type="text" value={details.captcha} />
                  </div>
                </Col>

                <Col className={classnames(styles.mt_20)} lg={2} md={2} sm={5} xl={2} xs={4}>
                  <div className={classnames(styles.capcha_img)}>
                    <img
                      alt={'Blog'} className={classnames(styles.blog_image_responsive)}
                      src={`data:image/png;base64,${captchaImage}`} />
                  </div>
                </Col>

                <Col className={classnames(styles.mt_20)} lg={1} md={1} sm={2} xl={1} xs={1}>
                  <div>
                    <Button
                      className={classnames(styles.btn_primary, styles.btn_refersh)}
                      onClick={this.getCaptcha}
                      type="button">
                      <Icon color={'#f1f1f1'} names={[REFRESH]} styles={'icon15 darkGrayAlt'} />
                    </Button>
                  </div>
                </Col>
              </Row>
              <Button
                className={classnames(styles.btn_primary, styles.btn_submit_contact)}
                onClick={this.contactUs}>
                Submit
              </Button>
            </Container>

            <Container className={classnames(styles.contact_details_box_container)}>
              <Row className={classnames(styles.box_contact)}>
                <Col className={classnames(styles.contact_details_col)} lg={4} md={4} sm={12} xl={4} xs={12}>
                  <div className={classnames(styles.contact_details_box)}>
                    <p> <span className={classnames(styles.contact_details_icon)}>
                      <Icon color={'#ee2d50'} names={[WHATSAPP]} styles={'icon25 darkGrayAlt'} />
                    </span> +91 9121 900 900 </p>
                  </div>
                </Col>
                <Col className={classnames(styles.contact_details_col)} lg={4} md={4} sm={12} xl={4} xs={12}>
                  <div className={classnames(styles.contact_details_box)}>
                    <p> <span className={classnames(styles.contact_details_icon)}>
                      <Icon color={'#ee2d50'} names={[PHONE]} styles={'icon25 darkGrayAlt'} />
                    </span> +91 9121 900 900 </p>
                  </div>
                </Col>
                <Col className={classnames(styles.contact_details_col)} lg={4} md={4} sm={12} xl={4} xs={12}>
                  <div className={classnames(styles.contact_details_box)}>
                    <p> <span className={classnames(styles.contact_details_icon)}>
                      <Icon color={'#ee2d50'} names={[MAIL]} styles={'icon25 darkGrayAlt'} />
                    </span> support@resumaker.co.in </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default withRouter(ContactUs)
