// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".resume_download___4oOFG{position:absolute;bottom:0px;right:0px;background-image:url(\"/assets/images/resume-download.png\");background-size:cover;background-repeat:no-repeat;background-position:bottom right}.resume_download___4oOFG{width:235px;height:264px}\n", "",{"version":3,"sources":["C:/AARWII/aarwii/Git/resumaker-web-app/public-web-app/src/components/common/Download/style.scss"],"names":[],"mappings":"AAAA,yBAGE,iBAAkB,CAClB,UAAW,CACX,SAAU,CACV,0DAA2D,CAC3D,qBAAsB,CACtB,2BAA4B,CAC5B,gCAAiC,CAClC,yBAGC,WAAY,CACZ,YAAa","file":"style.scss","sourcesContent":[".resume_download {\r\n  //width: 134px;\r\n  //height: 150px;\r\n  position: absolute;\r\n  bottom: 0px;\r\n  right: 0px;\r\n  background-image: url('/assets/images/resume-download.png');\r\n  background-size: cover;\r\n  background-repeat: no-repeat;\r\n  background-position: bottom right;\r\n}\r\n\r\n.resume_download {\r\n  width: 235px;\r\n  height: 264px;\r\n}\r\n"]}]);
// Exports
exports.locals = {
	"resume_download": "resume_download___4oOFG",
	"resumeDownload": "resume_download___4oOFG"
};
module.exports = exports;
