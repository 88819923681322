import React, { Component } from 'react'
import classnames from 'classnames'
import { Container, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import PromoCard from './PromoCard'
import Download from '../../common/Download'
import GetStartedButton from '../../MainContent/Home/GetStartedButton'
import { getDocUrl } from '../../../constants'
import styles from './style.scss'

export default class Nurse extends Component {
    static propTypes = {
      history: PropTypes.object.isRequired,
      loginWithRedirect: PropTypes.func.isRequired,
    }

    render() {
      const { loginWithRedirect, history } = this.props
      return (
        <div>
          <Container fluid>
            <div className={classnames('page-main-wrapper')}>
              <div className={classnames(styles.container_xl, 'mt-5', 'mb-5')}>
                <div className={classnames(styles.page_content_header, 'row', 'align-items-center')}>
                  <Col lg={12} md={12} xl={12} xs={12}>
                    <div className={classnames('text-center', 'mx-auto')}>
                      <img
                        alt="fresher resume" className={classnames(styles.resume_sample_vector, 'img-fluid', 'mx-auto')}
                        src="/assets/images/nurse_vector.jpg" />
                      <div
                        className={classnames('mt-md-5', 'text-center', 'mb-md-4')}>
                        <h1 className={classnames('mt-4', styles.size_heading)}> Nurse Resume Example  </h1>
                        <p className={classnames('mb-0')}>Nursing is one of the best
                          professions with immense opportunities in the job market.
                          There are many employment opportunities
                          in the nursing profession, yet getting
                          one can be a challenge because of the competition.
                          In this guide, you will get a nurse resume summary,
                          how to feature skills and experience on a resume,
                          how to list a nurse manager&rsquo;s accomplishments,
                          and top tips for composing a nurse’s resume.</p>
                      </div>
                      <div className={classnames(styles.btn_gs)}>
                        <GetStartedButton label="Build My Resume" loginWithRedirect={loginWithRedirect} size="lg" />
                      </div>
                    </div>
                  </Col>
                  <div className={classnames(styles.resume_sample_container, 'mt-5', 'pt-3', 'pt-lg-4')}>
                    <Row>
                      <Col lg={6}>
                        <div className={classnames('mb-5', 'p-xl-5', 'mb-xl-0')}>
                          <div className={classnames(styles.resume_sample)}>
                            <img
                              alt="Resume Sample" className={classnames(styles.resume_sample_img)}
                              src="/assets/images/Nurse_Resume.jpg" />
                            <Download
                              downloadURL={getDocUrl('assets/docs/nurse-resume.docx')}
                              history={history} />
                          </div>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className={classnames('mb-5', 'p-xl-5', 'mb-xl-0')}>
                          <div className={classnames(styles.resume_sample)}>
                            <img
                              alt="Resume Sample" className={classnames(styles.resume_sample_img)}
                              src="/assets/images/Nurse_cl.jpg" />
                            <Download
                              downloadURL={getDocUrl('assets/docs/nurse-cl.docx')}
                              history={history} />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className={classnames(styles.resume_sample_header, 'text-center')}>
                      <div className={classnames(styles.container_xl)}>
                        <h2 className={classnames(styles.section_heading)}>
                          Nurse Resume Example (Text Format)
                        </h2>
                        <p className={classnames('mt-2', styles.section_paragraph)}>
                          Make sure you choose the right resume
                          format to suit your unique experience and the job you are applying.</p>
                      </div>
                    </div>
                    <div className={classnames(styles.resume_sample_wrapper)}>
                      <div className={classnames(styles.container_xl)}>
                        <Row>
                          <Col lg={8} xl={8} xs={12}>
                            <div className={classnames(styles.resume_sample_main)}>
                              <div className={classnames(styles.tab_content)}>
                                Text View
                              </div>
                            </div>
                            <div className={classnames(styles.resume_content_card,
                              styles.dz_card, styles.shadow_1, 'px-3', 'px-lg-5', 'py-5', 'tab-pane active')}>
                              <Row>
                                <h2 className={classnames(styles.section_heading, 'ml-2')}>
                                  Nurse Resume
                                </h2>
                              </Row>
                              <div className={classnames(styles.resume_content_title, 'mt-3')}>Summary</div>
                              <p className={classnames('mt-2', 'mb-2')}>
                                Knowledgeable Nurse Manager with excellent
                                conflict resolution and
                                problem-solving abilities demonstrated across
                                10 years of career history. Keeps units running at
                                peak efficiency, quality and performance.
                                Excellent communication and coordination skills.
                              </p>
                              <div className={classnames(styles.resume_content_title)}>
                                Work Experience</div>
                              <ul className={classnames(styles.interview_points, 'list-unstyled',
                                styles.content_title, 'mt-4')}>
                                <li className={classnames('mb-1')}>Head Nurse</li>
                                <li>ABC Hospital, Belas-Jan 2020 – till date</li>
                              </ul>
                              <ul className={classnames(styles.interview_points, 'mt-2')}>
                                <li>Strengthened unit care standards and policies with
                                  a focus on patient security and dignity.</li>
                                <li>Performed patient assessments and tailored care plans accordingly.</li>
                                <li>Identified unit areas of shortcomings and adjusted procedures
                                  to close gaps and improve policies.</li>
                                <li>Maximized team performance by monitoring nursing care, including
                                  patient assessment processes and treatment implementations. </li>
                                <li>Trained new nurses in proper techniques, care standards, norms, operational
                                  procedures and safety protocols to optimize performance and safety.</li>
                                <li>Kept optimal supply levels in treatment rooms, triage and
                                  other areas to meet typical patient loads.</li>
                              </ul>
                              <ul className={classnames(styles.interview_points, 'list-unstyled',
                                styles.content_title, 'mt-4')}>
                                <li className={classnames('mb-1')}>Nurse Assistant</li>
                                <li>ABC Hospital, Belas-Jan 2020 – till date</li>
                              </ul>
                              <ul className={classnames(styles.interview_points, 'mt-2')}>
                                <li>Gathered dietary information,
                                  assisted with feeding and monitored
                                  intake to help patients achieve nutritional
                                  goals and support wellness goals.</li>
                                <li>Helped patients effectively manage
                                  routine bathing, grooming and other hygiene needs.</li>
                                <li>Monitored, tracked and carried out important
                                  patient information to healthcare staff to help
                                  optimize treatment planning and care delivery.</li>
                                <li>Evaluated patient&rsquo;s progress on treatment, communicating
                                  observations to physician on duty to maintain
                                  or improve prescribed care.</li>
                                <li>Utilized nursing knowledge to identify patient
                                  needs and offer appropriate remedies.</li>
                              </ul>
                              <div className={classnames(styles.resume_content_title)}>
                                Skills
                              </div>
                              <ul className={classnames(styles.interview_points, 'mt-4')}>
                                <li>Medical record auditing</li>
                                <li>Patient care planning</li>
                                <li> Process evaluation</li>
                                <li> Resource Allocation </li>
                                <li> Direct Patient Care</li>
                                <li>Clinical Training </li>
                                <li> Staff leadership</li>
                                <li>Case Management</li>
                              </ul>
                              <div className={classnames(styles.resume_content_title, 'mt-3')}>
                                AWARDS </div>
                              <ul className={classnames(styles.interview_points, 'mt-4')}>
                                <li>Best Nurse Award 2015</li>
                                <li>Awarded as Top Manager of Medical</li>
                              </ul>
                              <div className={classnames(styles.resume_content_title)}>
                                Hobbies
                              </div>
                              <ul className={classnames(styles.interview_points, 'mt-4')}>
                                <li>Social Work</li>
                                <li>Gardening</li>
                                <li>Travelling</li>
                              </ul>
                            </div>
                            <div className={classnames(styles.resume_sample_header, 'pb-5')}>
                              <h2 className={classnames(styles.section_heading, 'mb-2')}>
                                How to Write How to Write a Nurse Resume</h2>
                              <p>In this guide, you will get
                                bits of knowledge into a
                                nurse resume summary, how to feature skills and
                                experience on a resume, how to list a
                                nurse manager&rsquo;s accomplishments, and top tips
                                for composing a nurse’s resume.</p>
                            </div>
                            <div
                              aria-labelledby="resumeContentTab"
                              className={classnames(styles.resume_content_card,
                                styles.dz_card, styles.shadow_1, 'px-0', 'px-lg-5', 'py-5', 'tab-pane active')}
                              id="resumeContent" role="tabpanel">
                              <h3 className={classnames(styles.resume_content_heading, 'mt-0')}>
                                How to Write a Nurse Resume Summary</h3>
                              <p className={classnames('mb-3')}>A perfect nursing resume
                                summary is succinct and
                                simple to peruse. It matches the applicant&rsquo;s
                                experience with the job descriptions. Additionally,
                                it arouses the reader&rsquo;s curiosity to
                                the point that they will continue
                                reading the resume further.
                                Many people write resume summaries
                                in paragraph form. This is ideal when you want
                                to capture a lot of content
                                in a more compact space. However, with such a format,
                                the reviewer takes a lot
                                of time to peruse your summary.
                                If you want your summary to more
                                readable, use bullet points to allow
                                hiring managers to scan
                                through it quickly and pick up your points.</p>
                              <h5 className={classnames('font-weight-bold')}>Here is what to include
                                in a resume summary.</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Level of experience</li>
                                <li>Your qualifications for the position</li>
                                <li>Individual attributes that make you a solid
                                  match for the work as well as organization.</li>
                                <li> Professional accomplishments.</li>
                                <li>Education, certifications, or remarkable
                                  experience that might
                                  make you unique.</li>
                                <li>Quantifiable improvements
                                  that you&rsquo;ve made for past bosses.</li>
                                <li>Honors or awards that
                                  you&rsquo;ve gotten from past employers.</li>
                              </ul>
                              <p>When composing content in your
                                summary, avoid standalone
                                clichés, such as &ldquo;proactive&rdquo; or &ldquo;team player&rdquo;.
                                Instead, attempt to pass on
                                these ideas with solid examples.
                                As a team player, you may
                                mention that you attended
                                to 100 patients daily, or as a
                                result-oriented person, you achieved a 70% success rate.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>
                                Sample Nursing Resume Summary</h5>
                              <h5 className={classnames('ml-3', 'font-weight-bold', 'mt-4')}>Good Example</h5>
                              <blockquote><p className={classnames(styles.resume_rank, 'ml-0')}>
                                Enrolled Nurse with more
                                than seven years of experience.
                                Specialized in pharmacology, neurology, gynecology,
                                and intensive care.
                                <ul className={classnames(styles.interview_points, 'mt-4')}>
                                  <li>Broad experience
                                    and extensive comprehension of
                                    pathophysiology and
                                    pharmacology of critically sick patients.</li>
                                  <li>Accorded legitimacy awards as an exceptionally
                                    competent patient or family instructor.</li>
                                  <li>Elevated to Charge Nurse as a result of
                                    demonstrating excellent leadership skills.</li>
                                  <li>A member of the Canadian Association of
                                    Critical Care Nurses (CAACN) since 2020.</li>
                                </ul>
                              </p></blockquote>
                              <h5 className={classnames('ml-3', 'font-weight-bold')}>Bad Example</h5>
                              <blockquote><p className={classnames(styles.resume_rank, 'ml-0')}>
                                I am a pediatric registered nurse with vast experience
                                managing the medication and care of newborns.
                              </p></blockquote>
                              <h3 className={classnames(styles.resume_content_heading, 'mt-0')}>
                                Nursing Resume Objective</h3>
                              <p className={classnames('mb-3')}>Resume objectives are meant for candidates who
                                don&rsquo;t have a ton of hands-on experience
                                but have achieved training and career goals.
                                However, candidates with industry experience
                                ought to utilize a resume summary.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2', 'mb-3')}>
                                Example of Nursing Resume Objective</h5>
                              <h5 className={classnames('ml-2', 'font-weight-bold')}>Good Example</h5>
                              <blockquote><p className={classnames(styles.resume_rank, 'ml-0')}>
                                Reliable authorized RN qualified to work
                                in high-stress conditions and remain calm under tension.
                                Looking to use fastidious
                                record-keeping skills to get nursing experience.
                              </p></blockquote>
                              <h5 className={classnames('ml-2', 'font-weight-bold')}>Bad Example</h5>
                              <blockquote><p className={classnames(styles.resume_rank, 'ml-0')}>
                                Recently authorized RN searching for a challenging nursing role in
                                a clinical office where I can scrutinize my abilities.
                              </p></blockquote>
                              <div className={classnames('card', 'p-3', 'px-lg-4', 'mt-5',
                                styles.ProTip, styles.interview_blockquote)}>
                                <h5 className={classnames('ml-5', 'font-weight-bold')}>Pro Tip </h5>
                                <p className={classnames('ml-5')}>
                                  Use metrics to quantify your achievements.
                                  Example: “Attended to an average of 100 patients per day.”.</p>
                              </div>
                              <h3 className={classnames(styles.resume_content_heading, 'mt-5', 'mb-4')}>
                                How to Highlight Skills  in a Nurse Resume
                              </h3>
                              <img
                                alt="Nurseimage" className={classnames('mt-2', 'mb-3')}
                                src="/assets/images/nurse_skill.jpg" />
                              <p className={classnames('mb-2')}>As a nursing professional, you are required
                                to have a broad scope of
                                both hard and soft skills to be successful.
                                The skills part of your resume shows
                                potential employers that you have the capacities
                                to be fruitful in the job you are applying for.
                                Picking the right skills to
                                feature on your nursing resume can help you stand
                                out as the best candidate for hiring managers.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-0', styles.align)}>
                                Hard versus soft
                                nursing abilities</h5>
                              <p className={classnames('mb-2')}>The essential contrast between hard
                                and soft skills is the way we
                                pick up them and how we utilize them.
                                Hard skills refer to technical skills
                                that can be acquired through schooling.
                                Soft skills relate more to the character
                                attributes we have picked up
                                and created all through our lifetime.</p>
                              <p className={classnames('mb-0')}>Your resume ought to incorporate
                                both hard and soft skills
                                to make you stand out as the best
                                candidate before your recruiters.</p>

                              <h5 className={classnames('font-weight-bold', 'mt-2', 'mb-3')}>
                                Examples of Hard &amp; Soft Skills and
                                How to Highlight the on a Resume</h5>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}> Technology skills</h5>
                              <p className={classnames('mb-2')}>Medical nurses may utilize
                                technology to share patient and family schooling data,
                                screen fundamental signs, and keep up
                                patient records. Emergency
                                clinics are moving
                                towards electronic clinical record systems.
                                On your resume, show the clinical
                                technology skills you have. For example,
                                you may say that you have done
                                medical technology skills and computer-related programs.
                                Example: “Excellent medical
                                technology skills, ability to maintain
                                and retrieve patient records
                                using the medical record system.”</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Patient safety skills</h5>
                              <p className={classnames('mb-2')}>Understanding patient safety
                                skills will help limit the danger of patient harm, boost recovery,
                                and shield clinical staff from obligation issues.
                                On your resume, show the
                                degree of your patient safety skills.
                                Example: &ldquo;Endowed with superb patient
                                safety skills, capacity to establish safe
                                conditions that minimize
                                the danger of falls, preventing blunders,
                                and learning from mistakes when they happen.&rdquo;</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Emergency care skills</h5>
                              <p className={classnames('mb-2')}>Featuring your emergency care
                                skills on your resume shows that you are fit for
                                dealing with crises and
                                complex circumstances.
                                Example: &ldquo;Endowed with sufficient
                                crisis care abilities, proficient at reacting
                                instantly to clinical
                                issues a patient is encountering.&rdquo;</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Communication skills</h5>
                              <p className={classnames('mb-2')}>Nurses need to
                                discuss adequately with patients, the patient&rsquo;s
                                families, specialists,
                                and other clinical staff.
                                Ensure you feature the degree of
                                your verbal, non-verbal, and written skills.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Example</h5>
                              <p className={classnames('mb-2')}>&ldquo;Possess great communication skills,
                                able to listen, observe,
                                and emphasize with patients.&rdquo;</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Critical thinking skills</h5>
                              <p className={classnames('mb-2')}>Critical thinking entails the
                                capacity to deal with complex circumstances
                                in the working environment and
                                smoothly develop creative solutions.
                                Highlight your critical-thinking skills
                                on a resume and show their
                                relevance to your work situation.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Example</h5>
                              <p className={classnames('mb-2')}>&ldquo;Endowed with excellent
                                problem-solving skills, ready to think rapidly,
                                gauge advantages and disadvantages
                                and make informed choices when a patient&rsquo;s
                                life is threatened.&rdquo;</p>
                              <div className={classnames('card', 'p-3', 'px-lg-4', 'mt-5',
                                styles.ProTip, styles.interview_blockquote)}>
                                <h5 className={classnames('ml-5', 'font-weight-bold')}>Pro Tip
                                </h5><p className={classnames('ml-5')}>
                                  Ensure you use a combination of hard and soft skills on your resume.
                                  Additionally, always mirror the skills and keywords from the job descriptions.</p>
                              </div>
                              <h3 className={classnames(styles.resume_content_heading, 'mt-5', 'mb-2')}>
                                How to Write Work Experience in Nurse Resume</h3>
                              <p className={classnames('mb-3')}>Work experience is one of
                                the main segments in your resume.
                                Furthermore, your clinical aptitudes are very significant,
                                but so is your expert experience.
                                Your potential employer may be
                                interested to know whether you are familiar
                                with working in a hospital environment.</p>
                              <h5 className={classnames('font-weight-bold')}>
                                Here’s how to structure your work experience section.</h5>
                              <ul className={classnames(styles.interview_points, styles.content_list, 'mt-3')}>
                                <li>Position name</li>
                                <li>Organization Name</li>
                                <li>Dates</li>
                                <li>Duties and Achievements</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold')}>
                                Example</h5>
                              <ul className={classnames(styles.interview_points, styles.content_list,
                                'list-unstyled', 'mt-3')}>
                                <li>Medical Nurse</li>
                                <li>Netizen Hospital</li>
                                <li>02/2013 - 07/2020</li>
                              </ul>
                              <ul className={classnames(styles.interview_points, 'mb-2')}>
                                <li>Recorded patient&rsquo;s imperative signs,
                                  blood pressure, and glucose levels</li>
                                <li>Worked in oncology wards of
                                  the emergency clinic for three years</li>
                                <li>Utilized EHR programming to record patient data</li>
                              </ul>
                              <p className={classnames('mb-2')}>If you want to stand out,
                                you should highlight your
                                achievements rather than responsibilities.</p>
                              <p className={classnames('mb-0')}>For instance, rather than saying,
                                &ldquo;Screened patients upon
                                landing in crisis ward,&rdquo; say, &ldquo;Managed crisis
                                ward intake assessments for up to 25 patients every hour
                                by measuring vital signs and organizing cases as needs are.&rlquo;</p>
                              <h4 className={classnames('font-weight-bold', 'mt-3')}>
                                What If You Don’t Have Work Experience?</h4>
                              <p className={classnames('mb-2')}>If you are a current student
                                attempting to land an entry-level position, don’t worry;
                                there is a way of highlighting your
                                work experience. You may not be an enrolled nurse now; you
                                can incorporate the skills
                                and techniques you&rsquo;ve learned in school on your resume</p>
                              <h4 className={classnames('font-weight-bold', 'mt-3')}>
                                What To Include In The Experience Section?</h4>
                              <p className={classnames('mb-2')}>If you want your resume to stand out, here
                                is what to include in the experience area.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>
                                Unit Type</h5>
                              <p className={classnames('mb-2')}>Indicate whether you have worked
                                in an Emergency Room, Intensive Care Unit (ICU), or
                                Labor and Delivery Unit (L &amp; D).
                                Additionally, incorporate data about the
                                admission of injury patients or
                                overflow patients from different units.
                                With such information, the recruiters will
                                know the kind of patients you can deal with,
                                hence increasing your chances of getting employed.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>
                                Facility Type</h5>
                              <p className={classnames('mb-2')}>Different facilities
                                have various conditions and daily assignments.
                                Nurses likewise work in various facilities.
                                For example, emergency care facilities
                                are busy with the entry and exit of patients.
                                However, nursing homes have perpetual
                                inhabitants that require long-term attendance.</p>
                              <p className={classnames('mb-2')}>On your resume, highlight the
                                kind of facility where you have been working.
                                This will enable recruiters to make decisions on your suitability.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>Sample Nursing Resume
                                Experience Section</h5>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>Right Sample</h5>
                              <ul className={classnames(styles.interview_points, styles.content_list,
                                'list-unstyled', 'mt-3')}>
                                <li className={classnames('mb-1')}>RN Case Manager, Labor &amp; Delivery Unit</li>
                                <li className={classnames('mb-1')}>March 2015 – June 2017</li>
                                <li>Norter Community Hospital, New Delhi</li>
                              </ul>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Managed all patient care given by Registered Nurses
                                  and Nurse Technicians handling 30-bed unit</li>
                                <li>Interviewed new nurses to ensure that their experience
                                  and abilities satisfied the needs of our unit.</li>
                                <li>Dealt with the unit&rsquo;s income
                                  and spending plan, including the allocation
                                  of assets for patient care, equipment, and staff supplies.</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>Wrong Sample</h5>
                              <ul className={classnames(styles.interview_points, styles.content_list,
                                'list-unstyled', 'mt-3')}>
                                <li className={classnames('mb-1')}>RN Case Manager, Labor &amp; Delivery Unit</li>
                                <li className={classnames('mb-1')}>March 2015 – June 2017</li>
                                <li>October 2010 - Present</li>
                              </ul>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Administered initial nursing
                                  evaluations of new patient admissions.</li>
                                <li>Interviewed nursing candidates.</li>
                                <li>Took care of unit accounting.</li>
                              </ul>
                              <div className={classnames('card', 'p-3', 'px-lg-4', 'mt-5',
                                styles.ProTip, styles.interview_blockquote)}>
                                <h5 className={classnames('ml-5', 'font-weight-bold')}>Pro Tip
                                </h5><p className={classnames('ml-5')}>
                                  Highlighting your experience isn’t enough to attract your
                                  recruiter&rsquo;s attention. It is advisable to highlight your
                                  area of expertise as well.
                                  For example, don&rsquo;t say, &ldquo;I have 10+ years of work experience
                                  as a nurse,&rdquo; but say,
                                  &ldquo;Emergency department nurse with 10+ years of work experience
                                  in labor and delivery unit.&rdquo;</p>
                              </div>
                              <h3 className={classnames(styles.resume_content_heading, 'mt-5')}>
                                How to write a Nurse Manager&rsquo;s Achievements,
                                Hobbies, Training and certifications on Resume</h3>
                              <p className={classnames('mb-3')}>
                                Adding accomplishments, achievements,
                                and awards to your resume show how well you acted in your work.
                                This will make you ahead of your competitors.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>Achievement Section</h5>
                              <p className={classnames('mb-2')}>Highlighting your professional
                                achievements on a resume
                                is the best way of making each employer enlist you.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-0', 'mb-2')}>
                                Example
                              </h5>
                              <ul className={classnames(styles.interview_points, 'mt-2')}>
                                <li>Oversaw the decrease of wrong
                                  medicine organization by 80%</li>
                                <li>Awarded for providing high-quality
                                  in-patient care with a delivery unit
                                  with 5,000 delivery per year</li>
                              </ul>
                              <div className={classnames('card', 'p-3', 'px-lg-4', 'mt-5',
                                styles.ProTip, styles.interview_blockquote)}>
                                <h5 className={classnames('ml-5', 'font-weight-bold')}>Pro Tip
                                </h5><p className={classnames('ml-5')}>
                                  Highlight hobbies that relate to the position
                                  you are applying for, and list them toward the bottom of your resume.</p>
                              </div>
                              <h5 className={classnames('font-weight-bold', 'mb-2', 'mt-5')}>
                                Interests and Hobbies</h5>
                              <p className={classnames('mb-2')}>Your resume hobbies and interests can truly
                                differentiate you from a multitude of job seekers,
                                and make your resume perfect.
                                Incorporate alluring interests
                                that are significant to the organization.
                                Highlight hobbies that show clear characteristics of your personality.
                                Your interest, such as hiking,
                                has something to do with you.
                                Likewise, teamwork is fundamental
                                in the medical care field,
                                so specialists and doctors are searching for somebody they&rsquo;ll
                                coexist with.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>Examples of hobbies:</h5>
                              <ul className={classnames(styles.interview_points, 'mt-2')}>
                                <li>Travelling</li>
                                <li>Climbing</li>
                                <li>Music</li>
                                <li>Yoga</li>
                              </ul>
                              <h4 className={classnames('font-weight-bold')}>
                                How to List Certifications on a Nurse Resume</h4>
                              <h5 className={classnames('font-weight-bold', 'mb-2', 'mt-3')}>
                                Certifications for a Nurse Resume</h5>
                              <p className={classnames('mb-2')}>When featuring
                                certifications, don&rsquo;t
                                just list abbreviations but
                                highlight their full meaning as well.
                                This is because the applicant tracking
                                system (ATS) may not be customized to peruse abbreviated content.
                                Try to list the certifying body,
                                accreditation/confirmation number (where appropriate),
                                and the lapse date.</p>
                              <p className={classnames('mb-2')}>Numerous nurses
                                will accomplish exceptional
                                certifications as they
                                progress in their professions.
                                These oughts to be featured in your resume.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>Example of
                                certifications:</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li className={classnames('mb-1')}>
                                  CPR certified through the Canadian Heart Association</li>
                                <li className={classnames('mb-0')}>
                                  Qualified Holistic Nurse, Board Certified</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Here are the tips on the best way
                                to list certifications on your resume</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>List certifications that are pertinent to the
                                  position you are applying for.</li>
                                <li>Give details of certifying
                                  authorities, their areas, and dates of accreditation.</li>
                                <li>List your certifications in opposite
                                  chronological order, beginning with the current ones.</li>
                              </ul>
                              <h3 className={classnames('font-weight-bold', 'styles.align', 'mb-3')}>
                                Top Professional Tips for Writing a Nurse Resume</h3>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Here are the tips for composing a perfect nursing manager resume.</h5>
                              <p className={classnames('mb-2')}>
                                Emphasize your significant nursing qualifications
                                note that degrees, licenses, and certifications are
                                essential for recruitment and career growth.</p>
                              <p className={classnames('mb-2')}>
                                Add all degrees, such as RN and LPN, after your name so they
                                show up in enormous textual style at the top of your resume.
                                Ensure you list certifications in the
                                qualifications summary below your resume’s headline.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Exhibit your top performance</h5>
                              <p className={classnames('mb-2')}>
                                Focus on your achievements in every business or entry-level position.
                                Achievements may incorporate experience
                                working with challenging patients,
                                hazardous ailments and wounds, and chronic conditions.</p>
                              <p className={classnames('mb-2')}>
                                Note that achievements aren&rsquo;t
                                restricted to formal acknowledgement.
                                Consider ways you exceeded any
                                expectations, delivered outstanding quality care,
                                enhanced patient fulfillment, improved security, or brought down expenses.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Demonstrate that you are well-rounded</h5>
                              <p className={classnames('mb-2')}>Although it&rsquo;s
                                significant for nurses to have
                                skills and training in their areas,
                                employers prefer candidates who fit the work culture.</p>
                              <p className={classnames('mb-2')}>A well-rounded person could be the one
                                with community service or leadership experience.
                                Additionally, practising philanthropy
                                reflects a candidate&rsquo;s character and can make
                                the recruiters anxious to talk with you.
                                You can feature volunteer experience if you have it.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Put yourself in a recruiter&rsquo;s shoes</h5>
                              <p className={classnames('mb-2')}>Adopt the thought process of a
                                recruiter by including details that will help them assist
                                you in your pursuit of employment. Give a concise description
                                of employers, such as a 300-bed recovery medical clinic,
                                so employing administrators don&rsquo;t need to look into specifics.</p>
                              <h4 className={classnames('font-weight-bold')}>Alter, refine, and update</h4>
                              <p className={classnames('mb-2')}>A decent resume is
                                that is continually evolving. You need to refresh your
                                resume after any type of professional development,
                                such as acquiring certifications.</p>
                            </div>
                          </Col>
                          <Col lg={4} xl={4} xs={12}>
                            <PromoCard loginWithRedirect={loginWithRedirect} />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      )
    }
}
