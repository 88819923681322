import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { inject } from 'mobx-react'
import PropTypes from 'prop-types'
import { Container, Row, Col, InputGroup, FormControl, Button } from 'react-bootstrap'
import { get, set, replace, times, forEach } from 'lodash'
import classnames from 'classnames'
import ReactHtmlParser from 'react-html-parser'
import MetaTags from 'react-helmet'

import { getBlogImageUrl, getBlogVideoUrl } from '../../constants'
import BlogDetail from './BlogDetail'
import BlogCategory from './BlogCategory'
import BlogRecentPosts from './BlogRecentPosts'
import BlogTags from './BlogTags'
import { BLOG_KEY_ROUTE } from '../../router'
import { Icon, IconType } from '../common'
import styles from './style.scss'

@inject('mainModel')
class Blog extends Component {
  static propTypes = {
    filter: PropTypes.object,
    history: PropTypes.object.isRequired,
    mainModel: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    subscribe: PropTypes.func.isRequired,
  }

  static defaultProps = {
    filter: {},
  }

  constructor(props) {
    super(props)
    this.state = {
      blogList: [],
      recentBlogList: [],
      totalPages: 1,
      pageNumber: 1,
      subscriptionEmail: '',
      tag: undefined,
      category: get(props, 'filter.category'),
      searchFilter: undefined,
    }
  }

  componentDidMount() {
    const { mainModel, match } = this.props
    const selectedSubKey = get(match, 'params.key', '')
    if (selectedSubKey === '') {
      this.getBlogList()
      const callback = (recentBlogList) => {
        this.setState({
          recentBlogList,
        })
      }
      mainModel.getRecentBlogs(callback)
    }
    window.scrollTo(0, 0)
    // this.autoPopup = setInterval(() => this.initiateAutoPopup(), 10000)
  }

  // componentWillUnmount() {
  //   clearInterval(this.autoPopup)
  // }

  // initiateAutoPopup = async () => {
  //   const result = await popUpService.show({
  //     content: 'Offer Details are provided ',
  //     yesActionText: 'Continue',
  //     noActionText: 'Cancel',
  //   })
  //   if (result){
  //     console.log('Result::', result)
  //   }
  //   clearInterval(this.autoPopup)
  // }

  getRelatedBlogs = (callback) => {
    const { mainModel } = this.props
    mainModel.getRelatedBlogs(callback)
  }

  gotoBlogDetails = (blogId) => {
    const { history } = this.props
    const url = `${replace(BLOG_KEY_ROUTE, ':key', blogId)}`
    history.push(url)
    window.scrollTo(0, 0)
  }

  getBlogList = () => {
    const { mainModel } = this.props
    const { pageNumber, tag, category, searchFilter } = this.state
    const cb = (blogList, totalPages) => {
      this.setState({
        blogList,
        totalPages,
      })
    }
    mainModel.getBlogList({ pageNo: pageNumber, tag, category, filter: searchFilter }, cb)
    window.scrollTo(0, 0)
  }

  filterByTag = (tagValue) => {
    const { history } = this.props
    const url = `${replace(BLOG_KEY_ROUTE, ':key', '')}`
    history.push(url)
    this.filterBy('tag', tagValue)
    window.scrollTo(0, 0)
  }

  filterBy = (key, value) => {
    const state = this.state
    forEach(['tag', 'category', 'searchFilter'], (val) => {
      if (key === val){
        set(state, val, value)
      }
      else {
        set(state, val, undefined)
      }
    })
    this.setState(state, this.getBlogList())
    window.scrollTo(0, 0)
  }

  subscribe = () => {
    const { subscribe } = this.props
    const { subscriptionEmail } = this.state
    const cb = (message) => {
      this.setState({
        subscriptionEmail: message,
      })
    }
    subscribe(subscriptionEmail, cb)
  }

  gotoPage = (pageNumber) => {
    const state = this.state
    set(state, 'pageNumber', pageNumber)
    this.setState(state, this.getBlogList())
  }

  gotoURL = (url) => {
    const { history } = this.props
    history.push(url)
  }

  renderBlogList() {
    const { blogList, recentBlogList, subscriptionEmail, totalPages, searchFilter } = this.state
    const { GLASS } = IconType.SEARCH
    const { MAIL } = IconType.COMMUNICATION
    const { SHARE, VIEW, LIKE } = IconType.SOCIAL
    return (
      <div>
        <MetaTags>
          <title>{'Career Development Blogs | Resumaker'}</title>
          <meta
            content={'Highly recommeded blog for career guidance | Fresher interview tips | Resume writting tips '}
            name="description" />
          <link href={'https://www.resumaker.co.in/blog'} rel="canonical" />
        </MetaTags>
        <div className={classnames(styles.blog_banner)}>
          <Container />
        </div>
        <div className={classnames(styles.sidebar_page_container)}>
          <Container>
            <Row className={classnames(styles.sidebar_reverse_column)}>
              <Col lg={4} md={12} sm={12} xl={4} xs={12}>

                <div className={classnames(styles.blog_left_aside)}>
                  <InputGroup>
                    <FormControl
                      className={classnames(styles.subscribe_input)}
                      onChange={e => this.setState({ searchFilter: e.target.value })}
                      placeholder="Search"
                      value={searchFilter} />
                    <InputGroup.Append>
                      <Button
                        className={classnames(styles.subcribe_btn)}
                        onClick={() => this.filterBy('searchFilter', searchFilter)}
                        variant="outline-secondary">
                        <Icon color={'#f1f1f1'} names={[GLASS]} styles={'icon25 darkGrayAlt'} />
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                  <div className={classnames(styles.siebar_title)}>
                    <h3> Categories </h3>
                  </div>
                  <BlogCategory filterBy={this.filterBy} />
                  <BlogRecentPosts gotoBlogDetails={this.gotoBlogDetails} recentBlogs={recentBlogList} />
                  <BlogTags filterBy={this.filterBy} />
                </div>
                <div className={classnames(styles.blog_contact_sticky)}>
                  <div className={classnames(styles.blog_contact_us)}>
                    <img
                      alt="contact-icon" className={classnames(styles.blog_image_responsive)}
                      src="/assets/images/phone.png" />
                    <p> Got any Questions? </p> <p> Call us today</p>
                    <p> +91 9121 900 900 </p>
                    <p> support@resumaker.co.in </p>
                  </div>
                  <div className={classnames(styles.blog_newsletter)}>
                    <div className={classnames(styles.siebar_title)}>
                      <h3> Get new blogs to you mailbox </h3>
                      <InputGroup>
                        <FormControl
                          aria-describedby="basic-addon2"
                          aria-label="Recipient's username"
                          className={classnames(styles.subscribe_input)}
                          onChange={e => this.setState({ subscriptionEmail: e.target.value })}
                          placeholder="Please Subscribe Here"
                          value={subscriptionEmail} />
                        <InputGroup.Append>
                          <Button
                            className={classnames(styles.subcribe_btn)}
                            onClick={this.subscribe}
                            variant="outline-secondary">
                            <Icon color={'#f1f1f1'} names={[MAIL]} styles={'icon25 darkGrayAlt'} />
                          </Button>
                        </InputGroup.Append>
                      </InputGroup>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={8} md={12} sm={12} xl={8} xs={12}>
                <div className={classnames(styles.blog_list_horizontal_container)}>
                  {blogList && blogList.length > 0 && blogList.map((blog, index) => (
                    <div className={classnames(styles.div_blog_list_horizontal)}>
                      {get(blog, 'media.type') === 'images' && (
                        <img
                          alt={'imageurl'}
                          className={classnames(styles.blog_image_responsive)}
                          src={getBlogImageUrl(get(blog, 'media.url'))} />
                      )}
                      {get(blog, 'media.type') === 'videos' && (
                      <video
                        className={classnames(styles.blog_image_responsive)}
                        src={getBlogVideoUrl(get(blog, 'media.url'))} />
                      )}
                      <ul className={classnames(styles.blog_details)}>
                        <li> {get(blog, 'createdMonth')} {get(blog, 'createdDay')}, 20{get(blog, 'createdYear')}</li>
                        <li>{get(blog, 'author')}</li>
                      </ul>
                      <ul className={classnames(styles.blog_list_comment_sec)}>
                        <li><Icon color={'#f39100'} names={[VIEW]} styles={'icon25 darkGrayAlt'} />
                          <span>{get(blog, 'viewCount')} </span></li>
                        <li><Icon color={'#f39100'} names={[SHARE]} styles={'icon25 darkGrayAlt'} />
                          <span> {get(blog, 'shareCount')} </span></li>
                        <li><Icon color={'#f39100'} names={[LIKE]} styles={'icon25 darkGrayAlt'} />
                          <span> {get(blog, 'likeCount')} </span></li>
                      </ul>
                      <h4><span onClick={() => this.gotoBlogDetails(get(blog, 'blogId'))}>
                        {get(blog, 'title')}{' '}</span></h4>
                      <div className={classnames(styles.blog_list_content_hidden)}>
                        <p>{ ReactHtmlParser(get(blog, 'description')) }</p> </div>
                      <Button
                        className={classnames(styles.blog_readmore_btn)}
                        onClick={() => this.gotoBlogDetails(get(blog, 'blogId'))}> Read More </Button>
                    </div>
                  ))}
                </div>
                <div className={classnames(styles.blog_pagination_div)}>
                  <ul>
                    {times(totalPages, (i) => (<li>{this.renderPageNumber(i + 1)}</li>))}
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    )
  }

  renderPageNumber(pageNo) {
    const { pageNumber } = this.state
    return (
      <div
        className={classnames(styles.pagination_pages, { [styles.blog_pagination_li_active]: pageNo === pageNumber })}
        onClick={() => this.gotoPage(pageNo)}>
        {pageNo}
      </div>
    )
  }

  render() {
    const { match } = this.props
    const selectedSubKey = get(match, 'params.key', '')
    return (
      <Fragment>
        {selectedSubKey === '' && this.renderBlogList()}
        {selectedSubKey !== '' && (
        <BlogDetail
          {...this.props}
          filterByTag={this.filterByTag}
          gotoBlogDetails={this.gotoBlogDetails}
          gotoURL={this.gotoURL} />
        )}
      </Fragment>
    )
  }
}

export default withRouter(Blog)
