import React, { Component, Fragment } from 'react'
import { Modal, Container, Row } from 'react-bootstrap'
import PropTypes from 'prop-types'
import cs from 'classnames'
import st from './style.scss'

export default class PromoVideo extends Component {
static propTypes = {
  loginWithRedirect: PropTypes.func.isRequired,
}
constructor(props) {
  super(props)
  this.state = {
    show: false,
  }
}

handleClose = () => {
  this.setState({
    show: false,
  })
}

handleShow = () => {
  this.setState({
    show: true,
  })
}

render() {
  const { show } = this.state
  return (
    <Fragment>
      <Container className={cs(st.cc_fluid_pp)} fluid>
        <div className={cs(st.home_video_main_box)}>
          <div className={cs(st.home_video_box)} onClick={this.handleShow}>
            <img alt="step 51" src={'/assets/images/home_video_icon.png'} />
          </div>
          <div className={cs(st.home_video_content)}>
            <h3> Why most users like Resumaker? </h3>
            <p>Using Resumaker Builder is effortless that provides professionally designed templates,
              recruiter friendly resume formats, built-in editor to customize layouts, bundles of real-world
              recommendations from industry top professionals, lastly ATS friendly.
              You can easily download it in PDF just in one click.
            </p>
            <div
              className={cs(st.button, st.btn_hover)}
              onClick={() => this.props.loginWithRedirect({ redirectTo: '/dashboard/create' })}>
              Get Started
            </div>
          </div>
        </div>
      </Container>
      <Modal className={cs(st.home_model_popup)} onHide={this.handleClose} show={show} size="md">
        <Modal.Header className={cs(st.crop_header)} closeButton>
          <Modal.Title className={cs(st.crop_title, st.home_video_content_title)}>
            Why most users like Resumaker?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={cs(st.crop_body)}>
          <div className={cs(st.image_panel)}>
            <Row>
              {/* Height and width should be responsive, so we control from css with this div - create one. */}
              <iframe
                className={cs(st.video_box)} height="300" src="https://www.youtube.com/embed/OhAKBYYoy_0"
                title="Why most users like Resumaker?" width="600" />
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}
}
