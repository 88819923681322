import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { inject } from 'mobx-react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { get, replace } from 'lodash'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { CAREER_KEY_ROUTE } from '../../router'
// import { Button } from '../common'
// import CareerGuideDetail from './CareerGuideDetail'
import styles from './style.scss'

@inject('mainModel')
class CareerGuide extends Component {
  static propTypes = {
    mainModel: PropTypes.object.isRequired,
  }

  constructor(props){
    super(props)
    this.state = {
      careerGuides: [],
    }
  }

  componentDidMount(){
    const { mainModel, match } = this.props
    const selectedSubKey = get(match, 'params.subkey', '')
    if (selectedSubKey === ''){
      const cb = (careerGuides) => {
        this.setState({
          careerGuides,
        })
      }
      mainModel.getCareerGuideList(1, cb)
    }
  }

  gotoCareerGuideSection = (careerId) => {
    const { history } = this.props
    const url = `${replace(CAREER_KEY_ROUTE, ':key', careerId)}`
    history.push(url)
  }

  renderGuideList(){
    const { careerGuides } = this.state
    return (
      <Fragment>
        {careerGuides && careerGuides.length > 0 && careerGuides.map((careerGuide, index) => (
          <div className={classnames(styles['list-item'])}>
            <h1>{get(careerGuide, 'title')} </h1>
            <span>{get(careerGuide, 'description')} </span>
            {get(careerGuide, 'media.type') === 'image' &&
            <img alt={'img'} src={get(careerGuide, 'media.url')} />}
            {get(careerGuide, 'media.type') === 'video' &&
            <video alt={'img'} src={get(careerGuide, 'media.url')} />}
            <Button
              label={'Read More'}
              onClick={() => {
                const id = get(careerGuide, 'seqId')
                this.gotoCareerGuideSection(id)
              }} />
          </div>
        ))}
      </Fragment>
    )
  }

  render() {
    // const { match } = this.props
    // const selectedSubKey = get(match, 'params.key', '')
    return (
    // <Fragment>
    // {selectedSubKey === '' && this.renderGuideList()}
    // {selectedSubKey !== '' && <CareerGuideDetail {...this.props} />}
    // </Fragment>

      <div>
        <Container fluid>
          <div className={classnames(styles.container_xl, 'mt-5')}>
            <Row>
              <Col lg={12} xs={12}>
                <div
                  className={classnames('px-0', 'mx-auto')}>
                  <div className={classnames('mb-5', 'pb-md-3', styles.page_content_header,
                    'text-center')}>
                    <h1 className={classnames('mb-4')}>What is Career Guide ? How does it help ? </h1>
                    <p>Learn how to prepare for an interview and ace it. Find out what
                      questions recruiters ask and how to answer them. Follow expert
                      strategies and tips, go through the interviewing stage stress-free,
                      and land that job. Each guide comes
                      with real-life examples and pro tips from HR professionals.</p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12} xl={12} xs={12}>
                <div
                  className={classnames('px-0', 'mx-auto')}>
                  <div className={classnames(styles.dz_card, styles.shadow_1, 'py-4', 'px-md-4', 'px-3')}>
                    <div className={classnames('carrerguide-slide', 'slick', 'mb-4')}>
                      <div className={classnames(styles.slide_item)}>
                        <img
                          alt="Carrer Guide slide 3" className={classnames('img-fluid')}
                          src="/assets/images/Carrerguide.jpg" />
                      </div>
                      <Row>
                        <Col md={6}>
                          <div className={classnames('mb-3', 'mb-md-0')}>
                            <img alt="" className={classnames('img-fluid')} src="assets/images/cg1.jpg" />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div>
                            <img alt="" className={classnames('img-fluid')} src="/assets/images/cg2.jpg" />
                          </div>

                        </Col>
                      </Row>

                      <Row>
                        <div className={classnames('mt-5', 'justify-content-center')}>
                          <Col md={12} sm={112} xs={12}>
                            <div className={classnames('text-center')}>
                              <h3 className={classnames(styles.semi_heading)}>Interview Questions
                                and Answers</h3>
                              <p classNames={classnames(styles.text_lead)}>Duis aute irure dolor
                                reprehenderit in voluptate velit essl cillum dolore eud fugiat nulla
                                pariatur. Excepteur sint ocaec atus. </p>
                            </div>
                          </Col>
                        </div>
                      </Row>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <div className={classnames(styles.related_articles, 'mt-md-5', 'pt-5')}>
              <h3
                className={classnames('pl-md-4', 'pl-3', styles.semi_heading, 'mt-md-4')}>Related Articles</h3>
              <div className={classnames('row', 'mt-4', 'mt-md-5')}>
                <Row>
                  <Col lg={4} md={6} xs={12}>
                    <div
                      className={classnames('mb-3', 'mb-md-5')}>
                      <div className={classnames(styles.article_card, styles.dz_card, styles.shadow_1, styles.card)}>
                        <img alt="" className={classnames('card-img-top')} src="/assets/images/article1.jpg" />
                        <div className={classnames('card-body', 'px-3', 'pb-5')}>
                          <h4 className={classnames(styles.card_title)}><a href="/">
                            Jumpstart Your Online Store with Payment Gateway.</a></h4>
                          <p className={classnames(styles.card_text)}>Megha Laroia
                            in Marketing and Sales, Product and Features</p>
                          <p className={classnames(styles.card_text)}>Jun 25, 2020 · 2 min</p>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col lg={4} md={6} xs={12}>
                    <div className={classnames('mb-3', 'mb-md-5')}>
                      <div className={classnames(styles.article_card, styles.dz_card, styles.shadow_1, styles.card)}>
                        <img alt="" className={classnames('card-img-top')} src="/assets/images/article2.jpg" />
                        <div className={classnames('card-body', 'px-3', 'pb-5')}>
                          <h4 className={classnames(styles.card_title)}><a href="/">
                            Jumpstart Your Online Store with Payment Gateway.</a></h4>
                          <p className={classnames(styles.card_text)}>Megha Laroia in Marketing
                            and Sales, Product and Features</p>
                          <p className={classnames(styles.card_text)}>Jun 25, 2020 · 2 min</p>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col lg={4} md={6} xs={12}>
                    <div
                      className={classnames('mb-3', 'mb-md-5')}>
                      <div className={classnames(styles.article_card, styles.dz_card,
                        styles.shadow_1, styles.card)}>
                        <img
                          alt="" className={classnames('card-img-top')}
                          src="/assets/images/article3.jpg" />
                        <div className={classnames('card-body', 'px-3', 'pb-5')}>
                          <h4 className={classnames(styles.card_title)}><a href="/">
                            Jumpstart Your Online Store with Payment Gateway.</a></h4>
                          <p className={classnames(styles.card_text)}>Megha Laroia
                            in Marketing and Sales, Product and Features</p>
                          <p className={classnames(styles.card_text)}>Jun 25, 2020 · 2 min</p>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col lg={4} md={6} xs={12}>
                    <div
                      className={classnames('mb-3', 'mb-md-5')}>
                      <div className={classnames(styles.article_card, styles.dz_card,
                        styles.shadow_1, styles.card)}>
                        <img
                          alt="" className={classnames('card-img-top')}
                          src="/assets/images/article3.jpg" />
                        <div className={classnames('card-body', 'px-3', 'pb-5')}>
                          <h4 className={classnames(styles.card_title)}><a href="/">
                            Jumpstart Your Online Store with Payment Gateway.</a></h4>
                          <p className={classnames(styles.card_text)}>Megha Laroia in
                            Marketing and Sales, Product and Features</p>
                          <p className={classnames(styles.card_text)}>Jun 25, 2020 · 2 min</p>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col lg={4} md={6} xs={12}>
                    <div
                      className={classnames('mb-3', 'mb-md-5')}>
                      <div className={classnames(styles.article_card, styles.dz_card,
                        styles.shadow_1, styles.card)}>
                        <img alt="" className={classnames('card-img-top')} src="/assets/images/article2.jpg" />
                        <div className={classnames('card-body', 'px-3', 'pb-5')}>
                          <h4 className={classnames(styles.card_title)}><a href="/">
                            Jumpstart Your Online Store with Payment Gateway.</a></h4>
                          <p className={classnames(styles.card_text)}>Megha Laroia in Marketing
                            and Sales, Product &amp; Features</p>
                          <p className={classnames(styles.card_text)}>Jun 25, 2020 · 2 min</p>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col lg={4} md={6} xs={12}>
                    <div
                      className={classnames('mb-3', 'mb-md-5')}>
                      <div className={classnames(styles.article_card, styles.dz_card,
                        styles.shadow_1, styles.card)}>
                        <img alt="" className={classnames('card-img-top')} src="/assets/images/article1.jpg" />
                        <div className={classnames('card-body', 'px-3', 'pb-5')}>
                          <h4 className={classnames(styles.card_title)}><a href="/">
                            Jumpstart Your Online Store with Payment Gateway.</a></h4>
                          <p className={classnames(styles.card_text)}>Megha Laroia in Marketing
                            and Sales, Product &amp; Features</p>
                          <p className={classnames(styles.card_text)}>Jun 25, 2020 · 2 min</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Container>
        <div className={classnames(styles.page_spacer_bottom)} />
      </div>

    )
  }
}

export default withRouter(CareerGuide)
