import React, { Component } from 'react'
import classnames from 'classnames'
import { Container, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import PromoCard from './PromoCard'
import GetStartedButton from '../../MainContent/Home/GetStartedButton'
import { getDocUrl } from '../../../constants'
import { Download } from '../../common'
import styles from './style.scss'

export default class Accounting extends Component {
  static propTypes = {
    loginWithRedirect: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
  }

  render() {
    const { loginWithRedirect, history } = this.props
    return (
      <div>
        <Container fluid>
          <div className={classnames('page-main-wrapper')}>
            <div className={classnames(styles.container_xl, 'mt-5', 'mb-5')}>
              <div className={classnames(styles.page_content_header, 'row', 'align-items-center')}>
                <Col lg={12} md={12} xl={12} xs={12}>
                  <div className={classnames('text-center', 'mx-auto')}>
                    <img
                      alt="fresher resume" className={classnames(styles.resume_sample_vector, 'img-fluid', 'mx-auto')}
                      src="/assets/images/Accountant_vector.jpg" />
                    <div
                      className={classnames('mt-md-5', 'text-center', 'mb-md-4')}>
                      <h1 className={classnames('mt-4', styles.size_heading)}> Accountant Executive Resume  </h1>
                      <p className={classnames('mb-0', styles.article)}>Composing an Account Executive resume
                        is a significant advance in your
                        pursuit of employment opportunities. When crafting your resume,
                        make certain to reference the expected set of responsibilities
                        and highlight any skills, awards, and accreditations that match your
                        employer’s needs. You may likewise need to incorporate a summary
                        or objective synopsis that shows your qualifications and capabilities.</p>
                    </div>
                    <div className={classnames(styles.btn_gs)}>
                      <GetStartedButton label="Get Started" loginWithRedirect={loginWithRedirect} size="lg" />
                    </div>
                  </div>
                </Col>
                <div className={classnames(styles.resume_sample_container, 'mt-5', 'pt-3', 'pt-lg-4')}>
                  <Row>
                    <Col lg={6}>
                      <div className={classnames('mb-5', 'p-xl-5', 'mb-xl-0')}>
                        <div className={classnames(styles.resume_sample)}>
                          <img
                            alt="Resume Sample" className={classnames(styles.resume_sample_img)}
                            src="/assets/images/account_resume.jpg" />
                          <Download
                            downloadURL={getDocUrl('assets/docs/Acc-res.docx')}
                            history={history}
                            thanksURL={'/Accounting'} />
                          {/* <a className={classnames(styles.resume_download)}
                            href={getDocUrl('assets/docs/Acc-res.docx')}>
                            <span className={classnames('sr-only')}>Download</span></a> */}
                        </div>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className={classnames('mb-5', 'p-xl-5', 'mb-xl-0')}>
                        <div className={classnames(styles.resume_sample)}>
                          <img
                            alt="Resume Sample" className={classnames(styles.resume_sample_img)}
                            src="/assets/images/account_cl.jpg" />
                          <Download
                            downloadURL={getDocUrl('assets/docs/Acc-cl.docx')}
                            history={history}
                            thanksURL={'/Accounting'} />
                          {/* <a
                            className={classnames(styles.resume_download)}
                            href={getDocUrl('assets/docs/Acc-cl.docx')}>
                            <span className={classnames('sr-only')}>Download</span></a> */}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className={classnames(styles.resume_sample_header, 'text-center')}>
                    <div className={classnames(styles.container_xl)}>
                      <h2 className={classnames(styles.section_heading)}>
                        Account Executive Resume (Text Format)
                      </h2>
                      <p className={classnames('mt-2', styles.section_paragraph)}>
                        Make sure you choose the right resume
                        format to suit your unique experience and the job you are applying.</p>
                    </div>
                  </div>
                  <div className={classnames(styles.resume_sample_wrapper)}>
                    <div className={classnames(styles.container_xl)}>
                      <Row>
                        <Col lg={8} xl={8} xs={12}>
                          <div
                            className={classnames(styles.resume_sample_main)}>
                            <div className={classnames(styles.tab_content)}>
                              Text View
                            </div>
                          </div>
                          <div
                            className={classnames(styles.resume_content_card,
                              styles.dz_card, styles.shadow_1, 'px-3', 'px-lg-5', 'py-5', 'tab-pane active')}
                            >
                            <Row>
                              <h2 className={classnames(styles.section_heading, 'ml-2')}>
                                Account Executive Resume
                              </h2>
                            </Row>
                            <div className={classnames(styles.resume_content_title, 'mt-3')}>Summary</div>
                            <p className={classnames('mt-2', 'mb-2')}>
                              Experienced Accounts Executive with
                              3 years of experience in Accounting. Excellent reputation
                              for resolving problems and improving customer satisfaction.
                              Action-oriented Account Executive with excellent customer
                              oversight, issue resolution and relationship building skills.
                              Highly effective at maximizing repeat business opportunities.
                            </p>
                            <div className={classnames(styles.resume_content_title)}>
                              Skills
                            </div>
                            <ul className={classnames(styles.interview_points, 'mt-4')}>
                              <li>Relationship building and management </li>
                              <li> Account servicing </li>
                              <li> Forecast Preparation </li>
                              <li> Customer Presentations </li>
                              <li> Progress Reporting </li>
                              <li> Database Management </li>
                              <li> Written and verbal communication </li>
                              <li> Account management </li>
                            </ul>
                            <div className={classnames(styles.resume_content_title)}>
                              Work Experience</div>
                            <ul className={classnames(styles.interview_points,
                              'list-unstyled', styles.content_title, 'mt-4')}>
                              <li className={classnames('mb-1')}>XYZ Shares, Belas, US</li>
                              <li>Dec 2019 – till</li>
                            </ul>
                            <ul className={classnames(styles.interview_points, 'mt-2')}>
                              <li>Secured high-value accounts through consultative
                                selling, effective customer solutions and promoting
                                compelling business opportunities. </li>
                              <li>Increased profitability and pipeline development by
                                leveraging diverse marketing channels and sales strategies. </li>
                              <li>Drove new business development through qualifying
                                leads, building relationships and executing strategic sales.
                              </li>
                              <li>Marketed and sold solutions and services to
                                stakeholders across multiple levels of organization.
                              </li>
                              <li>Alerted and contacted customers about account
                                or informational issues to maintain high levels
                                of customer satisfaction. </li>
                              <li>Strengthened customer relationships with proactive
                                and collaborative approach to managing needs.</li>
                            </ul>
                            <div className={classnames(styles.resume_content_title, 'mt-3')}>
                              Education </div>
                            <ul className={classnames(styles.interview_points, 'list-unstyled', 'mt-4')}>
                              <li> Bachelor of Accountancy in Accounting And Business Management feb 2019 </li>
                            </ul>
                            <div className={classnames(styles.resume_content_title)}>
                              Hobbies
                            </div>
                            <ul className={classnames(styles.interview_points, 'mt-4')}>
                              <li>Travelling  </li>
                              <li>Baking  </li>
                              <li>Reading </li>
                            </ul>
                          </div>
                          <div className={classnames(styles.resume_sample_header, 'pb-5')}>
                            <h2 className={classnames(styles.section_heading, 'mb-2')}>
                              How to Write Account Executive Resume</h2>
                            <p>In this article, you will
                              get in-depth information
                              on how to write a resume for an account executive.
                              Let&rsquo;s dive in and see!</p>
                          </div>
                          <div
                            aria-labelledby="resumeContentTab"
                            className={classnames(styles.resume_content_card,
                              styles.dz_card, styles.shadow_1, 'px-0', 'px-lg-5', 'py-5', 'tab-pane active')}
                            id="resumeContent" role="tabpanel">
                            <h3 className={classnames(styles.resume_content_heading, 'mt-0')}>
                              How to Write an Account Executive&rsquo;s Resume Summary</h3>
                            <p className={classnames('mb-3')}>Account Executives are
                              employees who work with customers by
                              offering exceptional support consistently. In the summary section,
                              highlight your greatest achievements and utilize an assortment
                              of action words to make your summary more engaging.
                              Indicate your key attributes to showcase your skills and achievements.</p>
                            <h5 className={classnames('font-weight-bold')}>The following are the tips for
                              writing an Account Executive summary.</h5>
                            <h5 className={classnames('font-weight-bold', 'mt-4')}>
                              Scrutinize the job descriptions</h5>
                            <p className={classnames('mb-2')}>Analyzing the set of
                              job descriptions is critical to
                              composing an ideal resume summary. Pick significant keywords
                              from the description and include them in the summary.
                              Ensure that the skills you highlight match with the business&rsquo;
                              necessities.</p>
                            <h5 className={classnames('font-weight-bold')}>
                              Work history</h5>
                            <p className={classnames('mb-2')}>As to business history,
                              list vital perspectives,
                              such as the number of years worked and your
                              position in the previous role.</p>
                            <h5 className={classnames('font-weight-bold')}>
                              Relevant skills</h5>
                            <p className={classnames('mb-2')}>Ensure that you list
                              relevant skills dependent on the
                              sets of expectations. More importantly,
                              utilize a blend of hard and hard skills to make your resume stand out.</p>
                            <h5 className={classnames('font-weight-bold')}>
                              Key accomplishments</h5>
                            <p className={classnames('mb-3')}>List your key achievements, and use metrics
                              to quantify them. For example,
                              &ldquo;Grew sales by 60% during my first year of employment.&rdquo;</p>
                            <h5 className={classnames('font-weight-bold', 'mt-1')}>
                              Examples of Account Executive Resume Summaries</h5>
                            <h5 className={classnames('ml-3', 'font-weight-bold', 'mt-3')}>Good Example</h5>
                            <blockquote><p className={classnames(styles.resume_rank, 'ml-0')}>
                              Dependable Account Executive with 5+ years of work
                              experience in a fast-paced sales division.
                              A proven record of cultivating customer connections to enhance sales volume.
                              Skilled at business advancement and looking for new
                              open doors from a broad scope of customers.
                              Knowledge of evaluating customer drive and actualizing
                              programs that foster that drive. Experiencing in
                              developing and managing campaigns that effectively
                              address customer issues and objectives.
                            </p></blockquote>
                            <h5 className={classnames('ml-3', 'font-weight-bold')}>Bad Example</h5>
                            <blockquote><p className={classnames(styles.resume_rank, 'ml-0')}>
                              A profoundly experienced and qualified record account
                              executive with 10+ years of experience
                              in business growth and development.
                              I can work under tension and minimum supervision.
                              Optimistic to deliver excellent results.
                            </p></blockquote>
                            <div className={classnames('card', 'p-3', 'px-lg-4', 'mt-5',
                              styles.ProTip, styles.interview_blockquote)}>
                              <h5 className={classnames('ml-5', 'font-weight-bold')}>Pro Tip </h5>
                              <p className={classnames('ml-5')}>
                                In the summary section, highlight achievements
                                that are relevant to the job you are applying for.
                                Use a mix of hard and soft sills to make your resume stand out.</p>
                            </div>
                            <h3 className={classnames(styles.resume_content_heading, 'mt-5', 'mb-4')}>
                              How to Highlight Skills in Account Executive Resume
                            </h3>
                            <img
                              alt="Nurseimage" className={classnames('mt-2', 'mb-3')}
                              src="/assets/images/Account_skill.jpg" />

                            <p className={classnames('mb-2')}>An Account Executive
                              resume ought to plainly exhibit a candidate&rsquo;s
                              capacity to develop a long-lasting relationship with customers.
                              The executives ought to have
                              solid decision-making and management skills to enable
                              them to manage and address customer issues.</p>
                            <p className={classnames('mb-3')}>An Account Executive&rsquo;s essential obligations
                              are to understand the needs of customers
                              and devise a mission to addresses their
                              issues and their spending limitations.</p>
                            <h5 className={classnames('font-weight-bold')}>Here are the tips for
                              highlighting skills on your resume</h5>
                            <ul className={classnames(styles.interview_points, 'mt-3')}>
                              <li>On your resume, demonstrate that you
                                have strong advertising and marketing skills</li>
                              <li>Highlighting the level of your communication skills</li>
                              <li>Show how your skills have helped you to accomplish particular
                                goals in your previous employment. For example,
                                &ldquo;Used my relationship management skills
                                to recruit an average of 200 customers monthly.&rdquo;</li>
                              <li className={classnames('mb-0')}>Ensure that you highlight your skills in the
                                major sections of a resume, such as
                                the summary, skill, and experience</li>
                            </ul>
                            <h5 className={classnames('font-weight-bold', 'mb-0', styles.align)}>
                              Hard and Soft Skills</h5>
                            <p className={classnames('mb-0')}>Hard skills refer
                              to industry-specific tools and technical abilities
                              required for a particular task. On the contrary,
                              soft skills relate to your personality or working
                              style that are difficult to quantify.</p>
                            <p className={classnames('mb-0')}>Ensure that you highlight relevant skills that
                              show your potential to manage and serve clients and other stakeholders.</p>
                            <h5 className={classnames('font-weight-bold', 'mt-2')}>Examples of Hard skills</h5>
                            <ul className={classnames(styles.interview_points, 'mt-3')}>
                              <li>Marketing skills</li>
                              <li>computer proficiency</li>
                              <li>Cross-selling abilities</li>
                            </ul>
                            <h5 className={classnames('font-weight-bold', 'mt-1')}>Examples of Soft skills</h5>
                            <ul className={classnames(styles.interview_points, 'mt-3')}>
                              <li>Excellent communication skills</li>
                              <li>Critical thinking</li>
                              <li>Interpersonal skills</li>
                              <li>Integrity</li>
                              <li>Listening skills</li>
                            </ul>
                            <h5 className={classnames('font-weight-bold')}> Strategies
                              on how to highlight
                              account executive skills on your resume</h5>
                            <h5 className={classnames('font-weight-bold', styles.align)}>Here are the
                              strategies on how to
                              list an account executive&rsquo;s skills on your resume.</h5>
                            <ul className={classnames(styles.interview_points)}>
                              <li>Use a combination of hard and soft skills
                                in order to achieve a perfect mix</li>
                              <li>List your skills using the bullet points</li>
                              <li>Before you highlight skills on a resume, analyze the job
                                descriptions to understand the recruiter&rsquo;s
                                requirements regarding skills</li>
                              <li>Highlight skills that are relevant to the employer&rsquo;s needs</li>
                            </ul>
                            <div className={classnames('card', 'p-3', 'px-lg-4', 'mt-5',
                              styles.ProTip, styles.interview_blockquote)}>
                              <h5 className={classnames('ml-5', 'font-weight-bold')}>Pro Tip </h5>
                              <p className={classnames('ml-5')}>
                                Check the job descriptions and pick skills that
                                are relevant to the employer’s job requirements.
                                More importantly, use the keywords in the
                                job descriptions to highlight your skills.</p>
                            </div>
                            <h3 className={classnames(styles.resume_content_heading, 'mt-5', 'mb-2')}>
                              How to Write
                              Work Experience in
                              Account Executive Resume</h3>
                            <p className={classnames('mb-0')}>If you have worked in
                              a similar position before,
                              list your achievements, and quantify them. However,
                              in case you are inexperienced, highlight your volunteer
                              experience or relevant transferable skills, such
                              as interpersonal or communication skills.</p>
                            <p>Make sure to utilize power action verbs and mention
                              job-specific accomplishments that may support your suitability.</p>
                            <h5 className={classnames('font-weight-bold')}>
                              Here are the strategies on how to
                              highlight the work experience section</h5>
                            <ul className={classnames(styles.interview_points, 'mt-3')}>
                              <li>Mention what you did and quantify how well you performed it.
                                Use percentages, dollar values, numbers,
                                or amounts for quantification.
                                For example: &ldquo;Surpassed monthly performance by 30%.&rdquo;</li>
                              <li>Avoid using pronouns, such as &ldquo;I&rdquo; and &ldquo;the&rdquo;.
                                For example, don&rsquo;t say, &ldquo;I increased sales by 20%,&rdquo;
                                but say, &ldquo;Increased sales by 20%.&rdquo;</li>
                              <li>Use numerous action words such as &ldquo;accomplished,&rdquo;
                                &ldquo;achieved,&rdquo; &ldquo;delivered,&rdquo; etc.
                                However, use present tense for ongoing
                                activities and past tense for those you are not involved in.</li>
                              <li>Highlight your experience in reverse
                                chronological order, starting with the current ones</li>
                            </ul>
                            <h5 className={classnames('font-weight-bold')}>
                              Example of Account Executive
                              Experience on a Resume</h5>
                            <ul className={classnames(styles.interview_points, 'list-unstyled', 'mt-3')}>
                              <li className={classnames('mb-2')}>Account Executive</li>
                              <li>June 2010-Dec 2015</li>
                            </ul>
                            <ul className={classnames(styles.interview_points, 'mt-3')}>
                              <li className={classnames('mb-2')}>Facilitated a perfect lead process
                                that led to a 75% in annual sales volume.</li>
                              <li>Received 70% positive feedback from a customer survey</li>
                              <li>Fostered excellent client relationships
                                that resulted in 50% company growth</li>
                              <li>Managed a team of 100 people devoted to achieving success in regards
                                to the connection between advertising initiatives and our clients.</li>
                              <li>Surpassed targets by 20% in cross-selling the company products to clients</li>
                            </ul>
                            <div className={classnames('card', 'p-3', 'px-lg-4', 'mt-5',
                              styles.ProTip, styles.interview_blockquote)}>
                              <h5 className={classnames('ml-5', 'font-weight-bold')}>Pro Tip
                              </h5><p className={classnames('ml-5')}>
                                Use action verbs to describe your work history. Some of these
                                verbs include &ldquo;achieved,&rdquo; &ldquo;surpassed,&rdquo;
                                and &ldquo;delivered.&rdquo;
                                Utilize past tense
                                to describe your achievements in
                                the previous employment and present tense to highlight
                                activities in your current job.</p>
                            </div>
                            <h3 className={classnames(styles.resume_content_heading, 'mt-5')}>
                              How to write Account
                              Executive Achievements,
                              Hobbies, Training and certifications on Resume</h3>
                            <p className={classnames('mb-1')}>Featuring key achievements,
                              accomplishments, certifications, and hobbies
                              on your resume show how well you performed in your past work.
                              This will make you more competitive and enhance your chances of recruitment.</p>
                            <h5 className={classnames('font-weight-bold', 'mt-3')}>How To Highlight Achievement</h5>
                            <p className={classnames('mb-1')}>List your key accomplishments in a manner that
                              shows that you are suitable for the position.</p>
                            <h5 className={classnames('font-weight-bold', 'mt-2')}>Examples</h5>
                            <ul className={classnames(styles.interview_points, 'mt-3')}>
                              <li className={classnames('mb-1')}>Received the company&rsquo;s best account
                                executive award for two consecutive years</li>
                              <li className={classnames('mb-1')}>Achieved 60% customer growth,
                                which was above the company&rsquo;s benchmark</li>
                              <li>Managed and mentored more than 200
                                employees across different branch networks</li>
                            </ul>
                            <div className={classnames('card', 'p-3', 'px-lg-4', 'mt-5',
                              styles.ProTip, styles.interview_blockquote)}>
                              <h5 className={classnames('ml-5', 'font-weight-bold')}>Pro Tip
                              </h5><p className={classnames('ml-5')}>
                                Always use metrics, such as percentages and numbers,
                                to quantify your achievements.
                                Example: “Surpassed my business growth target by 20%
                                during my first year of employment.”</p>
                            </div>
                            <h5 className={classnames('font-weight-bold', 'mt-5')}>
                              How to highlight hobbies</h5>
                            <p className={classnames('mb-1', 'mt-3')}>Highlight hobbies that are
                              relevant to the content of the job description.
                              For instance, if one of your hobbies is making friends,
                              adding it on your resume will
                              convince your recruiters of your ability to get along with
                              customers and grow business.</p>
                            <h5 className={classnames('font-weight-bold', 'mt-3')}>Here are the tips on how to
                              list hobbies on your resume</h5>
                            <ul className={classnames(styles.interview_points, 'mt-3')}>
                              <li className={classnames('mb-1', 'ml-0')}>Highlight only hobbies relating to
                                the position you are applying for</li>
                              <li className={classnames('mb-0')}>Use a maximum of three hobbies</li>
                              <li>Highlight hobbies based on skills
                                listed in the job description</li>
                              <li>List hobbies toward the bottom of your resume</li>
                            </ul>
                            <h5 className={classnames('font-weight-bold')}>Examples of hobbies to list
                              on account executive resume</h5>
                            <ul className={classnames(styles.interview_points, 'mt-3')}>
                              <li>Travelling</li>
                              <li>Hiking</li>
                              <li>Reading</li>
                              <li>Watching business documentaries</li>
                            </ul>
                            <h5 className={classnames('font-weight-bold')}>How to highlight
                              Certifications
                              on a resume</h5>
                            <p>Certifications show that you are skilled and
                              qualified for the role you are applying for.
                              Ensure to add certifications that are
                              relevant to the skills in the job description.</p>
                            <div className={classnames('card', 'p-3', 'px-lg-4', 'mt-5',
                              styles.ProTip, styles.interview_blockquote)}>
                              <h5 className={classnames('ml-5', 'font-weight-bold')}>Pro Tips</h5>
                              <ul className={classnames(styles.interview_points, 'ml-4', 'mt-3')}>
                                <li>Indicate the name of the certifications,
                                  date, and the certifying authority.</li>
                                <li>Use a reverse-chronological
                                  order to list your certifications.</li>
                                <li>List certifications in a
                                  separate section of your resume.</li>
                              </ul>
                            </div>
                            <h5 className={classnames('font-weight-bold', 'mt-5')}>Example of How to Add
                              Certifications on a Resume</h5>
                            <h5 className={classnames('font-weight-bold', 'mt-3')}>Certifications</h5>
                            <ul className={classnames(styles.interview_points, 'ml-4', styles.content_list, 'mt-3')}>
                              <li>Account Executive
                                Professional (AEP), Management Institute,
                                India - January 2010.</li>
                              <li>Business Growth &amp; Product Development Manager (BGDM),
                                Mumbai College, India - February 2017</li></ul>
                            <h3 className={classnames(styles.resume_content_heading, 'mt-5')}>
                              Top Professional Tips for
                              Writing Account Executive Resume</h3>
                            <h5 className={classnames('font-weight-bold', 'mt-3')}>The following
                              are the top tips for writing an account executive resume.</h5>
                            <h5 className={classnames('font-weight-bold', 'mt-4', 'mb-2')}>Measure your
                              accomplishments</h5>
                            <p className={classnames('mb-2')}>
                              Resumes need measurements attached to accomplishments.
                              Hiring managers are less interested in your obligations and duties,
                              but are more concerned with your impact on their businesses.
                              While describing your experience, make sure to incorporate
                              numbers or rates where conceivable. If you have experience in retail,
                              gauge the number of clients you served every day as opposed
                              to stating you worked at the counter.</p>
                            <h5 className={classnames('font-weight-bold', 'mb-2')}>Feature key skills</h5>
                            <p className={classnames('mb-2')}>Account executives need to
                              carry out numerous duties.
                              They should be prepared to
                              communicate with clients via telephone, live talk, or even face to face.
                              However, two fundamental things
                              that recruiters need to see are that you are organized and trustworthy.</p>
                            <h5 className={classnames('font-weight-bold', 'mt-0', 'mb-2')}>
                              Describe client
                              assistance solutions
                            </h5>
                            <p className={classnames('mb-2')}>Handling clients’ complaints can be challenging
                              if you don’t know how to go about it.
                              On occasion, you&rsquo;ll be entrusted with creating
                              imaginative answers for individual explicit issues.
                              If you have related knowledge in client support,
                              now is your chance to highlight it.</p>
                            <h5 className={classnames('font-weight-bold', 'mb-2')}>
                              Highlight your
                              technical experience</h5>
                            <p className={classnames('mb-2')}>As an account executive,
                              you should be technically knowledgeable.
                              It doesn&rsquo;t make a difference what profession you&rsquo;re going into;
                              there will consistently be some type of technology you should explore.
                              Regardless of whether this is logging client grievances in
                              an information system, chatting with customers on Live Chat, or overseeing
                              client relations on social media.
                              Recruiters will be intrigued to check whether you
                              have any experience in these frameworks.</p>
                            <p className={classnames('mb-2')}>Commit a section of your
                              resume to your innovative experience.
                              Here, you can take note of your Office Suite
                              capability and related knowledge.</p>
                            <h5 className={classnames('font-weight-bold', 'mb-2')}> Talk about your
                              interpersonal skills</h5>
                            <p className={classnames('mb-2')}>Business development is
                              established on relational abilities.
                              These skills are essential for communicating with
                              customers and colleagues at the workplace.
                              Your interpersonal skills will be what will make
                              you successful once you get hired.</p>
                            <p className={classnames('mb-2')}>As opposed to just expressing that you have
                              high interpersonal skills, state that in an earlier job,
                              you were in charge of communicating with a
                              team of 20 or you sent 100+ emails every day</p>
                            <h5 className={classnames('font-weight-bold', 'mb-2')}>Highlight your
                              management experience</h5>
                            <p className={classnames('mb-2')}>An account executive work
                              can regularly include administrative functions.
                              This can be dealing with a client service group or motivating staff.
                              Therefore, the executives’ experience is ideal for client service role.
                              It likewise demonstrates your
                              capacity to cooperate with others and discover
                              an agreement, something essential for business growth.</p>
                            <h5 className={classnames('font-weight-bold', 'mb-2')}>
                              Use a clean and readable design</h5>
                            <p className={classnames('mb-2')}>
                              Account executive jobs are increasing every year, and
                              recruiters use electronic frameworks to screen resumes
                              that get through their entryway. This implies you&rsquo;ll profit
                              by having a neat and tidy designed resume with clear textual styles.
                              You can achieve this utilizing a Single Column resume design.</p>
                            <h5 className={classnames('font-weight-bold', 'mb-2')}>
                              Have a companion edit your resume</h5>
                            <p className={classnames('mb-2')}>
                              Referring to your resume to a companion permits you to know how your
                              resume is seen by someone else.
                              Maybe it isn&rsquo;t as charming as you thought it to be,
                              or there might be an expression that isn&rsquo;t as
                              regular as you might suspect.</p>
                            <h5 className={classnames('font-weight-bold', 'mb-2')}>
                              Incorporate your hobbies and interests</h5>
                            <p className={classnames('mb-2')}>Many contend that hobbies and interests aren&rsquo;t
                              pertinent to your job.
                              Nonetheless, this frequently isn&rsquo;t the situation. Your hobbies and
                              interests can help you speak with others and work in a group.
                              They can likewise demonstrate your capacity to hold discussions
                              with clients and cause them to feel esteemed.</p>
                            <p className={classnames('mb-2')}>You can incorporate these in
                              your resume in a separate section toward the end of the CV.</p>
                            <h5 className={classnames('font-weight-bold', 'mb-2')}>
                              Talking commitment</h5>
                            <p className={classnames('mb-2')}>Your informative oral capacities
                              are crucial for a career in account executive.
                              Talking engagements, such as public speaking, are incredible
                              methods to show these aptitudes off.
                              You can incorporate these as a component
                              of an Achievements or Most Proud of Section.</p>
                            <h5 className={classnames('font-weight-bold', 'mb-2')}>
                              Add legitimate references</h5>
                            <p className={classnames('mb-2')}>Many people know that
                              references are significant for job applications.
                              However, did you understand how crucial they are for account
                              executive positions? References are your audits – organizations
                              need workers that will drive clients to give them great reviews.
                              When you have a trustworthy reference on your resume,
                              it shows the recruiter that you can inspire people.</p>
                            <h5 className={classnames('font-weight-bold', 'mb-2')}>
                              Add keywords</h5>
                            <p className={classnames('mb-2')}>Keywords will assist
                              you with getting found in an electronic candidate search.
                              Account executive keywords include employment titles,
                              such as client assistance, client relationship
                              the executives, and business development.</p>
                            <p className={classnames('mb-2')}>You ought to optimize
                              your resume for the keywords search.
                              This will make your resume to be effortlessly
                              found whenever it is entered in the information database.</p>
                          </div>
                        </Col>
                        <Col lg={4} xl={4} xs={12}>
                          <PromoCard loginWithRedirect={loginWithRedirect} />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    )
  }
}
