import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import MetaTags from 'react-helmet'
import classnames from 'classnames'
import { SEO } from '../../../constants/constants'
import styles from './style.css'

class PaymentRefundPolicy extends Component {
  render() {
    return (
      <Fragment>
        <MetaTags>
          <title>Payment and Refund Policy | Resumaker</title>
          <meta content={SEO.DESCRIPTION} name="description" />
        </MetaTags>
        <div>
          <Container>
            <h2 className={classnames(styles.h2_title)}> Refund Policy </h2>
            <p> Returns is a scheme provided by respective sellers directly under this policy in terms
              of which the option of exchange, replacement and/ or refund is offered by the
              respective sellers to you. All products listed under a particular categor
              may not have the same returns policy. For all products, the returns/replacement
              policy provided on the product page shall prevail over the general returns policy.
              Do refer the respective items applicable return/replacement policy on the product page
              for any exceptions to this returns policy and the table below. </p>
          </Container>
          <Container>
            <Row>
              <Col md={4} sm={6}>
                <div className={classnames(styles.rf_block)} style={{ borderColor: '#f39100' }}>
                  <h3> Report </h3>
                  <p> Returns is a scheme provided by respective sellers directly under this policy
                    in terms of which the option of exchange, replacement and/ or refund is offered
                    by the respective sellers to you. All products listed under a particular categor
                    not have the same returns policy. For all products, the returns/replacement policy
                    provided on the product page shall prevail over the general returns policy.
                    Do refer the respective item applicable return/replacement policy on the product
                    page for any exceptions to this returns policy and the table below.  </p>
                </div>
              </Col>
              <Col md={4} sm={6}>
                <div className={classnames(styles.rf_block)} style={{ borderColor: '#f39100' }}>
                  <h3> Analyse </h3>
                  <p> Returns is a scheme provided by respective sellers directly under this
                    policy in terms of which the option of exchange, replacement and/ or
                    refund is offered by the respective sellers to you. All products
                    listed under a particular categor may not have the same returns policy.
                    For all products, the returns/replacement policy provided on the product
                    page shall prevail over the general returns policy. Do refer the
                    respective item applicable return/replacement policy on the
                    product page for any exceptions to this returns policy and the table below.  </p>
                </div>
              </Col>
              <Col md={4} sm={6}>
                <div className={classnames(styles.rf_block)} style={{ borderColor: '#000000' }}>
                  <h3> Processed </h3>
                  <p> Returns is a scheme provided by respective sellers directly
                    under this policy in terms of which the option of exchange, replacement
                    and/ or refund is offered by the respective sellers to you. All products
                    listed under a particular categor may not have the same returns policy.
                    For all products, the returns/replacement policy provided on the product
                    page shall prevail over the general returns policy. Do refer the respective
                    item applicable return/replacement policy on the product page for any
                    exceptions to this returns policy and the table below.  </p>
                </div>
              </Col>
            </Row>
          </Container>
          <br />
          <br />
          <br />
          <Container>
            <h2 className={classnames(styles.h2_title)}> Payment Policy </h2>
            <p> Returns is a scheme provided by respective sellers directly under
              this policy in terms of which the option of exchange, replacement
              and/ or refund is offered by the respective sellers to you. All
              products listed under a particular categor may not have the same
              returns policy. For all products, the returns/replacement policy
              provided on the product page shall prevail over the general returns policy.
              Do refer the respective item applicable return/replacement policy on the
              product page for any exceptions to this returns policy and the table below. </p>
          </Container>
          <Container>
            <Row>
              <Col md={4} sm={6}>
                <div className={classnames(styles.rf_block)} style={{ borderColor: '#f39100' }}>
                  <h3> Report </h3>
                  <p> Returns is a scheme provided by respective sellers directly
                    under this policy in terms of which the option of exchange,
                    replacement and/ or refund is offered by the respective sellers to you.
                    All products listed under a particular categor may not have the same
                    returns policy. For all products, the returns/replacement policy provided
                    on the product page shall prevail over the general returns policy.
                    Do refer the respective item applicable return/replacement policy
                    on the product page for any exceptions to this returns policy and the
                    table below.  </p>
                </div>
              </Col>
              <Col md={4} sm={6}>
                <div className={classnames(styles.rf_block)} style={{ borderColor: '#f39100' }}>
                  <h3> Analyse </h3>
                  <p> Returns is a scheme provided by respective sellers directly
                    under this policy in terms of which the option of exchange,
                    replacement and/ or refund is offered by the respective sellers to you.
                    All products listed under a particular categor may not have the same returns policy.
                    For all products, the returns/replacement policy provided on the product page shall
                    prevail over the general returns policy. Do refer the respective item applicable
                    return/replacement policy on the product page for any exceptions to this returns policy
                    and the table below.  </p>
                </div>
              </Col>
              <Col md={4} sm={6}>
                <div className={classnames(styles.rf_block)} style={{ borderColor: '#000000' }}>
                  <h3> Processed </h3>
                  <p> Returns is a scheme provided by respective sellers directly
                    under this policy in terms of which the option of exchange,
                    replacement and/ or refund is offered by the respective sellers to you.
                    All products listed under a particular categor may not have the same returns policy.
                    For all products, the returns/replacement policy provided on the product page shall
                    prevail over the general returns policy. Do refer the respective items applicable
                    return/replacement policy on the product page for any exceptions to this returns policy
                    and the table below.  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <br />
        <br />
      </Fragment>
    )
  }
}

export default withRouter(PaymentRefundPolicy)
