import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col, Carousel, CarouselItem } from 'react-bootstrap'
import cs from 'classnames'
// import { Icon, IconType } from '../../common'
import MetaTags from 'react-helmet'
import Expert from './Expert'
import Guided from './Guided'
// import GuidedCompleteFeatures from './GuidedCompleteFeatures'
import Faq from './Faq'
import { SEO } from '../../../constants/constants'
import GetStartedButton from '../Home/GetStartedButton'
import st from './style.scss'

export default class Pricing extends Component {
  static propTypes = {
    gotoURL: PropTypes.func.isRequired,
    loginWithRedirect: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      showExpert: false,
    }
  }
  toggleSection = () => {
    const { showExpert } = this.state
    this.setState({
      showExpert: !showExpert,
    })
  }

  render() {
    const { showExpert } = this.state
    const { gotoURL } = this.props
    return (
      <div>
        <MetaTags>
          <title>Pricing | Best Resume Builder | Export Resume | Resumaker</title>
          <meta content={SEO.DESCRIPTION} name="description" />
        </MetaTags>
        <div className={cs(st.section_bg)}>
          <Container>
            <div>
              <div className={cs(st.center_text)}>
                <h2>The simplest solution of your job starts here </h2>
                <p>
                  To stand out and increase your chances of getting an interview invitation,
                  you need a competitive resume.
                  A resume that can bring you closer to landing your dream job.
                  Choose from our plan to get unlimited access to premium tool and content.
                </p>
              </div>
              <div className={cs(st.box)}>
                <div className={cs(st.text, st.animated, st.pulse)}>
                  Signup and when checkout apply coupon <span>EARLY2250 </span>
                  to avail <strong> 50% </strong> discount on monthly plan.
                </div>
              </div>
            </div>
          </Container>
          <Container>
            {/* <div className={cs(st.section, st.product_option)}>
              <div className={cs(st.resume_options)}>
                <div className={cs(st.guided_resume)}>Guided Resume</div>
                <Form>
                  <Form.Switch
                    className={cs(st.toggle_btn)}
                    id="resume-type"
                    label=""
                    onClick={this.toggleSection}
                    type="switch" />
                </Form>
                <div className={cs(st.hire_expert, st.guided_resume)}>Hire Expert</div>
              </div>
            </div> */}
            {(showExpert ? <Expert /> : (
              <Fragment> <Guided loginWithRedirect={this.props.loginWithRedirect} />
                {/* <GuidedCompleteFeatures /> */}
              </Fragment>
            ))}
          </Container>
          <Container>
            <Row className={cs('pt-5')}>
              <Col lg={6} md={12} sm={12} xl={6} xs={12}>
                <div className={cs(st.contact)}>
                  <img alt="" src="/assets/images/content.png" />
                </div>
              </Col>
              <Col className={cs(st.contact)} lg={6} md={12} sm={12} xl={6} xs={12}>
                <h4>Contact area</h4>
                <p>
                  Resumaker gives priority to customer satisfaction.
                  We are here to address your queries at the earliest possible,
                  just drop us a note or call our customer support.
                </p>
                <div className={cs(st.contact_btn)} onClick={() => gotoURL('/contact')}>
                  <GetStartedButton
                    label="Reach us" size="md" />
                </div>
              </Col>
            </Row>
          </Container>
          <div className={cs(st.companies_list_section)}>
            <Container>
              <Row className={cs(st.companies_list)}>
                <Col className={cs(st.companies_list_items)} lg={7} md={7} sm={12} xl={7} xs={12}>
                  <h4>Over 5,000+ users from top organizations rely on Resumaker</h4>
                  <img alt="" src="/assets/images/home_logo_3.png" />
                  <img alt="" src="/assets/images/home_logo_6.png" />
                  <img alt="" src="/assets/images/home_logo_7.png" />
                  <img alt="" src="/assets/images/home_logo_2.png" />
                </Col>
                <Col lg={5} md={5} sm={12} xl={5} xs={12}>
                  <Carousel className={cs(st.review)} id="sampleSlide" indicators={false}>
                    <CarouselItem>
                      <div className="itemContent">
                        <div className={cs(st.review_title)}>
                          <img alt="" src="/assets/images/review-star.png" />
                          <h5> An excellent tool for creating Resume</h5>
                        </div>
                        <div className={cs(st.review_text)}>
                          <p>
                            After using Resumaker for the first, I was thrilled to receive
                            a positive callback from the target organization.
                          </p>
                          <h6>Adam Roy</h6>
                        </div>
                      </div>
                    </CarouselItem>
                    <CarouselItem>
                      <div className="itemContent">
                        <div className={cs(st.review_title)}>
                          <img alt="" src="/assets/images/review-star.png" />
                          <h5> Excellent site to build resume</h5>
                        </div>
                        <div className={cs(st.review_text)}>
                          <p>Unique performance is what I admire most about Resumaker.
                            They helped me get a top position at the world-best engineering firm.
                          </p>
                          <h6>Akanksha Singh</h6>
                        </div>
                      </div>
                    </CarouselItem>
                    <CarouselItem>
                      <div className="itemContent">
                        <div className={cs(st.review_title)}>
                          <img alt="" src="/assets/images/review-star.png" />
                          <h5> Professional Service</h5>
                        </div>
                        <div className={cs(st.review_text)}>
                          <p>
                            High-quality performance and professionalism are
                            the key principles that outstand Resumaker from other providers.
                            I could not describe my joy after receiving
                          </p>
                          <h6>Akhil | Fresh Graduate</h6>
                        </div>
                      </div>
                    </CarouselItem>
                    <CarouselItem>
                      <div className="itemContent">
                        <div className={cs(st.review_title)}>
                          <img alt="" src="/assets/images/review-star.png" />
                          <h5> My dream come true</h5>
                        </div>
                        <div className={cs(st.review_text)}>
                          <p>
                            I strongly recommend Resumaker for resume planning and preparation.
                            They are well-versed and competent in their role and responsibilities.
                            They made my dream come true. That might happen to you as well.
                          </p>
                          <h6>Rebecca Saloni | Human Resource manager</h6>
                        </div>
                      </div>
                    </CarouselItem>
                    <CarouselItem>
                      <div className="itemContent">
                        <div className={cs(st.review_title)}>
                          <img alt="" src="/assets/images/review-star.png" />
                          <h5> Saved me tons of time</h5>
                        </div>
                        <div className={cs(st.review_text)}>
                          <p>
                            Unique performance is what I admire most about Resumaker.
                            They helped me get a top position at the world-best engineering firm.
                            They aligned my experiences and skills with the job descriptions.
                          </p>
                          <h6>Mary Reddington | Engineer</h6>
                        </div>
                      </div>
                    </CarouselItem>
                  </Carousel>
                </Col>
              </Row>
            </Container>
          </div>
          <Faq />
        </div>
      </div>
    )
  }
}
