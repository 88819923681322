import React, { Component } from 'react'
import classnames from 'classnames'
import { Container, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import PromoCard from './PromoCard'
import Download from '../../common/Download'
import GetStartedButton from '../../MainContent/Home/GetStartedButton'
import { getDocUrl } from '../../../constants'
import styles from './style.scss'

export default class BestResume2022 extends Component {
    static propTypes = {
      history: PropTypes.object.isRequired,
      loginWithRedirect: PropTypes.func.isRequired,
    }
    render() {
      const { loginWithRedirect, history } = this.props
      return (
        <div>
          <Container fluid>
            <div className={classnames('page-main-wrapper')}>
              <div className={classnames(styles.container_xl, 'mt-5', 'mb-5')}>
                <div className={classnames(styles.page_content_header, 'row', 'align-items-center')}>
                  <Col lg={12} md={12} xl={12} xs={12}>
                    <div className={classnames('text-center', 'mx-auto')}>
                      <img
                        alt="fresher resume" className={classnames(styles.resume_sample_vector, 'img-fluid', 'mx-auto')}
                        src="/assets/images/Best_vector.jpg" />
                      <div
                        className={classnames('mt-md-5', 'text-center', 'mb-md-4')}>
                        <h1 className={classnames('mt-4', styles.size_heading)}> Best Resume Template in 2022</h1>
                        <p className={classnames('mb-0')}>
                          Writing the best resume can be challenging if
                          you don&#8242;t know how to go about it. If you don&#8242;t know
                          how to craft a resume or feel that your CV isn&#8242;t good enough
                          to beat the competition, don&#8242;t worry; we&#8242;ve got you covered.</p>
                      </div>
                      <div className={classnames(styles.btn_gs)}>
                        <GetStartedButton label="Build My Resume" loginWithRedirect={loginWithRedirect} size="lg" />
                      </div>
                    </div>
                  </Col>
                  <div className={classnames(styles.resume_sample_container, 'mt-5', 'pt-3', 'pt-lg-4')}>
                    <Row>
                      <Col lg={6}>
                        <div className={classnames('mb-5', 'p-xl-5', 'mb-xl-0')}>
                          <div className={classnames(styles.resume_sample)}>
                            <img
                              alt="Resume Sample" className={classnames(styles.resume_sample_img)}
                              src="/assets/images/Best_resume.jpg" />
                            <Download
                              downloadURL={getDocUrl('assets/docs/Best-res-2022.docx')}
                              history={history} />
                          </div>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className={classnames('mb-5', 'p-xl-5', 'mb-xl-0')}>
                          <div className={classnames(styles.resume_sample)}>
                            <img
                              alt="Resume Sample" className={classnames(styles.resume_sample_img)}
                              src="/assets/images/Best_cl.jpg" />
                            <Download
                              downloadURL={getDocUrl('assets/docs/Best-cl-2022.docx')}
                              history={history} />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className={classnames(styles.resume_sample_header, 'text-center')}>
                      <div className={classnames(styles.container_xl)}>
                        <h2 className={classnames(styles.section_heading)}>
                          Best Resume Sample in 2022
                        </h2>
                        <p className={classnames('mt-2', styles.section_paragraph)}>
                          Make sure you choose the right resume
                          format to suit your unique experience and the job you are applying.</p>
                      </div>
                    </div>
                    <div className={classnames(styles.resume_sample_wrapper)}>
                      <div className={classnames(styles.container_xl)}>
                        <Row>
                          <Col lg={8} xl={8} xs={12}>

                            <div className={classnames(styles.resume_sample_header, 'pb-5')}>
                              <h2 className={classnames(styles.section_heading, 'mb-2')}>
                                How to Write a Best Resume in 2022</h2>
                              <p>In this guide, you&#8242;ll get insights into what makes a resume stand out,
                                how to write a resume in 2022, 2022 best resume format,
                                the best resume template in 2022, key skills in 2022,
                                and the most used resume template in 2022.</p>
                            </div>
                            <div
                              aria-labelledby="resumeContentTab"
                              className={classnames(styles.resume_content_card,
                                styles.dz_card, styles.shadow_1, 'px-0', 'px-lg-5', 'py-5', 'tab-pane active')}
                              id="resumeContent" role="tabpanel">
                              <h3 className={classnames(styles.resume_content_heading, 'mt-0')}>
                                How to Write a Resume in 2022</h3>
                              <p className={classnames('mb-2')}>Great resume composing is a fine art and
                                can have the effect of making you invited for an interview. Nonetheless,
                                many individuals don&#8242;t consider it to be
                                an artistic expression but a commitment.
                                Many people consider composing a resume as something they
                                need to do to find a new line of work.</p>
                              <p className={classnames('mb-0')}>To craft a good resume, you must spend your time,
                                think about how to go about it, and be a creative writer.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>
                                Here is what to consider if you want to compose the best resume.</h5>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>
                                Beware of Applicant Tracking Systems (ATS)</h5>
                              <p className={classnames('mb-2')}>It would be best if you realized
                                that great deals of organizations today are utilizing
                                Applicant Tracking Systems to help them screen resume
                                and locate the best candidates.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                How does this work?</h5>
                              <p className={classnames('mb-2')}> The Applicant Tracking Systems
                                examines your resume for specific keywords and gives
                                you a score dependent on how well your
                                resume coordinates a foreordained list of keywords
                                selected by the organization you’re interviewing with.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-0', 'mb-2')}>
                                Here is what to do to make your resume get past ATS:</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Don&#8242;t simply zero in on keywords on job descriptions
                                  but focus around semantic keywords also. Modern resume
                                  screeners go past keywords to search for semantic matches.
                                  For example, if the keyword in the job advert is CPA,
                                  the screener will search for related terms, such as
                                  bookkeeping, auditing, and fiscal summaries.</li>
                                <li>Organize the words in your resume.
                                  It is vital to review the set of job descriptions
                                  to construct a list of primary and secondary words.</li>
                                <li>Counsel an insider for help in finding applicable words.
                                  It never damages to get cordial with an HR supervisor or
                                  manager in your field—you can go directly to
                                  the source and inquire whether they could
                                  either analyze your resume or recommend what
                                  sorts of skills they consider in a candidate.</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold')}>
                                Utilize the Right Resume Fonts
                              </h5>
                              <p className={classnames('mb-2')}>
                                It would help if you had your resume to stick out,
                                yet for the correct reasons. Your resume is a showcasing
                                instrument that sells you to a company in a professional way.</p>
                              <p className={classnames('mb-2')}>
                                If your resume has an amateurish text style,
                                the chances are that the recruiters will
                                give less consideration to it. It is prudent to
                                utilize Serif text styles since they are adapted
                                text styles with tails and other beautifying markings.
                                Instances of serif textual styles incorporate Times New Roman.
                                They are seen as being dependable, legitimate, and traditional.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Understand Resume Layout and Formatting</h5>
                              <p className={classnames('mb-2')}> The primary guideline of the
                                layout is to keep it perfect and clear. You need a resume
                                that is legible and easy to follow.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-3')}>
                                Here is an outline of a resume format</h5>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Margin</h5>
                              <p className={classnames('mb-2')}>Keep your edges to ½ to 1 inch on
                                all sides of the paper, particularly in case you&#8242;re sending
                                your resume to anybody you think may print it out.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Font size</h5>
                              <p className={classnames('mb-2')}>Except for your name, which can be bigger,
                                you need to keep your text dimension at somewhere
                                in the range of 10 and 12 points.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Spacing</h5>
                              <p className={classnames('mb-2')}>Single spacing works best,
                                with a clear line between each part of the content.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Paper</h5>
                              <p className={classnames('mb-2')}>If you&#8242;re printing out your
                                resume, try to utilize a laser
                                printer or inkjet printer that produces great outcomes.
                                Use off-white or ivory papers.</p>
                              <p className={classnames('mb-2')}>The primary thing you need to do is to
                                ensure that a potential employer realizes
                                whose resume they&#8242;re dealing with.</p>
                              <p className={classnames('mb-2')}>Ensure you incorporate your own data
                                at the TOP of your resume. Incorporate your complete name,
                                telephone number, email, and individual marking site or
                                brilliant white paper, and consistently adhere to the
                                standard 8 ½ X 11 paper in the highest quality you can afford.
                                Ensure if there is a watermark on the paper that is
                                representing whatever you do, keep it readable.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Understand How to Write Resume Categories</h5>
                              <p className={classnames('mb-2')}>Ensure that your resume categories
                                are very much characterized and coordinated properly for your
                                identity and the kind of resume you&#8242;re utilizing.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-3')}>
                                Here are the categories that you need to include on your resume.</h5>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Header</h5>
                              <p className={classnames('mb-2')}>The principal thing you need to
                                do is ensure that a potential employer realizes
                                whose resume they&#8242;re dealing with.</p>
                              <p className={classnames('mb-2')}>Ensure you incorporate your own
                                data at the top of your resume. Include your complete name,
                                telephone number, email, and personal branding website.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Resume Objective</h5>
                              <p className={classnames('mb-2')}>A resume objective is an outline
                                of your work objectives with the organization you&#8242;re applying
                                to and should take up close to a sentence or two.
                                An objective statement is ideal for fresh graduates
                                without experience. Ensure that you include your degrees,
                                abilities, and years of experience on a resume.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Resume Summary</h5>
                              <p className={classnames('mb-2')}>A resume summary is a quick
                                recap of your skills and experiences and ought to be
                                close to a sentence or two. Highlight your top skills,
                                highest qualifications, and individual characteristics.
                                Ensure you focus on your title, capabilities, or experience
                                issues that are identified with the position you are applying for.
                                Additionally, incorporate a particular value
                                that you can bring to the organization.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Experience</h5>
                              <p className={classnames('mb-2')}>This part is the place where
                                you talk about your work history and feature employers
                                you worked for and what you did, and how long you did it.
                                Make certain to incorporate your work title just as a
                                bulleted list of your obligations as well as duties.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Skills</h5>
                              <p className={classnames('mb-2')}>This is the place where you need to feature
                                your important skills that relate straightforwardly to the
                                position or occupation you&#8242;re applying for. Ensure you list skills
                                that set you apart and help show a potential employer
                                that you&#8242;re the ideal possibility for the work!</p>
                              <p className={classnames('mb-2')}>These can incorporate things
                                like technical skills, language abilities, PC abilities,
                                and anything that sets you apart from other competitors.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Education</h5>
                              <p className={classnames('mb-2')}>For fresh graduates and individuals simply
                                entering the work market, this would mean where you went to class.
                                For individuals in technical fields or fields where outside training
                                is either required or encouraged, you would incorporate this data here.
                                Arrange your academic qualifications from the highest to the lowest.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                References</h5>
                              <p className={classnames('mb-2')}>You may include references on
                                your resume, but it is not a must. That doesn&#8242;t
                                mean you needn&#8242;t bother with them. Instead, have them as
                                a different list, and whenever requested, don&#8242;t hesitate to provide it.</p>

                              <h3 className={classnames(styles.resume_content_heading, 'mt-3', 'mb-2')}>
                                Best Resume Format 2022</h3>
                              {/* <img
                              alt="Resume Sample" className={classnames('mt-1', 'mb-3')}
                            src="/assets/images/developersp.jpg" /> */}
                              <p className={classnames('mb-2')}>The primary reason for a resume
                                is to make a recruiter intrigued enough to welcome you to an interview.
                                Accordingly, realizing which resume format will work best for you
                                is vital to your prosperity.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-3', 'mb-3')}>
                                Here are the types of resume formats to choose from</h5>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Chronological Resume Format</h5>
                              <p className={classnames('mb-2')}>The chronological resume is, by all accounts,
                                the most famous resume design utilized. This sort of resume ordinarily
                                contains a goal as well as career summary statement and a
                                sequential listing (from latest to past) of every one of your
                                employers alongside related achievements. Academic data is
                                incorporated alongside certifications and exceptional skills.</p>
                              <p className={classnames('mb-2')}>This kind of resume arrangement might be
                                fine for somebody who is capable, but if you are switching careers
                                or are merely entering the labor force, this sort of resume
                                will undoubtedly assist you to succeed in your endeavors.</p>
                              <p className={classnames('mb-2')}>The chronological format shows hiring
                                managers that your latest work experience is applicable
                                to their requirements, introduces yourself as far as promotions
                                and upward career mobility, and exhibits that you&#8242;ve had a
                                typical profession without work gaps or terminations.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Functional Resumes</h5>
                              <p className={classnames('mb-2')}>Functional resumes feature your abilities,
                                such as communication, managing, or training, as opposed to your
                                ordered work history. You&#8242;ll actually have to sum up your work history,
                                yet this is normally done at the lower part of your resume.</p>
                              <p className={classnames('mb-2')}>In contrast to a chronological resume,
                                a functional resume explicitly focuses on the work you&#8242;re applying for
                                and ensure that it features the skills and capacities you have that
                                identify with that position.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Blend Resume Format</h5>
                              <p className={classnames('mb-2')}>The blend resume format takes the very
                                most fantastic aspects of a functional resume (applicable skills,
                                capabilities, and explicitly focused on data) and consolidates
                                it with the chronological resume (your work history).</p>
                              <h3 className={classnames(styles.resume_content_heading, 'mt-3')}>
                                What Employers Look from You In 2022</h3>
                              <p className={classnames('mb-2')}>In 2022, employers are searching for
                                a resume that sticks out. Here are the tips to make your resume look
                                wonderful before recruiters.</p>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Make your resume outwardly engaging.</li>
                                <li>Customize your resume to coordinate the work necessities.</li>
                                <li>Zero in on accomplishments, not simply obligations and duties.</li>
                                <li>Quantify whenever possible.</li>
                                <li>Utilize the proper resume length.</li>
                                <li>Spotlight just on pertinent data.</li>
                              </ul>
                              <h3 className={classnames(styles.resume_content_heading, 'mt-4')}>
                                Key Skills in 2022 for Your Resume</h3>
                              <p className={classnames('mb-3')}>Recruiters are searching for explicit
                                resume skills and capacities for the work they&#8242;re attempting to fill.
                                Your obligation is to ensure you fit what they&#8242;re searching for.
                                Ensure to highlight explicit skills that are tailored for every
                                application you submit.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-4')}>Here are the
                                skills to put on a resume
                                that are universally valued by potential employers.</h5>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>
                                Communication</h5>
                              <p className={classnames('mb-2')}>Communication involves
                                listening, verbal, and written. This is the main skill employers
                                value in an applicant.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Technical Literacy Skills</h5>
                              <p className={classnames('mb-2')}>Pretty much every work these
                                days requires some degree of computer capability, including
                                spreadsheets, word processing, and emails.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Interpersonal Skills</h5>
                              <p className={classnames('mb-2')}>Interpersonal skills refer to how well you fit in a
                                team and your ability to relate to co-workers.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Organization Skills</h5>
                              <p className={classnames('mb-2')}>Arranging abilities allude
                                to how well you can design, plan, put together,
                                and execute activities and errands inside a period. Organization
                                skills can likewise apply to objective setting and accomplishment.</p>
                              <h3 className={classnames(styles.resume_content_heading, 'mt-4')}>
                                Most Used Resume Template in 2022</h3>
                              <p className={classnames('mb-3')}>The resume format you
                                use will rely upon your
                                experience, capabilities, and the
                                sort of occupation you are managing.</p>
                              <h5 className={classnames('font-weight-bold')}>Here are the most used
                                resume templates in 2022.</h5>
                              <h5 className={classnames('font-weight-bold', 'mt-3', 'mb-2')}>
                                Simple resume templates</h5>
                              <p className={classnames('mb-2')}>A simple resume template is the
                                best methodology for an assortment of positions.
                                Under this template, you focus on your achievements,
                                experience, training, and many more.</p>
                              <p className={classnames('mb-2')}>Simple templates are adaptable
                                and function admirably across an assortment of fields and occupation titles.
                                Truth be told, some recruiters
                                incline toward this template since it permits them to assess the
                                competitor&#8242;s capabilities honestly.</p>
                              <p className={classnames('mb-2')}>Because your template is
                                simple doesn&#8242;t mean your resume must be. It is advisable
                                to leave off evident skills like MS Word or web research.
                                Additionally, avoid highlighting obsolete or insignificant experience.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Professional resume templates</h5>
                              <p className={classnames('mb-2')}>A professional resume template is ideal for
                                formal positions in corporate organizations.</p>
                              <p className={classnames('mb-2')}>If you are applying for work that
                                requires formal attire, those are good signs that your application
                                requires a professional resume. The best resume layouts are expertly
                                created with quieted shading palettes and clean lines to send the message
                                that you are a refined candidate with the right
                                skills and experience to complete a serious task.</p>
                              <p className={classnames('mb-2')}>To benefit from your
                                professional template, it&#8242;s significant that you amplify
                                the space to incorporate just the most important details and
                                achievements that will show a potential employer how you can
                                increase the value of their organization. Ensure your composing likewise conveys
                                an expert tone, and don&#8242;t be hesitant to ask a
                                companion for help with editing and proofreading.</p>
                              <h5 className={classnames('font-weight-bold')}>
                                Modern resume templates</h5>
                              <p className={classnames('mb-2')}>A modern resume template is
                                a current and smart feel for candidates in innovative fields.</p>
                              <p className={classnames('mb-2')}>In case you&#8242;re a candidate looking to demonstrate
                                that you&#8242;re up to date, the resume template will work for you.
                                These styles ooze a feeling of cool, quiet, and assortment with
                                intriguing shading palettes and solid text styles.</p>
                              <h5 className={classnames('font-weight-bold')}>Creative resume templates</h5>
                              <p className={classnames('mb-2')}>A creative resume template is strong, unique,
                                and ideal for artistic fields and new organizations.
                                If you are a creative artist, it is vital to use
                                this kind of template to craft your resume.</p>
                              <p className={classnames('mb-2')}>From photography to fashions,
                                visual depiction, and even cosmetology, creative profession is
                                tremendous and varied. If you are applying for creative positions,
                                you need to convey passion and ability.
                                A creative resume layout is an ideal approach to do precisely that.</p>
                            </div>
                          </Col>
                          <Col lg={4} xl={4} xs={12}>
                            <PromoCard loginWithRedirect={loginWithRedirect} />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      )
    }
}
