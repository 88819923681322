import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { inject } from 'mobx-react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { get } from 'lodash'
import { Container, Button, Row, Col } from 'react-bootstrap'
import MetaTags from 'react-helmet'
import ReactHtmlParser from 'react-html-parser'
import { FacebookShareButton, FacebookIcon, LinkedinShareButton, LinkedinIcon,
  TwitterShareButton, TwitterIcon } from 'react-share'
import { getBlogImageUrl, getBlogVideoUrl, getFrontendMappedUrl } from '../../constants'
import BlogDetailTestimonial from './BlogDetailTestimonial'
import BlogDetailTemplate from './BlogDetailTemplate'
import BlogDetailMoreBlogs from './BlogDetailMoreBlogs'
import { Icon, IconType } from '../common'

import styles from './style.scss'

@inject('mainModel')
class BlogDetail extends Component {
static propTypes = {
  filterByTag: PropTypes.func.isRequired,
  gotoBlogDetails: PropTypes.func.isRequired,
  gotoURL: PropTypes.func.isRequired,
  mainModel: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

constructor(props){
  super(props)
  this.state = {
    blogDetail: {},
    relatedBlogs: [],
    shareUrl: '',
  }
}

componentDidMount(){
  const { match } = this.props
  const blogId = get(match, 'params.key', '')
  this.fetchBlogDetails(blogId)
  window.scrollTo(0, 0)
}

fetchBlogDetails = (blogId, pushurl = false) => {
  const { mainModel } = this.props
  const cb = (blogDetail) => {
    this.setState({
      blogDetail,
      shareUrl: getFrontendMappedUrl(`blog/${get(blogDetail, 'blogId')}`),
    })
  }
  mainModel.getBlogData(blogId, cb)
  mainModel.getRelatedBlogs((relatedBlogs) => this.setState({ relatedBlogs }))
  if (pushurl){
    this.props.gotoBlogDetails(blogId)
  }
}

share = (platform) => {
}

render() {
  const { gotoURL } = this.props
  const { blogDetail, relatedBlogs, shareUrl } = this.state
  const sections = get(blogDetail, 'sections')
  const media = get(blogDetail, 'media')
  const seo = get(blogDetail, 'seo')
  const tags = get(blogDetail, 'tags')
  const { SHARE, VIEW, LIKE } = IconType.SOCIAL
  const { cannonical } = getFrontendMappedUrl(`blog/${get(blogDetail, 'blogId')}`)

  return (
    <div>
      <Container>
        <MetaTags>
          <title>{get(seo, 'title')}</title>
          <meta content={get(seo, 'description')} name="description" />
          <link href={cannonical} rel="canonical" />
          <meta content={get(seo, 'title')} property="og:title" />
          <meta content={getBlogImageUrl(get(media, 'url'))} property="og:image" />
        </MetaTags>
        <Row>
          <Col lg={2} md={2} sm={3} xl={2} xs={12} />
          <Col lg={8} md={12} sm={12} xl={8} xs={12}>
            <div className={classnames(styles.blog_details_content)}>
              <h1>{get(blogDetail, 'title')}</h1>
              <h5> Written by {get(blogDetail, 'author')} </h5>
              <p className={classnames(styles.date)}>
                {get(blogDetail, 'createdMonth')} {get(blogDetail, 'createdDay')},
                20{get(blogDetail, 'createdYear')}</p>
            </div>
          </Col>
          <Col lg={2} md={2} sm={3} xl={2} xs={12} />
          <Col lg={12} md={12} sm={12} xl={12} xs={12}>
            {get(media, 'type') === 'images' && (
            <img
              alt={'Blog'}
              className={classnames(styles.blog_image_responsive_dtl, styles.blog_details_image)}
              src={getBlogImageUrl(get(media, 'url'))} />
            )}
            {get(media, 'type') === 'videos' && (
            <video
              className={classnames(styles.blog_image_responsive)}
              src={getBlogVideoUrl(get(media, 'url'))} />
            )}
          </Col>
        </Row>
      </Container>
      <Container className={classnames(styles.mt_30)}>
        <Row className={classnames(styles.blog_details_complete_div)}>
          <Col className={classnames(styles.mt_20, styles.pd_share_00)} lg={1} md={1} sm={12} xl={1} xs={12}>
            <ul className={classnames(styles.blog_details_social_list)}>
              <li>
                <Icon color={'#787878'} names={[VIEW]} styles={'icon10 darkGrayAlt'} />
                <div className={classnames(styles.blog_deatils_count_icon)}>
                  {get(blogDetail, 'viewCount')} </div>
              </li>
              <li>
                <Icon color={'#787878'} names={[LIKE]} styles={'icon10 darkGrayAlt'} />
                <div className={classnames(styles.blog_deatils_count_icon)}>
                  {get(blogDetail, 'likeCount')} </div>
              </li>
              <li>
                <Icon color={'#787878'} names={[SHARE]} styles={'icon10 darkGrayAlt'} />
                <div className={classnames(styles.blog_deatils_count_icon)}>
                  {get(blogDetail, 'shareCount')} </div>
              </li>
              <li>
                <div className={classnames(styles.blog_deatils_share)}> Share </div>
              </li>
              <li>
                <FacebookShareButton
                  onClick={() => this.share('FACEBOOK')}
                  url={shareUrl}>
                  <FacebookIcon round={true} size={40} />
                </FacebookShareButton>
              </li>
              <li>
                <LinkedinShareButton
                  onClick={() => this.share('LINKED_IN')}
                  url={shareUrl}>
                  <LinkedinIcon round={true} size={40} />
                </LinkedinShareButton>
              </li>
              <li>
                <TwitterShareButton
                  onClick={() => this.share('TWITTER')}
                  url={shareUrl}>
                  <TwitterIcon round={true} size={40} />
                </TwitterShareButton>
              </li>
            </ul>
          </Col>
          <Col className={classnames(styles.mt_20)} lg={8} md={8} sm={12} xl={8} xs={12}>
            <p>{ ReactHtmlParser(get(blogDetail, 'description')) }</p>
            {sections && sections.length > 0 && sections.map((section, index) => (
              <div className={classnames(styles.blog_details_lists)}>
                <h4> {get(section, 'title')} </h4>
                <p>{ ReactHtmlParser(get(section, 'description')) }</p>
              </div>
            ))}
            <div className={classnames(styles.blog_details_sign_up_div)}>
              <div
                className={classnames(styles.blog_details_sign_up)}
                onClick={() => gotoURL('/hire-expert')}> Hire Resume Expert </div>
            </div>
            {/* <div className={classnames(styles.blog_details_sign_up_div)}>
              <div
                className={classnames(styles.blog_details_subcriptions)}
                onClick={() => gotoURL('/sample-resume')}> Sample Resume </div>
              <a className={classnames(styles.blog_details_pricetable)} href="/pricing"> Price Table </a>
            </div> */}
          </Col>
          <Col className={classnames(styles.mt_20)} lg={3} md={3} sm={12} xl={3} xs={12}>
            <BlogDetailTemplate gotoURL={gotoURL} />
            <BlogDetailTestimonial />
          </Col>
        </Row>
        <BlogDetailMoreBlogs gotoBlogDetails={this.fetchBlogDetails} moreBlogs={relatedBlogs} />
        <Row>
          <Col className={classnames(styles.mt_20)} lg={12} md={12} sm={12} xl={12} xs={12}>
            <ul className={classnames(styles.blog_list_suggestions)}>
              {tags && tags.length > 0 && tags.map((tag, index) => (
                <li><Button
                  className={classnames(styles.blog_list_suggestions_tags)}
                  onClick={() => this.props.filterByTag(tag.trim())}>
                  {tag}
                </Button></li>
              ))}
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
}
export default withRouter(BlogDetail)
