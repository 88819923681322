/* eslint-disable max-len */
import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import MetaTags from 'react-helmet'
import { Container, Row, Col } from 'react-bootstrap'
import cs from 'classnames'
import PromoVideo from './PromoVideo'
import UserCompnay from './UserCompany'
import st from './style.scss'
import Templates from './Templates'
import FlowExplainer from './FlowExplainer'
import Testimonials from './Testimonials'
import HomeFAQ from './HomeFAQ'
import WhySection from './WhySection'

class Home extends Component {
  static propTypes = {
    gotoURL: PropTypes.func.isRequired,
    loginWithRedirect: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      showFullTestmonial: false,
    }
  }
  showFullTestmonial = () => {
    const { showFullTestmonial } = this.state
    this.setState({
      showFullTestmonial: !showFullTestmonial,
    })
  }

  render() {
  // const { gotoURL } = this.props
    const { showFullTestmonial } = this.state

    return (
      <Fragment>
        <MetaTags>
          <title>Resume Maker | Free Resume Templates | Resumaker</title>
          <meta content={'Creating your resume is easy with Resumaker. Select template, follow recommendations, input your data then customize using one click options. You next job resume is ready.'} name="description" />
          <link href="https://www.resumaker.co.in/" rel="canonical" />
        </MetaTags>
        <section className={cs(st.hero_banner)}>
          <Container className={cs(st.cc_fluid_pp)} fluid>
            <Row>
              <Col lg={6} md={12} sm={12} xl={6} xs={12}>
                <div>
                  <h1> THERE&lsquo;S A BETTER WAY TO GROW.</h1>
                  <p>
                    It‘s your <span> RESUME </span> that speaks about your abilities and skills.
                  </p><p>Make your resume your <span> BRAND</span>, present it confidently.
                  </p>
                  <div
                    className={cs(st.button, st.btn_hover)}
                    onClick={() => this.props.loginWithRedirect({ redirectTo: '/dashboard/create' })}>
                    Get Started
                  </div>
                </div>
              </Col>
              <Col className={cs(st.hero_bg_section)} lg={6} md={12} sm={12} xl={6} xs={12}>
                <div className={cs(st.desktop)}><img alt="start" className={cs(st.hero_image)} src={'/assets/images/hero_image.png'} />
                </div>
                <div className={cs(st.mobile)}>
                  <img alt="start" className={cs(st.hero_image)} src={'/assets/images/mob_hero_image.png'} />
                </div>
                <div className={cs(st.preview_view)}>
                  <div className={cs(st.desktop)}>
                    <img alt="start" src={'/assets/images/preview_view.jpg'} />
                  </div>
                  <div className={cs(st.mobile)}>
                    <img alt="start" src={'/assets/images/mob_preview_view.jpg'} />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className={cs(st.grid_section)}>
          <Container className={cs(st.cc_fluid_pp)} fluid>
            <UserCompnay />
          </Container>
        </section>
        <section className={cs(st.count_section)}>
          <Container className={cs(st.cc_fluid_pp, st.pp_00)} fluid>
            <div className={cs(st.flex_section)}>
              <div className={cs(st.count_user_image)}>
                <img alt="" src={'/assets/images/clam_fellow.png'} />
              </div>
              <div className={cs(st.count_content)}>
                <div className={cs(st.count_resume)}>
                  <div>
                    <h5> 15,000+ </h5>
                    <p> Resumes Downloaded </p>
                  </div>
                  <div>
                    <h5> 900+ </h5>
                    <p> Impressive icons </p>
                  </div>
                </div>
                <div className={cs(st.count_resume, st.mtb_0px, st.count_grid)}>
                  <div className={cs(st.ds_none_1199)}> </div>
                  <div>
                    <h5>3,000+ </h5>
                    <p> Recommendations </p>
                  </div>
                  <div className={cs(st.ds_none_1199)}> </div>
                  <div>
                    <h5> 10,000+ </h5>
                    <p> Satisfied Users </p>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
        <section>
          <Templates loginWithRedirect={this.props.loginWithRedirect} />
        </section>
        <section className={cs(st.testimonial_section)}>
          <Testimonials />
        </section>
        <FlowExplainer showFullTestmonial={showFullTestmonial} />
        <section className={cs(st.home_video)}>
          <PromoVideo loginWithRedirect={this.props.loginWithRedirect} />
        </section>
        <WhySection />
        <section className={cs(st.home_faq)}>
          <HomeFAQ />
        </section>
        <section className={cs(st.desktop, st.footer_ground)} />
        <section className={cs(st.mobile, st.mob_footer_ground)} />
      </Fragment>
    )
  }
}
export default withRouter(Home)
