// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".nav-tabs .nav-item.nav-link.active{color:#212121;background-color:#ffffff;border-top:5px solid #f39100;border-radius:0px;font-weight:500}.nav-tabs .nav-item.nav-link{font-size:16px;font-family:'Poppins', sans-serif;color:#212121 !important;font-weight:400}.nav-tabs .nav-link:hover{border:1px solid #fdfdfd}\n", "",{"version":3,"sources":["C:/AARWII/aarwii/Git/resumaker-web-app/public-web-app/src/assets/styles/style.scss"],"names":[],"mappings":"AAAA,oCACE,aAAc,CACd,wBAAyB,CACzB,4BAA6B,CAC7B,iBAAkB,CAClB,eAAgB,CACjB,6BAGA,cAAe,CACf,iCAAkC,CAClC,wBAAyB,CACzB,eAAgB,CAChB,0BAGC,wBAAyB","file":"style.scss","sourcesContent":[".nav-tabs .nav-item.nav-link.active {\r\n  color: #212121;\r\n  background-color: #ffffff;\r\n  border-top: 5px solid #f39100;\r\n  border-radius: 0px;\r\n  font-weight: 500;\r\n}\r\n\r\n.nav-tabs .nav-item.nav-link {\r\n\tfont-size: 16px;\r\n\tfont-family: 'Poppins', sans-serif;\r\n\tcolor: #212121 !important;\r\n\tfont-weight: 400;\r\n}\r\n\r\n.nav-tabs .nav-link:hover {\r\n  border: 1px solid #fdfdfd;\r\n}"]}]);
// Exports
exports.locals = {
	"nav-tabs": "nav-tabs",
	"navTabs": "nav-tabs",
	"nav-item": "nav-item",
	"navItem": "nav-item",
	"nav-link": "nav-link",
	"navLink": "nav-link",
	"active": "active"
};
module.exports = exports;
