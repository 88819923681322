import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'react-bootstrap'
import cs from 'classnames'
// import { Icon, IconType } from '../../common'
import MetaTags from 'react-helmet'
import Expert from './Expert'
import Guided from './Guided'
// import GuidedCompleteFeatures from './GuidedCompleteFeatures'
// import GetStartedButton from '../Home/GetStartedButton'
import { SEO } from '../../../constants/constants'
import st from './style.scss'

export default class UpgradePlan extends Component {
  static propTypes = {
    gotoURL: PropTypes.func.isRequired,
    loginWithRedirect: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      showExpert: false,
    }
  }
  toggleSection = () => {
    const { showExpert } = this.state
    this.setState({
      showExpert: !showExpert,
    })
  }

  render() {
    const { showExpert } = this.state
    // const { gotoURL } = this.props
    return (
      <div>
        <MetaTags>
          <title>Pricing | Best Resume Builder | Export Resume | Resumaker</title>
          <meta content={SEO.DESCRIPTION} name="description" />
        </MetaTags>
        <div className={cs(st.section_bg)}>
          <Container>
            <Row className={cs('pt-5')}>
              <Col className={cs(st.contact)} lg={6} md={12} sm={12} xl={6} xs={12}>
                <h4>Thanks for downloading.. </h4>
                <p><span>
                  Don&lsquo;t miss chance, beat crowd with your resume premium.</span></p>
                <p>Resumaker builder offers your variety of customization options and provides
                  your recommendations for impressive content.
                </p>
                {/* <div className={cs(st.contact_btn)} onClick={() => gotoURL('/contact')}>
                  <GetStartedButton
                    label="Reach us" size="md" />
                </div> */}
              </Col>
              <Col lg={6} md={12} sm={12} xl={6} xs={12}>
                <div className={cs(st.contact)}>
                  <img alt="" src="/assets/images/content.png" />
                </div>
              </Col>
            </Row>
          </Container>
          <Container>
            <div>
              <div className={cs(st.box)}>
                <div className={cs(st.text, st.animated, st.pulse)}>
                  Signup and when checkout apply coupon <span>EARLY2250 </span>
                  to avail <strong> 50% </strong> discount on monthly plan.
                </div>
              </div>
            </div>
          </Container>
          <Container>
            {(showExpert ? <Expert /> : (
              <Fragment> <Guided loginWithRedirect={this.props.loginWithRedirect} />
                {/* <GuidedCompleteFeatures /> */}
              </Fragment>
            ))}
          </Container>
        </div>
      </div>
    )
  }
}
