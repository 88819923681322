import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import cs from 'classnames'
import { FOOTER } from '../../constants'
import st from './style.scss'

export default class Footer extends Component {
static propTypes = {
  openURL: PropTypes.func.isRequired,
}
render() {
  const { openURL } = this.props
  return (
    <Fragment>
      <div className={cs(st.login_footer)}>
        <ul>
          <li> <div className={cs(st.footer_link)}>{FOOTER.COPY_RIGH} {FOOTER.RIGHTS} </div> </li>
          <li> <div
            className={cs(st.footer_link, st.show_cursor)}
            onClick={() => openURL('terms-conditions')}> Terms &amp; Conditions</div> </li>
          <li> <div
            className={cs(st.footer_link, st.show_cursor)}
            onClick={() => openURL('privacy-policy')}> Privacy Policy </div> </li>
        </ul>
      </div>
    </Fragment>
  )
}
}
