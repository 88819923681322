import React, { Component } from 'react'
import classnames from 'classnames'
import { Container, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import PromoCard from './PromoCard'
import Download from '../../common/Download'
import GetStartedButton from '../../MainContent/Home/GetStartedButton'
import { getDocUrl } from '../../../constants'
import styles from './style.scss'

export default class ITProfessional extends Component {
    static propTypes = {
      history: PropTypes.object.isRequired,
      loginWithRedirect: PropTypes.func.isRequired,
    }

    render() {
      const { loginWithRedirect, history } = this.props
      return (
        <div>
          <Container fluid>
            <div className={classnames('page-main-wrapper')}>
              <div className={classnames(styles.container_xl, 'mt-5', 'mb-5')}>
                <div className={classnames(styles.page_content_header, 'row', 'align-items-center')}>
                  <Col lg={12} md={12} xl={12} xs={12}>
                    <div className={classnames('text-center', 'mx-auto')}>
                      <img
                        alt="fresher resume" className={classnames(styles.resume_sample_vector, 'img-fluid', 'mx-auto')}
                        src="/assets/images/ITP_vector.jpg" />
                      <div
                        className={classnames('mt-md-5', 'text-center', 'mb-md-4', styles.get_started)}>
                        <h1 className={classnames('mt-4', styles.size_heading)}>
                          Software Engineer Resume (IT Resume)</h1>
                        <p className={classnames('mb-0')}>Software engineers are adept at addressing most of
                          the challenging assignments in the technological world.
                          However, the majority of them don&rsquo;t know how to compose
                          compelling resumes for job applications.
                          If you are looking for a software engineer position, it is pivotal to
                          acquaint yourself with making a resume.
                          This article gives bits of knowledge on the
                          best way to compose a software engineer resume.</p>
                      </div>
                      <div className={classnames(styles.btn_gs)}>
                        <GetStartedButton label="Build My Resume" loginWithRedirect={loginWithRedirect} size="lg" />
                      </div>
                    </div>
                  </Col>
                  <div className={classnames(styles.resume_sample_container, 'mt-5', 'pt-3', 'pt-lg-4')}>
                    <Row>
                      <Col lg={6}>
                        <div className={classnames('mb-5', 'p-xl-5', 'mb-xl-0')}>
                          <div className={classnames(styles.resume_sample)}>
                            <img
                              alt="Resume Sample" className={classnames(styles.resume_sample_img)}
                              src="/assets/images/itp_resume.jpg" />
                            <Download
                              downloadURL={getDocUrl('assets/docs/itp-resume.docx')}
                              history={history} />
                          </div>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className={classnames('mb-5', 'p-xl-5', 'mb-xl-0')}>
                          <div className={classnames(styles.resume_sample)}>
                            <img
                              alt="Resume Sample" className={classnames(styles.resume_sample_img)}
                              src="/assets/images/itp_cl.jpg" />
                            <Download
                              downloadURL={getDocUrl('assets/docs/itp-cl.docx')}
                              history={history} />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className={classnames(styles.resume_sample_header, 'text-center')}>
                      <div className={classnames(styles.container_xl)}>
                        <h2 className={classnames(styles.section_heading)}>
                          Software Engineer Resume (Text Format)
                        </h2>
                        <p className={classnames('mt-2', styles.section_paragraph)}>
                          Make sure you choose the right resume
                          format to suit your unique experience and the job you are applying.</p>
                      </div>
                    </div>
                    <div className={classnames(styles.resume_sample_wrapper)}>
                      <div className={classnames(styles.container_xl)}>
                        <Row>
                          <Col lg={8} xl={8} xs={12}>
                            <div className={classnames(styles.resume_sample_main)}>
                              <div className={classnames(styles.tab_content)}>
                                Text View
                              </div>
                            </div>
                            <div className={classnames(styles.resume_content_card,
                              styles.dz_card, styles.shadow_1, 'px-3', 'px-lg-5', 'py-5', 'tab-pane active')}>
                              <Row>
                                <h2 className={classnames(styles.section_heading, 'ml-2')}>
                                  Software Engineer Resume
                                </h2>
                              </Row>
                              <div className={classnames(styles.resume_content_title, 'mt-3')}>Summary</div>
                              <p className={classnames('mt-2', 'mb-2')}>
                                Persistent professional equipped with
                                comprehensive experience in the IT industry. Provide strong technical
                                direction across multiple projects. A responsible and articulate individual
                                with strengths in solutions optimization, mobile and cloud solutions and
                                global project management.
                              </p>
                              <div className={classnames(styles.resume_content_title)}>
                                Work Experience</div>
                              <ul className={classnames(styles.interview_points, 'list-unstyled',
                                styles.content_title, 'mt-4')}>
                                <li className={classnames('mb-0')}>Software Engineering Analyst</li>
                                <li className={classnames('mb-0')}>XYZ Tech, Belas, US, Dec 2019 - till date </li>

                              </ul>
                              <ul className={classnames(styles.interview_points, 'mt-2')}>
                                <li>Coordinated with hardware and system
                                  engineering leads to gather and develop system requirements.</li>
                                <li>Researched, designed and implemented
                                  scalable applications for data
                                  extraction, analysis, retrieval and indexing.</li>
                                <li>Introduced agile methodologies and
                                  effective development best practices to division to
                                  enhance product development.</li>
                                <li>Conducted regression testing, analyzed results and
                                  submitted observations to the development team.</li>
                                <li>Installed and configured software applications and
                                  tested solutions for functionality. </li>
                                <li>Worked in team-driven environments and communicated
                                  effectively with all stakeholders.</li>
                                <li>Partnered with the development team on
                                  product development, application
                                  support plans, and prototype programs.</li>
                              </ul>
                              <ul className={classnames(styles.interview_points, 'list-unstyled',
                                styles.content_title, 'mt-4')}>
                                <li className={classnames('mb-0')}>Associate Software Engineer</li>
                                <li>Sky Tech, Belas, US, Jan 2018 – Nov 2019</li>
                              </ul>
                              <ul className={classnames(styles.interview_points, 'mt-2')}>
                                <li>Worked closely with other business analysts, development
                                  teams and infrastructure specialists to
                                  deliver high availability solutions
                                  for mission-critical applications.</li>
                                <li>Collaborated with developers and product
                                  owners to stay current on product
                                  features and intended functionality.</li>
                                <li>Debugged code and located root
                                  causes of problems by reviewing
                                  configuration files and logs.</li>
                                <li>Wrote and optimized test cases to maximize
                                  the success of manual software
                                  testing with consistent, thorough strategies.</li>
                                <li>Researched, designed and implemented
                                  scalable applications for data
                                  extraction, analysis, retrieval and indexing.</li>
                                <li>Adjusted design parameters to boost
                                  performance and incorporate new features.</li>
                              </ul>
                              <div className={classnames(styles.resume_content_title)}>
                                Skills
                              </div>
                              <ul className={classnames(styles.interview_points, 'mt-4')}>
                                <li>Software applications</li>
                                <li>Database management software</li>
                                <li> Database programming</li>
                                <li> Agile </li>
                                <li> Portable code writer</li>
                                <li>Scrum </li>
                                <li> Solutions Deployment</li>
                                <li>Build Releases</li>
                                <li>A/B testing</li>
                              </ul>
                              <div className={classnames(styles.resume_content_title, 'mt-3')}>
                                Education summary</div>
                              <ul className={classnames(styles.interview_points, 'list-unstyled', 'mt-4')}>
                                <li> B. Tech (Bachelor of Technology), 2017</li>
                              </ul>
                              <div className={classnames(styles.resume_content_title, 'mt-3')}>Certifications</div>
                              <ul className={classnames(styles.interview_points, 'mt-4')}>
                                <li>
                                  AWS Certified Solutions Architect</li>
                                <li>
                                  MCSE: Cloud Platform and Infrastructure (Microsoft)</li>
                              </ul>
                            </div>
                            <div className={classnames(styles.resume_sample_header, 'pb-5')}>
                              <h2 className={classnames(styles.section_heading, 'mb-2')}>
                                How to Write a Software Engineer Resume</h2>
                              <p>This article gives bits of
                                knowledge on the best way to compose a software
                                engineer resume summary, how to
                                feature aptitudes and achievements on the resume,
                                how to list accomplishments, and the top
                                tips for composing a software engineer’s resume.</p>
                            </div>
                            <div
                              aria-labelledby="resumeContentTab"
                              className={classnames(styles.resume_content_card,
                                styles.dz_card, styles.shadow_1, 'px-0', 'px-lg-5', 'py-5', 'tab-pane active')}
                              id="resumeContent" role="tabpanel">
                              <h3 className={classnames(styles.resume_content_heading, 'mt-0')}>
                                How to Write a Software Engineer Resume Summary</h3>
                              <p className={classnames('mb-2')}>Bear in mind that a resume summary refers
                                to your professional statement at the top of a resume.
                                It depicts your experience, abilities, and accomplishments.
                                The summary’s essence is to clarify your capabilities
                                for the occupation in 3-5 sentences and persuade
                                the recruiters to read the entire resume.</p>
                              <p className={classnames('mb-0')}>If you need to
                                compose a perfect summary, you should
                                portray your solid characteristics in a few words
                                and highlight your expert experience. Moreover, state how to assist the
                                business in accomplishing their objectives, add information
                                on your key accomplishments, and use metrics where applicable.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>  Tips on How to Compose
                                a Software Engineer Resume Summary.</h5>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>  Here are the tips on how
                                to highlight skills on a software engineer resume.</h5>
                              <h5 className={classnames('font-weight-bold', 'mb-2', 'mt-4')}>
                                Tip #1: Describe who you are</h5>
                              <p className={classnames('mb-2')}> Clearly describe who you are by
                                highlighting your expertise, experience, and strengths/skills</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Example:</h5>
                              <p className={classnames('mb-2')}> Programming graduate with a qualification
                                in network communication and computer programming.
                                Committed to executing new strategies and improving
                                correspondences between offices. Master in T-SQL, C#, and Python.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Tip #2: Make the summary an elevator pitch</h5>
                              <p className={classnames('mb-2')}> An elevator pitch is a
                                summary that is concise and to the point.
                                Ensure that your resume summary has all the
                                requirements, and it is brief to be
                                perused within the shortest time.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Example:</h5>
                              <p className={classnames('mb-2')}> Performance-oriented senior computer programmer
                                with 10+ years of experience with key critical
                                thinking and network analysis. Experienced in
                                managing huge designing teams to achieve solid
                                objectives within timelines. Database
                                administration and MySQL,.NET.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Tip #3: Keep it short</h5>
                              <p className={classnames('mb-2')}> Your resume summary
                                has to be short but satisfactory
                                to feature your skill and uniqueness.</p>
                              <p className={classnames('mb-2')}>  A summary shouldn’t be
                                a repetition of the information
                                already on your resume, but it should help the
                                recruiter comprehend
                                your experience, skills, and traits.
                                It&rsquo;s a superb method of  integrating
                                your work history to assist HR managers in
                                seeing how your experience would
                                convert into the job they&rsquo;re recruiting for.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Examples of Software
                                Engineer Resume Summaries</h5>
                              <p className={classnames('mb-2')}> Here is the right and wrong examples
                                of software engineer resume summaries</p>
                              <h5 className={classnames('font-weight-bold', 'mb-0', styles.align)}>
                                Tip #4: Prioritize what technical
                                skills
                                to list on your resume and what not</h5>
                              <h5 className={classnames('font-weight-bold', 'mb-0', styles.align)}>
                                Right</h5>
                              <p className={classnames('mb-0')}> A back-end designer with 10+ of experience
                                in building huge scope applications and
                                vital contributions to the engineering
                                of open-source projects ABC and KLM. Anxious to
                                utilize specialized abilities and tremendous
                                experience with building Java-based applications
                                at XYZ organization&rsquo;s Beta undertaking.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-0', styles.align)}>
                                Wrong</h5>
                              <p className={classnames('mb-3')}> An accomplished computer programmer
                                with the energy to create complex applications.
                                Willing to learn new things and intending to
                                fill the position as a
                                Software Engineer at MNO organization.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2', styles.align)}>
                                Tips on How to Highlight Skills
                                on a Software Engineer Resume.</h5>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Here are the tips on how to highlight
                                skills on a software engineer resume.</h5>
                              <h5 className={classnames('font-weight-bold', 'mb-2', 'mt-3')}>
                                Tip #1: List relevant technology
                                skills</h5>
                              <p className={classnames('mb-2')}> Posting aptitudes needed for the
                                position isn’t an easy decision.
                                You ought to be precise in listing your relevant skills.
                                However, if you highlight a large number
                                of abilities, recruiting managers
                                may think that your knowledge is
                                excessively broad and not sufficiently
                                profound to perform a significant duty.
                                It will likewise seem as though you committed the newbie
                                error of inflating your resume with aptitudes you don&rsquo;t have.</p>
                              <p className={classnames('mb-2')}> Also, you need to show that you have
                                in-depth knowledge of particular skills.
                                Ensure that you highlight your
                                skills many times on your resume.
                                In case you&rsquo;re a C++ expert, make sure you
                                mention how you used the skill to
                                perform in the experience section.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Tip #2: Show what you
                                accomplished utilizing your skills</h5>
                              <p className={classnames('mb-2')}>Besides listing the skills on a
                                resume, it is vital to quantify your achievements
                                to show how valuable you are to an employer.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Example:</h5>
                              <p className={classnames('mb-2')}>&rsquo;Undertook web design for the
                                management wchich saved $6,000 in labor cost every week.&rdquo;</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Tip #3: List the Skills in a Separate
                                Section</h5>
                              <p className={classnames('mb-2')}>List your skills in major sections of a
                                resume, such as the summary, experience, and skill.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Tip #4: Prioritize what technical
                                skills to list on your resume and what not</h5>
                              <p className={classnames('mb-2')}>To begin with, don&rsquo;t list obsolete or
                                unimportant innovation that you&rsquo;ve been
                                working with five years back.
                                The industry changes quickly, and including Pascal on
                                your CV may look weird.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Tip #5: Avoid listing obvious
                                skills</h5>
                              <p className={classnames('mb-2')}>Try not to list obvious abilities
                                like Microsoft Office or experience in Windows OS.
                                It’s assumed that everyone
                                possesses such skills; hence they are not unique.</p>
                              <p className={classnames('mb-2')}>Additionally, analyse the job description and
                                your potential employer.
                                Organize the abilities in your
                                resume that the organization needs.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-3')}>
                                Examples of Top Skills for
                                a Software Engineer Resume</h5>
                              <h5 className={classnames('font-weight-bold', 'mb-4')}>
                                Here are the most
                                generally utilized skills.</h5>
                              <h5 className={classnames('font-weight-bold', 'mb-0')}>
                                Hard Skills for a Software Engineer</h5>
                              <ul className={classnames(styles.interview_points, 'list-unstyled', 'mt-3')}>
                                <li>Data Structures</li>
                                <li>PHP</li>
                                <li>Debugging</li>
                                <li>Web Development</li>
                                <li>AI</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold', 'mb-0')}>
                                Soft Skills for a Software Engineer</h5>
                              <ul className={classnames(styles.interview_points, 'list-unstyled', 'mt-3')}>
                                <li>Correspondence</li>
                                <li>Creativity</li>
                                <li>Communication</li>
                                <li>Time-Management</li>
                              </ul>
                              <h3 className={classnames(styles.resume_content_heading, 'mt-3', 'mb-4')}>
                                How to Describe Software Engineer
                                Skills on Your Resume</h3>
                              <img
                                alt="Resume Sample" className={classnames('mt-1', 'mb-3')}
                                src="/assets/images/itp_skill.jpg" />
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>
                                Here is how to describe software
                                engineer skills on a resume</h5>
                              <ul className={classnames(styles.interview_points, 'mt-4')}>
                                <li>Try to distribute your skills all
                                  through the resume, not simply in the skills segment.
                                  You can drop a portion
                                  of your skills in the resume
                                  summary, education area,
                                  and work experience segment.</li>
                                <li>Cautiously become familiar with the job
                                  description and organize
                                  your skill segments as indicated by it.</li>
                                <li>Desist from listing all your technical
                                  skills on the resume. However, highlight those that
                                  are relevant to the employer’s needs.</li>
                              </ul>
                              <h3 className={classnames(styles.resume_content_heading, 'mt-5')}>
                                How to Write Work Experience
                                in Your Software Engineer Resume</h3>
                              <p className={classnames('mb-2')}>Note that when recruiters look at your experience
                                area, they assemble a
                                psychological model
                                in their mind, attempting to envision your
                                career and how well
                                it aligns with
                                the new opportunity. Irrelevant work history
                                can make you lose the chance.</p>
                              <p className={classnames('mb-3')}>In case you&rsquo;re
                                applying for a senior
                                programmer, remember that this position
                                is once in a while
                                a managerial one, but it is more biased
                                towards your technical expertise.
                                That implies you can handle complex
                                activities under minimum supervision.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>Here’s how to structure
                                your work experience section</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Position name</li>
                                <li>Organization Name</li>
                                <li>Dates</li>
                                <li>Duties and Achievements</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold')}>
                                Example</h5>
                              <ul className={classnames(styles.interview_points, 'list-unstyled',
                                styles.content_list, 'mt-3')}>
                                <li className={classnames('mb-0')}>Computer programmer</li>
                                <li className={classnames('mb-0')}> YZ Company</li>
                                <li>02/2018 - 06/2020</li>
                              </ul>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Established an eCommerce website
                                  with multiple payments resulting
                                  in a 30 % in the organization’s income.</li>
                                <li>Developed a new framework to effectively
                                  deal with many customer documents.</li>
                                <li>Reliably accomplished 100% compliance
                                  as per the industry’s best practices</li>
                                <li>Decreased security breaches by 60%</li>
                              </ul>
                              <p>To make your resume look different
                                from the competition, you should
                                feature your top accomplishments.
                                This will allow the employer to
                                identify the benefits of recruiting you.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-3', 'mb-2')}>
                                What if You Don&rsquo;t Have
                                Work Experience?</h5>
                              <p className={classnames('mb-0')}>Sometimes, you may be applying for a
                                job when you don’t have experience.
                                If you are in such a scenario, don’t worry.
                                Highlight your internship, volunteer work, or any other
                                accomplishments at your school.</p>
                              <p className={classnames('mb-2')}>Always use Action Words to Make Your Software Engineer
                                Resume perfect. Some of the words include:</p>
                              <ul className={classnames(styles.interview_points, 'mb-2', 'list-unstyled')}>
                                <li>Achieved</li>
                                <li>Accomplished</li>
                                <li>Delivered</li></ul>
                              <p className={classnames('mb-2')}>Additionally, you should utilize power words
                                to make your accomplishments stick out.
                                These words include:</p>
                              <ul className={classnames(styles.interview_points, 'mb-2', 'list-unstyled')}>
                                <li>Conceptualized</li>
                                <li>Decided</li>
                                <li>Started</li>
                                <li>Initiated</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>
                                Example of Software Engineer
                                Experience on a Resume</h5>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>
                                Here are the right and
                                wrong examples</h5>
                              <h5 className={classnames('font-weight-bold', 'mt-4')}>
                                Right</h5>
                              <ul className={classnames(styles.interview_points, styles.content_list,
                                'list-unstyled', 'mt-3')}>
                                <li>Senior Software Engineer</li>
                                <li>CLD Inc.</li>
                                <li>June 2010 – July 2015</li>
                              </ul>
                              <ul className={classnames(styles.interview_points)}>
                                <li>Liable for initiating groups
                                  that dealt with the product improvement
                                  life cycle for ten customer projects, start to finish.</li>
                                <li>Fabricated framework to deal with
                                  a huge number of customer
                                  records in proprietary frameworks.</li>
                                <li>Reliably accomplished 100% compliance
                                  as per the industry’s best practices</li>
                                <li>Diminished security breaches
                                  by 60% for all clients within the company.</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold', 'mt-4')}>
                                Wrong</h5>
                              <ul className={classnames(styles.interview_points,
                                styles.content_list, 'list-unstyled', 'mt-3')}>
                                <li>Senior Software Engineer</li>
                                <li>CLD Inc.</li>
                                <li>June 2010 – July 2015</li>
                              </ul>
                              <ul className={classnames(styles.interview_points, 'mb-0')}>
                                <li>Answerable for driving groups of programming engineers.</li>
                                <li>Utilized Java to actualize
                                  scalable features for custom programming.</li>
                                <li>Worked freely to comply with tight
                                  time constraints in a dynamic environment</li>
                                <li>Utilized a strong comprehension of
                                  web conventions to deal with a huge workload.</li>
                                <li>Dealt with many security projects</li>
                              </ul>

                              <h3 className={classnames(styles.resume_content_heading, 'mt-4')}>
                                How to Write Software Engineer
                                Achievements, Hobbies, Training and certifications on Resume</h3>
                              <h5 className={classnames('mt-4', 'font-weight-bold')}>Instructions on how to
                                highlight
                                your achievements </h5>
                              <p className={classnames('mb-2')}>If you want to get recruited, always
                                highlight your experience utilizing explicit details, such
                                as project type, the technology utilized, and the nature of clients.
                                Such a description will assist the recruiters
                                in shortlisting you for the job.</p>
                              <h5 className={classnames('font-weight-bold')}>Here are the tips on
                                the most
                                proficient methods to feature your achievements</h5>
                              <h5 className={classnames('font-weight-bold', 'mt-3', 'mb-2')}>
                                Tip #1: List your skills and
                                show how you applied them</h5>
                              <p className={classnames('mb-2')}>Highlight your skills
                                and mention how you utilized
                                them to accomplish particular tasks.
                                For instance, if
                                you are familiar with
                                Scrum, show when you used it.
                                In case you&rsquo;re a specialist in
                                Python, at which employment
                                did you utilize that language.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Tip #2: Avoid using
                                bland bullet points</h5>
                              <p className={classnames('mb-2')}>Using uninteresting bullet
                                points may fail to satisfy the recruiters.
                                So, as opposed to composing a flat
                                list item like &ldquo;liable for delivering
                                effective solutions utilizing
                                cloud platforms,&rdquo; you could make something
                                significantly more convincing, as “leveraged
                                expertise CDL cloud platforms to deliver effective
                                solutions for ten unique application teams.”</p>
                              <h5 className={classnames('font-weight-bold')}>How to Highlight
                                Hobbies on Software Engineer&rsquo;s Resume</h5>
                              <p className={classnames('mb-2')}>Highlighting your interests permits the employer to
                                become acquainted with what your identity is.
                                They can likewise show that you&rsquo;ll be a good
                                fit in the team, particularly if you
                                like social activities.</p>
                              <h5 className={classnames('font-weight-bold')}>Here are the hobbies you can
                                list on your resume</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Voyaging</li>
                                <li>Skiing </li>
                                <li>Tennis </li>
                                <li>Cycling </li>
                                <li>Swimming  </li>
                                <li>Hiking  </li>
                              </ul>
                              <div className={classnames('card', 'p-3', 'px-lg-4', styles.ProTip,
                                styles.interview_blockquote)}>
                                <h5 className={classnames('ml-5', 'font-weight-bold')}>Pro Tip
                                </h5><p className={classnames('ml-5')}>When listing hobbies on your
                                  resume, ensure that you align the job description with them.</p>
                              </div>
                              <h3 className={classnames(styles.resume_content_heading, 'mt-4', 'mb-3')}>
                                How to List Certifications on a Resume</h3>
                              <h5 className={classnames('font-weight-bold')}>Here are the tips to help
                                list certifications on your resume</h5>
                              <ul className={classnames(styles.interview_points, 'mt-4')}>
                                <li>List pertinent certifications
                                  that enhance your current profile. </li>
                                <li>Ensure you highlight your
                                  certifications in a separate section. </li>
                                <li>Give details of the certifying authority, date, and area. </li>
                                <li>Follow the chronological order to list your accreditations. </li>
                                <li>Notice the expiry date if your accreditation has one.   </li>
                                <li>In case you have any honours or
                                  certifications, don’t hesitate to show them in your resume. </li>
                              </ul>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>Example</h5>
                              <ul className={classnames(styles.interview_points, 'list-unstyled',
                                styles.content_list, 'mt-3')}>
                                <li className={classnames('mb-0')}>Certifications</li>
                                <li className={classnames('mb-0')}>Advance Computer Engineering,
                                  Coursera Certificate, India</li>
                                <li>Software Analytics, Dalice University, Australia</li>
                              </ul>
                              <h5 className={classnames('mt-3', 'font-weight-bold')}>
                                Why are certifications important?</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>They are significant proof
                                  of abilities obtained and information acquired. </li>
                                <li>They make you more
                                  competitive in the job market.  </li>
                                <li>They may help you bargain for better salaries</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold')}>Tips on how to add
                                certifications to your resume</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Add it in their section to get the employer’s attention  </li>
                                <li>Try not to mistake it for training programs  </li>
                                <li>Continuously indicate the certifying authority, date, and area </li>
                                <li>List them in reverse chronological order  </li>
                              </ul>
                              <h3 className={classnames(styles.resume_content_heading, 'mt-3', 'mb-2')}>
                                Top Professional Tips for Software Engineer Resume</h3>
                              <h5 className={classnames('font-weight-bold')}>Here are the tips
                                for composing a software engineer&rsquo;s resume</h5>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>
                                Compose your summary cautiously</h5>
                              <p className={classnames('mb-2')}>Utilize the summary for your potential benefit.
                                You don&rsquo;t need to make it sound like you&rsquo;re already
                                an expert. Focus on soft skills. Interpret what you
                                learned and what you did, and adapt
                                them to the work environment. Attempt to make a
                                quantifiable, significant articulation that
                                will make HR managers interested in you.   </p>
                              <h5 className={classnames('font-weight-bold')}>Compose your occupation history</h5>
                              <p className={classnames('mb-2')}>You don&rsquo;t have
                                to list each occupation you&rsquo;ve ever
                                had on your resume. Incorporate just the positions
                                that are relevant to the job. Utilize your employment
                                history to show your career progressions showing how
                                you have transitioned into the role you are applying for.  </p>
                              <h5 className={classnames('font-weight-bold')}>Underscore your projects</h5>

                              <p className={classnames('mb-2')}>You should separate your resume
                                into four principle segments:
                                experience, schooling, abilities, and undertakings.
                                That last part is particularly
                                significant for a programmer’s resume. </p>
                              <h5 className={classnames('mb-2', 'font-weight-bold')}>Tweak your resume for the work</h5>
                              <p className={classnames('mb-2')}>Tailor your resume to an individual employment
                                opportunity by including many keywords as could
                                be expected under the circumstances.
                                When your resume matches the
                                set job descriptions, it shows that you are an
                                ideal fit for the work. </p>
                              <h5 className={classnames('mb-2', 'font-weight-bold')}>Make sure your
                                resume is ATS friendly</h5>
                              <p className={classnames('mb-2')}>Note that whenever
                                you apply for any position, your resume
                                frequently goes through an Applicant Tracking System before
                                it actually arrives at a recruiter. The ATS investigates your
                                resume and introductory letter, and then rank your application
                                depending on how well the keywords compare with
                                the set of working responsibilities. </p>
                              <p className={classnames('mb-2')}>If you need your
                                resume to be considered, ensure it&rsquo;s in the
                                right format and matches the job descriptions well.</p>
                              <h3 className={classnames('mt-3', 'font-weight-bold')}>Conclusion</h3>
                              <p className={classnames('mt-2')}>A good resume can boost your chances of
                                getting
                                shortlisted for interviews. However, not everyone
                                is familiar with crafting a perfect resume.
                                If you are applying for a software engineer position,
                                ensure you format your CV well, and use the right
                                keywords to enhance your success. Hiring managers are
                                interested in resumes that are well-written
                                and informative. More importantly, don’t forget to use
                                metrics to quantify
                                your achievements in the work experience section.</p>
                            </div>
                          </Col>
                          <Col lg={4} xl={4} xs={12}>
                            <PromoCard loginWithRedirect={loginWithRedirect} />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      )
    }
}
