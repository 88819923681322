import React, { Component, Fragment } from 'react'
import cs from 'classnames'
import { Container, Row, Col, Carousel } from 'react-bootstrap'
import st from './style.scss'

export default class HomeFAQ extends Component{
  render(){
    return (
      <Fragment>
        <div className={cs(st.desktop)}>
          <Container className={cs(st.cc_fluid_pp)} fluid>
            <h2> Resumaker Builder FAQs </h2>
            <Row>
              <Col lg={6} md={12} sm={12} xl={6} xs={12}>
                <div className={cs(st.faq_box)}>
                  <div className={cs(st.faq_image)}>
                    <img alt="faq" src={'/assets/images/faq_img_1.png'} />
                  </div>
                  <div className={cs(st.faq_content)}>
                    <h3> Why Resumaker is Best Free Resume Builder? </h3>
                    <p> Resumaker is an Intelligent Resume Builder. It provides recommendations,
                      all templates are professionally designed, easy to create resume,
                      users can change templates in just one click and contains many customization options etc.
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={12} sm={12} xl={6} xs={12}>
                <div className={cs(st.faq_box)}>
                  <div className={cs(st.faq_image)}>
                    <img alt="faq" src={'/assets/images/faq_img_1.png'} />
                  </div>
                  <div className={cs(st.faq_content)}>
                    <h3> Why should I use Resume Builder  ? </h3>
                    <p> This questtion is not limited to Resumaker, but any resume builder.
                      However there are basic resume builders and Intelligent resume builders like Resumaker.
                      <ul>
                        <li>Resumaker Builder offer well defined templates</li>
                        <li>Old fasioned resumes are outdated, companies not prefer to read pages of text</li>
                        <li>Provides tools and functions to customize resume</li>
                        <li>Hanldes everything needed to create impressive resume</li>
                        <li>Easy to use, but the out come is professionally designed resume</li>
                        <li>Editing of resume is easy, just update target
                          section then builder handles rest</li>
                      </ul>
                    </p>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={6} md={12} sm={12} xl={6} xs={12}>
                <div className={cs(st.faq_box)}>
                  <div className={cs(st.faq_image)}>
                    <img alt="faq" src={'/assets/images/faq_img_1.png'} />
                  </div>
                  <div className={cs(st.faq_content)}>
                    <h3> What is ATS and how Resumaker handle this? </h3>
                    <p> ATS means Applicant Trackign System. Organization use ATS to filter
                      applicants resume based on defined criteria, this mainly depends on user skiils,
                      how well resume content is articulated and acedamic details etc.
                      Resumaker provides recommendations on related skill,
                      suggest strong key word when users create resume.
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={12} sm={12} xl={6} xs={12}>
                <div className={cs(st.faq_box)}>
                  <div className={cs(st.faq_image)}>
                    <img alt="faq" src={'/assets/images/faq_img_1.png'} />
                  </div>
                  <div className={cs(st.faq_content)}>
                    <h3> What Is a Resumaker Guided Builder Tool?</h3>
                    <p>Resumaker guides users in every section of their resume.
                      Resuamker asks set of questions
                      to know user preferences then generates Intelligent
                      recommendations which are suitable for the users.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className={cs(st.mobile)}>
          <Container className={cs(st.cc_fluid_pp)} fluid>
            <h2>  Resumaker Builder FAQs</h2>
            <Carousel variant="dark">
              <Carousel.Item>
                <div className={cs(st.faq_box)}>
                  <div className={cs(st.faq_image)}>
                    <img alt="faq" src={'/assets/images/faq_img_1.png'} />
                  </div>
                  <div className={cs(st.faq_content)}>
                    <h3> Why Resumaker is Best Free Resume Builder? </h3>
                    <p> The backbone of Resumaker team from fortune 100 companies.
                      We have added all the elements required to make your interviewer identify your capabilities.
                      we support you via our expert channel,
                      so you approach an interview more professionally.
                    </p>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className={cs(st.faq_box)}>
                  <div className={cs(st.faq_image)}>
                    <img alt="faq" src={'/assets/images/faq_img_1.png'} />
                  </div>
                  <div className={cs(st.faq_content)}>
                    <h3> Why approach a Professional Resume Writer ? </h3>
                    <p> Resume writing is not everyone&lsquo;s job. Professional resume writers
                      know the best ways of creating
                      impressive resumes which can attract the attention of a potential employer
                      also leads to call for an interview.
                      The resumes crafted by expert writers will enhance the chances
                      of your interview call. So, don&lsquo;t take a chance for your dream job.
                    </p>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className={cs(st.faq_box)}>
                  <div className={cs(st.faq_image)}>
                    <img alt="faq" src={'/assets/images/faq_img_1.png'} />
                  </div>
                  <div className={cs(st.faq_content)}>
                    <h3> What is ATS and how Resumaker handle this? </h3>
                    <p> Yes, we have Professional Resume Writers with hand-on experience
                      in creating ATS friendly Resume,
                      they hold good knowledge about the tools, AL and ML trends easily
                      filter-out routing resume.
                      Our Experts do proper research on each profile to make candidate profile
                      unique and ATS friendly. Visit our website to learn more.
                    </p>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className={cs(st.faq_box)}>
                  <div className={cs(st.faq_image)}>
                    <img alt="faq" src={'/assets/images/faq_img_1.png'} />
                  </div>
                  <div className={cs(st.faq_content)}>
                    <h3> What Is a Resumaker Guided Builder Tool?</h3>
                    <p>Resume build is an online tool that you can use to create your resume instantly.
                      At Resumaker, we provide a self-explanatory and easy-to-use tool so you
                      can build your resume in no time yet maintaining the quality.</p>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </Container>
        </div>
      </Fragment>
    )
  }
}
