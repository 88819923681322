import React, { Component } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import GetStartedButton from '../../MainContent/Home/GetStartedButton'
import { Icon, IconType } from '../../common'
import styles from './style.scss'

export default class PromoCard extends Component {
static propTypes = {
  loginWithRedirect: PropTypes.func.isRequired,
}

render() {
  const { RIGHT_CIRCLE_ARROW } = IconType.DIRECTION
  const { loginWithRedirect } = this.props
  return (
    <div className={classnames(styles.resume_card_sticky)}>
      <div
        className={classnames('mt-lg-5', 'order-lg-2', styles.resume_sidebar, 'sticky-effect')}>
        <div className={classnames('card', styles.shadow_1, styles.resume_detail_card)}>
          <img alt="resume card" className={classnames('mb-3')} src="/assets/images/iris.jpg" />
          <h3 className={classnames(styles.resumed_detail_heading, 'mb-3')}>How Resumaker helps?</h3>
          <ul className={classnames(styles.resume_detail_keypoints)}>
            <li><Icon
              color={'#e83950'} height={'20'}
              names={[RIGHT_CIRCLE_ARROW]} width={'20'} /> Effective recommendations</li>
            <li><Icon
              color={'#e83950'} height={'20'}
              names={[RIGHT_CIRCLE_ARROW]} width={'20'} />Easy to use builder</li>
            <li><Icon
              color={'#e83950'} height={'20'}
              names={[RIGHT_CIRCLE_ARROW]} width={'20'} />Real time edit</li>
            <li><Icon
              color={'#e83950'} height={'20'}
              names={[RIGHT_CIRCLE_ARROW]} width={'20'} />Customize layout</li>
            <li><Icon
              color={'#e83950'} height={'20'}
              names={[RIGHT_CIRCLE_ARROW]} width={'20'} />Download just in one click</li>
          </ul>
          <div className={classnames(styles.btn_center)}>
            <GetStartedButton label="Create Resume Now" loginWithRedirect={loginWithRedirect} size="lg" />
          </div>
        </div>
      </div>
    </div>
  )
}
}
