import React, { Component } from 'react'
import classnames from 'classnames'
import { Container, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import PromoCard from './PromoCard'
import Download from '../../common/Download'
import GetStartedButton from '../../MainContent/Home/GetStartedButton'
import { getDocUrl } from '../../../constants'
import styles from './style.scss'

export default class BusinessDevelopment extends Component {
    static propTypes = {
      history: PropTypes.object.isRequired,
      loginWithRedirect: PropTypes.func.isRequired,
    }
    render() {
      const { loginWithRedirect, history } = this.props
      return (
        <div>
          <Container fluid>
            <div className={classnames('page-main-wrapper')}>
              <div className={classnames(styles.container_xl, 'mt-5', 'mb-5')}>
                <div className={classnames(styles.page_content_header, 'row', 'align-items-center')}>
                  <Col lg={12} md={12} xl={12} xs={12}>
                    <div className={classnames('text-center', 'mx-auto')}>
                      <img
                        alt="fresher resume" className={classnames(styles.resume_sample_vector, 'img-fluid', 'mx-auto')}
                        src="/assets/images/bdm_vector.jpg" />
                      <div
                        className={classnames('mt-md-5', 'text-center', 'mb-md-4')}>
                        <h1 className={classnames('mt-4', styles.size_heading)}>
                          Business Development Manager Resume (Marketing Resume) </h1>
                        <p className={classnames('mb-0')}>Every company focuses
                          on employing a good business development manager to meet
                          its marketing needs. Whether you are a
                          business development manager or newbie, you will need an excellent business development
                          resume to convince a potential employer to give you a job.
                          You will get insights of
                          how to highlight skills, experience, how to capture achievements and
                          tips for writing a business development resume.</p>
                      </div>
                      <div className={classnames(styles.btn_gs)}>
                        <GetStartedButton label="Build My Resume" loginWithRedirect={loginWithRedirect} size="lg" />
                      </div>
                    </div>
                  </Col>
                  <div className={classnames(styles.resume_sample_container, 'mt-5', 'pt-3', 'pt-lg-4')}>
                    <Row>
                      <Col lg={6}>
                        <div className={classnames('mb-5', 'p-xl-5', 'mb-xl-0')}>
                          <div className={classnames(styles.resume_sample)}>
                            <img
                              alt="Resume Sample" className={classnames(styles.resume_sample_img)}
                              src="/assets/images/bdm_resume.jpg" />
                            <Download
                              downloadURL={getDocUrl('assets/docs/bdm-resume.docx')}
                              history={history} />
                          </div>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className={classnames('mb-5', 'p-xl-5', 'mb-xl-0')}>
                          <div className={classnames(styles.resume_sample)}>
                            <img
                              alt="Resume Sample" className={classnames(styles.resume_sample_img)}
                              src="/assets/images/bdm_cl.jpg" />
                            <Download
                              downloadURL={getDocUrl('assets/docs/bdm-cl.docx')}
                              history={history} />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className={classnames(styles.resume_sample_header, 'text-center')}>
                      <div className={classnames(styles.container_xl)}>
                        <h2 className={classnames(styles.section_heading)}>
                          Business Development Manager (Text Format)
                        </h2>
                        <p className={classnames('mt-2', styles.section_paragraph)}>
                          Make sure you choose the right resume
                          format to suit your unique experience and the job you are applying.</p>
                      </div>
                    </div>
                    <div className={classnames(styles.resume_sample_wrapper)}>
                      <div className={classnames(styles.container_xl)}>
                        <Row>
                          <Col lg={8} xl={8} xs={12}>
                            <div className={classnames(styles.resume_sample_main)}>
                              <div className={classnames(styles.tab_content)}>
                                Text View
                              </div>
                            </div>
                            <div className={classnames(styles.resume_content_card,
                              styles.dz_card, styles.shadow_1, 'px-3', 'px-lg-5', 'py-5', 'tab-pane active')}>
                              <Row>
                                <h2 className={classnames(styles.section_heading, 'ml-2')}>
                                  Business Development Manager
                                </h2>
                              </Row>
                              <div className={classnames(styles.resume_content_title, 'mt-3')}>Summary</div>
                              <p className={classnames('mt-3', 'mb-2')}>
                                Top-ranked business development
                                manager recognized for contributions to record-setting
                                business development figures, territory expansion and new account
                                development. Proven ability to lead business development teams to
                                achieve multimillion-dollar revenue gains. Offer an
                                in-depth understanding of the business development cycle process
                                and remain focused on customer satisfaction throughout
                                all stages. Experienced, “fearless” cold-caller and
                                expert presenter, negotiator and closer.
                              </p>
                              <div className={classnames(styles.resume_content_title)}>
                                Professional Experience</div>
                              <ul className={classnames(styles.interview_points, 'list-unstyled',
                                styles.content_title, 'mt-4')}>
                                <li className={classnames('mb-1')}>ABC Company</li>
                                <li>Sometown
                                  business development Manager, 1/2014 to Present</li>
                              </ul>
                              <p className={classnames('mt-2', 'mb-2')}>
                                Recruited to lead start-up of Northeast business development
                                region and manage a 12-member team within
                                $8.2 million, 12-state territory.
                                Grow market share by increasing gasket
                                product-line business development to warehouse
                                distributers and retail stores.
                              </p>
                              <h6 className={classnames('mt-3', 'font-weight-bold')}>Impact &amp; Results:
                              </h6>
                              <ul className={classnames(styles.interview_points, 'mt-4')}>
                                <li>Increased territory business development from less than
                                  $4 million to $8.2 million within two years,
                                  exceeding quota by 12% in 2011 and 15% in 2010. </li>
                                <li>Ranked as #1 business development manager
                                  (out of 12) in 2011 and 2010. </li>
                                <li>Fostered a robust, sustainable network
                                  of buyers from Maine to Florida, leveraging
                                  strong listening, presentation and closing
                                  skills to optimize business development results despite
                                  previously dominant competitor advantage. </li>
                                <li>Introduced new gasket lines into the market, often
                                  closing sight-unseen business development of newly released products. </li>
                                <li>Demonstrated an unwavering commitment to customer service,
                                  adding new customers while maintaining premium
                                  service levels with existing accounts. </li>
                              </ul>
                              <ul className={classnames(styles.interview_points, 'list-unstyled',
                                styles.content_title, 'mt-4')}>
                                <li className={classnames('mb-1')}>DEF COMPANY</li>
                                <li>Sometown
                                  business development Manager, 2/2010 to 1/2019</li>
                              </ul>
                              <h6 className={classnames('mt-4', 'font-weight-bold')}>Impact &amp; Results:
                              </h6>
                              <ul className={classnames(styles.interview_points, 'mt-4')}>
                                <li>Surpassed business development goals by 19% in 2009 and 14% in 2008. </li>
                                <li>Recognized for superior performance as
                                  a two-time district “Employee of the Month” honouree. </li>
                                <li>Increased business development of company-owned paint
                                  sealants by 24%, selling $245K in sealant products in 2009. </li>
                              </ul>
                              <div className={classnames(styles.resume_content_title, 'mt-3')}>
                                Education </div>
                              <ul className={classnames(styles.interview_points, 'mt-4')}>
                                <li>Masters’ in Business Administration (MBA) </li>
                                <li> Bachelors’ in Business Management</li>
                              </ul>
                              <div className={classnames(styles.resume_content_title)}>
                                Certifications
                              </div>
                              <ul className={classnames(styles.interview_points, 'mt-4')}>
                                <li>Advanced Digital Marketing</li>
                                <li> Six Sigma, Black Belt</li>
                              </ul>
                              <div className={classnames(styles.resume_content_title)}>
                                Skills
                              </div>
                              <ul className={classnames(styles.interview_points, 'mt-4')}>
                                <li> B2B business development</li>
                                <li> Microsoft Office</li>
                                <li> Problem-solving</li>
                                <li> Communication skills</li>
                                <li> Public speaking</li>
                                <li> Proposal writing</li>
                                <li> Account management</li>
                                <li> Vendor management</li>
                                <li> Relationship management</li>
                              </ul>
                              <div className={classnames(styles.resume_content_title)}>
                                Languages
                              </div>
                              <ul className={classnames(styles.interview_points, 'mt-4')}>
                                <li>English</li>
                                <li> Spanish</li>
                                <li>French</li>
                              </ul>
                            </div>
                            <div className={classnames(styles.resume_sample_header, 'pb-5')}>
                              <h2 className={classnames(styles.section_heading, 'mb-2')}>
                                How to Write a Business Development Manager Resume</h2>
                              <p>
                                In this guide, you will get insights into a
                                business development resume summary, how to highlight skills and
                                experience in a resume, how to capture a business
                                development manager’s achievements and
                                top tips for writing a business development resume.</p>
                            </div>
                            <div
                              aria-labelledby="resumeContentTab"
                              className={classnames(styles.resume_content_card,
                                styles.dz_card, styles.shadow_1, 'px-0', 'px-lg-5', 'py-5', 'tab-pane active')}
                              id="resumeContent" role="tabpanel">
                              <h3 className={classnames(styles.resume_content_heading, 'mt-0')}>
                                How to Write Business Development summary</h3>
                              <p className={classnames('mb-3')}>A resume summary highlights
                                the most critical skills and experiences in business development.
                                With the resume summary, employers can quickly
                                note what a business development candidate can offer to their organization.
                                More importantly, a resume summary sets you
                                apart from the competition and increases your
                                chances of being shortlisted for the interviews.
                                Here are the steps to writing a
                                compelling business development resume summary.</p>
                              <h5 className={classnames('font-weight-bold')}>Step #1: Select the
                                Right Type of Summary</h5>
                              <p className={classnames('mb-2')}>
                                Bear in mind that there are three types of
                                summaries you can choose from. These are
                                bulleted summary, transferable
                                skills summary, and direct address summary.
                                The type of summary you use will
                                depend on the kind of information
                                you have and how you want to convey it.
                                Here is a highlight of these summaries.</p>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Bulleted Summary: This is an ideal choice when
                                  you are an entry-level business development. A bulleted
                                  summary allows you to express your
                                  skills in a format that is easy to read.</li>
                                <li> Transferable Skills Summary: This
                                  type of summary is applicable when you
                                  have achievements, awards, or other
                                  transferable skills in the business development industry.</li>
                                <li> Direct Address Summary: This summary
                                  is appropriate when you have directs skills
                                  relevant to the advertised role.</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold')}>
                                Step #2: Highlight Skills, Achievements, and Experience</h5>
                              <p className={classnames('mb-2')}> A good resume should incorporate
                                the skills and ability that relate
                                to the job you are applying for.
                                Here is what you should include
                                in your business development resume summary.</p>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Relevant experience</li>
                                <li> Business development skills</li>
                                <li>  Attributes that portrays you
                                  as a great business development</li>
                                <li> Awards or recognition from your
                                  previous business development background</li>
                                <li>  Accomplishments in business development</li>
                                <li> Any other traits that show how
                                  you will add value to the business development team</li>
                              </ul>
                              <p className={classnames('mb-3')}>A resume summary is a brief highlight
                                of your skills and attributes. Therefore, avoid including
                                generic skills, educational information, and work history in the summary.</p>
                              <h5 className={classnames('font-weight-bold')}>
                                Step #3: Use a Professional Format in Your Summary</h5>
                              <p className={classnames('mb-3')}>The summary provides an overview of
                                the skills and experience for the job
                                you are applying for. To format your
                                resume well, capture specific and relevant work experience.
                                For instance, you can start your summary
                                by composing, “Committed business development professional
                                with more than five years of experience
                                in the banking business development industry.”</p>
                              <p className={classnames('mb-3')}>In the second sentence, include awards
                                and relevant accomplishments to showcase
                                your competencies. For instance, you could mention,
                                “Continuously maintained a 45% retention rate
                                of new clients for the past three years.”
                                Examples of business development Resume Summaries</p>
                              <h5 className={classnames('font-weight-bold', 'mt-1')}>
                                Here are the steps to writing a
                                compelling business development resume summary</h5>
                              <h5 className={classnames('ml-3', 'font-weight-bold', 'mt-3')}>Good Example</h5>
                              <blockquote><p className={classnames(styles.resume_rank, 'ml-0')}>
                                Result-oriented and proactive insurance business development
                                executive with 10+ years of work experience.
                                Committed to enhancing Nemis regional business development goals
                                by promoting health insurance products to various
                                employers and individual clients. Consistently
                                surpassed targets by 30% and identified 200+ new leads.
                              </p></blockquote>
                              <h5 className={classnames('ml-3', 'font-weight-bold')}>Bad Example</h5>
                              <blockquote><p className={classnames(styles.resume_rank, 'ml-0')}>
                                Experienced insurance business development executive with
                                significant achievements in marketing. Interested
                                to join Nemis as a business development manager.
                              </p></blockquote>
                              <div className={classnames('card', 'p-3', 'px-lg-4', 'mt-5',
                                styles.ProTip, styles.interview_blockquote)}>
                                <h5 className={classnames('ml-5', 'font-weight-bold')}>Pro Tip </h5>
                                <p className={classnames('ml-5')}>
                                  For a Business Development Manager job, add relevant volunteer work,
                                  and focus on your achievements.</p>
                              </div>
                              <h3 className={classnames(styles.resume_content_heading, 'mt-5', 'mb-4')}>
                                How to Highlight Skills in Business Development  Resume
                              </h3>
                              <img
                                alt="Resume Sample" className={classnames('mt-4', 'mb-4')}
                                src="/assets/images/BDM_skill.jpg" />
                              <p className={classnames('mb-2')}>If you want to increase
                                your chances of getting
                                a business development job, it crucial to list the skills plus
                                examples to show your potential employer that you are
                                the most suitable candidate for the job.</p>

                              <h5 className={classnames('font-weight-bold')}>Here are the
                                common business development skills and
                                how to highlight them in your resume. </h5>
                              <h5 className={classnames('font-weight-bold', 'mb-0', styles.align)}>
                                Skill #1:  Passion for Achieving Results</h5>
                              <p className={classnames('mb-0')}>The desire to achieve
                                targets is one of the most important
                                attributes that employers look for when shortlisting a
                                business development manager for an interview. In your resume, ensure you
                                mention instances where you achieved the desired results.</p>
                              <p className={classnames('mb-0')}>For example, you could say, “Committed to
                                achieving results, delivering a  sales increase
                                of more than 50% in 10 months in my previous employment.”</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}> Skill #2:  Good
                                Communication Skills</h5>
                              <p className={classnames('mb-0')}>Communication skills are
                                essential attribute for a business development.
                                Customers need to be informed about the qualities and
                                usefulness of products to make wise decisions.</p>
                              <p className={classnames('mb-0')}>In your resume, you ought to indicate that you
                                have the relationship skills that will make the
                                customers get interested in the products or
                                services you are selling to them.</p>

                              <h5 className={classnames('font-weight-bold', 'mt-2')}>Example</h5>
                              <p className={classnames('mb-0')}>“Excellent communication skills, ability to
                                proactively market bank products to customers through various channels.”</p>

                              <h5 className={classnames('font-weight-bold', 'mt-3')}>  Skill #3:
                                Listening Skills </h5>
                              <p className={classnames('mb-0')}>When selling products
                                to customers, active listening skills can help you
                                understand customers’ needs and serve them better.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}> Example </h5>
                              <p className={classnames('mb-0')}>“Active listening skills, ability to
                                get customers feedback and use them to improve our services.”</p>

                              <h5 className={classnames('font-weight-bold', 'mt-3')}>  Skill #4: High Curiosity </h5>
                              <p className={classnames('mb-0')}>An excellent business development manager or candidate
                                desires to learn more about the company they are working
                                for and the products/services they are selling.
                                This knowledge will acquaint the business development representative
                                with relevant information to execute their job.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}> Example </h5>
                              <p className={classnames('mb-0')}> “Strong desire to learn more
                                about the company, its products, and business development techniques.”</p>

                              <h5 className={classnames('font-weight-bold', 'mt-3')}> Skill #5:
                                A Desire to Close Deals</h5>
                              <p className={classnames('mb-0')}>A successful sale requires
                                the ability to endure
                                in selling products to clients to close many deals.
                                The ability to continue pitching services to
                                customers despite previous failures is crucial
                                for a business development’s success.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}> Example </h5>
                              <p className={classnames('mb-0')}>“Ability to focus on my business development
                                targets despite the challenges.”
                                If you want to increase your chances of getting a business development job,
                                it crucial to list the skills plus examples to show your
                                potential employer that you are the most suitable candidate for the job.</p>
                              <div className={classnames('card', 'p-3', 'px-lg-4', 'mt-5',
                                styles.ProTip, styles.interview_blockquote)}>
                                <h5 className={classnames('ml-5', 'font-weight-bold')}>Pro Tip </h5>
                                <p className={classnames('ml-5')}>
                                  Always use the job
                                  advertisement to determine which skills to list in your resume.
                                  Most employers highlight the skills in job descriptions.</p>
                              </div>
                              <h3 className={classnames(styles.resume_content_heading, 'mt-5', 'mb-2')}>
                                How to Write Work Experience in Business Development Resume</h3>
                              <p className={classnames('mb-2')}>To ensure you stand
                                above the crowd, you need to
                                organize your work experience correctly.</p>
                              <h5 className={classnames('font-weight-bold')}>
                                Here is what you should do to get it right</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li> Arrange your past positions in reverse-chronological order,
                                  beginning with the most recent.</li>
                                <li> Indicate your previous title, employer’s name,
                                  and the duration you were employed.</li>
                                <li>  Use bullet points to describe your
                                  past work responsibilities.</li>
                                <li> Ensure you list only past duties that
                                  are related to the job.</li>
                                <li>  Quantify your accomplishments to give
                                  a clear picture of your performance.</li>
                                <li>Utilize action verbs when beginning each
                                  bullet point for maximum impact.</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold')}>Example of business development
                                Experience on a Resume</h5>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>Right Example</h5>
                              <ul className={classnames(styles.interview_points, 'list-unstyled', 'mt-3')}>
                                <li>  business development Executive</li>
                                <li>October 2018- November 2020</li>
                                <li> Pangila Commercial Bank, IN</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold')}>
                                Key Qualifications &amp; Accomplishment</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li> Developed sales targets based on market research</li>
                                <li> Managed individual business development representative
                                  and motivated any team member who didn’t achieve the targets</li>
                                <li> Managed to open accounts for ten
                                  corporate organizations</li>
                                <li>Managed a group of sales representatives in
                                  selling bank products to various customers</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold')}>
                                Key Achievements</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Achieved more than $700K in sales in each quarter from 2019 to date.
                                </li>
                              </ul>
                              <h5 className={classnames('font-weight-bold')}> Wrong Example</h5>
                              <ul className={classnames(styles.interview_points, 'list-unstyled', 'mt-2')}>
                                <li className={classnames('mb-0')}> business development Executive</li>
                                <li>Pangila Commercial Bank, IN</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold')}>
                                Key Qualifications &amp; Accomplishment</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li> Developed business sales targets</li>
                                <li> Managed individual business development representatives</li>
                                <li> Managed corporate relationships</li>
                              </ul>
                              <p className={classnames('mb-1')}>From the examples above, you can see that
                                the second resume is concise with sketchy details.
                                However, the first resume example is detailed,
                                uses numbers, and meets what is required in
                                a resume’s work experience section</p>
                              <h3 className={classnames(styles.resume_content_heading, 'mt-5')}>
                                How to write Business Development Manager
                                Achievements, Hobbies, Training and certifications on Resume</h3>
                              <p className={classnames('mb-1')}>Highlighting achievements and accomplishments
                                is what distinguishes a top candidate from the crowd.
                                Many job seekers fail to list their achievements, which makes
                                them unsuccessful in their job search.</p>
                              <p className={classnames('mb-1')}>
                                To enhance your chances of getting shortlisted, you should
                                indicate your achievements in the resume.</p>

                              <h5 className={classnames('font-weight-bold', 'mt-3')}> How to List Your Achievements</h5>
                              <p className={classnames('mb-1')}>The achievements you list
                                in your resume should include the following.
                                Timelines</p>
                              <p className={classnames('mb-1')}>Mention the time
                                you worked on the project.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>Example</h5>
                              <p className={classnames('mb-1')}> “Increased customer satisfaction rate
                                by 40% within two months of employment at X Ltd.</p>
                              <p className={classnames('mb-0')}> Also, indicate how many
                                times a week, you worked
                                on a particular task. Example: “Served 20 customers daily.”</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>
                                Scale</h5>
                              <p className={classnames('mb-1', 'mt-2')}>Mention the number
                                of employees you managed. Example: “Managed
                                a team of 20 business development representatives in my region.”
                                Indicate how you utilized the budget.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>Example #1</h5>
                              <p className={classnames('mb-1', 'mt-1')}>“Successfully conducted
                                mobile banking campaigns
                                spending less amount of money than what was allocated in the budget.”
                                Results</p>
                              <p className={classnames('mb-1', 'mt-1')}>If possible,
                                it is advisable to show the results
                                that accompanied your achievements.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>Example #2</h5>
                              <p className={classnames('mb-1', 'mt-1')}>“Delivered a 20% growth
                                in profitability
                                within the first quarter of employment.”</p>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>Example of a
                                Business Development Manager’s Achievements</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li> Increased customer satisfaction rate by 40% within
                                  2 months of employment at X Ltd</li>
                                <li>Managed a team of more than 100 business development
                                  representatives in my region</li>
                                <li> Delivered a 50% growth in profitability
                                  within the first quarter of employment.</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold')}> How to List
                                Hobbies on Your Resume</h5>
                              <p className={classnames('mb-1', 'mt-1')}>To connect with
                                hiring managers, you need
                                to know how to include hobbies in your resume.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>Here are the
                                tips on how to list hobbies on your resume</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li> Hobbies should be listed towards the bottom of your resume</li>
                                <li>Limit your hobbies to a maximum of three</li>
                                <li> Utilize only hobbies that relate to the position</li>
                                <li>Desist from listing too personal or political hobbies</li>
                                <li>Write hobbies based on the job skills in the job description</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>Examples of Hobbies
                                Related to Job Descriptions</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li> Able to meet strict deadlines</li>
                                <li>Ability to work under pressure</li>
                                <li>Ability to delegate tasks to team members</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>Examples of Hobbies
                                Based on Your Passion
                                o Travelling</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li> Playing football</li>
                                <li>Listening to music</li>
                                <li>Swimming</li>
                                <li>Yoga</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>How to List Certifications
                                on a Resume</h5>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>Here are the tips
                                on how to list certifications on your resume</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li> Mention certifications that are relevant to
                                  the position you are applying for.</li>
                                <li>Ensure that you list the certifications in a
                                  separate section within your resume.</li>
                                <li> Give details of the certifying authorities,
                                  their locations, and dates of certification.</li>
                                <li> List your certifications in reverse chronological
                                  order, starting with the current ones.</li></ul>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>Example of How to
                                List Certifications on a Resume</h5>
                              <ul className={classnames(styles.interview_points, 'list-unstyled', 'mt-3')}>
                                <li className={classnames('mb-2')}> Certifications</li>
                                <li className={classnames('mb-2')}>Sales Management Professional (SMP),
                                  Sales Management Institute,
                                  India-July 2019.</li>
                                <li>Market Research Professional (MRP),
                                  Derika Data Science College, Australia-January 2015.</li>

                              </ul>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}> Top Professional Tips
                                for Writing a Business Development Resume</h5>
                              <p className={classnames('mb-2')}>If you intend to
                                look for a business development role in
                                any top company, bear in mind that your resume is
                                the first impression employers see of you.
                                You have to make it persuasive and concise.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-1', styles.align)}>
                                Here are the tips to help you write a resume that
                                can impress your potential employer</h5>
                              <h5 className={classnames('font-weight-bold', 'mt-3', 'mb-2')}>Write a
                                Compelling business development Pitch</h5>
                              <p className={classnames('mb-2')}>
                                Your resume’s career objective is a business development pitch, and
                                it should appear at the top of your resume.
                                A business development pitch must be attractive
                                and compelling to hook hiring managers
                                into reading your resume further.</p>
                              <p className={classnames('mb-2')}>
                                List your skills, knowledge, abilities, and what you
                                can accomplish if you are hired.
                                More importantly, tailor the resume summary to the
                                content of the job description. The skills you list
                                in the career objective should include keywords from
                                the job description.</p>
                              <p className={classnames('mb-2')}>
                                This will make your resume to be among the top search
                                results on the employer’s applicant tracking systems (ATS).</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>Reference
                                Essential business development Skills</h5>
                              <p className={classnames('mb-2')}>Ensure that you highlight your
                                skills according to the
                                recruiter’s language and expectations to help you
                                proceed to the interview stage.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-0', 'mb-2')}>
                                Here are some of the common skills you may include in your resume
                              </h5>
                              <ul className={classnames(styles.interview_points, 'mt-2')}>
                                <li> Time management</li>
                                <li>Verbal and written communication</li>
                                <li> Customer acquisition and retention</li>
                                <li>Closing deals</li>
                                <li>Proactive and self-motivated</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Showcase Your Results</h5>
                              <p className={classnames('mb-2')}>Listing your skills
                                on your resume is not sufficient
                                to convince the hiring managers of your competence.
                                To ease any doubts, ensure that you support your
                                skills with examples or actual statistics.
                                For instance, you may mention specific targets
                                achieved within a particular period.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Here are examples of how you can highlight the skills
                                and achievements on your resume</h5>
                              <ul className={classnames(styles.interview_points, 'mt-2')}>
                                <li> Made an average of $4 million in annual sales</li>
                                <li>Exceeded targets, leading to a 60% increase in
                                  revenue for the past 12 months</li>
                                <li> Strong product knowledge in the bank’s credit
                                  and operations products</li>
                              </ul>
                              <div className={classnames('card', 'p-3', 'px-lg-4', 'mt-5',
                                styles.ProTip, styles.interview_blockquote)}>
                                <h5 className={classnames('ml-5', 'mt-2', 'font-weight-bold')}>Pro Tip
                                </h5><p className={classnames('ml-5')}>After crafting your resume, it is
                                  vital to proofread and edit it to correct any errors. You may
                                  use a spell-checker tool or hire someone to edit it for you.
                                  It is advisable to read your resume aloud to identify and mistakes.</p> </div>
                              <h3 className={classnames('mt-5', 'font-weight-bold')}>Conclusion </h3>
                              <p>Knowing how to write a good resume is crucial in
                                enhancing your chances of recruitment.
                                Hiring managers are interested in resumes that are
                                well-written and informative. To succeed in your job search, ensure
                                that you organize your resume appropriately and present it
                                in a manner that showcases your core competencies</p>

                            </div>
                          </Col>
                          <Col lg={4} xl={4} xs={12}>
                            <PromoCard loginWithRedirect={loginWithRedirect} />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      )
    }
}
