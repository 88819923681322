import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import classnames from 'classnames'
import { get } from 'lodash'
import { getBlogImageUrl, getBlogVideoUrl } from '../../constants'
import styles from './style.scss'

class BlogDetailMoreBlogs extends Component {
  static propTypes = {
    gotoBlogDetails: PropTypes.func.isRequired,
    moreBlogs: PropTypes.array.isRequired,
  }

  render() {
    const { moreBlogs } = this.props
    return (
      <div className={classnames(styles.more_blog_blocks)}>
        <Row>
          {moreBlogs && moreBlogs.length > 0 && moreBlogs.map((blog, index) => (
            <Col className={classnames(styles.mt_20)} lg={4} md={4} sm={12} xl={4} xs={12}>
              <div className={classnames(styles.down_blog_box)}>
                <div
                  className={classnames(styles.down_blog_image)}
                  onClick={() => this.props.gotoBlogDetails(get(blog, 'blogId'), true)}>
                  {get(blog, 'media.type') === 'images' && (
                    <img
                      alt={'imageurl'}
                      className={classnames(styles.blog_image_responsive)}
                      src={getBlogImageUrl(get(blog, 'media.url'))} />
                  )}
                  {get(blog, 'media.type') === 'videos' && (
                    <video
                      className={classnames(styles.blog_image_responsive)}
                      src={getBlogVideoUrl(get(blog, 'media.url'))} />
                  )}
                </div>
                <div className={classnames(styles.down_blog_image_content)}>
                  <h5 className={classnames(styles.down_blog_image_title)}>
                    <span onClick={() => this.props.gotoBlogDetails(get(blog, 'blogId'), true)}>
                      {get(blog, 'title')}{' '}</span>
                  </h5>
                  <p>{get(blog, 'description')}</p>
                  <p className={classnames(styles.down_blog_image_content_date)}>
                    {get(blog, 'createdMonth')} {get(blog, 'createdDay')}, 20{get(blog, 'createdYear')}
                  </p>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    )
  }
}
export default withRouter(BlogDetailMoreBlogs)
