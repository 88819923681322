import React, { Component } from 'react'
import classnames from 'classnames'
import { Container, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import MetaTags from 'react-helmet'
import PromoCard from './PromoCard'
import Download from '../../common/Download'
import GetStartedButton from '../../MainContent/Home/GetStartedButton'
import { getDocUrl } from '../../../constants'
import styles from './style.scss'

export default class HumanResource extends Component {
    static propTypes = {
      loginWithRedirect: PropTypes.func.isRequired,
    }

    render() {
      const { loginWithRedirect, history } = this.props
      return (
        <div>
          <MetaTags>
            <title>{'Human Resource Manager Resume Summary | Resumaker'}</title>
            <meta
            // eslint-disable-next-line max-len
              content={'Create a professional Human Resource Manager resume that makes an impressive statement of your personality and qualifications in the interview. Get your free resume now.'}
              name="description" />
            <link href={'https://www.resumaker.co.in/free-resume-templates'} rel="canonical" />
          </MetaTags>
          <Container fluid>
            <div className={classnames('page-main-wrapper')}>
              <div className={classnames(styles.container_xl, 'mt-5', 'mb-5')}>
                <div className={classnames(styles.page_content_header, 'row', 'align-items-center')}>
                  <Col lg={12} md={12} xl={12} xs={12}>
                    <div className={classnames('text-center', 'mx-auto')}>
                      <img
                        alt="fresher resume" className={classnames(styles.resume_sample_vector, 'img-fluid', 'mx-auto')}
                        src="/assets/images/HRM_vector.jpg" />
                      <div
                        className={classnames('mt-md-5', 'text-center', 'mb-md-4')}>
                        <h1 className={classnames('mt-4', styles.size_heading)}>
                          Human Resource Manager Resume (HR Resume)  </h1>
                        <p className={classnames('mb-0')}>Human Resources Managers
                          deal with planning,
                          directing, coordinating, and administering the employee
                          management function of the organization.
                          Remember that as a Human Resources Manager,
                          you will do essential things like recruiting, planning, and managing payroll.
                          Therefore, you need a resume that attracts the attention of the recruiters.</p>
                      </div>
                      <div className={classnames(styles.btn_gs)}>
                        <GetStartedButton label="Build My Resume" loginWithRedirect={loginWithRedirect} size="lg" />
                      </div>
                    </div>
                  </Col>
                  <div className={classnames(styles.resume_sample_container, 'mt-5', 'pt-3', 'pt-lg-4')}>
                    <Row>
                      <Col lg={6}>
                        <div className={classnames('mb-5', 'p-xl-5', 'mb-xl-0')}>
                          <div className={classnames(styles.resume_sample)}>
                            <img
                              alt="Resume Sample" className={classnames(styles.resume_sample_img)}
                              src="/assets/images/HRM_resume.jpg" />
                            <Download
                              downloadURL={getDocUrl('assets/docs/hrm-resume.docx')}
                              history={history} />
                          </div>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className={classnames('mb-5', 'p-xl-5', 'mb-xl-0')}>
                          <div className={classnames(styles.resume_sample)}>
                            <img
                              alt="Resume Sample" className={classnames(styles.resume_sample_img)}
                              src="/assets/images/HRM_CL.jpg" />
                            <Download
                              downloadURL={getDocUrl('assets/docs/hrm-cl.docx')}
                              history={history} />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className={classnames(styles.resume_sample_header, 'text-center')}>
                      <div className={classnames(styles.container_xl)}>
                        <h2 className={classnames(styles.section_heading)}>
                          Human Resource Management (Text Format)
                        </h2>
                        <p className={classnames('mt-2', styles.section_paragraph)}>
                          Make sure you choose the right resume
                          format to suit your unique experience and the job you are applying.</p>
                      </div>
                    </div>
                    <div className={classnames(styles.resume_sample_wrapper)}>
                      <div className={classnames(styles.container_xl)}>
                        <Row>
                          <Col lg={8} xl={8} xs={12}>
                            <div className={classnames(styles.resume_sample_main)}>
                              <div className={classnames(styles.tab_content)}>
                                Text View
                              </div>
                            </div>
                            <div className={classnames(styles.resume_content_card,
                              styles.dz_card, styles.shadow_1, 'px-3', 'px-lg-5', 'py-5', 'tab-pane active')}>
                              <Row>
                                <h2 className={classnames(styles.section_heading, 'ml-2')}>
                                  Human Resource Manager Resume
                                </h2>
                              </Row>
                              <div className={classnames(styles.resume_content_title, 'mt-3')}>Profile</div>
                              <p className={classnames('mt-2', 'mb-2')}>
                                Conscientious Human Resources professional
                                with force for helping employers recruit, develop and
                                retain qualified candidates.
                                Skilled at partnering with the senior management team to
                                build employee-centric cultures,
                                promoting positive determination and optimizing productivity.
                                Motivating and positive with excellent interpersonal,
                                coaching and communication skills.
                              </p>
                              <div className={classnames(styles.resume_content_title)}>
                                Work Experience</div>
                              <ul className={classnames(styles.interview_points, 'list-unstyled',
                                styles.content_title, 'mt-4')}>
                                <li className={classnames('mb-0')}>Human Resources Manager</li>
                                <li>XYZ Solutions, Belas US, Dec 2019 – till date</li>
                              </ul>
                              <ul className={classnames(styles.interview_points, 'mt-2')}>
                                <li>Guided policies and procedures to harmonize responses,
                                  provide appropriate investigation actions and resolve grievances.</li>
                                <li>Assessed and aligned compensation packages to market to attract highly
                                  qualified applicants for organizational vacancies.</li>
                                <li>Created and implemented dynamic initiatives to improve employee engagement.</li>
                                <li>Forecasted expected personnel demands and developed
                                  sturdy approaches to achieve objectives. </li>
                                <li>Liaised between multiple business
                                  divisions to enhance communications.</li>
                                <li>Structured compensation and benefits
                                  according to market conditions and budget demands.</li>
                                <li>Cultured management on successful policy implementation and
                                  enforcement actions to prevent employee legal entanglements.</li>
                                <li>Developed succession plans and promotion paths for all staff.</li>
                              </ul>
                              <ul className={classnames(styles.interview_points, 'list-unstyled',
                                styles.content_title, 'mt-4')}>
                                <li className={classnames('mb-0')}>Human Resources Executive</li>
                                <li>ABC Solutions, Belas US, Jan 2014 - Nov 2019</li>
                              </ul>
                              <ul className={classnames(styles.interview_points, 'mt-2')}>
                                <li>Coordinated work activities for HR managers,
                                  specialists and recruiting agents.</li>
                                <li>Followed programs closely to assess the effectiveness
                                  and make proactive changes to meet changing demands.</li>
                                <li>Monitored and handled all employee claims,
                                  including performance-based and harassment incidents.</li>
                                <li>Structured compensation and benefits
                                  according to market conditions and budget demands.</li>
                                <li>Fostered cross-functional relationships to connect
                                  managers and employees and improve overall efficiency.</li>
                                <li>Liaised between multiple business divisions to enhance communications.</li>
                                <li>Developed succession plans and promotion paths for all staff.</li>
                                <li>Designed sturdy compensation and benefits packages to attract top employees.</li>
                                <li>Directed hiring and onboarding programs for new employees.</li>
                                <li>Met with employees annually for progress reviews and performance assessments.</li>
                              </ul>
                              <div className={classnames(styles.resume_content_title, 'mt-3')}>Certifications</div>
                              <ul className={classnames(styles.interview_points, 'mt-4')}>
                                <li>SHRM Senior Certified Professional (SHRM-SCP)</li>
                                <li>Society for Human Resource Management (SHRM) certification</li>
                                <li> SHRM Certified Professional (SHRM-CP)</li>
                              </ul>
                              <div className={classnames(styles.resume_content_title)}>
                                Skills
                              </div>
                              <ul className={classnames(styles.interview_points, 'mt-4')}>
                                <li>Recruitment</li>
                                <li>Personnel Recruitment</li>
                                <li>Compensation Structuring</li>
                                <li> Equal Opportunities Facilitation </li>
                                <li> Company Organization</li>
                                <li>Conflict Resolution </li>
                                <li> Multitasking abilities</li>
                                <li>Talent management</li>
                                <li>Training programs</li>
                              </ul>
                              <div className={classnames(styles.resume_content_title, 'mt-3')}>
                                Education summary</div>
                              <ul className={classnames(styles.interview_points, 'list-unstyled', 'mt-4')}>
                                <li>MBA - Human Resources Management (Sir Andrew College, Belas)</li>
                              </ul>
                              <div className={classnames(styles.resume_content_title)}>
                                Accomplishments
                              </div>
                              <ul className={classnames(styles.interview_points, 'mt-4')}>
                                <li>Created and used Microsoft Excel to
                                  develop inventory tracking spreadsheets.</li>
                                <li>Resolved product issue through consumer testing.</li>
                              </ul>
                            </div>
                            <div className={classnames(styles.resume_sample_header, 'pb-5')}>
                              <h2 className={classnames(styles.section_heading, 'mb-3')}>
                                How to Write Human Resource Resume</h2>
                              <p className={classnames('mb-3')}>As a forthcoming human resource manager,
                                you will be answerable for recruiting the correct workers.
                                The enrollment specialists will have a hard time believing
                                you&#8242;re skilled in the event that you,
                                yourself, don&#8242;t present an ideal resume.</p>
                              <p>In this article, you will get in-depth information
                                on how to write a human resource manager&#8242;s resume.</p>
                            </div>
                            <div
                              aria-labelledby="resumeContentTab"
                              className={classnames(styles.resume_content_card,
                                styles.dz_card, styles.shadow_1, 'px-0', 'px-lg-5', 'py-5', 'tab-pane active')}
                              id="resumeContent" role="tabpanel">
                              <h3 className={classnames(styles.resume_content_heading, 'mt-0')}>
                                How to Write Human Resource Resume Summary</h3>
                              <p className={classnames('mb-0')}>Your resume summary is the principal
                                bit of substance that will
                                acquaint you with recruiting administrators.
                                It rapidly introduces you to the recruiters, provides
                                your background, and incorporates
                                accomplishments to demonstrate your HR aptitudes.</p>
                              <p className={classnames('mb-2')}>Feature your top specialized
                                abilities, highest qualifications,
                                and individual qualities.
                                Focus on your title, recognizing certifications, or experience
                                issues that are related to HR.
                                Then include a specific
                                value that you can bring to the company. Ensure that the length of your
                                summary is between 3 and 6 lines.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>Here is how to write a
                                human resource resume summary</h5>
                              <ul className={classnames(styles.interview_points, 'mt-4')}>
                                <li>Analyze the job descriptions and craft a resume
                                  that is tailored to the employer&#8242;s requirements.</li>
                                <li>Highlight your work experience using essential details, such as the number
                                  of years worked and the previous position.</li>
                                <li>List only relevant skills to the job you are applying for</li>
                                <li> Use metrics to highlight and quantify your accomplishments</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>
                                Examples of Human Resource Manager Resume Summaries</h5>
                              <p>The following are good and bad examples of
                                customer service resume summaries.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>Good Example</h5>
                              <blockquote><p className={classnames(styles.resume_rank, 'ml-0')}>
                                Human Resource Manager with more than five years of work experience in recruiting,
                                training, and payroll management.
                                Familiar with office applications and employee service software tools.
                                Adept at employee motivation and retention. Managed a payroll of up to 15,000
                                employees and maintained a 95% retention rate for three consecutive years.
                              </p></blockquote>
                              <h5 className={classnames('ml-3', 'font-weight-bold')}>Bad Example</h5>
                              <blockquote><p className={classnames(styles.resume_rank, 'ml-0')}>
                                Qualified and highly-experienced human resource manager
                                with 5+ years of work experience in the industry.
                                Ready to take up new challenges and better employees&#8242; welfare.
                              </p></blockquote>
                              <div className={classnames('card', 'p-3', 'px-lg-4', 'mt-5',
                                styles.ProTip, styles.interview_blockquote)}>
                                <h5 className={classnames('ml-5', 'font-weight-bold')}>Pro Tips </h5>
                                <ul className={classnames(styles.interview_points, 'mt-4', 'ml-5')}>
                                  <li>Use metrics to quantify your achievements on the summary.
                                    In case you lack experience,
                                    highlight relevant volunteer work, or focus on any other accomplishments.</li>
                                  <li>Utilize the resume objective when you lack the experience.
                                    The objective statement shows your career goals instead,
                                    but it still gives an achievement with numbers to validate your worth to them.</li>
                                  <li>List relevant skills that relate to the job you are applying for.
                                    You can pick such skills from the job description.</li>
                                </ul>
                              </div>
                              <h3 className={classnames(styles.resume_content_heading, 'mt-5', 'mb-4')}>
                                How to Highlight Skills on a Human Resource Resume
                              </h3>
                              <img
                                alt="Nurseimage" className={classnames('mt-2', 'mb-3')}
                                src="/assets/images/HRM_skill.jpg" />
                              <p className={classnames('mb-0')}>Recruiters analyze your skills to
                                determine whether you are the
                                right fit for the job. Hiring managers are aware of the
                                relevant skills needed for the role,
                                and they always scan resumes to find out if they
                                have the keywords related to the skills.</p>
                              <p>Human resource managers play a crucial role in recruiting,
                                training and managing staff welfare. Therefore, they need
                                skills to do such tasks.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>Here is how
                                to highlight
                                skills on your resume</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Highlight both soft and hard skills on your resume</li>
                                <li>Analyze the HR manager job descriptions and understand what
                                  you are expected of</li>
                                <li>To determine the right keywords to use on a resume, highlight skills
                                  they&#8242;re looking for in the job requirements section
                                  of the advertisement.</li>
                                <li>If any of your abilities match a highlighted skill
                                  from the job advertisement, put that on your resume.</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold')}>Here are some
                                of the important HR skills
                                and why you should always highlight on your resume</h5>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>Communication
                                Skill</h5>
                              <p className={classnames('mb-2')}>Communication skill is crucial for HR managers
                                because they handle employee relations and human resources matters.</p>
                              <h5 className={classnames('font-weight-bold')}>Conflict
                                management</h5>
                              <p className={classnames('mb-2')}>Conflict management skill helps HR
                                managers resolve a wide range of conflicts
                                to maintain the smooth operation of the company&#8242;s activities</p>
                              <h5 className={classnames('font-weight-bold')}>Decision making</h5>
                              <p>Decision-making skill helps HR managers decide on hiring
                                employees and dealing with challenges.</p>
                              <div className={classnames('card', 'p-3', 'px-lg-4', 'mt-5',
                                styles.ProTip, styles.interview_blockquote)}>
                                <h5 className={classnames('ml-5', 'font-weight-bold')}>Pro Tip </h5>
                                <p className={classnames('ml-5')}>
                                  Ensure to check the job description to determine the kind of human
                                  resource management skills an employer wants.
                                  Additionally, highlight both hard and soft skills to set your
                                  resume apart from competitors.</p>
                              </div>
                              <h3 className={classnames(styles.resume_content_heading, 'mt-5')}>
                                How to Write Work Experience in
                                Human Resource Resume</h3>
                              <p className={classnames('mb-3')}>If you are applying for a management post,
                                your employment history should be relevant and detailed.
                                Before you highlight your work history, review the
                                HR manager job description to familiarize yourself
                                with the recruiter&#8242;s requirements. This helps you
                                understand the duties, tasks, and other
                                key details for this job post.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>Here is how to highlight work
                                experience on an HR manager&#8242;s resume</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Help a recruiter understand the extent of your
                                  experience by providing your job duties in bullet format,
                                  focusing on at least five critical activities.</li>
                                <li>Highlight the extent of your responsibility by
                                  giving details on company turnover and
                                  employee count or the business as well as
                                  the industry of operations.</li>
                                <li>Provide experience from the last ten years and make
                                  sure that your resume doesn&#8242;t go beyond two pages.</li>
                                <li>Utilize strong verbs to highlight your responsibilities.
                                  Focus on skills that are relevant to your field
                                  and make use of the best keywords.
                                  Example: “Exceeded customer growth target by 10% in 2020.”</li>
                                <li>Quantify your achievements using percentages,
                                  dollar values, numbers, and amounts.
                                </li>
                                <li>List your past jobs using reverse
                                  chronological order: from the most recent to oldest.</li>
                              </ul>
                              <p>Your employment history should focus on the outcome
                                of your projects and also highlight your specific input.
                                Your recruiters may intend to implement something similar, and it is
                                important to know that you can deliver as opposed to just taking part.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>
                                Example of Human Resource Manager
                                Experience on a Resume</h5>
                              <h5 className={classnames('font-weight-bold', 'mt-4')}>Example</h5>
                              <ul className={classnames(styles.interview_points, 'list-unstyled',
                                styles.content_list, 'mt-3')}>
                                <li className={classnames('mb-0')}>Human Resource Manager</li>
                                <li className={classnames('mb-0')}> June 2017- August 2020</li>
                                <li>Enamel Consulting</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold')}>
                                Key Qualifications &amp; Accomplishment</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>

                                <li>Solved staff complaints within the recommended time</li>
                                <li>Served employees within the recommended turn-around time</li>
                                <li>Received 98% positive feedback from employee survey outcome</li>
                                <li>Established an online record-keeping system for a 200 person sales department,
                                  including payroll, fuel, and travel
                                  reimbursements, quarterly bonus pay-outs,
                                  monthly commission payments, and leave management,
                                  halving the time spent by sales administrators on these issues.</li>
                                <li>Facilitated an onboarding program for all 4,000
                                  incoming graduates across the country, which
                                  reduced their settling in the period from one month.</li>
                              </ul>
                              <div className={classnames('card', 'p-3', 'px-lg-4', 'mt-5',
                                styles.ProTip, styles.interview_blockquote)}>
                                <h5 className={classnames('ml-5', 'font-weight-bold')}>Pro Tips </h5>
                                <ul className={classnames(styles.interview_points, 'mt-4', 'ml-5')}>
                                  <li>Bold and capitalize the section heading. Ensure that
                                    the heading stands out, and name it with any of the
                                    titles, such as &quot;work history,&quot; &quot;employment history,
                                    &quot; or &quot;work experience.&quot;</li>
                                  <li>List your experience section below the resume
                                    summary if you have a lot of professional
                                    experience and below the education section if you lack a work history.</li>
                                </ul>
                              </div>
                              <h3 className={classnames(styles.resume_content_heading, 'mt-5')}>
                                How to write a HR Manager&rsquo;s Achievements,
                                Hobbies, Training and certifications on Resume</h3>
                              <p className={classnames('mb-2')}>Besides degree and
                                diploma certificates, Human Resources Managers
                                should have achievements, hobbies, certifications, and training to
                                keep their skills current with global human
                                resource trends.
                                Be sure to include these in your education section as well.</p>
                              <h5 className={classnames('font-weight-bold')}>How to Highlight Your
                                Achievements on a Resume</h5>
                              <p className={classnames('mb-2')}>Knowing how to highlight key achievements
                                on your resume may help you get
                                a good job. Your achievements prove to recruiters
                                that you were an excellent performer in your previous assignment,
                                which may make you the best candidate for the job.</p>
                              <h5 className={classnames('font-weight-bold')}>Examples of How to Describe
                                Human Resource achievement</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Surpassed staff training target by 30%</li>
                                <li>Achieved a 40% employee retention rate, which was above the company’s average</li>
                                <li>Ranked the best HR officer within my first three years of employment</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold')}>How to Highlight Hobbies
                                on Human Resource Manager Resume</h5>
                              <p className={classnames('mb-2')}>Listing the right hobbies on your resume can boost
                                your chances of getting hired. Highlighting hobbies, such as traveling,
                                on your resume demonstrate to your recruiters that you
                                are fit for jobs that require regular traveling.</p>
                              <h5 className={classnames('font-weight-bold')}>Here are the reasons why you
                                should list hobbies on your resume</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>When you lack enough experience</li>
                                <li>When you have limited or irrelevant skills</li>
                                <li>The recruiter value uniqueness in applicants</li>
                                <li>The job description matches your hobbies</li>
                              </ul>
                              <p className={classnames('mb-2')}>Examples of the hobbies
                                you can highlight on your resume
                                include socializing, traveling, reading,
                                volunteering, community service, etc.</p>
                              <h5 className={classnames('font-weight-bold')}>How to Highlight
                                Certifications on a Resume</h5>
                              <p className={classnames('mb-2')}>Certifications are crucial, and adding them to your
                                resume is an excellent way of proving to the recruiters that
                                you are fit for the job. Certifications show the right
                                qualifications and competence to perform in a job.</p>
                              <h5 className={classnames('font-weight-bold')}>Tips on How to Highlight
                                Certifications on Your Resume</h5>
                              <p className={classnames('mb-2')}>Before you list certifications
                                on your resume, bear in mind that certifications
                                are different from online courses. Certifications
                                are acquired from relevant authorities
                                after achieving some skills, and online classes
                                may not always lead to certifications.</p>
                              <ul className={classnames(styles.interview_points)}>
                                <li>Highlight certifications the right way.</li>
                                <li>Make sure you title the section “certification.”</li>
                                <li>Highlight the certifications in reverse-chronological order</li>
                                <li>When adding certifications, list the following: Name of the certification,
                                  certifying authority, date of certification, and location.</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold')}>Examples of Certification</h5>
                              <h5 className={classnames('font-weight-bold', 'mt-4', 'mb-2')}>Right</h5>
                              <p className={classnames('mb-2')}>HRM, ITEL Management School, Malaysia</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>Wrong</h5>
                              <p>Excellent Human Resource certificate, ITEL Management School</p>
                              <div className={classnames('card', 'p-3', 'px-lg-4', 'mt-5', styles.ProTip,
                                styles.interview_blockquote)}>
                                <h5 className={classnames('ml-5', 'font-weight-bold')}>Pro Tip
                                </h5><p className={classnames('ml-5')}>
                                  Ensure to highlight certifications that are
                                  relevant to the job you are applying for.</p>
                              </div>
                              <h3 className={classnames('font-weight-bold', 'mt-5', styles.align)}>
                                Top Professional Tips for Writing a Human Resource Manager Resume</h3>
                              <p className={classnames('mb-2')}>
                                Knowing how to craft a resume is a critical aspect of your career advancement.
                                If you are looking for an HR manager
                                position and don’t know how to write a perfect resume, don&#8242;t worry,
                                the following tips will help you a lot.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2', 'mt-3')}>Use a Good Headline</h5>
                              <p className={classnames('mb-2')}>Include a headline statement
                                that informs recruiters &quot;who&quot; you are professionally in regard
                                to your current career objective. With just a
                                quick glance, recruiters instantly recognize that
                                you’re an HR manager, an employee and benefits specialist, or a senior
                                HR and organizational development executive.</p>
                              <p className={classnames('mb-2')}>Ensure that whatever you
                                highlight in the headline matches what you have done
                                in the past and what you want to do in the future. This is crucial because you
                                want recruiters to perceive you as the most qualified and experienced
                                candidate for the positions you are applying for.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-0', 'mb-2')}>
                                Share Details of Your Past Employment
                              </h5>
                              <p className={classnames('mb-2')}>Assist your
                                recruiters in understanding the extent of your experience by
                                providing details about your employment history. You can feature the number
                                of employees, locations, specific business, and performance. Utilize excellent
                                strategies to highlight your employment history. Mind about your goals and add
                                the details that make you a good fit for the recruiters.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Emphasize Achievements</h5>
                              <p className={classnames('mb-2')}>Highlighting achievements is an integral approach to
                                making your resume stand out. Without specific
                                accomplishments, your resume will sound generic.</p>
                              <p className={classnames('mb-2')}>Although your qualifications
                                are essential, recruiters want
                                to know more about you. They want to understand what
                                you have achieved, and how you have contributed to business objectives.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>Highlight HR Keywords</h5>
                              <p className={classnames('mb-2')}>Keywords are important for both human readers
                                and electronic gadgets scanning your resume.
                                If you don&#8242;t include the keywords, you may be
                                perceived as unqualified and your resume
                                may be passed over. Ensure to list keywords in every section of your resume.
                                With the right keyword usage, recruiters will immediately gain a sense of your
                                competence, setting you apart from the competition.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>Prepare Your Resume for
                                Applicant Tracking Systems (ATS)</h5>
                              <p className={classnames('mb-2')}>As an HR candidate,
                                follow best practices for formatting your resume,
                                incorporating the right keywords, and increasing the keyword density.
                                This gives you a greater chance of being found in the ATS keyword
                                scan when HR managers use specific terms to do the searches.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Highlight What Makes You Special</h5>
                              <p className={classnames('mb-2')}>
                                Although your professional qualifications and experience are vital
                                in positioning you as a qualified candidate,
                                adding relevant certifications will set you apart from
                                the competition, hence boosting your chances of recruitment.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Edit and Proofread Your Resume</h5>
                              <p>Recruiters don’t have the time to go through
                                irrelevant experiences, fluffy adjectives,
                                and grammar errors that weighs down your resume.
                                Conduct a repeated review and careful editing on your
                                resume to determine what&#8242;s essential to include to it perfect.
                                You may get someone to proofread and edit your CV on your behalf.</p>
                            </div>
                          </Col>
                          <Col lg={4} xl={4} xs={12}>
                            <PromoCard loginWithRedirect={loginWithRedirect} />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      )
    }
}
