import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { get } from 'lodash'
import classnames from 'classnames'

import { AppConstants } from '../../constants/application-constants'
import styles from './style.scss'

const queryString = require('query-string')

@inject('applicationModel')
@observer
class OAuthContainer extends Component {
  static propTypes = {
    applicationModel: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }

  constructor(props){
    super(props)
    const params = queryString.parse(props.location.search)
    this.state = {
      authToken: get(params, 'authToken', ''),
      refreshToken: get(params, 'refreshToken', ''),
      success: get(params, 'authToken', '') !== '',
      error: get(params, 'error', ''),
    }
  }

  componentDidMount(){
    const { applicationModel } = this.props
    const { authToken, success, refreshToken } = this.state
    const redirectDetails = applicationModel.getRedirect()
    const redirectUrl = get(redirectDetails, 'redirectTo')
    console.log('authToken', authToken)
    if (success){
      applicationModel.setLogin(true)
      localStorage.setItem('authToken', authToken)
      localStorage.setItem('isLogin', true)
      if (refreshToken){
        localStorage.setItem('refreshToken', refreshToken)
      }
      if (redirectUrl){
        window.location.assign(`${AppConstants.dashboardHost}/${redirectUrl}`)
      }
      else {
        window.location.assign(`${AppConstants.dashboardHost}/dashboard`)
      }
    }
  }

  render() {
    const { token, error } = this.state
    return (
      <div className={classnames(styles.login_page)}>
        {error}
        {token}
      </div>
    )
  }
}
export default withRouter(OAuthContainer)
