import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { Container, Button } from 'react-bootstrap'
import cs from 'classnames'
import MetaTags from 'react-helmet'
import PropTypes from 'prop-types'
import st from '../style.scss'
import appstyle from '../../../containers/AppContainer/appmain.scss'
import { SEO } from '../../../constants/constants'

class TermsNConditions extends Component {
static propTypes = {
  gotoURL: PropTypes.func.isRequired,
}
render() {
  return (
    <Fragment>
      <MetaTags>
        <title> Terms &amp; Conditions | Resumaker</title>
        <meta content={SEO.DESCRIPTION} name="description" />
      </MetaTags>
      <div className={cs(st.notice, st.notice_conditions)}>
        <Container className={cs(st.box_notice)}>
          <h2 className={cs(st.notice_title)}>
            Terms &amp; Conditions
          </h2>
          <div className={cs(st.notice_conditions_div)}>
            <p className={cs(st.tt_center)}>
              The www.resumaker.co.in (Resumaker) terms of service (terms)
              regulate how you access and use Resumaker website and the
              services (&#39;Services&#39;). Kindly, go through the terms
              keenly and you are free to contact us for clarifications.
            </p>
            <h4>1. Acceptance of the Terms</h4>
            <p>
              By accessing the site and accepting the terms of service
              agreement shows that you have the right and the capacity to
              agree to go by the agreement. We have the right to make updates
              to the agreement anytime we wish to (at our own interest), the
              kind of changes we provide by any reasonable and legitimate
              means without limitations whatsoever. The updates include new
              versions of the agreement being posted on the site. Note that,
              you are automatically become bound by all additional updated
              guidelines, protocols, terms and conditions applicable to all
              service related to any service you access and use on this site.
              We retain the right to temporarily or permanently do a partial
              or a complete site modification or even discontinue it regularly
              and at any time at our own discretion with and without notifying
              the users. We also have the right to modify or wave the fees
              charged in relation with the use of the site and the services
              and provide opportunities for some or to all site users. You
              agree that we shall not be faulty for any site modification,
              termination or even discontinuation of the site to you or to any
              third party be it partial, in whole or of any service and
              feature accessed via the site. If you continue using the site
              after such changes, it will send an indication that you accept
              the changes.
            </p>
            <h4>2. Administration Issues</h4>
            <p>
              The Site is controlled and operated by AARWII TechSolutions Pvt
              Ltd, India, and is only subjected to the laws and jurisdiction
              of Andhra Pradesh, India. Resumaker does not guarantee safety or
              availability of the site services at any specific country or
              part of the world. Anyone who wishes to access the site services
              can do the same from anywhere and at any time as he/she wishes.
              As you agree to the terms and conditions of this site, you
              become subject to the Indian export controls and you are then
              responsible for any violations of such controls, rules and the
              regulations that restrict exports.
            </p>
            <h4>3. Information submitted through the site</h4>
            <p>
              Resumaker privacy policy (Located at
              www.resumaker.co.in/privacy-policy) governs your submission of
              information through the Site. This Agreement incorporates by
              reference the terms and conditions of the Privacy Policy. The
              governance includes all the creation and modifications and the
              reviews at Resumaker. Any information you provide in relation
              with your use of the site and its services, is expected to
              remain true, accurate and always complete. In addition, you are
              tied to maintain and update the information you provide
              regularly. You agree to the policy that any false, inaccurate or
              incomplete provided by you, may be used to terminate your access
              and use of the services.
            </p>
            <h4>4. Rules of conduct</h4>
            <p>
              As you use the site, it is expected of you that you comply and
              strictly abide to the rules and regulations. You are in addition
              required to respect other users, their rights and their dignity
              as well. Your continued use of the site depends on your
              compliance with set rules and regulation otherwise any
              ignorance/violation of any of the rules may result to a
              termination of your access to the site.
            </p>
            <h4>5. Description of the Services</h4>
            <p>
              We offer certain specialized services to the users of the website
              with access to certain specialized content and services related
              to professional resumes, including without limitation, the
              creation of electronic profiles, resume organization and
              conversion functionality, forums, video clips, photographs,
              graphics, images, charts, text, data, user comments, postings,
              messages, articles and other similar content (such content and
              services, collectively, the services). Every content and
              services made accessible over the Site that were not made
              available as of the LAST UPDATED date, shall automatically
              be deemed with an immediate effect to be part of the Services
              when they are first made available for the users through the
              Site.
            </p>
            <h4>6. Termination</h4>
            <p>
              The termination agreement is valid and effective until
              termination. The Resumaker may decide to terminate your access
              to use of the site at its own judgment for any reason and at any
              time. This includes if the Resumaker believes that you have
              acted against the letter of this agreement. If this termination
              is executed, you cease to benefit the right to access the site
              and its services with immediate effect. You agree that any case
              of termination of your access to the site and its services may
              be executed without any prior notice, and that the Resumaker may
              disable or erase your username and password and all other
              related information an even all other files associated to this
              information will thereby be inaccessible to you and any other
              person. You agree that the Resumaker shall not be liable both
              you and any third party for the immediate termination of your
              access to the site and all the files and services in the site
              and it shall not be expected to make any information/ files
              available for you whatsoever.
            </p>
            <h4>7. License</h4>
            <p>
              For the sake of clarity, you remain the owner of any materials
              you submit through the site or including on any Resumaker.
              Nonetheless, you are required to note that we need certain
              rights in connection with your submission so that we can make
              them available on the site. For instance, you give to Resumaker
              and its designers a global, non-exclusive, transferable,
              royalty-free, fully paid up, perpetual, irrevocable right and
              license, without compensation to you.
            </p>
            <ul className={cs(st.notice_list)}>
              <li>
                {' '}
                <span>a)</span>To use, reproduce, distribute, adapt (including
                without limitation edit, modify, translate, and reformat),
                create derivative works of, publicly display and publicly
                perform such Submission, in any media now known or hereafter
                developed, for business purposes, and
              </li>
              <li>
                <span>b)</span>To sublicense the preceding rights, through
                several ranks, to the maximum limits allowed by applicable
                law; This is if, however, that in each case, with respect to
                the information, files or materials you submit on Resumaker,
                such rights shall be subject to any restrictions or
                limitations established by you in connection with your
                creation or maintenance.
              </li>
            </ul>
            <h4>8. Information on the Site</h4>
            <p>
              All/Any Information made available on the Site or through a
              Forum may be provided both by Resumaker and by third-party
              visitors to the Site. Kindly note that Site visitors may post
              material/information or make statements on the Site that are
              inaccurate, violating the laws or deceptive. Resumaker does not
              declare and is not responsible for any Proposal, opinion,
              advice, information, material or even statements provided on the
              Site or through a Forum (including on any Resumaker) by third
              parties, and Resumaker is not responsible for any information or
              materials made available through the Site or through a Forum
              (including on any Resumaker) or results obtained by using any
              such information or materials. Under no circumstances will the
              entire Resumaker fraternity be liable for any loss or damage
              caused by your reliance on such information or materials. The
              opinions expressed on the Site or through a Forum (including on
              any Resumaker) reflect solely the opinions of the individuals
              who submitted such opinions, and may not reflect the opinions of
              Resumaker.
            </p>
            <p>
              Moreover, Resumaker shall not by any case have any liability
              for, any damages caused by the use or misuse by any third party
              of the information that you make public through any part of the
              Site or through a Forum. IF YOU CHOOSE TO MAKE ANY OF YOUR
              PERSONALLY IDENTIFIABLE OR OTHER PERSONAL INFORMATION AVAILABLE
              ON PUBLIC VIA THE SITE OR THROUGH A FORUM (INCLUDING ON ANY
              RESUMAKER), YOU DO THAT AT YOUR OWN RISK.
            </p>
            <h4>9. Registration, User Names and Passwords</h4>
            <p>
              It is a requirement that you register with Resumaker so that you
              can be able to access the site, its services and certain areas
              within the site. With respect to such registration, we may
              decide not to approve you, and you may be denied the chance to
              use, a user name that is already in use by another user; that
              may be judged as a case of impersonating someone else; that
              belongs to another person; that violates the intellectual
              property; that is offensive; or that we reject for any other
              reason in our judgment. Your user name and password are
              confidential and for your personal use only, and not for use by
              any other person, including other members of any organization
              for which you work or with which you are otherwise affiliated.
            </p>
            <h4>10. SPECIAL NOTE REGARDING THE SERVICES</h4>
            <p>
              It is here brought to your attention that, we provide some
              specific services that are specialized in a way, with connection
              to the creation of an online (electric) profile for a single
              individual who registers for the Site and provides resume
              information and for individuals for whom a resume is provided to
              the Site. These kind of profiles include features that are
              enhanced and determined by Resumaker (each such electronic
              profile, together with all of other information, video or audio
              clips, photographs, graphics, images, charts, text, data,
              comments, postings messages, articles and other similar content
              posted by an individual), Resumaker will not screen or review
              any of the User to determine suitability for employment, nor
              will it consider any of the individuals for employment with
              Resumaker. Resumaker does not act as an agent for any individual
              submitting his or her resume or Resumaker through the Site or
              any company that may view an individual resume or Resumaker
              through the Site, and nothing herein creates an
              employer-employee, agency or other relationship between
              Resumaker and such individual. Resumaker is not, and shall not
              be, responsible for recruiting, hiring, or any other decisions
              related to employment with respect to the Resumaker or any
              individuals using the Site.
            </p>
            <p>
              The Site acts as a venue for individuals to create Resume and
              for certain companies to view such Resume. Resumaker does not
              review, screen, edit or monitor the Resume or make any judgments
              about or selections of Resumaker User or individuals. Resumaker
              is not involved in the actual transaction between a potential
              employer and any individuals through the Site. Therefore,
              Resumaker has no control over the Resume or the quality, truth,
              accuracy, reliability, completeness or timeliness of such
              Resume, or the ability of a potential employer to offer
              opportunities to individuals, or the ability of individuals to
              fill job openings, and Resumaker makes no representations or
              warranties about any Resumaker User, potential employer or
              individual.
            </p>
            <h4>11. Forums</h4>
            <p>
              We and our third-party service providers have the right to make
              the services available for the site users through the Site (for
              example the chat functionality and massage boards) to which you
              are able to post information and materials.
              PLEASE BE AWARE THAT SUCH FORUMS MAY BE PROVIDED BY A THIRD
              PARTY, AND MAY BE SUBJECT TO THE TERMS AND CONDITIONS OF SUCH
              THIRD PARTY, AS WELL AS THIS AGREEMENT
            </p>
            <h4>12. Links</h4>
            <p>
              It is possible for the site to provide links to other web sites.
              This is because Resumaker has zero control over such sites and
              resources, you agree that Resumaker is not accountable for the
              availability of such external sites or resources, and Resumaker
              neither endorses nor is responsible or liable for any content,
              advertising, products or other materials on or available through
              such sites or resources. You acknowledge and agree that
              Resumaker does not endorse such sites and is not and shall not
              be responsible or liable for any links from those sites to the
              Site, any content.
            </p>
            <h4>13. Copyright Policy</h4>
            <p>
              The Digital Millennium Copyright Act of 1998 avails recourse for
              copyright owners who believe that material appearing on the
              Internet infringes their rights under U.S. copyright law. If you
              believe in good faith that materials available on the Site
              infringe your copyright, you (or your agent) may send Resumaker
              a notice requesting that Resumaker to remove the material or
              block access to it. If you believe in good faith that someone
              has wrongly filed a notice of copyright infringement against
              you, the DMCA permits you to send Resumaker a counter-notice.
              Both notices and counter notices are supposed to meet the
              current statutory requirements imposed by the DMCA.
            </p>
            <h4>14. Contact Us</h4>
            <p>
              For more information and clarification on the meaning of
              application of this Agreement, please feel free to direct the
              question to us. Kindly note that communications via electronic
              mails will not necessarily be secure. It is advised that you
              should not include credit card information or other sensitive
              information in your email correspondence with us.
            </p>
            <p className={cs(appstyle.mt_20)}>
              Head Office:
              <span className={cs(st.span_block)}>
                <div className={cs(st.link)} onClick={() => this.props.gotoURL('/')}>www.resumaker.co.in</div>
              </span>
              <span className={cs(st.span_block)}>
                AARWII TechSolutions Pvt Ltd,
              </span>
              <span className={cs(st.span_block)}>
                {' '}
                Plot #9, IT Park, Mangalagiri, PIN - 522503,
              </span>
              <span className={cs(st.span_block)}>
                Andhra Pradesh, India. Email: support@resumaker.co.in
              </span>
              <span className={cs(st.span_block)}>
                Phone: (+91) 9121 900 900 (available Monday to Friday, 9 AM -
                6 PM)
              </span>
            </p>
          </div>
          <div className={cs(st.btn_group)}>
            <Button
              className={cs(
                st.btn,
                st.btn_primary,
                st.decline,
              )}
              >
              Decline
            </Button>
            <Button
              className={cs(
                st.btn,
                st.btn_primary,
                st.accept,
              )}
              >
              Accept the terms
            </Button>
          </div>
        </Container>
      </div>
    </Fragment>
  )
}
}

export default withRouter(TermsNConditions)
