import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'mobx-react'
import * as mobX from 'mobx'
import { toast } from 'react-toastify'
import ReactGA from 'react-ga'

import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/styles/style.scss'
import { AppContainer, ToastContainer } from './containers'
import { stores } from './store'

mobX.configure({ enforceActions: true })
toast.configure()
if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('UA-120517618-2')
}

render(
  <Provider {...stores}>
    <div>
      <AppContainer {...stores} />
      <ToastContainer toastModel={stores.toastModel} />
    </div>
  </Provider>,
  document.getElementById('root'),
)

window.store = stores
