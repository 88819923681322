import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import classnames from 'classnames'
import styles from './style.scss'

class BlogDetailTestimonial extends Component {
  render() {
    return (
      <div className={classnames(styles.blog_details_testimonials)}>
        <h5> Jamnesh Sinh </h5>
        <span className={classnames(styles.blog_details_testimonial_position)}> Senior Operations Manager </span>
        <p>Resumaker team created impressive resume for me,
          I got interivew call with-in a week after updating my resume in job portal.
          Interview went smooth and my current pay is much higher. </p>
      </div>
    )
  }
}
export default withRouter(BlogDetailTestimonial)
