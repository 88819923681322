import React, { Component } from 'react'
import classnames from 'classnames'
import { Container, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import PromoCard from './PromoCard'
import Download from '../../common/Download'
import GetStartedButton from '../../MainContent/Home/GetStartedButton'
import { getDocUrl } from '../../../constants'
import styles from './style.scss'

export default class UIDesigner extends Component {
    static propTypes = {
      history: PropTypes.object.isRequired,
      loginWithRedirect: PropTypes.func.isRequired,
    }

    render() {
      const { loginWithRedirect, history } = this.props
      return (
        <div>
          <Container fluid>
            <div className={classnames('page-main-wrapper')}>
              <div className={classnames(styles.container_xl, 'mt-5', 'mb-5')}>
                <div className={classnames(styles.page_content_header, 'row', 'align-items-center')}>
                  <Col lg={12} md={12} xl={12} xs={12}>
                    <div className={classnames('text-center', 'mx-auto')}>
                      <img
                        alt="fresher resume" className={classnames(styles.resume_sample_vector, 'img-fluid', 'mx-auto')}
                        src="/assets/images/UI_Vector.jpg" />
                      <div
                        className={classnames('mt-md-5', 'text-center', 'mb-md-4')}>
                        <h1 className={classnames('mt-4', styles.size_heading)}>UI/UX Designer Resume  </h1>
                        <p className={classnames('mb-0')}>Writing a UI/UX Designer resume
                          can be challenging if you don&#8242;t know how to go about it.
                          For one reason, you need to present your work history,
                          summary, and skills in a manner that meets an employer&#8242;s requirements.
                          To enhance your chances of getting hired,
                          you must think of giving your resume a good shape.
                          It is essential to bear in mind
                          that a resume is meant to sell the person that has it.
                        </p>
                      </div>
                      <div className={classnames(styles.btn_gs)}>
                        <GetStartedButton label="Build My Resume" loginWithRedirect={loginWithRedirect} size="lg" />
                      </div>
                    </div>
                  </Col>
                  <div className={classnames(styles.resume_sample_container, 'mt-5', 'pt-3', 'pt-lg-4')}>
                    <Row>
                      <Col lg={6}>
                        <div className={classnames('mb-5', 'p-xl-5', 'mb-xl-0')}>
                          <div className={classnames(styles.resume_sample)}>
                            <img
                              alt="Resume Sample" className={classnames(styles.resume_sample_img)}
                              src="/assets/images/UI_resume.jpg" />
                            <Download
                              downloadURL={getDocUrl('assets/docs/UI-resume.docx')}
                              history={history} />
                          </div>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className={classnames('mb-5', 'p-xl-5', 'mb-xl-0')}>
                          <div className={classnames(styles.resume_sample)}>
                            <img
                              alt="Resume Sample" className={classnames(styles.resume_sample_img)}
                              src="/assets/images/UI_CL.jpg" />
                            <Download
                              downloadURL={getDocUrl('assets/docs/UI-cl.docx')}
                              history={history} />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className={classnames(styles.resume_sample_header, 'text-center')}>
                      <div className={classnames(styles.container_xl)}>
                        <h2 className={classnames(styles.section_heading)}>
                          UI/UX Designer (Text Format)
                        </h2>
                        <p className={classnames('mt-2', styles.section_paragraph)}>
                          Make sure you choose the right resume
                          format to suit your unique experience and the job you are applying.</p>
                      </div>
                    </div>
                    <div className={classnames(styles.resume_sample_wrapper)}>
                      <div className={classnames(styles.container_xl)}>
                        <Row>
                          <Col lg={8} xl={8} xs={12}>
                            <div className={classnames(styles.resume_sample_main)}>
                              <div className={classnames(styles.tab_content)}>
                                Text View
                              </div>
                            </div>
                            <div className={classnames(styles.resume_content_card,
                              styles.dz_card, styles.shadow_1, 'px-3', 'px-lg-5', 'py-5', 'tab-pane active')}>
                              <Row>
                                <Col className={classnames('align-items-start')} md={12}>
                                  <h3 className={classnames(styles.resume_content_heading)}>
                                    UI/UX Designer
                                  </h3>
                                  <div className={classnames(styles.resume_content_subtitle)} />
                                </Col>
                                <Col md={6}>
                                  <p className={classnames('mb-0', 'text-right')} />
                                </Col>
                              </Row>
                              <div className={classnames(styles.resume_content_title)}> Profile</div>
                              <p className={classnames('mt-3', 'mb-2')}>
                                Perceptive and results-driven Web Designer dedicated to
                                building and optimizing user-focused websites for customers
                                with various business objectives. Advanced and creative when
                                crafting compelling websites, apps and platforms to
                                thrust competitive advantage and revenue growth.
                                Technically proficient and analytical problem solver with a calm and focused attitude.
                              </p>
                              <div className={classnames(styles.resume_content_title)}>

                                Skills
                              </div>
                              <ul className={classnames(styles.interview_points, 'mt-4')}>
                                <li>Iterative Development
                                </li>
                                <li>UX Design
                                </li>
                                <li> UX Performance Analysis
                                </li>
                                <li> User Data Collection
                                </li>
                                <li>UX Diagnostic Management
                                </li>
                                <li>Web development projects
                                </li>
                                <li> Technical Analysis
                                </li>
                                <li>Programming
                                </li>
                                <li>Teambuilding
                                </li>
                              </ul>
                              <div className={classnames(styles.resume_content_title)}>
                                Work History</div>
                              <ul className={classnames(styles.interview_points, 'list-unstyled',
                                styles.content_title, 'mt-4')}>
                                <li className={classnames('mb-0')}>Senior UX Designer</li>
                                <li> XYZ Solutions, Belas US, Dec 2019</li>
                              </ul>
                              <ul className={classnames(styles.interview_points)}>
                                <li>Analyzed user feedback to influence future
                                  UX updates, ameliorating user concerns and pain
                                  points during subsequent patch cycles.
                                </li>
                                <li>Performed manual testing on UX design elements,
                                  and complete UX designs, authoring feedback reports for future updates.
                                </li>
                                <li>Maximized system performance, enhancing the
                                  end-user experience by applying iterative back end development updates.
                                </li>
                                <li>Managed cohesive design quality across 5+ projects,
                                  adhering to prescribed themes and functional choices.
                                </li>
                                <li>Observed emerging UX design trends and practices for useful
                                  techniques and cutting-edge developments suitable for integration into
                                  future projects.
                                </li>
                                <li>Originated production of page content such
                                  as visuals and text copy to meet project specifications.
                                </li>
                              </ul>
                              <ul className={classnames(styles.interview_points, styles.content_title,
                                'list-unstyled', 'mt-4')}>
                                <li className={classnames('mb-0')}>Junior UX Designer
                                </li>
                                <li>ABC Solutions Belas, US, Jan 2014 - Nov 2019
                                </li>
                              </ul>
                              <ul className={classnames(styles.interview_points)}>
                                <li>Working coding practices based on commonly
                                  accepted standards to establish site design and user interface.
                                </li>
                                <li>Delivered user support to clients
                                  to train, educate and answer questions.
                                </li>
                                <li>Sustained strict budgetary and scheduling guidelines
                                  to satisfy customers with high-quality, targeted designs.
                                </li>
                                <li>Created influential Content Management
                                  Systems to serve as an interface for the client.
                                </li>
                                <li>Designed and engineered RESTful web
                                  services to manipulate dynamic datasets.
                                </li>
                                <li>Reviewed code to validate structures,
                                  assess security and verify browser, device
                                  and operating system compatibility.
                                </li>
                                <li>Actively listen to customers&#8242; requests,
                                  confirming full understanding before addressing concerns.
                                </li>
                              </ul>

                              <div className={classnames(styles.resume_content_title, 'mt-3')}>
                                Education Summary</div>
                              <ul className={classnames(styles.interview_points, 'list-unstyled', 'mt-3')}>
                                <li>B. Tech in Computer Science
                                </li>

                              </ul>
                              <div className={classnames(styles.resume_content_title)}>
                                Additional Skills
                              </div>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>HTML5, CSS3, Responsive Design Cert., JavaScript
                                </li>
                                <li>Adobe Certified Expert (ACE)
                                </li>
                                <li>Certified Springboard UI / UX</li>
                                <li>Certified Ironhack UX/UI Design Bootcamp</li>
                              </ul>
                              <div className={classnames(styles.resume_content_title, 'mt-3')}>
                                Languages</div>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>English
                                </li>
                                <li>Spanish</li>
                                <li>Hebrew</li>

                              </ul>
                            </div>
                            <div className={classnames(styles.resume_sample_header, 'pb-5')}>
                              <h2 className={classnames(styles.section_heading, styles.section_h2, 'mb-2')}>
                                How to Write UI/UX Designer Resume
                              </h2>
                              <p className={classnames('mb-2')}>To compose a perfect resume,
                                you must find a way to present every bit of the sections
                                to show your prowess and intrigue the recruiters.
                                One way to be on the safe side is to view the resume
                                from a client&#8242;s perspective. In doing this, you could
                                spot the tone and style required.</p>
                              <p>In this article,
                                you will get detailed information on how
                                to write a resume for UI/UX Designer.</p>
                            </div>
                            <div
                              aria-labelledby="resumeContentTab"
                              className={classnames(styles.resume_content_card,
                                styles.dz_card, styles.shadow_1, 'px-0', 'px-lg-5', 'py-5', 'tab-pane active')}
                              id="resumeContent" role="tabpanel">
                              <h3 className={classnames(styles.resume_content_heading, 'mb-2')}>
                                How to Write UI/UX Designer Resume Summary</h3>
                              <p className={classnames('mb-2')}>Your resume summary is the main
                                aspect that will introduce you to recruiters.
                                It rapidly acquaints you with recruiters, offers your background
                                information, and incorporates achievements to show your HR aptitudes.</p>
                              <p className={classnames('mb-2')}>Highlight your top specialized skills,
                                highest qualifications, and individual qualities. Focus on your title,
                                qualifications, or experience issues that are related to UI/UX Designer.
                                Then include a specific value that you can bring to the company.
                                Make sure that the length of your summary is between 3 and 6 lines.</p>
                              <p className={classnames('mb-2')}>To craft a good summary,
                                you must understand the job you want. Avoid creating generic
                                summaries because they can work against you. Bear in mind that
                                the best summary should be in view of the job
                                you wish to get at a particular time.</p>
                              <h5 className={classnames('font-weight-bold')}>
                                Here is how to write a UI/UX Designer resume summary</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Familiarize yourself with the job descriptions and
                                  compose a resume that is tailored to the employer&#8242;s needs.</li>
                                <li>List your work history using relevant details,
                                  such as the number of years worked and the previous position.</li>
                                <li>Highlight only skills relating to the job you are applying for.</li>
                                <li>Utilize metrics, such as percentage,
                                  to highlight and quantify your accomplishments.</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold', 'mt-4')}>
                                Examples of UI/UX Resume Summaries</h5>
                              <p>The following are good and bad examples of UX/UI resume summaries.</p>
                              <h5 className={classnames('ml-3', 'font-weight-bold', 'mt-3')}>Good Example</h5>
                              <blockquote><p className={classnames(styles.resume_rank, 'ml-0')}>
                                Highly qualified UI Designer, looking forward to using proven skills
                                in UI prototyping to enhance user experience at Deroride Dev.
                                At Satellite Cloud Ltd. deployed innovative features with 20% higher
                                performance than the previous UI Designer.
                                Completed jobs and surpassed targets with 97% client scores.
                              </p></blockquote>
                              <h5 className={classnames('ml-3', 'font-weight-bold')}>Bad Example</h5>
                              <blockquote><p className={classnames(styles.resume_rank, 'ml-0')}>
                                Competent UI Designer with 5+ years of experience.
                                Responsible for UX design at Savona Design.
                                Responsible for collecting project needs and delivering all jobs on time.
                              </p></blockquote>
                              <div className={classnames('card', 'p-3', 'px-lg-4', 'mt-5',
                                styles.ProTip, styles.interview_blockquote)}>
                                <h5 className={classnames('ml-5', 'font-weight-bold')}>Pro Tips
                                </h5>  <ul className={classnames(styles.interview_points, 'ml-4', 'mt-3')}>
                                  <li>Utilize metrics to quantify your accomplishments on the summary.
                                    In case you lack experience, highlight internship work,
                                    or focus on any other achievements.</li>
                                  <li>Highlight your most impressive achievement in your UI/UX
                                    Designer resume summary. It&#8242;s one of the things that
                                    attract the recruiters to your resume.</li>
                                </ul>
                              </div>
                              <h3 className={classnames(styles.resume_content_heading, 'mt-5', 'mb-4')}>
                                How to Highlight Skills on a UI/UX Designer Resume
                              </h3>
                              <img
                                alt="Nurseimage" className={classnames('mt-2', 'mb-3')}
                                src="/assets/images/UI_Skill.jpg" />
                              <p className={classnames('mb-2')}>
                                Hiring managers assess your skillset to ascertain whether
                                you are suitable for the job. Recruiters are aware of the
                                relevant skills needed for the job, and they always scan
                                resumes to determine whether they are relevant to the skills required.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>Here are the best practices for
                                Here are the best practices for highlighting skills on your resume</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Understand what skills the recruiter
                                  needs as per the job advert</li>
                                <li>Don&#8242;t just highlight them, but show how you
                                  used them to accomplish a particular goal.</li>
                                <li>Ensure you use a combination of soft
                                  and hard skills on your resume.</li>
                                <li className={classnames('mb-0')}>
                                  Analyze the job descriptions and highlight
                                  skills that are relevant to the employer&#8242;s needs.</li>
                              </ul>
                              <h5 className={classnames('ml-3', 'font-weight-bold', 'mt-3')}>Good Example</h5>
                              <blockquote><p className={classnames(styles.resume_rank, 'ml-0')}>
                                <ul className={classnames(styles.interview_points, 'mt-3')}>
                                  <li>Managed UI design for 95% of customer jobs.</li>
                                  <li>Gathered project requirements
                                    with 97% customer satisfaction.</li>
                                  <li>Reduced lead times for customer jobs by 40%.</li>
                                  <li>Managed 10+ projects simultaneously.</li>
                                </ul>
                              </p>
                              </blockquote>
                              <h5 className={classnames('ml-3', 'font-weight-bold')}>Bad Example</h5>
                              <blockquote><p className={classnames(styles.resume_rank, 'ml-0')}>
                                UX/UI Designer Skills: Efficient worker,
                                collecting project requirements, Adobe Expertise.
                              </p></blockquote>
                              <h5 className={classnames('font-weight-bold', styles.align)}>
                                UI/UX Designer Hard and Soft Skills</h5>
                              <p className={classnames('mb-2')}>
                                Hard skills refer to qualifications and technical capabilities
                                required to achieve a particular job. However, soft skills encompass
                                your personal qualities or working style.
                                Unlike hard skills, soft skills are difficult to measure.</p>
                              <p className={classnames('mb-2')}>
                                You should highlight your skills in bullet points and then put your
                                level of knowledge into a bracket in front of each one. Also,
                                make sure you grade your knowledge from example, beginner,
                                intermediate, or expert. Check the below formats:</p>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>
                                Example of hard skills</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>UI Prototyping (Expert)</li>
                                <li>JavaScript/jQuery (Expert)</li>
                                <li>UX Writing (Intermediate)</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>
                                Example of soft skills</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Collaboration (Expert)</li>
                                <li>Problem Solving (Expert)</li>
                                <li>Interpersonal skills (Expert)</li>
                              </ul>

                              <div className={classnames('card', 'p-3', 'px-lg-4', 'mt-5',
                                styles.ProTip, styles.interview_blockquote)}>
                                <h5 className={classnames('ml-5', 'font-weight-bold')}>Pro Tips
                                </h5>  <ul className={classnames(styles.interview_points, 'ml-4', 'mt-3')}>
                                  <li>Before you highlight skills on your resume,
                                    analyze the job descriptions to determine the
                                    kind of UI/UX skills an employer wants.</li>
                                  <li>For better results, use a combination
                                    of hard and soft skills.</li>
                                </ul>
                              </div>
                              <h3 className={classnames(styles.resume_content_heading, 'mt-5', 'mb-2')}>
                                How to Write Work Experience in UI/UX Resume</h3>
                              <p className={classnames('mb-2')}>
                                Presenting your experiences on a resume is one of
                                the most important things you should pay attention to.
                                With good experiences, you would stand apart from your competitors.</p>
                              <p className={classnames('mb-2')}>
                                Before you highlight your employment history, ensure
                                it is relevant and detailed. Review your recruiter&#8242;s job description
                                to familiarize yourself with their requirements.
                                This helps you understand the duties, tasks,
                                and other key details for the job post.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>
                                Here is how to highlight work experience on a UI/UX Designer resume</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Use bullet points to highlight your job
                                  duties and focus on at least five critical activities.</li>
                                <li>Highlight experience from the last ten years
                                  and make sure that your resume doesn&#8242;t go beyond two pages.</li>
                                <li>Utilize action verbs to highlight your responsibilities.
                                  Focus on skills that are relevant to your
                                  field and make use of the best keywords.
                                  Example: &#8243;Surpassed customer growth target by 20% in 2020.&#8243;</li>
                                <li>Use metrics, such as percentages, to quantify your accomplishments.
                                  For example: &#8243;Served an average of 100 customers per day.&#8243;</li>
                                <li>Present your experience using reverse
                                  chronological order: from the most recent to oldest.</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold')}>
                                Example of UX Experience on a Resume</h5>
                              <h5 className={classnames('ml-3', 'font-weight-bold', 'mt-3')}>Good Example</h5>
                              <blockquote>
                                <p className={classnames(styles.resume_rank, 'ml-0')}>
                                  <ul className={classnames(styles.interview_points, 'list-unstyled', 'mt-3')}>
                                    <li>Apex Group</li>
                                    <li>UX Designer</li>
                                    <li>2010–2019</li></ul></p>
                                <p className={classnames(styles.resume_rank, 'ml-0')}>
                                  <ul className={classnames(styles.interview_points, 'mt-3')}>
                                    <li>Top UX Designer for a high-end design firm.</li>
                                    <li>Got 100% positive feedback from clients.</li>
                                    <li>Managed all design elements, templates, and brand consistency.</li>
                                    <li>Finished projects an average of 20% before deadlines.</li>
                                    <li>Enhanced UX scores by 30% above average for the company.</li>
                                  </ul>
                                </p>
                              </blockquote>
                              <h5 className={classnames('ml-3', 'font-weight-bold')}>Bad Example</h5>
                              <blockquote>
                                <p className={classnames(styles.resume_rank, 'ml-0')}>
                                  <ul className={classnames(styles.interview_points, 'list-unstyled', 'mt-3')}>
                                    <li>Apex Group</li>
                                    <li>UX Designer</li>
                                    <li>2010–2019</li></ul></p>
                                <p className={classnames(styles.resume_rank, 'ml-0')}>
                                  <ul className={classnames(styles.interview_points, 'mt-3')}>
                                    <li>Top UX Designer for high-end design firm.</li>
                                    <li>Managed all design elements and brand consistency.</li>
                                    <li>Able to finish projects within deadlines.</li>
                                    <li>Worked with cross-functional teams to
                                      turn client requirements into finished apps.</li>
                                  </ul>
                                </p></blockquote>

                              <div className={classnames('card', 'p-3', 'px-lg-4', 'mt-5',
                                styles.ProTip, styles.interview_blockquote)}>
                                <h5 className={classnames('ml-5', 'font-weight-bold')}>Pro Tips
                                </h5>  <ul className={classnames(styles.interview_points, 'ml-4', 'mt-3')}>
                                  <li>It is vital to arrange your works chronologically.
                                    This is implies that you go from the most recent to the oldest.
                                    This would show your employer the level at which you pay
                                    attention to details.</li>
                                  <li>You should mind about your formatting.
                                    Consider the font size and the type of font you will use.
                                    You may use any font that you love, but you must consider how
                                    you will foreground some parts that could impress your employers.</li>
                                </ul>
                              </div>
                              <h3 className={classnames(styles.resume_content_heading, 'mt-5')}>
                                How to write UI/UX Achievements, Hobbies,
                                Training, and certifications on Resume</h3>
                              <p className={classnames('mb-1')}>Knowing how to present your achievements,
                                hobbies, and certifications can set you apart from other competitors when
                                it comes to job search. Hobbies are things you enjoy doing.
                                Typically, many customers are conversant with some kinds of
                                things common among tech guys too. Your hobbies could be
                                playing games and watching movies.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>
                                How to Highlight Your Achievements on a Resume</h5>
                              <p className={classnames('mb-1')}>
                                Highlighting key achievements on your
                                resume may help you get a good job. Your accomplishments
                                confirm to the recruiters that you were a good performer
                                in the previous assignment, which may make you the best candidate for the job.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>
                                Example on how to list achievements on a UX Designer Resume</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Designed five ecommerce webapps to sell calendars.</li>
                                <li>
                                  Designed wireframe for local flower shop.</li>
                                <li>Improved UI scores 30% for animal shelter website.</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold')}>
                                How to Highlight Hobbies on a Resume.</h5>
                              <p className={classnames('mb-1', 'mt-0')}>Hobbies are activities done for pleasure.
                                Hobbies are done for free, and they are not meant for payment.
                                Make sure to highlight hobbies that are relevant to the job you are applying for.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>Examples of hobbies</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Playing games</li>
                                <li>Watching anime</li>
                                <li>Reading fan fictions</li>

                              </ul>
                              <div className={classnames('card', 'p-3', 'px-lg-4', 'mt-5',
                                styles.ProTip, styles.interview_blockquote)}>
                                <h5 className={classnames('ml-5', 'font-weight-bold')}>Pro Tips
                                </h5>  <ul className={classnames(styles.interview_points, 'ml-4', 'mt-3')}>
                                  <li>Highlight hobbies that are relevant to the position you are applying for</li>
                                  <li>Restrict yourself to a maximum of three hobbies</li>
                                </ul>
                              </div>
                              <h5 className={classnames('font-weight-bold', 'mt-5', 'mb-2')}>
                                How to Highlight Certification on a Resume</h5>
                              <p className={classnames('mb-2')}>
                                Listing certifications on your resume can boost your chances of recruitment.
                                If you have certifications, it is advisable to learn how to add them.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>
                                Here is how to list certifications on your resume</h5>
                              <h5 className={classnames('font-weight-bold', 'mt-4')}>
                                Title of the certification</h5>
                              <p className={classnames('mb-2')}>
                                Make sure to indicate the full title of
                                certification under the title &#8243;Certifications.&#8243;
                                It is essential to type the name of the certification clearly,
                                and spell out any abbreviations.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-0', 'mb-2')}>
                                Highlight the certifying authority
                              </h5>
                              <p className={classnames('mb-2')}>
                                It is crucial to list the certifying authority to
                                allow for quick verification of your documents.
                                Always include the name of the organization that awarded it to you.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Date of certification</h5>
                              <p className={classnames('mb-2')}>
                                The date earned informs your recruiter of how long
                                it has taken since you earned the certification.
                                Also, it provides the hiring manager with the information
                                they need in case they want to verify the certification.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Provide details on the associated skills</h5>
                              <p className={classnames('mb-2')}>
                                It is essential to list skills that are relevant to the certification.
                                This is meant to explain the certification&#8242;s relevance
                                and answer any queries your recruiter may have.</p>
                              <div className={classnames('card', 'p-3', 'px-lg-4', 'mt-5',
                                styles.ProTip, styles.interview_blockquote)}>
                                <h5 className={classnames('ml-5', 'font-weight-bold')}>Pro Tips
                                </h5>  <ul className={classnames(styles.interview_points, 'ml-4', 'mt-3')}>
                                  <li>List the certifications in reverse-chronological order.</li>
                                  <li>When highlighting certifications,
                                    list the following: Name of the certification, certifying authority,
                                    date of certification, and location.</li>
                                  <li>Highlight certifications that are
                                    relevant to the job you are applying for.</li>
                                </ul>

                              </div>
                              <h5 className={classnames('font-weight-bold', 'mt-5')}>
                                Certification example</h5>
                              <ul className={classnames(styles.interview_points, 'list-unstyled', 'mt-3')}>
                                <li>Certificate of Adobe Photoshop completion</li>
                                <li>China Institute, February 2020</li>
                                <li>Expires: January 2030</li>
                                <li>Relevant skills: Photoshop, Adobe XD and Figma</li>

                              </ul>
                              <h3 className={classnames(styles.resume_content_heading, 'mt-5')}>
                                Top Professional Tips for Writing UI/UX Designer Resume</h3>
                              <h5 className={classnames('font-weight-bold', 'mt-3', 'mb-2')}>
                                Here are the tips for writing the UI/UX Designer resume</h5>

                              <h5 className={classnames('font-weight-bold', 'mt-4', 'mb-2')}>
                                Add your contact details</h5>
                              <p className={classnames('mb-2')}>
                                Ensure that your personal details, such as your name
                                and contact information, appears at the top of your resume.
                                You may also include your current title.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Compose a summary</h5>
                              <p className={classnames('mb-2')}>
                                Write a brief paragraph describing what makes you the
                                perfect candidate for the UI/UX Designer.
                                List your qualifications, and use metrics to measure your achievements.
                                Highlight skills that match the job description.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Highlight achievements</h5>
                              <p className={classnames('mb-2')}>
                                Present any awards or achievements you
                                have received in the UI department.
                                For instance, if you Improved UI scores 20%,
                                let the hiring managers know about it.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Highlight your experience</h5>
                              <p className={classnames('mb-2')}>
                                Identify your most recent roles and list the aspects
                                of each job that relate to UI/UX Designer job.
                                Include jobs that support your teamwork, designing, and negotiation skills.</p>

                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                List your qualifications</h5>
                              <p className={classnames('mb-2')}>
                                Your qualifications matter a lot when it comes to applying
                                for the UI/UX Designer position. Highlight your
                                qualifications, starting from the highest to the lowest.</p>

                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Highlight important certifications</h5>
                              <p className={classnames('mb-2')}>
                                If you want to enhance your chances of recruitment,
                                don&#8242;t hesitate to highlight your certifications on a resume.
                                It is advisable to add your certifications in
                                reverse-chronological order, starting from the current ones.
                                Moreover, highlight the certifying authority and the date when it was earned.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                List your skills</h5>
                              <p className={classnames('mb-2')}>
                                Use a combination of hard and soft skills on your resume.
                                Bear in mind that recruiters look for skills, such as
                                critical thinking, UI Prototyping, JavaScript, negotiation skills,
                                etc. Anything you highlight in the skills section should be backed
                                supported by experience and qualifications.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Highlight your technical experience</h5>
                              <p className={classnames('mb-2')}>
                                As a UI/UX Designer, you should have technical knowledge.
                                Almost all organizations need someone who is tech-savvy to work for them.
                                Recruiters will be captivated if you have any experience
                                in computer or management system.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Add keywords</h5>
                              <p className={classnames('mb-2')}>
                                Keywords can make your resume get selected in an electronic search.
                                It is crucial to optimize your resume for the keywords search.
                                This will make your resume to be easily found whenever it
                                is subjected to ATS (Applicant Tracking Systems).</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                List hobbies and interests</h5>
                              <p className={classnames('mb-2')}>
                                Although many people argue that hobbies and interests
                                aren&#8242;t essential to your job, listing them can set you apart,
                                especially where there is stiff competition. Adding hobbies,
                                such as socializing, shows your ability to hold discussions
                                with clients and make them feel appreciated.</p>

                            </div>
                          </Col>
                          <Col lg={4} xl={4} xs={12}>
                            <PromoCard loginWithRedirect={loginWithRedirect} />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      )
    }
}
