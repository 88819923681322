import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap'
import classnames from 'classnames'
// import { get } from 'lodash'
import Footer from './Footer'
import SocialLogin from './SocialLogin'
import { Icon, IconType } from '../common'
import styles from './style.scss'

class Login extends Component {
  static propTypes = {
    launchDashboard: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
    openURL: PropTypes.func.isRequired,
    redirectURL: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
  }

  constructor(props){
    super(props)
    this.state = {
      // userName: '',
      // password: '',
    //   modelForgot: false,
    //   modelOTP: false,
    //   modelReset: false,
    }
  }

  updateField = (key, value) => {
    this.setState({
      [key]: value,
    })
  }

  render() {
    const { MAIL } = IconType.COMMUNICATION
    const { openURL, launchDashboard, redirectURL, register } = this.props
    return (
      <Fragment>
        <Container fluid>
          <Row>
            <Col lg={6} md={6} sm={12} xl={6} xs={12}>
              <div className={classnames(styles.welcome_bg)} />
            </Col>
            <Col lg={6} md={6} sm={12} xl={6} xs={12}>
              <div className={classnames(styles.welcome_next_box)}>
                <div className={classnames(styles.welcome)}>
                  <div className={classnames(styles.title)}>
                    <div
                      className={classnames(styles.show_cursor)}
                      onClick={() => openURL('/')}><img
                        alt={'logo'} className={classnames(styles.logo_img)}
                        src={'assets/images/logo.svg'} /></div>
                    Welcome !
                  </div>
                  <div className={classnames(styles.welcome_intro)}>
                    Please sign in to Your Resumaker Account.
                  </div>
                  <div className={classnames(styles.login_options)}>
                    <SocialLogin launchDashboard={launchDashboard} register={register} />
                    <div className={classnames(styles.or_text)}>
                      or
                    </div>
                    <div
                      className={classnames(styles.login_div, styles.type_2, styles.button_effect)}
                      onClick={() => redirectURL('login-with-email')}>
                      <div className={classnames(styles.title, styles.login_icon_img)}>
                        <Icon
                          color={'#6c757dd1'}
                          height={'24'}
                          names={[MAIL]}
                          viewHeight={'24'}
                          viewWidth={'24'} width={'24'} />
                      </div>
                      <div className={classnames(styles.login_content)}>
                        <div
                          className={classnames(styles.create_account)}>
                          Login with Email
                        </div>
                      </div>
                    </div>
                    <div className={classnames(styles.dont_have_account)}>
                      Don&rsquo;t have an Account ? <span>
                        <span
                          className={classnames(styles.create_account, styles.show_cursor)}
                          onClick={() => openURL('signup')}>
                          Create Account </span> </span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Footer openURL={openURL} />
          </Row>
        </Container>
      </Fragment>
    )
  }
}
export default withRouter(Login)
