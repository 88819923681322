/* eslint-disable no-undef */
import React, { Component, Fragment } from 'react'
import cs from 'classnames'
import { Container, Row, Col, Carousel } from 'react-bootstrap'
import { map, get } from 'lodash'
import PropTypes from 'prop-types'
import { TEMPLATE } from '../../../constants'
import st from './style.scss'

export default class Templates extends Component{
static propTypes = {
  loginWithRedirect: PropTypes.func.isRequired,
}
render(){
  const { loginWithRedirect } = this.props
  return (
    <Fragment>
      <div className={cs(st.desktop)}>
        <section className={cs(st.templates_slider)}>
          <Container fluid>
            <h2> Resume templates </h2>
            <Row>
              {map(TEMPLATE, (value, key) => (
                <Col className={cs(st.mt_40)} lg={3} md={6} sm={6} xl={3} xs={12}>
                  <div className={cs(st.img_resume_select_option)}>
                    <img
                      alt="template"
                      className={cs(st.slider_img)}
                      src={get(value, 'image')} />
                    <div
                      className={cs(st.select_template_button, st.button_effect)}
                      onClick={() => loginWithRedirect({
                        redirectTo: '/dashboard/create', templateId: get(value, 'templateId') })}> Select Template
                    </div>
                  </div>
                  <div className={cs(st.template_box_content)}>
                    <h3>{get(value, 'templateName')}</h3>
                    <p> {get(value, 'description')}</p>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </section>
      </div>
      <section className={cs(st.mobile, st.templates_slider)}>
        <Container className={cs(st.cc_fluid_pp)} fluid>
          <h2> Resume templates</h2>
          <Row>
            <Col className={cs(st.mt_40)} xs={12}>
              <Carousel variant="dark">
                {map(TEMPLATE, (value, key) => (
                  <Carousel.Item>
                    <div className={cs(st.img_resume_select_option)}>
                      <img
                        alt="template"
                        className={cs(st.slider_img)}
                        src={get(value, 'image')} />
                      <Carousel.Caption>
                        <div
                          className={cs(st.select_template_button)}
                          onClick={() => loginWithRedirect({
                            redirectTo: '/dashboard/create', templateId: get(value, 'templateId') })}> Select Template
                        </div>
                      </Carousel.Caption>
                    </div>
                    <div className={cs(st.template_box_content)}>
                      <h3>{get(value, 'templateName')}</h3>
                      <p> {get(value, 'description')}</p>
                    </div>
                  </Carousel.Item>
                ))}
                <Carousel.Item>
                  <div className={cs(st.img_resume_select_option)}>
                    <img
                      alt="template"
                      className="d-block w-100"
                      src={get(TEMPLATE.IRIS, 'image')} />
                    <Carousel.Caption>
                      <div
                        className={cs(st.select_template_button)}
                        onClick={() => loginWithRedirect({
                          redirectTo: '/dashboard/create', templateId: get(value, 'templateId') })}>
                        Select Template
                      </div>
                    </Carousel.Caption>
                  </div>
                  <div className={cs(st.template_box_content)}>
                    <h3>{get(TEMPLATE.IRIS, 'templateName')}</h3>
                    <p> {get(TEMPLATE.IRIS, 'description')}</p>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className={cs(st.img_resume_select_option)}>
                    <img
                      alt="template"
                      className="d-block w-100"
                      src={get(TEMPLATE.MARS, 'image')} />
                    <Carousel.Caption>
                      <div
                        className={cs(st.select_template_button)}
                        onClick={() => loginWithRedirect({
                          redirectTo: '/dashboard/create', templateId: get(value, 'templateId') })}>
                        Select Template
                      </div>
                    </Carousel.Caption>
                  </div>
                  <div className={cs(st.template_box_content)}>
                    <h3>{get(TEMPLATE.MARS, 'templateName')}</h3>
                    <p> {get(TEMPLATE.MARS, 'description')}</p>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className={cs(st.img_resume_select_option)}>
                    <img
                      alt="template"
                      className="d-block w-100"
                      src={get(TEMPLATE.CETUS, 'image')} />
                    <Carousel.Caption>
                      <div
                        className={cs(st.select_template_button)}
                        onClick={() => loginWithRedirect({
                          redirectTo: '/dashboard/create', templateId: get(value, 'templateId') })}>
                        Select Template
                      </div>
                    </Carousel.Caption>
                  </div>
                  <div className={cs(st.template_box_content)}>
                    <h3>{get(TEMPLATE.CETUS, 'templateName')}</h3>
                    <p> {get(TEMPLATE.CETUS, 'description')}</p>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className={cs(st.img_resume_select_option)}>
                    <img
                      alt="template"
                      className="d-block w-100"
                      src={get(TEMPLATE.CLASSICTEMP1, 'image')} />
                    <Carousel.Caption>
                      <div
                        className={cs(st.select_template_button)}
                        onClick={() => loginWithRedirect({
                          redirectTo: '/dashboard/create', templateId: get(value, 'templateId') })}>
                        Select Template
                      </div>
                    </Carousel.Caption>
                  </div>
                  <div className={cs(st.template_box_content)}>
                    <h3>{get(TEMPLATE.CLASSICTEMP1, 'templateName')}</h3>
                    <p> {get(TEMPLATE.CLASSICTEMP1, 'description')}</p>
                  </div>
                </Carousel.Item>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}
}
