import React, { Component } from 'react'
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap'
import cs from 'classnames'
import TickIcon from './TickIcon'
import st from './style.scss'
import { Icon, IconType } from '../../common'
import GetStartedButton from '../Home/GetStartedButton'

export default class Expert extends Component {
  render() {
    const { CHEVRONDOWN } = IconType.DIRECTION
    // const { TICK } = IconType.BUILDER
    return (
      <div>
        <Container className={cs(st.options_items_align)}>
          <Tab.Container defaultActiveKey="first" id="left-tabs-example">
            <Row>
              <Col lg={5} md={5} sm={12} xl={5} xs={12}>
                <div className={cs(st.options_items)}>
                  <Nav className={cs(st.options_items)}>
                    <Nav.Item>
                      <Nav.Link
                        className={cs(st.expert_types)}
                        eventKey="first"><TickIcon />
                        <img alt="" src="/assets/images/entry_level.png" /> Fresher</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        className={cs(st.expert_types)}
                        eventKey="second"><TickIcon />
                        <img alt="" src="/assets/images/professional_level.png" /> Professional</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        className={cs(st.expert_types)}
                        eventKey="third"><TickIcon />
                        <img alt="" src="/assets/images/executive_level.png" /> Executive</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        className={cs(st.expert_types, st.career_change_align)}
                        eventKey="fourth"><TickIcon />
                        <img alt="" src="/assets/images/career_change.png" />Change</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </Col>
              <Col lg={7} md={7} sm={12} xl={7} xs={12}>
                <div className={cs(st.options_details)}>
                  <Tab.Content className={cs(st.options_details)}>
                    <Tab.Pane className={cs(st.tab_pane)} eventKey="first">
                      <div className={cs(st.expert_left_items)}>
                        <Row>
                          <div className={cs(st.hire_expert_titles)}>
                            <h6>Textual Resume1 </h6>
                            <h6>Infographic Resume1</h6>
                          </div>
                        </Row>
                        <div className={cs(st.price_list_items, st.hire_expert_items)}>
                          <Row>
                            <div className={cs(st.hire_list_flex)}>
                              <h3><span className={cs(st.rupee_icon)}>&#8377;</span>4,999</h3>
                              <ul>
                                <li>
                                  <div>
                                    <TickIcon />
                                    <p>Textual Resume</p>
                                  </div>
                                </li>
                                <li>
                                  <div>
                                    <TickIcon />
                                    <p>Without SWOT Report</p>
                                  </div>
                                </li>
                              </ul>
                              <div className={cs(st.price_center)}>
                                <GetStartedButton
                                  label="Get this plan" size="md" />
                              </div>
                            </div>
                          </Row>
                        </div>
                        <div className={cs(st.price_list_items)}>
                          <Row>
                            <div className={cs(st.hire_list_flex)}>
                              <h3><span className={cs(st.rupee_icon)}>&#8377;</span>4,999</h3>
                              <ul>
                                <li>
                                  <div>
                                    <TickIcon />
                                    <p>Textual Resume</p>
                                  </div>
                                </li>
                                <li>
                                  <div>
                                    <TickIcon />
                                    <p>Without SWOT Report</p>
                                  </div>
                                </li>
                              </ul>
                              <div className={cs(st.price_center)}>
                                <GetStartedButton
                                  label="Get this plan" size="md" />
                              </div>
                            </div>
                          </Row>
                        </div>
                        <Col className={cs(st.note_point)} lg={12} md={12} sm={12} xl={12} xs={12}>
                          *All prices are in INR,prices includes GST 18%.
                        </Col>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane className={cs(st.options_details)} eventKey="second">
                      <div className={cs(st.expert_left_items)}>
                        <Row>
                          <div className={cs(st.hire_expert_titles)}>
                            <h6>Textual Resume1 </h6>
                            <h6>Infographic Resume1</h6>
                          </div>
                        </Row>
                        <div className={cs(st.price_list_items, st.hire_expert_items)}>
                          <Row>
                            <div className={cs(st.hire_list_flex)}>
                              <h3><span className={cs(st.rupee_icon)}>&#8377;</span>4,999</h3>
                              <ul>
                                <li>
                                  <div>
                                    <TickIcon />
                                    <p>Textual Resume</p>
                                  </div>
                                </li>
                                <li>
                                  <div>
                                    <TickIcon />
                                    <p>Without SWOT Report</p>
                                  </div>
                                </li>
                              </ul>
                              <div className={cs(st.price_center)}>
                                <GetStartedButton
                                  label="Get this plan" size="md" />
                              </div>
                            </div>
                          </Row>
                        </div>
                        <div className={cs(st.price_list_items)}>
                          <Row>
                            <div className={cs(st.hire_list_flex)}>
                              <h3><span className={cs(st.rupee_icon)}>&#8377;</span>4,999</h3>
                              <ul>
                                <li>
                                  <div>
                                    <TickIcon />
                                    <p>Textual Resume</p>
                                  </div>
                                </li>
                                <li>
                                  <div>
                                    <TickIcon />
                                    <p>Without SWOT Report</p>
                                  </div>
                                </li>
                              </ul>
                              <div className={cs(st.price_center)}>
                                <GetStartedButton
                                  label="Get this plan" size="md" />
                              </div>
                            </div>
                          </Row>
                        </div>
                        <Col className={cs(st.note_point)} lg={12} md={12} sm={12} xl={12} xs={12}>
                          *All prices are in INR,prices includes GST 18%.
                        </Col>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <div className={cs(st.expert_left_items)}>
                        <Row>
                          <div className={cs(st.hire_expert_titles)}>
                            <h6>Textual Resume1 </h6>
                            <h6>Infographic Resume1</h6>
                          </div>
                        </Row>
                        <div className={cs(st.price_list_items, st.hire_expert_items)}>
                          <Row>
                            <div className={cs(st.hire_list_flex)}>
                              <h3><span className={cs(st.rupee_icon)}>&#8377;</span>4,999</h3>
                              <ul>
                                <li>
                                  <div>
                                    <TickIcon />
                                    <p>Textual Resume</p>
                                  </div>
                                </li>
                                <li>
                                  <div>
                                    <TickIcon />
                                    <p>Without SWOT Report</p>
                                  </div>
                                </li>
                              </ul>
                              <div className={cs(st.price_center)}>
                                <GetStartedButton
                                  label="Get this plan" size="md" />
                              </div>
                            </div>
                          </Row>
                        </div>
                        <div className={cs(st.price_list_items)}>
                          <Row>
                            <div className={cs(st.hire_list_flex)}>
                              <h3><span className={cs(st.rupee_icon)}>&#8377;</span>4,999</h3>
                              <ul>
                                <li>
                                  <div>
                                    <TickIcon />
                                    <p>Textual Resume</p>
                                  </div>
                                </li>
                                <li>
                                  <div>
                                    <TickIcon />
                                    <p>Without SWOT Report</p>
                                  </div>
                                </li>
                              </ul>
                              <div className={cs(st.price_center)}>
                                <GetStartedButton
                                  label="Get this plan" size="md" />
                              </div>
                            </div>
                          </Row>
                        </div>
                        <Col className={cs(st.note_point)} lg={12} md={12} sm={12} xl={12} xs={12}>
                          *All prices are in INR,prices includes GST 18%.
                        </Col>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="fourth">
                      <div className={cs(st.expert_left_items)}>
                        <Row>
                          <div className={cs(st.hire_expert_titles)}>
                            <h6>Textual Resume1 </h6>
                            <h6>Infographic Resume1</h6>
                          </div>
                        </Row>
                        <div className={cs(st.price_list_items, st.hire_expert_items)}>
                          <Row>
                            <div className={cs(st.hire_list_flex)}>
                              <h3><span className={cs(st.rupee_icon)}>&#8377;</span>4,999</h3>
                              <ul>
                                <li>
                                  <div>
                                    <TickIcon />
                                    <p>Textual Resume</p>
                                  </div>
                                </li>
                                <li>
                                  <div>
                                    <TickIcon />
                                    <p>Without SWOT Report</p>
                                  </div>
                                </li>
                              </ul>
                              <div className={cs(st.price_center)}>
                                <GetStartedButton
                                  label="Get this plan" size="md" />
                              </div>
                            </div>
                          </Row>
                        </div>
                        <div className={cs(st.price_list_items)}>
                          <Row>
                            <div className={cs(st.hire_list_flex)}>
                              <h3><span className={cs(st.rupee_icon)}>&#8377;</span>4,999</h3>
                              <ul>
                                <li>
                                  <div>
                                    <TickIcon />
                                    <p>Textual Resume</p>
                                  </div>
                                </li>
                                <li>
                                  <div>
                                    <TickIcon />
                                    <p>Without SWOT Report</p>
                                  </div>
                                </li>
                              </ul>
                              <div className={cs(st.price_center)}>
                                <GetStartedButton
                                  label="Get this plan" size="md" />
                              </div>
                            </div>
                          </Row>
                        </div>
                        <Col className={cs(st.note_point)} lg={12} md={12} sm={12} xl={12} xs={12}>
                          *All prices are in INR,prices includes GST 18%.
                        </Col>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Col>
            </Row>
          </Tab.Container>

          <div className={cs(st.section)}>
            <div className={cs(st.section_title, st.features)}>
              <h4> Complete features list</h4>
              <Icon
                color={'#212121'}
                height={'50'}
                names={[CHEVRONDOWN]}
                viewHeight={'30'}
                viewWidth={'30'} width={'50'} />
            </div>
            <div>
              <Row className={cs(st.billing_table)}>
                <Col className={cs(st.options_items_align)} lg={12} md={12} sm={12} xl={12} xs={12}>
                  <table className={cs('table')}>
                    <thead className={cs(st.table_head, st.table_body)}>
                      <tr>
                        <th> <h4>Feature list</h4>
                          <p>At vero eos accusamus et iusto adio dignissmos ducimus qui. </p></th>
                        <td><div className={cs(st.align_blocks)}>Textual Resume</div></td>
                        <td><div className={cs(st.align_blocks)}>Infographic Resume</div></td>
                      </tr>
                    </thead>
                    <tbody className={cs(st.table_body)}>
                      <tr>
                        <td>Registartion free for lifetime
                        </td>
                        <td><TickIcon /></td>
                        <td><TickIcon /></td>
                      </tr>
                      <tr>
                        <td>Access to popular Resume templates</td>
                        <td><TickIcon /></td>
                        <td><TickIcon /></td>
                      </tr>
                      <tr>
                        <td>Create unlimited Resume</td>
                        <td><TickIcon /></td>
                        <td><TickIcon /></td>
                      </tr>
                      <tr>
                        <td>Full access to all features</td>
                        <td><TickIcon /></td>
                        <td><TickIcon /></td>
                      </tr>
                      <tr>
                        <td>Unlimited PDF downloads</td>
                        <td><TickIcon /></td>
                        <td><TickIcon /></td>
                      </tr>
                      <tr>
                        <td>No Brand watermark</td>
                        <td><TickIcon /></td>
                        <td><TickIcon /></td>
                      </tr>
                      <tr>
                        <td>Cancel anytime</td>
                        <td><TickIcon /></td>
                        <td><TickIcon /></td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    )
  }
}
