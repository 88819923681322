import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { SEOContainer } from '../../containers'

export const SAMPLE_RESUME_ROUTE = '/free-resume-templates'
export const SAMPLE_RESUME_KEY_ROUTE = '/free-resume-templates/:key'
export const FREE_TEMPLATE_ROUTE = '/resume-templates'

export const SEORoutes = () => (
  <Switch>
    <Route
      exact path={[SAMPLE_RESUME_ROUTE, SAMPLE_RESUME_KEY_ROUTE]}
      render={props => <SEOContainer {...props} selectedKey={'sample'} />} />
    <Route exact path={FREE_TEMPLATE_ROUTE} render={props => <SEOContainer {...props} selectedKey={'templates'} />} />
  </Switch>
)
