import React, { Component } from 'react'
import cs from 'classnames'
import st from './style.scss'

export default class UserCompnay extends Component{
  render(){
    return (
      <div className={cs(st.grid_layout)}>
        <img alt="" src={'/assets/images/home_logo_1.png'} />
        <img alt="" src={'/assets/images/home_logo_2.png'} />
        <img alt="" src={'/assets/images/home_logo_3.png'} />
        <img alt="" src={'/assets/images/home_logo_4.png'} />
        <img alt="" src={'/assets/images/home_logo_5.png'} />
        <img alt="" src={'/assets/images/home_logo_6.png'} />
        <img alt="" src={'/assets/images/home_logo_7.png'} />
        <img alt="" src={'/assets/images/home_logo_8.png'} />
      </div>
    )
  }
}
