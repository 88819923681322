import React, { Component } from 'react'
import { render } from 'react-dom'
import cs from 'classnames'

import st from './style.scss'

let resolve
export default class PopUpDialog extends Component {
  static create() {
    const containerElement = document.createElement('div')
    document.body.appendChild(containerElement)
    return render(<PopUpDialog />, containerElement)
  }

  constructor() {
    super()
    this.state = {
      //   headerTitle: 'Are you sure?',
      isOpen: false,
    }
    this.handleCancel = this.handleCancel.bind(this)
    this.handleConfirm = this.handleConfirm.bind(this)
  }

  handleCancel() {
    this.setState({ isOpen: false })
    resolve(false)
  }

  handleConfirm() {
    this.setState({ isOpen: false })
    resolve(true)
  }

  show({ headerTitle, content, subcontent, yesActionText, noActionText } = {}) {
    this.setState({
      isOpen: true,
      //  headerTitle: headerTitle || 'Are you sure ?',
      content,
      //  subcontent,
      yesActionText: yesActionText || 'OK',
      noActionText: noActionText || 'Cancel',
    })
    return new Promise((result) => {
      resolve = result
    })
  }

  render() {
    const { isOpen, content, yesActionText, noActionText } = this.state
    // const { subcontent, headerTitle } = this.state
    if (isOpen){
      return (
        <div className={cs(st.gridRow)}>
          <div className={cs(st.modal_bg_popup)}>
            <div className={cs(st.want_to_delete)}>
              {/* <div> {content} </div>
              <div className={cs(st.sure_popup, st.sure_yes)} onClick={this.handleConfirm}> {yesActionText} </div>
              <div className={cs(st.sure_popup, st.sure_no)} onClick={this.handleCancel}> {noActionText} </div> */}
              <div className={cs(st.delete_sturcture)}>
                <div className={cs(st.delete_content)}>
                  {content}
                </div>
                <div className={cs(st.delete_buttons)}>
                  <div onClick={this.handleConfirm}> {yesActionText}</div>
                  <div onClick={this.handleCancel}> {noActionText} </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return (<div />)
  }
}
