import React, { Component } from 'react'
import classnames from 'classnames'
import { Container, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import PromoCard from './PromoCard'
import Download from '../../common/Download'
import GetStartedButton from '../../MainContent/Home/GetStartedButton'
import { getDocUrl } from '../../../constants'
import styles from './style.scss'

export default class Accounting extends Component {
    static propTypes = {
      history: PropTypes.object.isRequired,
      loginWithRedirect: PropTypes.func.isRequired,
    }

    render() {
      const { loginWithRedirect, history } = this.props
      return (
        <div>
          <Container fluid>
            <div className={classnames('page-main-wrapper')}>
              <div className={classnames(styles.container_xl, 'mt-5', 'mb-5')}>
                <div className={classnames(styles.page_content_header, 'row', 'align-items-center')}>
                  <Col lg={12} md={12} xl={12} xs={12}>
                    <div className={classnames('text-center', 'mx-auto')}>
                      <img
                        alt="fresher resume" className={classnames(styles.resume_sample_vector, 'img-fluid', 'mx-auto')}
                        src="/assets/images/fresher_vector.jpg" />
                      <div
                        className={classnames('mt-md-5', 'text-center', 'mb-md-4')}>
                        <h1 className={classnames('mt-4', styles.size_heading)}> Fresher Resume Example  </h1>
                        <p className={classnames('mb-0', styles.article)}>As a fresh graduate, it may be
                          somewhat hard to create an engaging resume. This is because you have
                          practically zero work history and little industry knowledge.
                          The fact isn&#8242;t that you are wrong but rather that you recently moved on
                          from a school that wouldn&#8242;t give you everything
                          you need in the job environment.</p>
                      </div>
                      <div className={classnames(styles.btn_gs)}>
                        <GetStartedButton label="Build My Resume" loginWithRedirect={loginWithRedirect} size="lg" />
                      </div>
                    </div>
                  </Col>
                  <div className={classnames(styles.resume_sample_container, 'mt-5', 'pt-3', 'pt-lg-4')}>
                    <Row>
                      <Col lg={6}>
                        <div className={classnames('mb-5', 'p-xl-5', 'mb-xl-0')}>
                          <div className={classnames(styles.resume_sample)}>
                            <img
                              alt="Resume Sample" className={classnames(styles.resume_sample_img)}
                              src="/assets/images/fresher_resume.jpg" />
                            <Download
                              downloadURL={getDocUrl('assets/docs/fresher-resume.docx')}
                              history={history} />
                          </div>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className={classnames('mb-5', 'p-xl-5', 'mb-xl-0')}>
                          <div className={classnames(styles.resume_sample)}>
                            <img
                              alt="Resume Sample" className={classnames(styles.resume_sample_img)}
                              src="/assets/images/fresher_cl.jpg" />
                            <Download
                              downloadURL={getDocUrl('assets/docs/fresher-cl.docx')}
                              history={history} />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className={classnames(styles.resume_sample_header, 'text-center')}>
                      <div className={classnames(styles.container_xl)}>
                        <h2 className={classnames(styles.section_heading)}>
                          Fresher Resume Example (Text Format)
                        </h2>
                        <p className={classnames('mt-2', styles.section_paragraph)}>
                          Make sure you choose the right resume
                          format to suit your unique experience and the job you are applying.</p>
                      </div>
                    </div>
                    <div className={classnames(styles.resume_sample_wrapper)}>
                      <div className={classnames(styles.container_xl)}>
                        <Row>
                          <Col lg={8} xl={8} xs={12}>
                            <div className={classnames(styles.resume_sample_main)}>
                              <div className={classnames(styles.tab_content)}>
                                Text View
                              </div>
                            </div>
                            <div className={classnames(styles.resume_content_card,
                              styles.dz_card, styles.shadow_1, 'px-3', 'px-lg-5', 'py-5', 'tab-pane active')}>
                              <Row>
                                <h2 className={classnames(styles.section_heading, 'ml-2')}>
                                  Fresh Graduate
                                </h2>
                              </Row>
                              <div className={classnames(styles.resume_content_title, 'mt-3')}>Objective</div>
                              <p className={classnames('mt-2', 'mb-2')}>
                                Skilled professional with strong background
                                in Agriculture, Marketing in Agro products which built
                                passion towards progressing career path in the space of Food
                                Products is looking for opportunity to acquire
                                practical knowledge and well as contribute to the best
                                in the assignments from my learning and experiences.
                              </p>
                              <div className={classnames(styles.resume_content_title, 'mt-3')}>Summary</div>
                              <ul className={classnames(styles.interview_points, 'mt-4')}>
                                <li>Secured ground level experience by visiting and interacting
                                  many individual farmers and the local Agro departments. </li>
                                <li> Handled the sales fertilizers and pesticides of top brand
                                  in South India with individual revenue targets reaching £100K+ </li>
                                <li>Disciplined individual with focus on the job done,
                                  have spent months of travel time,
                                  meeting people, understanding the
                                  ground reality, and reporting to the regional management.</li>
                                <li> Has undergone extensive training for
                                  45 days “Agriculture Input Retail Store Management”.</li>
                              </ul>
                              <div className={classnames(styles.resume_content_title)}>
                                Project</div>
                              <ul className={classnames(styles.interview_points,
                                'list-unstyled', styles.content_title, 'mt-4')}>
                                <li>RAWEp
                                  (Rural Agricultural Work Experience Programs) – Belas, US</li>
                              </ul>
                              <p className={classnames('mt-2')}>Internship project
                                that provides opportunity for the
                                students at Argo field level experience
                                to enhance theoretical knowledge
                                with the practical exposure by Sri Aurobindo
                                Institute of Rural Development.</p>
                              <ul className={classnames(styles.interview_points)}>
                                <li className={classnames('mt-2', 'mb-0')}>Working with farmers,
                                  coordinating with
                                  the institution and the government agencies.</li>
                                <li className={classnames('mb-1')}>Preparing the field report
                                  and project work documentation.</li>
                              </ul>
                              <ul className={classnames(styles.interview_points, 'list-unstyled',
                                styles.content_title, 'mt-4')}>
                                <li className={classnames('mb-1', 'mt-3')}>Marketing Manager Agro Chemical with
                                  Graphixtron Branding and Advertising, Partin, US, Jun 2018 to Jan 2020</li>
                              </ul>
                              <ul className={classnames(styles.interview_points)}>
                                <li className={classnames('mb-1', 'mt-2')}>Handled sales and marketing
                                  for Coromandel fertilizers.</li>
                                <li>Travel and Visiting the farmers and filed level study.</li>
                                <li>Working the local dealers and demand forecasting.</li>
                                <li>Organized various social media campaigns.</li>
                              </ul>
                              <div className={classnames(styles.resume_content_title)}>
                                Passion
                              </div>
                              <ul className={classnames(styles.interview_points, 'mt-4')}>
                                <li>Innovative Farming Products</li>
                                <li>Animal Care</li>
                                <li>Entrepreneurship</li>
                                <li>Research in Food Products</li>
                              </ul>
                              <div className={classnames(styles.resume_content_title, 'mt-3')}>
                                Education </div>
                              <ul className={classnames(styles.interview_points, 'mt-4')}>
                                <li>
                                  Bachelor’s in agriculture (Bhagwant University – US,Year 2014 - 2018)</li>
                                <li> Master’s in Food
                                  Science &#38; Technology (Cardiff Metropolitan University, Cardiff
                                  Batch | 2018 - 2019)</li>
                                <li>
                                  Computers (Certified Professional in MS Office)</li>
                              </ul>
                              <div className={classnames(styles.resume_content_title)}>
                                Personal &#38; Communication
                              </div>
                              <ul className={classnames(styles.interview_points, 'mt-4')}>
                                <li>Well organized professional</li>
                                <li>Proven track record of achieving targets</li>
                                <li>Easily gel with people and drive discussion</li>
                              </ul>
                              <div className={classnames(styles.resume_content_title)}>
                                Hobbies
                              </div>
                              <ul className={classnames(styles.interview_points, 'mt-4')}>
                                <li>Reading</li>
                                <li>Cricket</li>
                                <li>Travel</li>
                              </ul>
                            </div>
                            <div className={classnames(styles.resume_sample_header, 'pb-5')}>
                              <h2 className={classnames(styles.section_heading, 'mb-2')}>
                                How to Write a Fresh Graduate Resume</h2>
                              <p className={classnames('mb-2')}>If you have worked as an intern,
                                don’t hesitate to highlight
                                what you did and acquired to sell out yourself.
                                The fundamental objective of a resume is to ensure
                                that you impress your hiring managers through your skills.</p>
                              <p>In this guide, you will get
                                detailed information on how to write
                                a fresh graduate resume. Let&#8242;s dive in and see!</p>
                            </div>
                            <div
                              aria-labelledby="resumeContentTab"
                              className={classnames(styles.resume_content_card,
                                styles.dz_card, styles.shadow_1, 'px-0', 'px-lg-5', 'py-5', 'tab-pane active')}
                              id="resumeContent" role="tabpanel">
                              <h3 className={classnames(styles.resume_content_heading, 'mb-2')}>
                                How to Write a Fresh Graduate Resume Summary/Career Objective</h3>
                              <p className={classnames('mb-2')}>A career objective is a significant
                                area on a resume for a fresh graduate.
                                It is the first section the recruiters encounter before
                                reading the rest of your resume.</p>
                              <p className={classnames('mb-2')}>In writing a career objective
                                for your resume, you need to comprehend the work you need.
                                You could make a career objective that could be
                                utilized for general purposes.
                                However, the best objective should be tailored to
                                the work you are applying for.</p>
                              <p className={classnames('mb-2')}>For somebody without experience,
                                you will have to incorporate your dream position in your objective.
                                Then again, you may have a chosen set of experiences while in school.
                                For this situation, you would have to twist
                                them to fit in the ideal objectives consistently.</p>
                              <p className={classnames('mb-3')}>Make the career
                                objective short and highlight
                                skills and abilities that can
                                make a company successful.</p>
                              <h5 className={classnames('font-weight-bold')}>
                                Here is how to highlight your career objective</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Feature your degree and years of work experience</li>
                                <li>Describe your most strong capabilities</li>
                                <li>Feature your skills that will assist the
                                  organization with accomplishing its objectives</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold', 'mt-4')}>
                                Example of a resume objective for a fresh graduate</h5>
                              <h5 className={classnames('ml-3', 'font-weight-bold', 'mt-3')}>Good Example</h5>
                              <blockquote><p className={classnames(styles.resume_rank, 'ml-0')}>
                                Business graduate with demonstrated correspondence, travel arranging,
                                and email management abilities. Looking for a position as a clerical
                                specialist at Kame Consult, to leverage organizational
                                and research skills to support marketing.
                              </p></blockquote>
                              <h5 className={classnames('ml-3', 'font-weight-bold')}>Bad Example</h5>
                              <blockquote><p className={classnames(styles.resume_rank, 'ml-0')}>
                                Searching for an administrator partner job
                                where I can apply my abilities.
                                Not much office experience yet, but instead,
                                I&#8242;m a self-starter, and I can make a difference.
                              </p></blockquote>
                              <div className={classnames('card', 'p-3', 'px-lg-4', 'mt-5',
                                styles.ProTip, styles.interview_blockquote)}>
                                <h5 className={classnames('ml-5', 'font-weight-bold')}>Pro Tip </h5>
                                <p className={classnames('ml-5')}>
                                  Utilize metrics to quantify your accomplishments in the career objective.
                                  In case you lack experience, highlight
                                  internship work, or focus on any other achievements.</p>
                              </div>

                              <h3 className={classnames(styles.resume_content_heading, 'mt-5', 'mb-4')}>
                                How to Highlight Skills on a Fresh Graduate Resume
                              </h3>
                              <img
                                alt="Nurseimage" className={classnames('mt-2', 'mb-3')}
                                src="/assets/images/fresher_skill.jpg" />

                              <p className={classnames('mb-2')}>
                                Recruiters dissect your abilities to decide if you are equipped for the work.
                                Hiring managers know about the significant skills required for the work,
                                and they generally filter resumes to see if they are pertinent to their necessities.</p>
                              <p className={classnames('mb-2')}>
                                At the point you compose your skills, you may be selective by considering
                                the sort of task to be done. Most employers would adore you in the event
                                that you project the significant aptitudes for their undertaking.
                                Keep in mind that the vast majority of your potential employers are
                                having one issue or the other. Hence, you need to zero in on your
                                critical thinking aptitudes and mastery to show them
                                that you are able to solve problems.</p>
                              <p className={classnames('mb-2')}>
                                You can partition your skills into two.
                                The two sets will be soft and hard skills. In soft skills,
                                you will incorporate such abilities as correspondence, critical thinking,
                                deliverable, and working strength. In some cases, you should
                                incorporate the number of languages you speak to show your diversity.</p>
                              <p className={classnames('mb-2')}>
                                Then again, your hard skills ought to be deliberately positioned.
                                You may incorporate MS word strength along with
                                its packages, computer literacy, and many more.</p>
                              <p className={classnames('mb-3')}>
                                By and large, there is some knowledge of things you should need.
                                These are abilities as well. You should discover a method of
                                including them in your skills. You ought to have aptitudes,
                                such as relational connections, the capacity to work under
                                tension with practically no management, your capacity to
                                consolidate school with other requesting exercises, and so
                                on You should give more attention to these alongside your skills.
                                This is because they approve you to work with different persons and niches.</p>
                              <h5 className={classnames('font-weight-bold')}>Here are the best practices for
                                highlighting skills on your resume</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Familiarize yourself with the job
                                  advert and understand what skills are needed</li>
                                <li>Demonstrate how used your skills to achieve particular goals</li>
                                <li>Use both soft and hard skills on your resume</li>
                                <li className={classnames('mb-0')}>Analyze the job descriptions and highlight
                                  skills that are relevant to the employer&#8242;s needs</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold', styles.align)}>
                                Example of skills on a fresh graduate resume</h5>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>
                                Hard Skills</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>MS Word (expert)</li>
                                <li>Excel sheet preparations (intermediate)</li>
                                <li>Slides Preparation (beginner)</li>
                                <li>HTML and CSS (beginner)</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>
                                Soft Skills</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Excellent communication skills</li>
                                <li>Critical thinking</li>
                                <li>Proactive</li>
                                <li>Teamwork</li>
                              </ul>
                              <div className={classnames('card', 'p-3', 'px-lg-4', 'mt-5',
                                styles.ProTip, styles.interview_blockquote)}>
                                <h5 className={classnames('ml-5', 'font-weight-bold')}>Pro Tip </h5>
                                <p className={classnames('ml-5')}>
                                  Before you highlight skills on your resume, analyze the job descriptions
                                  to determine the kind of skills an employer wants.</p>
                              </div>
                              <h3 className={classnames(styles.resume_content_heading, 'mt-5', 'mb-2')}>
                                How to Write Work Experience in a Fresh Graduate Resume</h3>
                              <p className={classnames('mb-0')}>As a fresh graduate, you may not have
                                the important experience required for the work. In the event that you are
                                in such a situation, don&#8242;t stress; there is always a way out.
                                All things considered, your internships, part-time occupations,
                                or volunteer works have been crucial to you as you’ve developed yourself professionally.
                                This is the place where you place them. List the most recent
                                job on your list down to the earliest. Mention the organization,
                                your position, and how long you delivered in that work.
                                The significant key here is mentioning the details, so feature
                                your duties and what you&#8242;ve accomplished during your college life.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>
                                Here are examples of experiences you can include when writing your first resume</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Jobs like serving at a bookshop or
                                  part-time customer service associate at a retail store</li>
                                <li>Extracurricular activities, including sports and clubs</li>
                                <li>Apprenticeships and vocational training</li>
                                <li>Volunteer work</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold')}>
                                Example on how to highlight your work experience</h5>
                              <ul className={classnames(styles.interview_points, 'list-unstyled', 'mt-3')}>
                                <li className={classnames('mb-2')}>Work Experience</li>
                                <li>Intern Student</li>
                                <li>January 2020 - August 2020</li>
                                <li>Gauge Consulting</li>
                              </ul>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Participated in email sorting</li>
                                <li>Scheduled and organized meetings three days per week</li>
                                <li>Head cohort on some deliverables</li>
                              </ul>
                              <p>Always present the position you occupied to make sure
                                that you demonstrate strong leadership skills.
                                It would be best to provide the right
                                information at the right time when crafting a resume.
                                This will impress the hiring managers and
                                boost your chances of getting hired.</p>
                              <div className={classnames('card', 'p-3', 'px-lg-4', 'mt-5',
                                styles.ProTip, styles.interview_blockquote)}>
                                <h5 className={classnames('ml-5', 'font-weight-bold')}>Pro Tips
                                </h5>  <ul className={classnames(styles.interview_points, 'ml-4', 'mt-3')}>
                                  <li>Use bullet points to highlight your job duties and focus
                                    on at least five critical activities.</li>
                                  <li>Use metrics, such as percentages, to quantify your accomplishments.
                                    For example: &#8243;Served an average of 100 customers per day.&#8243;</li>
                                </ul>
                              </div>
                              <h3 className={classnames(styles.resume_content_heading, 'mt-5')}>
                                How to Highlight Achievements, Hobbies,
                                Training, and Certifications on a Fresh Graduate Resume</h3>
                              <p className={classnames('mb-1')}>As a fresh graduate, it is critical
                                to familiarize yourself with how to introduce your accomplishments,
                                hobbies, and certifications on your resume.
                                This is the best strategy for setting yourself
                                apart from other competitors.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>How to Highlight Your
                                Achievements on a Resume</h5>
                              <p className={classnames('mb-1')}>
                                Featuring key accomplishments on your resume may
                                assist you in finding a decent line of work.
                                Your achievements affirm to the hiring managers
                                that you were the best achiever in the past task, which may
                                make you the most ideally equipped contender for the work.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>
                                Example of how to highlight achievements for a fresh graduate</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Acquired the university
                                  award three times for excelling in a business presentation</li>
                                <li>
                                  Editor of the College Blog from 2010 to 2013.</li>
                                <li>My article in the school paper on crowd
                                  computing was linked to by Techdirt.</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold')}>
                                How to Highlight Hobbies on a Resume</h5>
                              <p className={classnames('mb-1', 'mt-0')}>Hobbies are activities done for enjoyment.
                                Hobbies are done for free,
                                and they are not meant to receive any reward. Make sure to highlight
                                hobbies that are relevant to the job you are applying for.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>Examples of hobbies</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Playing games</li>
                                <li>Swimming</li>
                                <li>Reading business magazines</li>
                                <li>Yoga</li>
                              </ul>
                              <div className={classnames('card', 'p-3', 'px-lg-4', 'mt-5',
                                styles.ProTip, styles.interview_blockquote)}>
                                <h5 className={classnames('ml-5', 'font-weight-bold')}>Pro Tips
                                </h5>  <ul className={classnames(styles.interview_points, 'ml-4', 'mt-3')}>
                                  <li>List hobbies that are relevant to the position you are applying for</li>
                                  <li>Limit yourself to a maximum of three hobbies</li>
                                </ul>
                              </div>
                              <h5 className={classnames('font-weight-bold', 'mt-5', 'mb-3')}>
                                How to Highlight Certifications on a Resume</h5>
                              <p className={classnames('mb-2')}>
                                Listing certifications on your resume can boost your chances of recruitment.
                                If you have certifications, don’t hesitate to highlight on your resume.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-3', 'mb-3')}>
                                Here is how to list certifications on your resume</h5>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Describe the title of certification</h5>
                              <p className={classnames('mb-2')}>
                                Highlight the full title of certification, and ensure it
                                appears under the title &#8243;Certifications.&#8243; Make sure you type
                                the name of the certification vividly and spell out any abbreviations.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-0', 'mb-2')}>
                                Highlight the certifying authority
                              </h5>
                              <p className={classnames('mb-2')}>Indicating the certifying authority
                                allows for quick verification of your certifications.
                                Always include the name of the organization that awarded it to you.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Certification date</h5>
                              <p className={classnames('mb-2')}>The date of certification
                                tells your recruiter of how long it has taken since you earned the certification.
                                Also, it provides the hiring manager with the
                                information they need in case they want to verify the certification.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}> Highlight associated skills</h5>
                              <p className={classnames('mb-2')}>Always list skills that are
                                relevant to the certification.
                                This is meant to explain the certification’s relevance and
                                answer any queries your recruiter may have.</p>
                              <div className={classnames('card', 'p-3', 'px-lg-4', 'mt-5',
                                styles.ProTip, styles.interview_blockquote)}>
                                <h5 className={classnames('ml-5', 'font-weight-bold')}>Pro Tips
                                </h5>  <ul className={classnames(styles.interview_points, 'ml-4', 'mt-3')}>
                                  <li>List the certifications in reverse-chronological order</li>
                                  <li>When highlighting certifications,
                                    list the following: Name of the certification, certifying authority,
                                    date of certification, and location.</li>
                                </ul>
                              </div>
                              <h3 className={classnames(styles.resume_content_heading, 'mt-5')}>
                                Top Professional Tips for Writing Fresh Graduate Resume</h3>
                              <h5 className={classnames('font-weight-bold', 'mt-3', 'mb-2')}>
                                Here are the tips for writing a fresh graduate resume</h5>

                              <h5 className={classnames('font-weight-bold', 'mt-4', 'mb-2')}>Zero in on the Future</h5>
                              <p className={classnames('mb-2')}>Without a work history before,
                                your expectations and plans for what&#8242;s to come is the next best thing.
                                This is the place where the objectives and targets segment of your
                                resume becomes an integral factor. Utilize this region to discuss
                                what you desire to accomplish in your profession and how that can
                                help your planned business. Fresh graduates
                                resume actually needs to incorporate this part.
                                Try not to be hesitant to inject your resume with your
                                own personality; it will set you apart from competitors.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Use References</h5>
                              <p className={classnames('mb-2')}>
                                Ideally, you have gotten the opportunity to obtain
                                top-notch references all through your school profession.
                                If not, take the time presently to contact educators and
                                companions who have useful experience with you in your preferred career.
                                Work study, volunteer opportunities, and even classroom
                                performance are magnificent additions to your resume.
                                Sometimes, what your references say about you can be more
                                convincing than work history entries on your resume.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Feature Education background</h5>
                              <p className={classnames('mb-2')}>
                                Note that a fresh graduate resume mainly focuses
                                on education because at this point in your life,
                                education is your greatest asset. Where appropriate,
                                address straight issues, information, and both classroom
                                and life experiences that have given you the training
                                that drove you to go after this position.
                                In the event that they are related to your career,
                                elective secondary school courses can be incorporated here also.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Incorporate Related Extracurricular Experience</h5>
                              <p className={classnames('mb-2')}>In the event that you are applying for
                                a programming profession and have gone through consistently since you were
                                15 composing your own projects, by all means, include that on your resume!
                                Also, any volunteer insight or hands-on involvement with a chapel or
                                another association can help present a convincing defence for why a
                                company should enlist you.
                                On the off chance that it ever assisted you with
                                turning out to be your main thing, remember it for your resume.</p>

                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Choose an appropriate resume format</h5>
                              <p className={classnames('mb-2')}>Before you compose a resume,
                                you should pick a satisfactory format.
                                The configuration ought to permit you to feature the characteristics
                                that make you an ideal candidate for their job.</p>
                              <p className={classnames('mb-2')}>Numerous businesses lean toward the
                                chronological resume design where you list experiences and accomplishments
                                from the latest to the most established. Notwithstanding, you can likewise
                                utilize a practical or mix continue to design in the event that
                                it permits you to best feature yourself as a solid match for the job.
                                Notwithstanding the resume design, ensure the
                                document has a steady structure all through.</p>
                              <h5 className={classnames('font-weight-bold', 'mb-2')}>
                                Mention skills relevant to the position</h5>
                              <p className={classnames('mb-2')}>Many people know that
                                It is likewise essential to include your abilities on your resume.
                                The ideal approach to do this is to read the job advert for the
                                particular skills and capabilities the employer is searching
                                for in the ideal applicant. After identifying and listing
                                the abilities needed for the job, you additionally need to
                                show how those accreditations assisted you to deliver in the past.</p>

                            </div>
                          </Col>
                          <Col lg={4} xl={4} xs={12}>
                            <PromoCard loginWithRedirect={loginWithRedirect} />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      )
    }
}
