import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { inject } from 'mobx-react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './style.scss'

@inject('mainModel')
class BlogCategory extends Component {
static propTypes = {
  filterBy: PropTypes.func.isRequired,
  mainModel: PropTypes.object.isRequired,
}

render() {
  return (

    <ul className={classnames(styles.blog_left_aside_lists)}>
      <li> <div onClick={() => this.props.filterBy('category', 'Resume')}> Resume </div> </li>
      <li> <div onClick={() => this.props.filterBy('category', 'Career')}> Career </div> </li>
      <li> <div onClick={() => this.props.filterBy('category', 'Interview')}> Interview </div> </li>
      <li> <div onClick={() => this.props.filterBy('category', 'Fresher')}> Fresher </div> </li>
      <li> <div onClick={() => this.props.filterBy('category', 'Professional')}> Professional </div> </li>
    </ul>
  )
}
}
export default withRouter(BlogCategory)
