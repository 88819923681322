import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'

import { registerForGoogleAnalytics } from '../../constants'
import { AppConstants } from '../../constants/application-constants'
import { MainHeader, MainContent, MainFooter, Blog, CareerGuide, Interview } from '../../components'
import styles from './style.scss'

@inject('mainModel', 'applicationModel')
@observer
class Home extends Component {
  static propTypes = {
    applicationModel: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    mainModel: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    selectedKey: PropTypes.string,
  }

  static defaultProps = {
    selectedKey: '',
  }

  constructor(props) {
    super(props)
    this.state = {
      filterDetails: {},
    }
    this.url = window.location.href
  }

  componentDidMount(){
    const { history } = this.props
    registerForGoogleAnalytics(history)
  }

  getRenderComponent() {
    const { selectedKey } = this.props
    const { filterDetails } = this.state
    switch (selectedKey) {
      case 'blog':
        return <Blog {...this.props} filter={filterDetails} subscribe={this.subscribe} />
      case 'interview':
        return <Interview {...this.props} />
      case 'career':
        return <CareerGuide {...this.props} />
      default:
        return (
          <MainContent
            {...this.props}
            filter={filterDetails} gotoURL={this.gotoURL}
            loginWithRedirect={this.loginWithRedirect} />
        )
    }
  }

  subscribe = (email, cb) => {
    const { mainModel } = this.props
    mainModel.subscribeNewSLetter(email, cb)
  }

  gotoURL = (url, filterDetails) => {
    const { history } = this.props
    this.setState({
      filterDetails,
    })
    history.push(url)
    window.scrollTo(0, 0)
  }

  loginWithRedirect = (redirectDetails) => {
    const { applicationModel, history } = this.props
    applicationModel.setRedirect(redirectDetails)
    if (applicationModel.getLogin()){
      const url = get(redirectDetails, 'redirectTo')
      window.location.assign(`${AppConstants.dashboardHost}${url}`)
    }
    else {
      history.push('/login')
    }
  }

  logout = () => {
    const { applicationModel } = this.props
    applicationModel.logout(
      () => this.openURL('/login-with-email'),
    )
  }

  render() {
    return (
      <div className={classnames(styles.main)}>
        <MainHeader
          gotoURL={this.gotoURL}
          loginWithRedirect={this.loginWithRedirect}
          logout={this.logout} />
        { this.getRenderComponent() }
        <MainFooter gotoURL={this.gotoURL} subscribe={this.subscribe} />
      </div>
    )
  }
}

export default withRouter(Home)
