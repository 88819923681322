import React from 'react'
import cs from 'classnames'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { Container, Row, Col } from 'react-bootstrap'

import st from './style.scss'

const queryString = require('query-string')

export default class ErrorPage extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  }

  constructor(props){
    super(props)
    const params = queryString.parse(props.location.search)
    this.state = {
      error: get(params, 'error', ''),
      eyeStyle: {},
    }
    this.eyeRef1 = React.createRef()
    this.eyeRef2 = React.createRef()
  }

  handleMouseMove = (event) => {
    this.updateEye(event, this.eyeRef1.current)
    this.updateEye(event, this.eyeRef2.current)
  }

  updateEye = (event, eye) => {
    const x = (eye.getBoundingClientRect().left) + (eye.getBoundingClientRect().width / 2)
    const y = (eye.getBoundingClientRect().top) + (eye.getBoundingClientRect().height / 2)
    const rad = Math.atan2(event.pageX - x, event.pageY - y)
    const rot = (rad * (180 / Math.PI) * -1) + 180
    this.setState({
      eyeStyle: {
        '-webkit-transform': `rotate(${rot}deg)`,
        '-moz-transform': `rotate(${rot}deg)`,
        '-ms-transform': `rotate(${rot}deg)`,
        transform: `rotate(${rot}deg)`,
      },
    })
  }

  gotoURL = (url) => {
    const { history } = this.props
    this.setState({
    })
    history.push(url)
    window.scrollTo(0, 0)
  }

  render() {
    const { error, eyeStyle } = this.state
    return (
      <div>
        <Container fluid>
          <Row>
            <Col
              className={cs(st.pp_left_00)} lg={2} md={3} sm={3} xl={2}
              xs={12}>
              <div className={cs(st.main_logo)} onClick={() => this.gotoURL('/')}>
                <div className={cs(st.desktop)}>
                  <img alt="start" src={'/assets/images/logo.svg'} />
                </div>
                <div className={cs(st.mobile)}>
                  <img alt="start" src={'/assets/images/logo.svg'} />
                </div>
              </div>
            </Col>
          </Row>
          <Row className={cs(st.mb_responsive)}>
            <Col className={cs(st.container)} onMouseMove={this.handleMouseMove} sm={12}>
              <div className={cs(st.page_500)}>
                <span className={cs(st.errorNum)}>5</span>
                <div ref={this.eyeRef1} className={cs(st.eye)} style={eyeStyle} />
                <div ref={this.eyeRef2} className={cs(st.eye)} style={eyeStyle} />
              </div>
              <div>
                <div className={cs(st.subText)}>
                  <h6>Oops..! Something went wrong. {error && { error }}</h6>
                </div>
                <div
                  className={cs(st.home_link)}
                  onClick={() => this.gotoURL('/')}>Go to home</div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}
