import React, { Component } from 'react'
import classnames from 'classnames'
import { Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import PromoCard from './PromoCard'
import Download from '../../common/Download'
import GetStartedButton from '../../MainContent/Home/GetStartedButton'
import { getDocUrl } from '../../../constants'
import styles from './style.scss'

export default class Bpo extends Component {
    static propTypes = {
      history: PropTypes.object.isRequired,
      loginWithRedirect: PropTypes.func.isRequired,
    }
    render() {
      const { loginWithRedirect, history } = this.props
      return (
        <div>

          <div className={classnames('page-main-wrapper')}>
            <div className={classnames(styles.container_xl, 'mt-5', 'mb-5')}>
              <div className={classnames(styles.page_content_header, 'row', 'align-items-center')}>
                <Col lg={12} md={12} xl={12} xs={12}>
                  <div className={classnames('text-center', 'mx-auto')}>
                    <img
                      alt="fresher resume" className={classnames(styles.resume_sample_vector, 'img-fluid', 'mx-auto')}
                      src="/assets/images/BPO_vector.jpg" />
                    <div
                      className={classnames('mt-md-5', 'text-center', 'mb-md-4')}>
                      <h1 className={classnames('mt-4', styles.size_heading)}>
                        Customer Service Representative Resume (BPO Resume) </h1>
                      <p className={classnames('mb-0')}>Customer service representatives matter a lot
                        to the well-being of a company&#8242;s business. It is crucial
                        to bear in mind that great
                        brands value excellent customer service and experience.
                        If you are looking for a customer service position, it is essential to compose
                        a top-notch resume that will enable you to get a perfect job.</p>
                    </div>
                    <div className={classnames(styles.btn_gs)}>
                      <GetStartedButton label="Build My Resume" loginWithRedirect={loginWithRedirect} size="lg" />
                    </div>
                  </div>
                </Col>
                <div className={classnames(styles.resume_sample_container, 'mt-5', 'pt-3', 'pt-lg-4')}>
                  <Row>
                    <Col lg={6}>
                      <div className={classnames('mb-5', 'p-xl-5', 'mb-xl-0')}>
                        <div className={classnames(styles.resume_sample)}>
                          <img
                            alt="Resume Sample" className={classnames(styles.resume_sample_img)}
                            src="/assets/images/bpo_resume.jpg" />
                          <Download
                            downloadURL={getDocUrl('assets/docs/bpo-resume.docx')}
                            history={history} />
                        </div>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className={classnames('mb-5', 'p-xl-5', 'mb-xl-0')}>
                        <div className={classnames(styles.resume_sample)}>
                          <img
                            alt="Resume Sample" className={classnames(styles.resume_sample_img)}
                            src="/assets/images/bpo_cl.jpg" />
                          <Download
                            downloadURL={getDocUrl('assets/docs/bpo-cl.docx')}
                            history={history} />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className={classnames(styles.resume_sample_header, 'text-center')}>
                    <div className={classnames(styles.container_xl)}>
                      <h2 className={classnames(styles.section_heading)}>
                        Customer Service Representative Resume (Text Format)
                      </h2>
                      <p className={classnames('mt-2', styles.section_paragraph)}>
                        Make sure you choose the right resume
                        format to suit your unique experience and the job you are applying.</p>
                    </div>
                  </div>
                  <div className={classnames(styles.resume_sample_wrapper)}>
                    <div className={classnames(styles.container_xl)}>
                      <Row>
                        <Col lg={8} xl={8} xs={12}>
                          <div
                            className={classnames(styles.resume_sample_main)}>
                            <div className={classnames(styles.tab_content)}>
                              Text View
                            </div>
                          </div>
                          <div className={classnames(styles.resume_content_card,
                            styles.dz_card, styles.shadow_1, 'px-3', 'px-lg-5', 'py-5', 'tab-pane active')}>
                            <Row>
                              <h2 className={classnames(styles.section_heading, 'ml-2')}>
                                Customer Service Representative Resume
                              </h2>
                            </Row>
                            <div className={classnames(styles.resume_content_title, 'mt-3')}>Summary</div>
                            <p className={classnames('mt-2', 'mb-2')}>
                              Focused customer relations professional skilled in
                              lead generation, customer relationship development and sales. Accomplished
                              in providing unsurpassed support to demanding customers.
                              Offering 4 years of experience in related roles, as well as a passion for
                              improving service delivery, enhancing knowledge and exceeding expectations.
                            </p>
                            <div className={classnames(styles.resume_content_title)}>
                              Work Experience</div>
                            <ul className={classnames(styles.interview_points,
                              'list-unstyled', styles.content_title, 'mt-4')}>
                              <li className={classnames('mb-0')}>Customer Service
                                Specialist</li>
                              <li>DEF Holding, Jan 2020 – Till Date</li>
                            </ul>
                            <ul className={classnames(styles.interview_points, 'mt-2')}>
                              <li>Drove team revenue totals by bringing in over $10 Million in sales.</li>
                              <li>Educated customers on promotions to enhance sales.</li>
                              <li>Preserved revenue streams by utilizing strong communication
                                and negotiation skills, offering refunds as last resort to maintain
                                customer satisfaction.</li>
                              <li>Evaluated account and service histories to identify trends, using data to
                                mitigate future issues.</li>
                              <li>Responded to customer requests for products, services
                                and company information.</li>
                              <li>Answered constant flow of customer calls with up to 3 calls
                                in queue per minute.</li>
                              <li>Maintained customer satisfaction with forward-thinking strategies
                                focused on addressing customer needs and resolving concerns.</li>
                            </ul>
                            <ul className={classnames(styles.interview_points, 'list-unstyled',
                              styles.content_title, 'mt-4')}>
                              <li className={classnames('mb-0')}>Call Center
                                Representative</li>
                              <li>XYZ Shares, Mar 2017 – Dec 2019</li>
                            </ul>
                            <ul className={classnames(styles.interview_points, 'mt-2')}>
                              <li>Achieved and consistently exceeded revenue quota through
                                product and service promotion during routine calls.</li>
                              <li>Reached out to customers after
                                completed sales to suggest additional
                                service or product purchases and
                                inquire about needs or concerns.</li>
                              <li>Reduced downtime to support quality
                                control, boost revenue and complete
                                projects on time and under budget.</li>
                              <li>Evaluated customer information to
                                explore issues, develop potential
                                solutions and maintain high-quality service.</li>
                              <li>Entered customer interaction details in XYZ Software to
                                track requests, document problems and
                                record solutions offered.</li>
                              <li>Engaged in continuous learning and development opportunities
                                to promote continued performance improvement.</li>
                            </ul>
                            <div className={classnames(styles.resume_content_title, 'mt-3')}>
                              Education</div>
                            <ul className={classnames(styles.interview_points, 'list-unstyled', 'mt-4')}>
                              <li>Alamanda College - Associate of Arts, Jan 2017</li>
                            </ul>
                            <div className={classnames(styles.resume_content_title)}>
                              Skills
                            </div>
                            <ul className={classnames(styles.interview_points, 'mt-4')}>
                              <li>International sales support</li>
                              <li>Complaint resolution</li>
                              <li>Report preparation</li>
                              <li> Sales expertise </li>
                              <li> Inbound and Outbound Calling</li>
                              <li>Key stakeholder relationship building  </li>
                              <li>  Multi-line phone talent</li>
                              <li>CRM</li>
                              <li>Money handling abilities</li>
                            </ul>
                            <div className={classnames(styles.resume_content_title, 'mt-3')}>Certifications</div>
                            <ul className={classnames(styles.interview_points, 'mt-4')}>
                              <li>Sales Essentials Certificate</li>
                              <li>Microsoft Certified Application Specialist (MCAS)</li>
                              <li>  Sales Manager Certificates</li>
                            </ul>
                          </div>
                          <div className={classnames(styles.resume_sample_header, 'pb-5')}>
                            <h2 className={classnames(styles.section_heading, 'mb-2')}>
                              How to Write a Customer Service Representative Resume</h2>
                            <p>This article gives insights on customer
                              representative resume summary, highlighting
                              skills and experience in a resume,
                              how to capture a customer representative&#8242;s achievements, and top tips
                              for writing a customer representative service resume.</p>
                          </div>
                          <div
                            aria-labelledby="resumeContentTab"
                            className={classnames(styles.resume_content_card,
                              styles.dz_card, styles.shadow_1, 'px-0', 'px-lg-5', 'py-5', 'tab-pane active')}
                            id="resumeContent" role="tabpanel">
                            <h3 className={classnames(styles.resume_content_heading, 'mt-0')}>
                              How to Write a Customer Service
                              Representative Resume Summary</h3>
                            <p className={classnames('mb-2')}>A resume summary is the first
                              aspect HR managers will encounter in your CV.
                              Recruiters take a few seconds to scan
                              through your summary, and if they find it
                              irrelevant, you may not qualify for
                              an interview. Therefore, this is the part
                              that you need to use to portray the
                              impression that you are the most suitable
                              candidate for the job.</p>
                            <p className={classnames('mb-0')}>Additionally, a resume summary is your
                              introduction to your
                              prospective employer. It highlights your personal qualities, aptitude skills, and
                              experiences in the position you are applying for. Soft skills are significant
                              considerations of employers and should be highlighted in the summary section.</p>
                            <p className={classnames('mb-0')}>A resume summary should be a brief statement
                              about your capabilities and
                              accomplishments concerning the position.</p>
                            <h5 className={classnames('font-weight-bold', 'mt-3')}>Here are the strategies for
                              writing a customer service (BPO) resume summary</h5>
                            <h5 className={classnames('font-weight-bold', 'mt-4', 'mb-2')}>
                              Strategy #1: Analyze the Job Description</h5>
                            <p className={classnames('mb-0')}>
                              Before you think of composing any resume, it is crucial
                              to familiarize yourself with the job description to craft a CV that
                              is tailored to the employer&#8242;s requirements.</p>
                            <h5 className={classnames('font-weight-bold', 'mt-2', 'mb-2')}>
                              Strategy #2: Work Experience</h5>
                            <p className={classnames('mb-0')}>
                              Mention the critical aspects of your work history, such as
                              the number of years. Give a highlight of the company size and your role
                              in previous employment.</p>
                            <h5 className={classnames('font-weight-bold', 'mt-2', 'mb-2')}>
                              Strategy #3: Relevant Skills</h5>
                            <p className={classnames('mb-0')}>
                              Indicate the most proficient and relevant skills concerning the
                              job you are applying for.</p>
                            <h5 className={classnames('font-weight-bold', 'mt-2', 'mb-2')}>
                              Strategy #4: Accomplishments</h5>
                            <p className={classnames('mb-0')}>
                              Highlight the best achievements in the previous jobs and
                              if possible, quantify them using some metrics (e.g., percentages).
                              For instance, &#8243;Surpassed my profitability target by 40% in 2019.&#8243;</p>
                            <h5 className={classnames('font-weight-bold', 'mt-2')}>Examples Of
                              Customer Service Representative Resume Summaries</h5>
                            <p className={classnames('mt-2', styles.align)}>
                              Here are good and bad examples
                              of customer service resume summaries</p>
                            <h5 className={classnames('ml-3', 'mt-3', 'font-weight-bold')}>
                              Good Example</h5>
                            <blockquote><p className={classnames(styles.resume_rank, 'ml-0')}>
                              Customer service representative with 10+ years of
                              work experience in relationship
                              management, technical support, telephone communication,
                              and general customer care.
                              Conversant with office applications
                              and client service software tools. Adept at
                              organizing customers in the service bay.
                              Handled up to 200 calls daily, helped customers
                              to sign up, and retrieved clients’ data.
                            </p></blockquote>
                            <h5 className={classnames('ml-3', 'font-weight-bold')}>Bad Example</h5>
                            <blockquote><p className={classnames(styles.resume_rank, 'ml-0')}>
                              Knowledgeable and
                              highly-experienced customer service representative
                              with more than ten years of work
                              experience in the industry.
                              Ready to work under pressure and take new challenges to
                              deliver impeccable results.
                            </p></blockquote>
                            <div className={classnames('card', 'p-3', 'px-lg-4', 'mt-5',
                              styles.ProTip, styles.interview_blockquote)}>
                              <h5 className={classnames('ml-5', 'font-weight-bold')}>Pro Tips</h5>
                              <p className={classnames('ml-5')}>
                                <ul className={classnames(styles.interview_points, 'mt-3')}>
                                  <li> In case you lack experience for the customer service
                                    representative position,
                                    don&#8242;t worry; add relevant volunteer work or focus on
                                    any of your accomplishments.</li>
                                  <li>Highlight specific skills only if they relate to the job.
                                    You can pick such skills from the job description.</li>

                                </ul>

                              </p>
                            </div>
                            <h3 className={classnames(styles.resume_content_heading, 'mt-5', 'mb-4')}>
                              How to Highlight Skills in a Customer service representative Resume
                            </h3>
                            <img
                              alt="Nurseimage" className={classnames('mt-2', 'mb-3')}
                              src="/assets/images/bpo_skill.jpg" />
                            <p className={classnames('mb-2')}>HR managers your
                              resume skills to determine
                              whether you are the most
                              suitable candidate for the job.
                              Employers are aware of the relevant skills needed
                              for the job, and they scan your resume to find
                              out it has the keywords related to the skills.</p>
                            <p className={classnames('mb-2')}> Customer service representatives
                              play a crucial role in serving clients
                              and managing customer-employer relationships.
                              To succeed in their roles, customer service
                              employees have to hold certain skills.</p>
                            <p className={classnames('mb-0')}>With customer service skills,
                              a representative will
                              communicate clearly, solve issues, address
                              customer complaints, and enhance client satisfaction.</p>
                            <h5 className={classnames('font-weight-bold', 'mt-2')}> Here are the two
                              options you can use to highlight skills on your resume</h5>
                            <h5 className={classnames('font-weight-bold', 'mt-3', 'mb-2')}>
                              Option #1: Listing them in
                              the Skill Section</h5>
                            <p className={classnames('mb-2')}>This is the best way to list your skills
                              and manifest your level of experience.
                              Capitalize the skills you list here, and ensure that they are 1-3 words.
                              Make sure that the number of skill you highlight don’t exceed 10.</p>
                            <h5 className={classnames('font-weight-bold', 'mt-2', 'mb-2')}> Option #2: Listing
                              Skills in Many Sections</h5>
                            <p className={classnames('mb-2')}>Mention the critical aspects
                              of your work history, such as the number of years.
                              Give a highlight of the company size and your role in previous employment.</p>
                            <h5 className={classnames('font-weight-bold', 'mt-2')}> Examples of Hard and
                              Soft Skills on Your Resume</h5>
                            <p className={classnames('mb-2')}>Hard skills are industry-specific
                              tools or knowledge required for a job.
                              On the other hand, soft skills are aspects of your personality or working style
                              that are difficult to measure.</p>
                            <p className={classnames('mb-0')}>Bear in mind that the ideal skills to include
                              in your resume are those demonstrating your
                              ability to serve others.</p>
                            <h5 className={classnames('font-weight-bold', 'mt-2')}>
                              Here are the common customer service
                              representative skills that you should
                              include in your
                              resume</h5>
                            <h5 className={classnames('font-weight-bold', 'mt-3')}>
                              Hard Skills</h5>
                            <ul className={classnames(styles.interview_points, 'mt-3')}>
                              <li>Proficient in computer</li>
                              <li>Knowledge in customer management</li>
                              <li>Posses cross-selling</li>
                            </ul>
                            <h5 className={classnames('font-weight-bold', 'mt-3')}>
                              Soft Skills</h5>
                            <ul className={classnames(styles.interview_points, 'mt-3')}>
                              <li>Excellent interpersonal skills</li>
                              <li>Integrity</li>
                              <li>Positive attitude</li>
                              <li>Communication</li>
                              <li>Problem-solving</li>
                              <li>Active listener</li>
                              <li>Courtesy</li>
                            </ul>
                            <h5 className={classnames('font-weight-bold', 'ml-2')}>
                              Tips on How to Describe Customer Service Skills on Your Resume</h5>
                            <p className={classnames('ml-2')}>
                              Here are the tips you can use to show
                              your potential employer
                              that you possess the relevant customer
                              service skills they are looking for</p>
                            <ul className={classnames(styles.interview_points, 'mt-3')}>
                              <li>Always check the job description to determine the kind of customer
                                service skills an employer wants.</li>
                              <li>Compose a list of your customer service skills and tailor them to
                                specific employer&#8242;s requirements.</li>
                              <li>Ensure your list your customer service
                                skills in a separate section on your resume</li>
                              <li>Always use bullet points to highlight the skills</li>
                              <li>To achieve the right mix, you should
                                include both soft and hard skills</li>
                            </ul>
                            <h3 className={classnames(styles.resume_content_heading, 'mt-3')}>
                              How to Write Work Experience in Your
                              Customer Service Representative Resume</h3>
                            <p className={classnames('mb-2')}>If you have experience
                              in customer service and intend
                              to apply for a similar position,
                              ensure you highlight your industry
                              experience on your resume. Mention your
                              key performance indicators and
                              the goals you achieved.</p>
                            <p className={classnames('mb-2')}>However, if you don’t
                              have the experience, highlight
                              your relevant transferable skills,
                              such as communication or problem-solving skills.</p>
                            <p className={classnames('mb-2')}>If you want to enhance your
                              chances of getting shortlisted
                              for the interviews, ensure to
                              highlight your achievements as opposed to listing your
                              responsibilities.</p>
                            <p className={classnames('mb-2')}>In the work experience section,
                              don&#8242;t just mention what you did, but quantify
                              how well you performed.</p>
                            <h5 className={classnames('font-weight-bold', 'mt-3')}>  Tips on How to
                              Write Work Experience</h5>
                            <p className={classnames('mt-3')}> Here are the
                              tips to help you
                              write your work experience section properly</p>
                            <ul className={classnames(styles.interview_points, 'mt-3')}>
                              <li>Start each item by mention the name of the location, date, or job tile
                                (e.g., Customer Service Officer, Customer Service Representative).
                                List experience in reverse chronological order (start with the latest ones).</li>
                              <li>Use strong verbs to describe your responsibilities.
                                Focus on skills that are relevant to your
                                field and incorporate the right keywords.
                                For example, &#8243;Surpassed customer growth target by 20% in 2019.&#8243;</li>
                              <li>Use a variety of action words
                                such as &#8243;achieved,&#8243; &#8243;surpassed,&#8243;
                                &#8243;enhanced,&#8243; etc.
                                Use present tense for ongoing activities
                                and past tense for those you are not involved in.</li>
                              <li>Where possible, quantify your achievements
                                using percentages, dollar values, numbers,
                                and amounts. For example: Served an
                                average of 100 customers  a day.</li>
                              <li>Desist from using &#8243;I,&#8243;  &#8243;the,&#8243; pronouns,
                                and prepositions.</li>
                              <li>Highlight your individual achievements,
                                but not that of your organization</li>
                              <li>Limit your entries to the four most crucial points</li>
                            </ul>
                            <p>Your employment history should focus on the outcome
                              of your projects and also highlight your specific input.
                              Your recruiters may intend to implement something similar, and it is
                              important to know that you can deliver as opposed to just taking part.</p>
                            <h5 className={classnames('font-weight-bold', 'mt-3')}>
                              Example of Customer Service Representative
                              Experience on a Resume</h5>
                            <ul className={classnames(styles.interview_points, 'list-unstyled', 'mt-3')}>
                              <li>Jan 2017 - Dec 2020</li>
                              <li>Indonesia Commercial Bank </li>
                            </ul>
                            <h5 className={classnames('font-weight-bold', 'mt-2')}>
                              Key Qualifications &amp; Responsibilities</h5>
                            <ul className={classnames(styles.interview_points, 'mt-3')}>
                              <li>Surpassed targets by more than 30% in
                                cross-selling mobile banking products to clients</li>
                              <li>Solved customer complaints within the recommended time</li>
                              <li>Served customers within the recommended turn-around time</li>
                              <li>Received 98% positive customer survey outcome</li>
                            </ul>
                            <h5 className={classnames('font-weight-bold', 'mt-2')}>
                              How to Handle Gaps in Your Resume Work Experience Section</h5>
                            <p className={classnames('mb-2')}>Note that long-term
                              unemployment doesn&#8242;t matter for recruiters
                              if they have been followed by professional experience in your area.
                              If you have been unemployed for less than
                              nine months, it won&#8242;t affect your
                              job application&#8242;s success.
                              However, employment gaps of at least nine months
                              can ruin your success, especially for medium or low skills jobs.</p>
                            <h5 className={classnames('font-weight-bold', 'mt-2')}>
                              How to List Work Experience on a Resume</h5>
                            <p className={classnames('mb-2')}>Here are the guidelines on how to list
                              the work experience section on your resume.</p>
                            <ul className={classnames(styles.interview_points)}>
                              <li>Write the section heading in bold and caps.
                                Ensure that the heading stands out,
                                and name it with any of the titles.</li>
                              <li>Work History</li>
                              <li>Employment History</li>
                              <li>Work Experience</li>
                              <li>Experience</li>
                              <li>Place your experience section below the resume summary
                                if you have a lot of professional experience and below the
                                education section if you don’t have a work history.</li>
                              <li>Highlight job descriptions in reverse chronological orders.
                                Begin with your current or most recent jobs.</li>
                              <li>Ensure that each entry is clear and legible.
                                Include details, such as job title, employer&#8242;s name, and dates worked.</li>
                              <li>Limit your job description to 5 bullets for every job</li>
                              <li>Add a &#8243;key achievement&#8243; section.
                                Example: &#8243;Surpassed customer recruitment target by 30%.&#8243;</li>
                            </ul>
                            <h3 className={classnames(styles.resume_content_heading, 'mt-5', 'mb-3')}>
                              How to write Customer Service Representative
                              Achievements, Hobbies, Training and
                              certifications on Resume</h3>
                            <h5 className={classnames('font-weight-bold', 'mt-2')}>How to List Your
                              Achievements </h5>
                            <p className={classnames('mb-0')}>Listing the key achievements on your resume may
                              help you get a plum job. Your accomplishments
                              show employers how well you performed in
                              your previous assignment, which will make
                              you ahead of the competition.</p>
                            <h5 className={classnames('font-weight-bold', 'mt-2', styles.align)}>Examples on How
                              to Describe Customer
                              Service Representative Achievement </h5>
                            <ul className={classnames(styles.interview_points, 'mt-1')}>
                              <li>Exceeded customer recruitment target by 40%</li>
                              <li>Achieved a 50% customer retention rate,
                                which was above the company&#8242;s average</li>
                              <li>Rated the best officer in customer
                                service for five consecutive years</li>
                            </ul>
                            <h5 className={classnames('font-weight-bold', 'mt-2')}>About Hobbies on Customer Service
                              Representative Resume</h5>
                            <p className={classnames('mb-1')}>Including relevant hobbies on your resume
                              can enhance your chances of recruitment.
                              For instance, if you mention that one of your
                              hobbies is travelling, it shows employers
                              that you are suitable for jobs that require
                              regular travelling.</p>
                            <h5 className={classnames('font-weight-bold', 'mt-2')}>
                              Here are the circumstances
                              under which you can include hobbies on your resume</h5>
                            <ul className={classnames(styles.interview_points)}>
                              <li>When you have inadequate experience</li>
                              <li>The skills you have is limited or irrelevant to the job</li>
                              <li>Employer regard uniqueness among applicants</li>
                              <li>The job description aligns with your hobbies</li>
                            </ul>
                            <h5 className={classnames('mb-3', 'font-weight-bold', 'mt-2')}>
                              Examples of hobbies</h5>
                            <p>Outdoor activities,
                              Travelling, Writing, Community service, etc.</p>
                            <h5 className={classnames('font-weight-bold', 'mt-2')}>How to List
                              Certifications on a Resume</h5>
                            <p className={classnames('mb-1')}>Adding certifications to your resume
                              is a better way of confirming to
                              the hiring managers that you are relevant for the job. Certifications
                              show that you have the skills to perform the job.</p>
                            <h5 className={classnames('font-weight-bold', 'mt-3')}> Tips on How to List
                              Certifications on Your Resume</h5>
                            <ul className={classnames(styles.interview_points, 'mt-3')}>
                              <li>Never confuse certifications with online courses.
                                Certifications come from the relevant authorities after acquiring skills.
                                Online classes may not translate to certifications.</li>
                              <li>List certifications the right way.</li>
                            </ul>
                            <h5 className={classnames('font-weight-bold', 'mt-2')}>Right</h5>
                            <p className={classnames('mb-1')}>PR, Ranco Consulting Group, India</p>
                            <h5 className={classnames('font-weight-bold', 'mt-2')}>Wrong</h5>
                            <p className={classnames('ml-1', 'mb-0')}>Perfect customer service
                              certificate, Ranco Consulting Group</p>
                            <ul className={classnames(styles.interview_points)}>
                              <li>Ensure you title the section “certification.”</li>
                              <li>List the certifications in reverse-chronological order</li>
                              <li>To list certifications, highlight the following: name of the certification,
                                name of certifying authority,
                                date of certification, and location</li>
                            </ul>
                            <h5 className={classnames('font-weight-bold', 'mt-3', 'mb-3')}> Where to Place
                              Certifications on a Resume</h5>
                            <h5 className={classnames('mb-0', 'font-weight-bold', 'mt-3')}> Example</h5>
                            <ul className={classnames(styles.interview_points, 'list-unstyled', 'mt-3')}>
                              <li className={classnames('mb-0')}>Mike John, CPA</li>
                              <li className={classnames('mb-0')}>Jane Jackson, RN</li>
                              <li>
                                Certification can also be placed on the education section.</li>
                            </ul>

                            <p className={classnames('mb-0')}>If you acquired a certification course
                              through a university, you can place it as follows.</p>
                            <h5 className={classnames('font-weight-bold', 'mt-2')}>Example</h5>
                            <ul className={classnames(styles.interview_points, 'list-unstyled',
                              styles.content_list, 'mt-2')}>
                              <li className={classnames('mb-0')}>Indonesia Management School, Indonesia</li>
                              <li className={classnames('mb-0')}>Customer Management Certification 2019</li>
                              <li>Learned customer service and relationship management</li>
                            </ul>
                            <h5 className={classnames('font-weight-bold', 'mt-3', 'mb-1')}>
                              Top Professional Tips for
                              Writing a Customer Service Representative Resume</h5>
                            <p className={classnames('mt-3', 'mb-1')}>
                              Here are the tips for writing a
                              customer service representative resume</p>
                            <h5 className={classnames('font-weight-bold', 'mt-4', 'mb-2')}>
                              Make Your Goals Clear
                            </h5>
                            <p className={classnames('mb-2')}>The customer service
                              industry is dynamic, and you should,
                              therefore, know the exact role you are applying for.
                              For instance, you can apply to work in a
                              call center or serve clients in person.
                              Edit your resume to show the exact job you are looking for.</p>
                            <h5 className={classnames('font-weight-bold', 'mt-2', 'mb-2')}>
                              Use Appropriate Keywords
                            </h5>
                            <p className={classnames('mb-2')}>Jobs applied through
                              company websites or job boards are subjected
                              to an applicant tracking system (ATS) before the hiring manager reviews them.
                              The tracking system scans your resume for particular keywords to determine
                              if it matches the role you are applying. Use the right keywords
                              to enhance your recruitment chances.</p>
                            <h5 className={classnames('font-weight-bold', 'mt-2', 'mb-2')}>
                              Use Compelling Bullet points
                            </h5>
                            <p className={classnames('mb-2')}>By using
                              attractive bullet points, you will win the recruiter’s
                              attention into reading your resume, hence enhancing your
                              chances of getting shortlisted for the interview.</p>
                            <h5 className={classnames('font-weight-bold', 'mt-2', 'mb-2')}>
                              Include Measurable Metrics
                            </h5>
                            <p className={classnames('mb-2')}>Quantify your job
                              duties and include metrics to every achievement you list.
                              You can use measures, such as percentages,
                              dollar value, amount, number, etc.</p>
                            <h3 className={classnames('mt-3', 'font-weight-bold')}>Conclusion</h3>
                            <p>Composing an excellent resume can enhance your chances of recruitment.
                              Hiring managers are interested in resumes that are well-written and informative.
                              To succeed in your job search, ensure that you craft your resume based
                              on the best practices, such as using appropriate
                              keywords and measurable metrics.</p>
                          </div>
                        </Col>
                        <Col lg={4} xl={4} xs={12}>
                          <PromoCard loginWithRedirect={loginWithRedirect} />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
}
