// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".h2_title___2titM{font-family:'Mulish', sans-serif;text-align:center;font-weight:800;margin-top:0px;margin-bottom:30px}.rf_block___2vLxj{padding:40px 15px;box-shadow:0 2px 4px rgba(0,0,0,0.08),0 4px 12px rgba(0,0,0,0.16);border-top:8px solid #f39100;border-radius:8px;margin-top:20px}\n", "",{"version":3,"sources":["C:/AARWII/aarwii/Git/resumaker-web-app/public-web-app/src/components/MainContent/PaymentRefundPolicy/style.css"],"names":[],"mappings":"AAAA,kBACC,gCAAiC,CACjC,iBAAkB,CACf,eAAgB,CAChB,cAAe,CACf,kBAAmB,CACtB,kBAGG,iBAAkB,CAClB,iEAAuE,CACvE,4BAA6B,CAC7B,iBAAkB,CAClB,eAAgB","file":"style.css","sourcesContent":[".h2_title {\r\n\tfont-family: 'Mulish', sans-serif;\r\n\ttext-align: center;\r\n    font-weight: 800;\r\n    margin-top: 0px;\r\n    margin-bottom: 30px;\r\n}\r\n\r\n.rf_block {\r\n    padding: 40px 15px;\r\n    box-shadow: 0 2px 4px rgba(0, 0, 0, .08), 0 4px 12px rgba(0, 0, 0, .16);\r\n    border-top: 8px solid #f39100;\r\n    border-radius: 8px;\r\n    margin-top: 20px;\r\n}"]}]);
// Exports
exports.locals = {
	"h2_title": "h2_title___2titM",
	"h2Title": "h2_title___2titM",
	"rf_block": "rf_block___2vLxj",
	"rfBlock": "rf_block___2vLxj"
};
module.exports = exports;
