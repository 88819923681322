import React, { Component } from 'react'
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap'
import PropTypes from 'prop-types'
import cs from 'classnames'
import { get, map } from 'lodash'
import TickIcon from './TickIcon'
import GetStartedButton from '../Home/GetStartedButton'
import { SUBSCRIPTIONPLANS } from '../../../constants'
import st from './style.scss'

export default class Guided extends Component {
  static propTypes = {
    loginWithRedirect: PropTypes.func.isRequired,
  }

  render() {
    const { loginWithRedirect } = this.props
    return (
      <div className={cs(st.section)}>
        <div className={cs(st.guided_desktop)}>
          <Container>
            <Row className={cs(st.price_cards)}>
              <Col className={cs(st.col_width)} lg={4} md={4} sm={12} xl={4} xs={12}>
                <div className={cs(st.price_table, st.free_plan_align)}>
                  <h6>{get(SUBSCRIPTIONPLANS.FREE, 'name')}</h6>
                  <h3><span className={cs(st.rupee_icon)}>&#8377;
                  </span> {get(SUBSCRIPTIONPLANS.FREE, 'value')}
                    <span className={cs(st.offer_size)}>/One time Offer</span></h3>
                  <div className={cs(st.price_col_heigh)}>
                    <ul>
                      {map(SUBSCRIPTIONPLANS.FREE.features, (value, key) => (
                        <li>
                          <div>
                            <TickIcon />
                            <p> {value}</p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className={cs(st.price_center, st.button, st.btn_hover)}>
                    <GetStartedButton
                      label="Get this plan" loginWithRedirect={loginWithRedirect}
                      planId={get(SUBSCRIPTIONPLANS.FREE, 'id')} size="md" />
                  </div>
                </div>
              </Col>
              <Col className={cs(st.col_width)} lg={4} md={4} sm={12} xl={4} xs={12}>
                <div className={cs(st.price_table, st.monthly_plan)}>
                  <h6>{get(SUBSCRIPTIONPLANS.MONTHLY, 'name')}</h6>
                  <h3><span className={cs(st.rupee_icon)}>&#8377;
                  </span> {get(SUBSCRIPTIONPLANS.MONTHLY, 'value')}
                    <span className={cs(st.offer_size)}>/{get(SUBSCRIPTIONPLANS.MONTHLY, 'sub_note')}</span>
                  </h3>
                  {/* <h3> {get(SUBSCRIPTIONPLANS.MONTHLY, 'value')} </h3> */}
                  <div className={cs(st.price_col_heigh)}>
                    <ul>
                      {map(SUBSCRIPTIONPLANS.MONTHLY.features, (value, key) => (
                        <li>
                          <div>
                            <TickIcon />
                            <p> {value}</p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className={cs(st.price_center, st.button, st.btn_hover)}>
                    <GetStartedButton
                      label="Get this plan" loginWithRedirect={loginWithRedirect}
                      planId={get(SUBSCRIPTIONPLANS.MONTHLY, 'id')} size="md" />
                  </div>
                </div>
              </Col>
              <Col className={cs(st.col_width)} lg={4} md={4} sm={12} xl={4} xs={12}>
                <div className={cs(st.price_table)}>
                  <h6>{get(SUBSCRIPTIONPLANS.PAYASUGO, 'name')}</h6>
                  <h3><span className={cs(st.rupee_icon)}>&#8377;
                  </span> {get(SUBSCRIPTIONPLANS.PAYASUGO, 'value')}
                    <span className={cs(st.offer_size)}>/{get(SUBSCRIPTIONPLANS.PAYASUGO, 'sub_note')}</span>
                  </h3>
                  {/* <h3> {get(SUBSCRIPTIONPLANS.PAYASUGO, 'value')} </h3> */}
                  <div className={cs(st.price_col_heigh)}>
                    <ul>
                      {map(SUBSCRIPTIONPLANS.PAYASUGO.features, (value, key) => (
                        <li>
                          <div>
                            <TickIcon />
                            <p> {value}</p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className={cs(st.price_center, st.button, st.btn_hover)}>
                    <GetStartedButton
                      label="Get this plan" loginWithRedirect={loginWithRedirect}
                      planId={get(SUBSCRIPTIONPLANS.PAYASUGO, 'id')} size="md" />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className={cs(st.guided_mobile)}>
          <Tab.Container defaultActiveKey="first" id="left-tabs-example">
            <Row>
              <Col lg={5} md={5} sm={12} xl={5} xs={12}>
                <div className={cs(st.options_items)}>
                  <Nav className={cs(st.options_items)}>
                    <Nav.Item>
                      <Nav.Link
                        className={cs(st.plan_types, st.guided_nav_tabs)}
                        eventKey="first">{get(SUBSCRIPTIONPLANS.FREE, 'name')}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        className={cs(st.plan_types, st.guided_nav_tabs, st.monthly_tab)}
                        eventKey="second">{get(SUBSCRIPTIONPLANS.MONTHLY, 'name')}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        className={cs(st.plan_types, st.guided_nav_tabs)}
                        eventKey="third">{get(SUBSCRIPTIONPLANS.PAYASUGO, 'name')}</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </Col>
              <Col lg={7} md={7} sm={12} xl={7} xs={12}>
                <div className={cs(st.options_details)}>
                  <Tab.Content className={cs(st.options_details)}>
                    <Tab.Pane className={cs(st.tab_pane)} eventKey="first">
                      <div className={cs(st.price_table)}>
                        <h6> {get(SUBSCRIPTIONPLANS.FREE, 'name')}  </h6>
                        <h3><span className={cs(st.rupee_icon)}>&#8377;
                        </span> {get(SUBSCRIPTIONPLANS.FREE, 'value')}
                          <span className={cs(st.offer_size)}>/{get(SUBSCRIPTIONPLANS.FREE, 'sub_note')}</span></h3>
                        <div>
                          <ul>
                            {map(SUBSCRIPTIONPLANS.FREE.features, (value, key) => (
                              <li>
                                <div>
                                  <TickIcon />
                                  <p> {value}</p>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div className={cs(st.price_center, st.button, st.btn_hover)}>
                          <GetStartedButton
                            label="Get this plan" loginWithRedirect={loginWithRedirect}
                            planId={get(SUBSCRIPTIONPLANS.FREE, 'id')} size="md" />
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane className={cs(st.options_details)} eventKey="second">
                      <div className={cs(st.price_table)}>
                        <h6>{get(SUBSCRIPTIONPLANS.MONTHLY, 'name')}</h6>
                        <h3><span className={cs(st.rupee_icon)}>&#8377;
                        </span> {get(SUBSCRIPTIONPLANS.MONTHLY, 'value')}
                          <span className={cs(st.offer_size)}>/{get(SUBSCRIPTIONPLANS.MONTHLY, 'sub_note')}</span>
                        </h3>
                        {/* <h3> {get(SUBSCRIPTIONPLANS.MONTHLY, 'value')}</h3> */}
                        <div>
                          <ul>
                            {map(SUBSCRIPTIONPLANS.MONTHLY.features, (value, key) => (
                              <li>
                                <div>
                                  <TickIcon />
                                  <p> {value}</p>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div className={cs(st.price_center, st.button, st.btn_hover)}>
                          <GetStartedButton
                            label="Get this plan" loginWithRedirect={loginWithRedirect}
                            planId={get(SUBSCRIPTIONPLANS.MONTHLY, 'id')} size="md" />
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <div className={cs(st.price_table)}>
                        <h6>{get(SUBSCRIPTIONPLANS.PAYASUGO, 'name')}</h6>
                        <h3><span className={cs(st.rupee_icon)}>&#8377;
                        </span> {get(SUBSCRIPTIONPLANS.PAYASUGO, 'value')}
                          <span className={cs(st.offer_size)}>/{get(SUBSCRIPTIONPLANS.PAYASUGO, 'sub_note')}</span>
                        </h3>
                        {/* <h3> {get(SUBSCRIPTIONPLANS.PAYASUGO, 'value')}</h3> */}
                        <h6 className={cs(st.offer_size)}>/on demand payment</h6>
                        <div>
                          <ul>
                            {map(SUBSCRIPTIONPLANS.PAYASUGO.features, (value, key) => (
                              <li>
                                <div>
                                  <TickIcon />
                                  <p> {value}</p>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div className={cs(st.price_center, st.button, st.btn_hover)}>
                          <GetStartedButton
                            label="Get this plan" loginWithRedirect={loginWithRedirect}
                            planId={get(SUBSCRIPTIONPLANS.PAYASUGO, 'id')} size="md" />
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
    )
  }
}
