import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './style.scss'

class BlogDetailTemplate extends Component {
  static propTypes = {
    gotoURL: PropTypes.func.isRequired,
  }
  render() {
    const { gotoURL } = this.props
    return (
      <div className={classnames(styles.blog_details_mockup_image)}>
        <div onClick={() => gotoURL('/resume-templates')}>
          <img
            alt={'Blog'}
            src={'/assets/images/Best_resume.jpg'} />
        </div>
        <div className={classnames(styles.blog_up_title)}>
          <h5><div onClick={() => gotoURL('/resume-templates')}>Best Resume Templates</div></h5>
          <p>Browser free resume templates designed for you to download
            and customize your resume instantly. It&rsquo;s easy, the template is designed in Microsoft Word.
          </p>
        </div>
      </div>
    )
  }
}
export default withRouter(BlogDetailTemplate)
