import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { Accordion, Card, Container } from 'react-bootstrap'
import MetaTags from 'react-helmet'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import GetStartedButton from '../Home/GetStartedButton'
import { SEO } from '../../../constants/constants'
import styles from './style.scss'

class FAQuestions extends Component {
  static propTypes = {
    loginWithRedirect: PropTypes.func.isRequired,
  }

  render() {
    // const { gotoURL } = this.props
    const { loginWithRedirect } = this.props
    return (
      <Fragment>
        <MetaTags>
          <title>FAQ | Frequiently Asked Questions | Resumaker</title>
          <meta content={SEO.DESCRIPTION} name="description" />
        </MetaTags>
        <div className={classnames(styles.banner)} />
        <div className={classnames(styles.section)}>
          <Container>
            <h2 className={classnames(styles.h2_title)}> FREQUENTLY ASKED QUESTIONS </h2>
          </Container>
          <Container>
            <Accordion defaultActiveKey="0">
              <Card className={classnames(styles.faq_card_shadow)}>
                <Accordion.Toggle as={Card.Header} className={classnames(styles.faq_card_header)} eventKey="14">
                  <h4 className={classnames(styles.h4_title)}>
                    Why my Resume is not getting shortlisted?
                  </h4>
                </Accordion.Toggle>
                <div className={classnames(styles.h4_faq_body)}>
                  <Accordion.Collapse eventKey="14">
                    <div className={classnames(styles.faq_content_accord)}>
                      Your resume is not just the source of information about
                      you to a recruiter but much more. Common reasons for not
                      getting shortlisted are unmatched cover letter and resume,
                      unclear resume summary, unorganized Resume section,
                      different font sizes, unnecessary details etc.
                    </div>
                  </Accordion.Collapse>
                </div>
              </Card>
              <Card className={classnames(styles.faq_card_shadow)}>
                <Accordion.Toggle as={Card.Header} className={classnames(styles.faq_card_header)} eventKey="15">
                  <h4 className={classnames(styles.h4_title)}>
                    Why am I not getting interview calls?
                  </h4>
                </Accordion.Toggle>
                <div className={classnames(styles.h4_faq_body)}>
                  <Accordion.Collapse eventKey="15">
                    <div className={classnames(styles.faq_content_accord)}>
                      The very basic reason for not getting interview calls
                      is that your resume is not matching with the job description
                      or skillsets asked by the recruiters. Every job role has a skillset,
                      and if your Resume is not updated or tailored according to
                      job roles/description, your chances are very minimum to get the interview calls.
                    </div>
                  </Accordion.Collapse>
                </div>
              </Card>
              <Card className={classnames(styles.faq_card_shadow)}>
                <Accordion.Toggle as={Card.Header} className={classnames(styles.faq_card_header)} eventKey="17">
                  <h4 className={classnames(styles.h4_title)}>
                    I am for XYZ industry, is Resumaker Expert Writer is expert in my profession writings?
                  </h4>
                </Accordion.Toggle>
                <div className={classnames(styles.h4_faq_body)}>
                  <Accordion.Collapse eventKey="17">
                    <div className={classnames(styles.faq_content_accord)}>
                      Yes, we have experts from a different professional
                      background with good hands-on experiences who have
                      written hundreds of resumes. We believe in excellence.
                    </div>
                  </Accordion.Collapse>
                </div>
              </Card>
              <Card className={classnames(styles.faq_card_shadow)} id="1">
                <Accordion.Toggle as={Card.Header} className={classnames(styles.faq_card_header)} eventKey="2">
                  <h4 className={classnames(styles.h4_title)}>
                    Which is the best Resume Template ?
                  </h4>
                </Accordion.Toggle>
                <div className={classnames(styles.h4_faq_body)}>
                  <Accordion.Collapse eventKey="2">
                    <div className={classnames(styles.faq_content_accord)}>
                      When Resumaker templates are designed, we have handpicked the most appealing designs in every
                      reader&#39;s view. To create a single template our team reviews dozens of prototypes before
                      making the final selection. However, every individual&#39;s choice is unique, so we recommend you
                      to select 3 templates, then generate your resume in a matter of 2-3 minutes. Thus, you can
                      compare and pick one. Remember, one&#39;s profile content may look rich in one template compare
                      to another. But the final choice is yours.
                    </div>
                  </Accordion.Collapse>
                </div>
              </Card>
              <Card className={classnames(styles.faq_card_shadow)}>
                <Accordion.Toggle as={Card.Header} className={classnames(styles.faq_card_header)} eventKey="11">
                  <h4 className={classnames(styles.h4_title)}>
                    What is ATS and How Resumaker Expert handle this?
                  </h4>
                </Accordion.Toggle>
                <div className={classnames(styles.h4_faq_body)}>
                  <Accordion.Collapse eventKey="11">
                    <div className={classnames(styles.faq_content_accord)}>
                      Yes, we have Professional Resume Writers with hand-on
                      experience in creating ATS friendly Resume, they hold
                      good knowledge about the tools, AL and ML trends easily
                      filter-out routing resume. Our Experts do proper research
                      on each profile to make candidate profile unique and ATS friendly.
                      Visit our website to learn more.
                    </div>
                  </Accordion.Collapse>
                </div>
              </Card>
              <Card className={classnames(styles.faq_card_shadow)}>
                <Accordion.Toggle as={Card.Header} className={classnames(styles.faq_card_header)} eventKey="8">
                  <h4 className={classnames(styles.h4_title)}>
                    Why should I go for paid Resume Writing Service,
                    free tools are available for creating a resume?
                  </h4>
                </Accordion.Toggle>
                <div className={classnames(styles.h4_faq_body)}>
                  <Accordion.Collapse eventKey="8">
                    <div className={classnames(styles.faq_content_accord)}>
                      The difference between a tool and an expert is that
                      tool will help you getting Resume in a template, but it
                      will not highlight your key skills, it may not refine your
                      details that catches high attention from the recruiters.
                      A resume is your profile, what matters most is &#8243;how it is written, what
                      message it carries than in which template it is printed&#8243;... So, Expert Writers
                      understand your requirement then finds best skills and qualities that match
                      your requirement in a most suitable template. A tool can&#8242;t bring experience
                      what Expert Resume Writers perceive. Visit our website to learn more.
                    </div>
                  </Accordion.Collapse>
                </div>
              </Card>
              <Card className={classnames(styles.faq_card_shadow)}>
                <Accordion.Toggle as={Card.Header} className={classnames(styles.faq_card_header)} eventKey="5">
                  <h4 className={classnames(styles.h4_title)}>
                    Why approach a Professional Resume Writer ?
                  </h4>
                </Accordion.Toggle>
                <div className={classnames(styles.h4_faq_body)}>
                  <Accordion.Collapse eventKey="5">
                    <div className={classnames(styles.faq_content_accord)}>
                      Resume writing is not everyone&#39;s job. Professional resume writers know the best ways of
                      creating impressive resumes which can attract the attention of a potential employer also leads
                      to call for an interview. The resumes crafted by expert writers will enhance the chances of your
                      interview call. So, don&#39;t take a chance for your dream job.
                    </div>
                  </Accordion.Collapse>
                </div>
              </Card>
              <Card className={classnames(styles.faq_card_shadow)}>
                <Accordion.Toggle as={Card.Header} className={classnames(styles.faq_card_header)} eventKey="4">
                  <h4 className={classnames(styles.h4_title)}>
                    Can you write or review my resume ?
                  </h4>
                </Accordion.Toggle>
                <div className={classnames(styles.h4_faq_body)}>
                  <Accordion.Collapse eventKey="4">
                    <div className={classnames(styles.faq_content_accord)}>
                      Yes, of course, we do. You can engage with us, our team will review your resume when require they
                      can prepare the best professional resume for you.
                    </div>
                  </Accordion.Collapse>
                </div>
              </Card>
              <Card className={classnames(styles.faq_card_shadow)}>
                <Accordion.Toggle as={Card.Header} className={classnames(styles.faq_card_header)} eventKey="3">
                  <h4 className={classnames(styles.h4_title)}>
                    How long will Resumaker take to write my resume ?
                  </h4>
                </Accordion.Toggle>
                <div className={classnames(styles.h4_faq_body)}>
                  <Accordion.Collapse eventKey="3">
                    <div className={classnames(styles.faq_content_accord)}>
                      Within 5 working days (Monday to Friday, except public holidays in India) you will receive draft
                      content and scope of improvements for further inputs from you. If the resume is of type
                      infographic there are possibilities for multiple revisions.
                      In nutshell, textual resumes are delivered
                      in 10 working days whereas infographic takes 10-15 days.
                    </div>
                  </Accordion.Collapse>
                </div>
              </Card>
              <Card className={classnames(styles.faq_card_shadow)}>
                <Accordion.Toggle as={Card.Header} className={classnames(styles.faq_card_header)} eventKey="1">
                  <h4 className={classnames(styles.h4_title)}>
                    How is Resumaker different from other services ?
                  </h4>
                </Accordion.Toggle>
                <div className={classnames(styles.h4_faq_body)}>
                  <Accordion.Collapse eventKey="1">
                    <div className={classnames(styles.faq_content_accord)}>
                      The backbone of Resumaker team from fortune 100 companies. Resumaker principles guide our team
                      providing users with quality. Our primary motto is identifying the potential capabilities of
                      users, project those into eye-catching content. We try to present one&#39;s profile on a single
                      page or two so that the employer can go through a resume as complete as possible. This will help
                      the user convey about him/her effectively. When it comes to our infographic resumes we present
                      multi-dimensional attributes into impressive graphical content. This will simplify reader&#39;s
                      ability to catch buzz words with facts so the resume will stand out.
                    </div>
                  </Accordion.Collapse>
                </div>
              </Card>
              <Card className={classnames(styles.faq_card_shadow)}>
                <Accordion.Toggle as={Card.Header} className={classnames(styles.faq_card_header)} eventKey="9">
                  <h4 className={classnames(styles.h4_title)}>
                    What is an Expert Resume Writing Service?
                  </h4>
                </Accordion.Toggle>
                <div className={classnames(styles.h4_faq_body)}>
                  <Accordion.Collapse eventKey="9">
                    <div className={classnames(styles.faq_content_accord)}>
                      Our Resume Expert Service is designed to cover all aspects for a
                      successful job search. Our Expert Resume Writers are highly trained
                      professionals from various areas and best in the presentation.
                      They have experience in writing profiles for many aspiring candidates
                      who benefited from our service.

                      Expert Resume Writers perform research along with details
                      collected from you; they browse through many job postings to
                      understand the requirement from the many companies, they get
                      the tone of recruiters, understand the ATS (Application Tracking Systems)
                      and finally produce content which grabs high attention from the ATS to
                      elevate the chances of interview call. The Expert Writer creates
                      a unique resume for you.
                    </div>
                  </Accordion.Collapse>
                </div>
              </Card>
              <Card className={classnames(styles.faq_card_shadow)}>
                <Accordion.Toggle as={Card.Header} className={classnames(styles.faq_card_header)} eventKey="10">
                  <h4 className={classnames(styles.h4_title)}>
                    What are the chances to get interview calls after
                    having a resume from Resumaker Expert Service?
                  </h4>
                </Accordion.Toggle>
                <div className={classnames(styles.h4_faq_body)}>
                  <Accordion.Collapse eventKey="10">
                    <div className={classnames(styles.faq_content_accord)}>
                      If you have an impressive and well- written Resume, the chances
                      of getting interview calls is high. The first impression of recruiter
                      or interviewer is your well-designed resume that grabs immediate
                      attention and paves paths for a smooth and successful interview.
                      Our Expert Writers provide you with valuable inputs such as
                      highlight in your strengths, possible interview questions
                      that you will be asked and many more tips. Visit our website to learn more.
                    </div>
                  </Accordion.Collapse>
                </div>
              </Card>
              <Card className={classnames(styles.faq_card_shadow)}>
                <Accordion.Toggle as={Card.Header} className={classnames(styles.faq_card_header)} eventKey="16">
                  <h4 className={classnames(styles.h4_title)}>
                    Why is expert service expensive?
                  </h4>
                </Accordion.Toggle>
                <div className={classnames(styles.h4_faq_body)}>
                  <Accordion.Collapse eventKey="16">
                    <div className={classnames(styles.faq_content_accord)}>
                      We not only create a resume, but we also work with our
                      client to make this process more successful.
                      We work closely with our client until his/her satisfaction.
                      We do our client&#8242;s SWOT and help them develop a successful career.
                    </div>
                  </Accordion.Collapse>
                </div>
              </Card>
              <Card className={classnames(styles.faq_card_shadow)}>
                <Accordion.Toggle as={Card.Header} className={classnames(styles.faq_card_header)} eventKey="12">
                  <h4 className={classnames(styles.h4_title)}>
                    Is Resumaker Expert guarantee to get hired?
                  </h4>
                </Accordion.Toggle>
                <div className={classnames(styles.h4_faq_body)}>
                  <Accordion.Collapse eventKey="12">
                    <div className={classnames(styles.faq_content_accord)}>
                      Of course not! No one can &#8243;guarantee you a job&#8243;.
                      Our Experts Writes Resume based on the candidate&#8242;s information along
                      with research they carry.
                      A good resume will lead to an interview call and influence the interview; getting
                      hired is depends on the candidate performance and hiring company decisions.
                      Visit our website to learn more.
                    </div>
                  </Accordion.Collapse>
                </div>
              </Card>
            </Accordion>
          </Container>
          <br />
          <br />
          <Container>
            <h2 className={classnames(styles.faq_footer_title)}> Start using Resumaker today </h2>
            <div className={classnames(styles.btn_center)}>
              <GetStartedButton label="Get Started" loginWithRedirect={loginWithRedirect} size="lg" />
            </div>
          </Container>
          <br />
        </div>
      </Fragment>
    )
  }
}

export default withRouter(FAQuestions)
