const dataUrl = process.env.DATA_URL
const dashboardHost = process.env.DASHBOARD_HOST
const frontEndUrl = 'https://www.resumaker.co.in'
const resourcesUrl = 'https://resources.resumaker.co.in'

export const AppConstants = {
  dataUrl,
  resourcesUrl,
  dashboardHost,
  frontEndUrl,
}
