import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Router } from 'react-router-dom'
import { toJS, observe } from 'mobx'
import { inject, observer } from 'mobx-react'
import { get } from 'lodash'
import { createBrowserHistory as createHistory } from 'history'

import { sendGoogleAnalytics } from '../../constants'
import { LOGIN_ROUTE, PublicRouter } from '../../router'
import { Private } from './Private'
import { apiResponseCode } from '../../services'
import './appmain.scss'

const history = createHistory()

@inject('applicationModel')
@observer
export default class AppContainer extends Component {
  constructor(props) {
    super(props)
    this.url = window.location.href
    this.state = {
      initialLoad: false,
    }
  }

  componentDidMount() {
    const { initialLoad } = this.state
    observe(apiResponseCode, change => {
      const newValue = toJS(change.newValue)
      const code = get(newValue, 'code')
      if (code === 401 || code === 403) {
        this.props.applicationModel.loginStatus(false)
        history.push(LOGIN_ROUTE)
      }
    })
    this.changeDocumentTitle()
    if (!initialLoad){
      if (this.props.location){
        sendGoogleAnalytics(this.props.location.pathname)
      }
      this.setState({
        initialLoad: true,
      })
    }
  }

  componentDidCatch(error, errorInfo) {
    console.error('AppContainer componentDidCatch', errorInfo, error)
  }

  changeDocumentTitle() {
    document.title = 'Resumaker'
  }

  render() {
    const { applicationModel } = this.props
    const isLogin = applicationModel.getLogin()
    return (
      <Router history={history}>
        {!isLogin && <PublicRouter />}
        {isLogin && <Private isValidRedirection />}
      </Router>
    )
  }
}

AppContainer.propTypes = {
  applicationModel: PropTypes.object.isRequired,
}
