import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Col, Button, Container, Row } from 'react-bootstrap'
import { validateEmail } from '../../constants'
import Footer from './Footer'
import styles from './style.scss'

class SubmitEmail extends Component {
  static propTypes = {
    getPasswordResetToken: PropTypes.func.isRequired,
    openURL: PropTypes.func.isRequired,
  }

  constructor(props){
    super(props)
    this.state = {
      email: '',
      validEmail: false,
      emailError: '',
    }
  }

  setEmail = (email) => {
    this.setState({
      email,
    })
  }

  getPasswordResetToken = () => {
    const { email, emailError } = this.state
    const { getPasswordResetToken } = this.props
    const emailValid = validateEmail(email)
    let errorMessage = ''
    let isValid = true
    if (!emailValid){
      errorMessage = 'Please enter valid email'
      isValid = false
    }
    if (emailError !== errorMessage){
      this.setState({
        emailError: errorMessage,
      })
    }
    if (isValid){
      getPasswordResetToken({ email })
    }
  }

  render(){
    const { email, validEmail, emailError } = this.state
    const { openURL } = this.props
    return (
      <Fragment>
        <Container fluid>
          <Row>
            <Col lg={6} md={6} sm={12} xl={6} xs={12}>
              <div className={classnames(styles.welcome_bg, styles.forgot_password_screen)} />
            </Col>
            <Col lg={6} md={6} sm={12} xl={6} xs={12}>
              <div className={classnames(styles.welcome_next_box)}>
                <div className={classnames(styles.welcome)}>
                  <div className={classnames(styles.login_title)}>
                    <div
                      className={classnames(styles.show_cursor)}
                      onClick={() => openURL('/')}>
                      <img alt={'logo'} className={classnames(styles.logo_img_login)} src={'assets/images/logo.svg'} />
                    </div>
                    <span> Forgot Password ? </span>
                  </div>
                  <div className={classnames(styles.welcome_back_title)}>
                    Please enter your email
                  </div>
                  <div>
                    <input
                      className={classnames(styles.login_input_forgot)} id="email" name="email"
                      onChange={(e) => this.setEmail(e.target.value)}
                      placeholder="Enter your email address here" type="email"
                      value={email} />
                    {!validEmail && (
                      <div className={classnames(styles.alert_email)}>
                        {emailError}
                      </div>
                    )}
                    <Button
                      className={classnames(styles.btn_loginpage_submit)}
                      onClick={this.getPasswordResetToken} type="submit"> Submit </Button>
                  </div>
                </div>
              </div>
            </Col>
            <Footer openURL={openURL} />
          </Row>
        </Container>
      </Fragment>
    )
  }
}
export default withRouter(SubmitEmail)
