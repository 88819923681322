import React, { Component } from 'react'
import cs from 'classnames'
import PropTypes from 'prop-types'
// import { Icon, IconType } from '../../common'
import st from './style.scss'

export default class GetStartedButton extends Component {
  static propTypes = {
    gotoURL: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    loginWithRedirect: PropTypes.func.isRequired,
    planId: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
  }

  fetchClassSet = (style, additionalMeta) => {
    const d = []
    if (additionalMeta && additionalMeta.length > 0){
      additionalMeta.map((m, i) => d.push(style[m]))
    }
    return cs(d)
  }

  render(){
    const { label, loginWithRedirect, size, planId } = this.props
    // const { THINRIGHTARROW } = IconType.DIRECTION
    return (
      <div
        className={cs(st.get_started_btn, this.fetchClassSet(st, [size]))}
        onClick={() => loginWithRedirect({
          redirectTo: '/dashboard/create', planId })}>
        {label}
        {/* <Icon
          className={cs(st.icon_left)} color={'#f1f1f1'} margin-left={'55px'}
          names={[THINRIGHTARROW]} viewHeight={'30'} viewWidth={'30'} /> */}
      </div>
    )
  }
}
