import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { OAUTH_ROUTE, OAuthRoutes, MainRoutes } from '.'

export const APP_ROUTE_NAME = 'Home'
export const PAGE_NOT_FOUND = '/404'

export const AppRoutes = p => (
  <Switch>
    <Route component={OAuthRoutes} path={[OAUTH_ROUTE]} />
    <Route component={MainRoutes} path="*" />
  </Switch>
)
