import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import cs from 'classnames'
import MetaTags from 'react-helmet'
import { Container, Row, Col, Accordion, Card } from 'react-bootstrap'
import HomeTemplates from '../../MainContent/Home/Templates'
import { Icon, IconType } from '../../common'
import st from './style.scss'

class Templates extends Component {
  static propTypes = {
    gotoURL: PropTypes.func.isRequired,
    loginWithRedirect: PropTypes.func.isRequired,
  }

  render() {
    const { loginWithRedirect } = this.props
    const { CHEVRONDOWN } = IconType.DIRECTION
    return (
      <Fragment>
        <MetaTags>
          <title>Templates | Resume format for freshers | format resume easily</title>
          <meta content={'Best Resume templates | CV formats | Free Resume Builder'} name="description" />
          <link href={'https://www.resumaker.co.in/resume-templates'} rel="canonical" />
        </MetaTags>
        <section className={cs(st.template_hero)}>
          <Container className={cs(st.cc_fluid_pp)} fluid>
            <Row>
              <Col className={cs(st.hero_content)} lg={6} md={12} sm={12} xl={6} xs={12}>
                <div>
                  <h1> Build your personal brand just in 15 minutes.</h1>
                  <p> With sleek designs and an easy to use online tool allows you to quickly
                    build and edit a professional resume with a personal touch. </p>
                  <div
                    className={cs(st.button, st.btn_hover)}
                    onClick={() => this.props.loginWithRedirect({ redirectTo: '/dashboard/create' })}>
                    Get Started
                  </div>
                </div>
              </Col>
              <Col lg={6} md={12} sm={12} xl={6} xs={12}>
                <div className={cs(st.desktop)}>
                  <img alt="start" className={cs(st.img_fluid)} src={'/assets/images/template_image.png'} />
                </div>
                <div className={cs(st.mobile)}>
                  <img alt="start" className={cs(st.img_fluid)} src={'/assets/images/mob_template_image.png'} />
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className={cs(st.templates_slider)}>
          <Container className={cs(st.cc_fluid_pp)} fluid>
            {/* DONT NOT DELETE THIS LINES  */}
            {/* <div className={cs(st.templates_pods)}>
              <span> Free Templates</span>
              <span> Mid Level Templates</span>
			<span> Free Templates</span>
            </div> */}
            <div className={cs(st.templates_title)}>
              Resume Templates
            </div>
            <Row>
              <HomeTemplates loginWithRedirect={loginWithRedirect} />
            </Row>
          </Container>
        </section>

        <section className={cs(st.resume_resource)}>
          <Container className={cs(st.cc_fluid_pp)} fluid>
            <h2> About your Resume </h2>
            <Row>
              <div className={cs(st.grid_flex)}>
                <div>
                  <img alt="step 01" className={cs(st.resource_number)} src={'/assets/images/faq_img_1.png'} />
                </div>
                <div className={cs(st.grid_flex_content)}>
                  <h3> What is the best way to start a resume? </h3>
                  <p> Begin by identifying your current or most recent job, followed by any past work experience.
                    Include your skills, handled projects, education details,
                    and any other relevant information like certifications, volunteer work, student activities,
                    or courses after your job experience section.
                    With Resumaker all these details are taken care, you got to provide the details as per template.</p>
                </div>
              </div>
            </Row>

            <Row>
              <div className={cs(st.grid_flex, st.flex_direction)}>
                <div>
                  <img alt="step 01" className={cs(st.resource_number)} src={'/assets/images/faq_img_1.png'} />
                </div>
                <div className={cs(st.grid_flex_content)}>
                  <h3> Should I use a resume template or build from scratch? </h3>
                  <p> It&apos;s better to use resume templates as it saves much time makes life easy.
                    Using Resumaker easily switch between multiple templates with the same resume content.</p>
                </div>

              </div>
            </Row>

            <Row>
              <div className={cs(st.grid_flex)}>
                <div>
                  <img alt="step 01" className={cs(st.resource_number)} src={'/assets/images/faq_img_1.png'} />
                </div>
                <div className={cs(st.grid_flex_content)}>
                  <h3> Can Resume Builder Help Me If I’m New To The Field? </h3>
                  <p> Yes! Our Resume Builder is the perfect resume maker tool
                    with self-explanatory interface. All the new and entry-level job seekers can use it.
                    Additionally, we have tips, suggestions and recommendations while you create resume so that you
                    can easily make your resume on our site.</p>
                </div>
              </div>
            </Row>

            <Row>
              <div className={cs(st.grid_flex, st.flex_direction)}>
                <div>
                  <img alt="step 01" className={cs(st.resource_number)} src={'/assets/images/faq_img_1.png'} />
                </div>
                <div className={cs(st.grid_flex_content)}>
                  <h3>How To Create a Perfect Resume </h3>
                  <p> If your Resume isn’t gathering enough calls for interviews,
                    it maybe the time for creating a new Resume.
                    Resume should be well formatted and readable without any mistakes.
                    This will help the interviewer to read and get to know about your skillset and abilities.
                    Next, your resume should have matching skillset for the job you are applying for.
                    Same resume may not serve the purpose for multiple job opportunities.</p>

                </div>
              </div>
            </Row>

          </Container>
        </section>

        <section className={cs(st.testimonial_section)}>
          <Container className={cs(st.cc_fluid_pp)} fluid>
            <h2> Don&lsquo;t take our word for it. </h2>
            <Row className={cs(st.testimonial_height)}>
              <Col lg={3} md={12} sm={12} xl={3} xs={12}>
                <div className={cs(st.textimonial_box)}>
                  <div className={cs(st.textimonial_box_image)}>
                    <div>
                      <img alt="step 01" src={'/assets/images/avatar_boy.png'} />
                    </div>
                    <div className={cs(st.textimonial_box_title)}>
                      <h5> Madhur Kadilaya </h5>
                      <h6> Cyber Security, Australia </h6>
                    </div>
                  </div>
                  <p> After using Resumaker for the first, I was thrilled to receive
                    a positive callback from the target organization. </p>
                </div>
                <div className={cs(st.textimonial_box)}>
                  <div className={cs(st.textimonial_box_image)}>
                    <div>
                      <img alt="step 01" src={'/assets/images/avatar_girl.png'} />
                    </div>
                    <div className={cs(st.textimonial_box_title)}>
                      <h5> Akanksha Singh </h5>
                      <h6> Digital Media </h6>
                    </div>
                  </div>
                  <p>  Unique performance is what I admire most about Resumaker.
                    They helped me get a top position at the world-best engineering firm. </p>
                </div>
                <div className={cs(st.textimonial_box)}>
                  <div className={cs(st.textimonial_box_image)}>
                    <div>
                      <img alt="step 01" src={'/assets/images/avatar_boy.png'} />
                    </div>
                    <div className={cs(st.textimonial_box_title)}>
                      <h5> Akhil Sharma </h5>
                      <h6> Final Year Student </h6>
                    </div>
                  </div>
                  <p> Very good tool for anyone to create Resume in few steps.. Highly recommended. </p>
                </div>
                {/* <div className={cs(st.textimonial_box)}>
                  <div className={cs(st.textimonial_box_image)}>
                    <div>
                      <img alt="step 01" src={'/assets/images/avatar_girl.png'} />
                    </div>
                    <div className={cs(st.textimonial_box_title)}>
                      <h5> Jamnesh Sinh </h5>
                      <h6> Operations Manager </h6>
                    </div>
                  </div>
                  <p> Resumaker team created impressive resume for me, I got interivew call with-in
                    a week after updating my resume in job portal.
                    Interview went smooth and my current pay is much higher. </p>
                </div> */}
              </Col>
              <Col lg={3} md={12} sm={12} xl={3} xs={12}>
                <div className={cs(st.textimonial_box)}>
                  <div className={cs(st.textimonial_box_image)}>
                    <div>
                      <img alt="step 01" src={'/assets/images/avatar_boy.png'} />
                    </div>
                    <div className={cs(st.textimonial_box_title)}>
                      <h5> Jamnesh Sinh </h5>
                      <h6> Operations Manager </h6>
                    </div>
                  </div>
                  <p> Resumaker team created impressive resume for me, I got interivew call with-in
                    a week after updating my resume in job portal.
                    Interview went smooth and my current pay is much higher. </p>
                </div>
                <div className={cs(st.textimonial_box)}>
                  <div className={cs(st.textimonial_box_image)}>
                    <div>
                      <img alt="step 01" src={'/assets/images/avatar_uni.png'} />
                    </div>
                    <div className={cs(st.textimonial_box_title)}>
                      <h5> Adam Roy </h5>
                      <h6> Account Executive </h6>
                    </div>
                  </div>
                  <p> It is conducive while writing a resume.
                    It suggested some valuable recommendations according to the job role
                    I was applying to the company. And it is straightforward to use.
                    This is the first time I have created a resume for a job application,
                    but this tool was helpful and guided while writing a resume. Thank you!
                  </p>
                </div>
              </Col>
              <Col lg={3} md={12} sm={12} xl={3} xs={12}>
                <div className={cs(st.textimonial_box)}>
                  <div className={cs(st.textimonial_box_image)}>
                    <div>
                      <img alt="step 01" src={'/assets/images/avatar_girl.png'} />
                    </div>
                    <div className={cs(st.textimonial_box_title)}>
                      <h5> Gopi Krishna </h5>
                      <h6> MFSc, Cardiff, UK</h6>
                    </div>
                  </div>
                  <p> I got the recommendation from my friend about Resumaker expert service.
                    Very happy with the service. Highly recommended. </p>
                </div>
                <div className={cs(st.textimonial_box)}>
                  <div className={cs(st.textimonial_box_image)}>
                    <div>
                      <img alt="step 01" src={'/assets/images/avatar_boy.png'} />
                    </div>
                    <div className={cs(st.textimonial_box_title)}>
                      <h5> Gowtham Verma </h5>
                      <h6> Food Science, Cardiff, UK </h6>
                    </div>
                  </div>
                  <p> I approached Resumaker experts for my academics resume.
                    I also used this for applying to internships
                    (in grad school) and got it. I highly recommend it. </p>
                </div>
                <div className={cs(st.textimonial_box)}>
                  <div className={cs(st.textimonial_box_image)}>
                    <div>
                      <img alt="step 01" src={'/assets/images/avatar_girl.png'} />
                    </div>
                    <div className={cs(st.textimonial_box_title)}>
                      <h5> Tejaswini Sarkar </h5>
                      <h6> Financial Analyst </h6>
                    </div>
                  </div>
                  <p> It&lsquo;s a nice platform for the people to choose the best suitable reume template. </p>
                </div>
              </Col>
              <Col lg={3} md={12} sm={12} xl={3} xs={12}>
                <div className={cs(st.textimonial_box)}>
                  <div className={cs(st.textimonial_box_image)}>
                    <div>
                      <img alt="step 01" src={'/assets/images/avatar_uni.png'} />
                    </div>
                    <div className={cs(st.textimonial_box_title)}>
                      <h5> Tanay Vidhate </h5>
                      <h6> Student </h6>
                    </div>
                  </div>
                  <p> It is effortless and very easy to use. As a student having no experience in writing resumes,
                    I built a resume quickly.</p>
                </div>
                <div className={cs(st.textimonial_box)}>
                  <div className={cs(st.textimonial_box_image)}>
                    <div>
                      <img alt="step 01" src={'/assets/images/avatar_boy.png'} />
                    </div>
                    <div className={cs(st.textimonial_box_title)}>
                      <h5> Teja Srinivas </h5>
                      <h6> Software Engineer </h6>
                    </div>
                  </div>
                  <p> I was looking for a job, and I wanted to create a good resume
                    had no idea what to do at that point until
                    I came across Resumaker, searched template,
                    and I jumped to Guided Tool, and I was happy at how my resume
                    came out beautifully and received an interview call. Just amazing!</p>
                </div>

              </Col>
            </Row>
          </Container>
        </section>

        <section className={cs(st.footer_faq)}>
          <Container>
            <Row>
              <Col lg={6} md={12} sm={12} xl={6} xs={12}>
                <h3> Frequently Asked Questions </h3>
                <img alt="step 01" className={cs(st.faq_women)} src={'/assets/images/faq_women.png'} />
              </Col>
              <Col lg={6} md={12} sm={12} xl={6} xs={12}>
                <div className={cs(st.accordion_box)}>
                  <Accordion>
                    <Card className={cs(st.faq_border)}>
                      <Accordion.Toggle eventKey="0">
                        <Card.Header className={cs(st.faq_card_header)}>
                          How to use Resumaker Guided Builder?
                          <Icon
                            color={'#212121'}
                            height={'30'}
                            names={[CHEVRONDOWN]}
                            viewHeight={'30'}
                            viewWidth={'30'} width={'30'} />
                        </Card.Header>
                      </Accordion.Toggle>
                      <Accordion.Collapse className={cs(st.faq_card_body)} eventKey="0">
                        <Card.Body>
                          <ol>
                            <li>
                              <h6>Pick a template</h6>
                            </li>
                            <li>
                              <h6>Fill basic information.</h6>
                            </li>
                            <li>
                              <h6>Follow instructions from Guided Builder</h6>
                            </li>
                            <li>
                              <h6>Customize the final look and feel</h6>
                            </li>
                            <li>
                              <h6>Click on generate resume to download </h6>
                            </li>
                            <h6>If you have a question while creating your resume,
                              you can watch how-to videos or contact support.</h6>
                          </ol>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
                <div className={cs(st.Accordion_box)}>
                  <Accordion>
                    <Card className={cs(st.faq_border)}>
                      <Accordion.Toggle eventKey="0">
                        <Card.Header className={cs(st.faq_card_header)}>
                          Why Do I Need Different Resumes For Every Job Application?
                          <Icon
                            color={'#212121'}
                            height={'30'}
                            names={[CHEVRONDOWN]}
                            viewHeight={'30'}
                            viewWidth={'30'} width={'30'} />
                        </Card.Header>
                      </Accordion.Toggle>
                      <Accordion.Collapse className={cs(st.faq_card_body)} eventKey="0">
                        <Card.Body>
                          Using a different resume improves the chances of being selected for
                          an interview because each company
                          requires different sets of information and skills which can&lsquo;t fit in one resume.
                          Prioritizing experience and skillset according to the
                          company needs in your resume helps recruiters call you for interview.
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
                <div className={cs(st.accordion_box)}>
                  <Accordion>
                    <Card className={cs(st.faq_border)}>
                      <Accordion.Toggle eventKey="0">
                        <Card.Header className={cs(st.faq_card_header)}>
                          What should I mention in my Resume?
                          <Icon
                            color={'#212121'}
                            height={'30'}
                            names={[CHEVRONDOWN]}
                            viewHeight={'30'}
                            viewWidth={'30'} width={'30'} />
                        </Card.Header>
                      </Accordion.Toggle>
                      <Accordion.Collapse className={cs(st.faq_card_body)} eventKey="0">
                        <Card.Body>
                          There are few mandatory attributes you should write in resume
                          <ol>
                            <li>
                              <h6>Contact Information</h6>
                            </li>
                            <li>
                              <h6>Career objective</h6>
                            </li>
                            <li>
                              <h6>Professional Summary</h6>
                            </li>
                            <li>
                              <h6>Working Experience</h6>
                            </li>
                            <li>
                              <h6>Educational Background</h6>
                            </li>
                            <li>
                              <h6>Skills</h6>
                            </li>
                            <li>
                              <h6>Certifications and Professional Memberships</h6>
                            </li>
                            <li>
                              <h6>Achievements and Awards</h6>
                            </li>
                          </ol>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
                <div className={cs(st.accordion_box)}>
                  <Accordion>
                    <Card className={cs(st.faq_border)}>
                      <Accordion.Toggle eventKey="0">
                        <Card.Header className={cs(st.faq_card_header)}>
                          How Do I Prepare My Resume For a Job Search?
                          <Icon
                            color={'#212121'}
                            height={'30'}
                            names={[CHEVRONDOWN]}
                            viewHeight={'30'}
                            viewWidth={'30'} width={'30'} />
                        </Card.Header>
                      </Accordion.Toggle>
                      <Accordion.Collapse className={cs(st.faq_card_body)} eventKey="0">
                        <Card.Body>
                          To qualify for a particular job the process starts with a perfect resume.
                          To prepare an ideal resume,
                          you must include valid and engaging information.
                          The main highlights you resume should consist of are personal/contact information,
                          objective, education, work and related experience, awards and honours,
                          activities/hobbies, skills, and references (if any).
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
                <div className={cs(st.accordion_box)}>
                  <Accordion>
                    <Card className={cs(st.faq_border)}>
                      <Accordion.Toggle eventKey="0">
                        <Card.Header className={cs(st.faq_card_header)}>
                          Is It Okay To Submit a Handwritten Resume?
                          <Icon
                            color={'#212121'}
                            height={'30'}
                            names={[CHEVRONDOWN]}
                            viewHeight={'30'}
                            viewWidth={'30'} width={'30'} />
                        </Card.Header>
                      </Accordion.Toggle>
                      <Accordion.Collapse className={cs(st.faq_card_body)} eventKey="0">
                        <Card.Body>
                          No, you cannot write your resume by hand
                          because most resumes are submitted online and scanned
                          by applicant tracking systems (ATS).
                          If your application is handwritten, ATS will quickly reject it.
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
                <div className={cs(st.accordion_box)}>
                  <Accordion>
                    <Card className={cs(st.faq_border)}>
                      <Accordion.Toggle eventKey="0">
                        <Card.Header className={cs(st.faq_card_header)}>
                          Do You Create ATS Resume?
                          <Icon
                            color={'#212121'}
                            height={'30'}
                            names={[CHEVRONDOWN]}
                            viewHeight={'30'}
                            viewWidth={'30'} width={'30'} />
                        </Card.Header>
                      </Accordion.Toggle>
                      <Accordion.Collapse className={cs(st.faq_card_body)} eventKey="0">
                        <Card.Body>
                          Yes, all our resumes are compatible with most
                          of the Applicant Tracking Systems on the market today.
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
                <div className={cs(st.accordion_box)}>
                  <Accordion>
                    <Card className={cs(st.faq_border)}>
                      <Accordion.Toggle eventKey="0">
                        <Card.Header className={cs(st.faq_card_header)}>
                          Can You Re-Edit My Resume? I Already Have One.
                          <Icon
                            color={'#212121'}
                            height={'30'}
                            names={[CHEVRONDOWN]}
                            viewHeight={'30'}
                            viewWidth={'30'} width={'30'} />
                        </Card.Header>
                      </Accordion.Toggle>
                      <Accordion.Collapse className={cs(st.faq_card_body)} eventKey="0">
                        <Card.Body>
                          Yes, to re-edit your resume, you will need help from our resume writing expert,
                          so it becomes easy to understand which parts you want to change or add.
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
                <div className={cs(st.accordion_box)}>
                  <Accordion>
                    <Card className={cs(st.faq_border)}>
                      <Accordion.Toggle eventKey="0">
                        <Card.Header className={cs(st.faq_card_header)}>
                          Is It Worth Using The Resumaker Builder Tool?
                          <Icon
                            color={'#212121'}
                            height={'30'}
                            names={[CHEVRONDOWN]}
                            viewHeight={'30'}
                            viewWidth={'30'} width={'30'} />
                        </Card.Header>
                      </Accordion.Toggle>
                      <Accordion.Collapse className={cs(st.faq_card_body)} eventKey="0">
                        <Card.Body>
                          Resumaker makes resume writing pain-free, simple, and fast.
                          Our tool is designed to help anyone from any
                          industry build a professional resume in 10 minutes or less.
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
                <div className={cs(st.accordion_box)}>
                  <Accordion>
                    <Card className={cs(st.faq_border)}>
                      <Accordion.Toggle eventKey="0">
                        <Card.Header className={cs(st.faq_card_header)}>
                          How Resumaker Is The Best Free Resume Builder Online?
                          <Icon
                            color={'#212121'}
                            height={'30'}
                            names={[CHEVRONDOWN]}
                            viewHeight={'30'}
                            viewWidth={'30'} width={'30'} />
                        </Card.Header>
                      </Accordion.Toggle>
                      <Accordion.Collapse className={cs(st.faq_card_body)} eventKey="0">
                        <Card.Body>
                          The backbone of Resumaker is it&lsquo;s team. The team is from fortune 100 companies.
                          The team has worked and idenfied all the elements to craft a perfect resume for
                          current industry standards.
                          Resumaker offers templates with wide range of suggestions that helps interviewer
                          identify your capabilities.
                          we support you
                          via our expert channel, so you approach an interview well prepared.
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

      </Fragment>
    )
  }
}
export default withRouter(Templates)
