import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { inject } from 'mobx-react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './style.scss'

@inject('mainModel')
class BlogTags extends Component {
static propTypes = {
  filterBy: PropTypes.func.isRequired,
  mainModel: PropTypes.object.isRequired,
}

render() {
  return (
    <div>
      <div className={classnames(styles.siebar_title)}>
        <h3> Tags </h3>
      </div>
      <div className={classnames(styles.blog_tags)}>
        <div onClick={() => this.props.filterBy('tag', 'Job')}>Job</div>
        <div onClick={() => this.props.filterBy('tag', 'Successful')}>Successful</div>
        <div onClick={() => this.props.filterBy('tag', 'Tips')}>Tips</div>
        <div onClick={() => this.props.filterBy('tag', 'Technology')}>Technology</div>
        <div onClick={() => this.props.filterBy('tag', 'Marketing')}>Marketing</div>
      </div>
    </div>
  )
}
}
export default withRouter(BlogTags)
