import React, { Component, Fragment } from 'react'
import cs from 'classnames'
import { Container, Row, Col, Carousel } from 'react-bootstrap'
import PropTypes from 'prop-types'
import st from './style.scss'

export default class FlowExplainer extends Component{
static propTypes = {
  loginWithRedirect: PropTypes.func.isRequired,
}
render(){
  return (
    <Fragment>
      <div className={cs(st.desktop)}>
        <section className={cs(st.step_section)}>
          <Container className={cs(st.cc_fluid_pp)} fluid>
            <h2> Creating your RESUME is Simple</h2>
            <Row className={cs(st.row, st.row_reverse)}>
              <Col lg={6} md={12} sm={12} xl={6} xs={12}>
                <div className={cs(st.step_image)}>
                  <img alt="Step 01 to Make a Resume" src={'/assets/images/step_1.png'} />
                </div>
                <div className={cs(st.arrow_division_2)}>
                  <img alt="arrow" src={'/assets/images/arrow_down_2.png'} />
                </div>
              </Col>
              <Col className={cs(st.step_pos_relative)} lg={6} md={12} sm={12} xl={6} xs={12}>
                <div className={cs(st.step_content)}>
                  <div className={cs(st.step_content_number)}> 01 </div>
                  <div className={cs(st.step_content_main_content)}>
                    <h3>Choose A Template </h3>
                    <p> Resumaker templates are designed with pricision and tuned for every job need.
                      Rich presentation and formatting is absolutely taken care.</p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className={cs(st.row)}>
              <Col className={cs(st.step_pos_relative)} lg={6} md={12} sm={12} xl={6} xs={12}>
                <div className={cs(st.step_content)}>
                  <div className={cs(st.step_content_number)}> 02 </div>
                  <div className={cs(st.step_content_main_content)}>
                    <h3> Enter Career Details </h3>
                    <p> Follow easy steps to fill up your career/employment details, education,
                      achievements and hobbies to create your personal brand.</p>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={12} sm={12} xl={6} xs={12}>
                <div className={cs(st.step_image)}>
                  <img alt="Step 02 to Make a Resume" src={'/assets/images/step_2.png'} />
                </div>
                <div className={cs(st.arrow_division)}>
                  <img alt="arrow" src={'/assets/images/down_arrow_1.png'} />
                </div>
              </Col>
            </Row>
            <Row className={cs(st.row, st.row_reverse)}>
              <Col lg={6} md={12} sm={12} xl={6} xs={12}>
                <div className={cs(st.step_image)}>
                  <img alt="Step 03 to Make a Resume" src={'/assets/images/step_3.png'} />
                </div>
                <div className={cs(st.arrow_division_2)}>
                  <img alt="arrow" src={'/assets/images/arrow_down_2.png'} />
                </div>
              </Col>
              <Col className={cs(st.step_pos_relative)} lg={6} md={12} sm={12} xl={6} xs={12}>
                <div className={cs(st.step_content)}>
                  <div className={cs(st.step_content_number)}> 03 </div>
                  <div className={cs(st.step_content_main_content)}>
                    <h3> Personalize your resume</h3>
                    <p> Handy tools will let you easily switch between the templates,
                      add styles, update color schemes and many more.</p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className={cs(st.step_pos_relative)} lg={6} md={12} sm={12} xl={6} xs={12}>
                <div className={cs(st.step_content)}>
                  <div className={cs(st.step_content_number)}> 04 </div>
                  <div className={cs(st.step_content_main_content)}>
                    <h3> Ready to Download </h3>
                    <p> Click on generate button to download your personalized resume,
                      just attach and send it to recruiters.</p>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={12} sm={12} xl={6} xs={12}>
                <div className={cs(st.step_image)}>
                  <img alt="Step 04 to Make a Resume" src={'/assets/images/step_4.png'} />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <div className={cs(st.mobile)}>
        <section className={cs(st.step_section)}>
          <Container className={cs(st.cc_fluid_pp)} fluid>
            <h2> Creating your RESUME is Simple</h2>
            <Carousel variant="dark">
              <Carousel.Item>
                <Row className={cs(st.row, st.row_reverse)}>
                  <Col lg={6} md={12} sm={12} xl={6} xs={12}>
                    <div className={cs(st.step_image)}>
                      <img alt="Step 01 to Make a Resume" src={'/assets/images/mob_step_1.png'} />
                    </div>
                    <div className={cs(st.arrow_division_2)}>
                      <img alt="arrow" src={'/assets/images/arrow_down_2.png'} />
                    </div>
                  </Col>
                  <Col className={cs(st.step_pos_relative)} lg={6} md={12} sm={12} xl={6} xs={12}>
                    <div className={cs(st.step_content)}>
                      <div className={cs(st.step_content_number)}> 01 </div>
                      <div className={cs(st.step_content_main_content)}>
                        <h3>Choose A Template </h3>
                        <p> Resumaker templates are designed with pricision and tuned for every job need.
                          Rich presentation and formatting is absolutely taken care.</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Carousel.Item>
              <Carousel.Item>
                <Row className={cs(st.row)}>
                  <Col className={cs(st.step_pos_relative)} lg={6} md={12} sm={12} xl={6} xs={12}>
                    <div className={cs(st.step_content)}>
                      <div className={cs(st.step_content_number)}> 02 </div>
                      <div className={cs(st.step_content_main_content)}>
                        <h3> Enter Career Details </h3>
                        <p> Follow easy steps to fill up your career/employment details, education,
                          achievements and hobbies to create your personal brand.</p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} md={12} sm={12} xl={6} xs={12}>
                    <div className={cs(st.step_image)}>
                      <img alt="Step 02 to Make a Resume" src={'/assets/images/mob_step_2.png'} />
                    </div>
                    <div className={cs(st.arrow_division)}>
                      <img alt="arrow" src={'/assets/images/down_arrow_1.png'} />
                    </div>
                  </Col>
                </Row>
              </Carousel.Item>
              <Carousel.Item>
                <Row className={cs(st.row, st.row_reverse)}>
                  <Col lg={6} md={12} sm={12} xl={6} xs={12}>
                    <div className={cs(st.step_image)}>
                      <img alt="Step 03 to Make a Resume" src={'/assets/images/mob_step_3.png'} />
                    </div>
                    <div className={cs(st.arrow_division_2)}>
                      <img alt="arrow" src={'/assets/images/arrow_down_2.png'} />
                    </div>
                  </Col>
                  <Col className={cs(st.step_pos_relative)} lg={6} md={12} sm={12} xl={6} xs={12}>
                    <div className={cs(st.step_content)}>
                      <div className={cs(st.step_content_number)}> 03 </div>
                      <div className={cs(st.step_content_main_content)}>
                        <h3> Personalize your resume</h3>
                        <p> Handy tools will let you easily switch between the templates,
                          add styles, update color schemes and many more..</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Carousel.Item>
              <Carousel.Item>
                <Row>
                  <Col className={cs(st.step_pos_relative)} lg={6} md={12} sm={12} xl={6} xs={12}>
                    <div className={cs(st.step_content)}>
                      <div className={cs(st.step_content_number)}> 04 </div>
                      <div className={cs(st.step_content_main_content)}>
                        <h3> Ready to Download </h3>
                        <p> Click on generate button to download your personalized resume,
                          just attach and send it to recruiters.</p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} md={12} sm={12} xl={6} xs={12}>
                    <div className={cs(st.step_image)}>
                      <img alt="Step 04 to Make a Resume" src={'/assets/images/mob_step_4.png'} />
                    </div>
                  </Col>
                </Row>
              </Carousel.Item>
            </Carousel>
          </Container>
        </section>
      </div>
    </Fragment>
  )
}
}
