import React, { Component } from 'react'
import cs from 'classnames'
import { Container, Row, Col } from 'react-bootstrap'
import st from './style.scss'

export default class Testimonials extends Component{
  constructor(props) {
    super(props)
    this.state = {
      showFullTestmonial: false,
    }
  }

showFullTestmonial = () => {
  const { showFullTestmonial } = this.state
  this.setState({
    showFullTestmonial: !showFullTestmonial,
  })
}

render(){
  const { showFullTestmonial } = this.state
  return (
    <Container className={cs(st.cc_fluid_pp)} fluid>
      <h2> Don&lsquo;t take our word for it </h2>
      <Row className={showFullTestmonial ? cs(st.testimonial_height_full) : cs(st.testimonial_height_mini)}>
        <Col lg={3} md={12} sm={12} xl={3} xs={12}>
          <div className={cs(st.textimonial_box)}>
            <div className={cs(st.textimonial_box_image)}>
              <div>
                <img alt="client" src={'/assets/images/avatar_boy.png'} />
              </div>
              <div className={cs(st.textimonial_box_title)}>
                <h5> Madhur Kadilaya </h5>
                <h6> Cyber Security, Australia </h6>
              </div>
            </div>
            <p> After using Resumaker for the first, I was thrilled to receive
              a positive callback from the target organization.</p>
          </div>
          <div className={cs(st.textimonial_box)}>
            <div className={cs(st.textimonial_box_image)}>
              <div>
                <img alt="client" src={'/assets/images/avatar_girl.png'} />
              </div>
              <div className={cs(st.textimonial_box_title)}>
                <h5> Akanksha Singh </h5>
                <h6> Digital Media </h6>
              </div>
            </div>
            <p> Unique performance is what I admire most about Resumaker.
              They helped me get a top position at the world-best engineering firm. </p>
          </div>
          <div className={cs(st.textimonial_box)}>
            <div className={cs(st.textimonial_box_image)}>
              <div>
                <img alt="client" src={'/assets/images/avatar_boy.png'} />
              </div>
              <div className={cs(st.textimonial_box_title)}>
                <h5> Akhil Sharma</h5>
                <h6> Final Year Student </h6>
              </div>
            </div>
            <p> Very good tool for anyone to create Resume in few steps.. Highly recommended. </p>
          </div>
          <div className={cs(st.textimonial_box)}>
            <div className={cs(st.textimonial_box_image)}>
              <div>
                <img alt="client" src={'/assets/images/avatar_boy.png'} />
              </div>
              <div className={cs(st.textimonial_box_title)}>
                <h5> Ashish Patel</h5>
                <h6> Senior System Analyst</h6>
              </div>
            </div>
            <p> Resume templates are straightforward for me to create
              an attractive and weightful resume. Thank you, Resumaker!</p>
          </div>
        </Col>
        <Col lg={3} md={12} sm={12} xl={3} xs={12}>
          <div className={cs(st.textimonial_box)}>
            <div className={cs(st.textimonial_box_image)}>
              <div>
                <img alt="client" src={'/assets/images/avatar_boy.png'} />
              </div>
              <div className={cs(st.textimonial_box_title)}>
                <h5>  Jamnesh Sinh  </h5>
                <h6> Operations Manager, Ahmedabad </h6>
              </div>
            </div>
            <p> Resumaker team created impressive resume for me, I got interivew call with-in
              a week after updating my resume in job portal.
              Interview went smooth and my current pay is much higher. </p>
          </div>
          <div className={cs(st.textimonial_box)}>
            <div className={cs(st.textimonial_box_image)}>
              <div>
                <img alt="client" src={'/assets/images/avatar_boy.png'} />
              </div>
              <div className={cs(st.textimonial_box_title)}>
                <h5> Adam Roy </h5>
                <h6> Account Executive </h6>
              </div>
            </div>
            <p> It is conducive while writing a resume.
              It suggested some valuable recommendations according to the job role
              I was applying to the company. And it is straightforward to use.
              This is the first time I have created a resume for a job application,
              but this tool was helpful and guided while writing a resume. Thank you!
            </p>
          </div>
          <div className={cs(st.textimonial_box)}>
            <div className={cs(st.textimonial_box_image)}>
              <div>
                <img alt="client" src={'/assets/images/avatar_boy.png'} />
              </div>
              <div className={cs(st.textimonial_box_title)}>
                <h5> Ateet Parihar</h5>
                <h6>Photographer, Noida </h6>
              </div>
            </div>
            <p> Reasonably helpful, professionally structured templates and recommendations.
              Very well explained instructions for creating a Resume. Love the service.</p>
          </div>
        </Col>
        <Col lg={3} md={12} sm={12} xl={3} xs={12}>
          <div className={cs(st.textimonial_box)}>
            <div className={cs(st.textimonial_box_image)}>
              <div>
                <img alt="client" src={'/assets/images/avatar_boy.png'} />
              </div>
              <div className={cs(st.textimonial_box_title)}>
                <h5> Gopi Krishna </h5>
                <h6> MFSc, Cardiff, UK</h6>
              </div>
            </div>
            <p> I got the recommendation from my friend about Resumaker expert service.
              Very happy with the service. Highly recommended. </p>
          </div>
          <div className={cs(st.textimonial_box)}>
            <div className={cs(st.textimonial_box_image)}>
              <div>
                <img alt="client" src={'/assets/images/avatar_boy.png'} />
              </div>
              <div className={cs(st.textimonial_box_title)}>
                <h5> Gowtham Verma </h5>
                <h6> Food Science, Cardiff, UK </h6>
              </div>
            </div>
            <p> I approached Resumaker experts for my academics resume.
              I also used this for applying to internships
              (in grad school) and got it. I highly recommend it. </p>
          </div>
          <div className={cs(st.textimonial_box)}>
            <div className={cs(st.textimonial_box_image)}>
              <div>
                <img alt="client" src={'/assets/images/avatar_girl.png'} />
              </div>
              <div className={cs(st.textimonial_box_title)}>
                <h5> Tejaswini Sarkar </h5>
                <h6> Financial Analyst </h6>
              </div>
            </div>
            <p> I spent less than 30 minutes turning my wordy resume into a professional and impressive resume.
              It is worth making an investment. THANKS!!!  </p>
          </div>
          <div className={cs(st.textimonial_box)}>
            <div className={cs(st.textimonial_box_image)}>
              <div>
                <img alt="client" src={'/assets/images/avatar_girl.png'} />
              </div>
              <div className={cs(st.textimonial_box_title)}>
                <h5> Sunita Gautam</h5>
                <h6> Operations Manager	</h6>
              </div>
            </div>
            <p> It&apos;s really a great tool. All the features are good, like professionally designed templates,
              patterns and colors. The relevant suggestions based on your role
              make it easy to build a resume without much effort.
              Customized font and color for the whole Resume is a unique feature.
              Very helpful tool. Thanks, Resumaker   </p>
          </div>
        </Col>
        <Col lg={3} md={12} sm={12} xl={3} xs={12}>
          <div className={cs(st.textimonial_box)}>
            <div className={cs(st.textimonial_box_image)}>
              <div>
                <img alt="client" src={'/assets/images/avatar_boy.png'} />
              </div>
              <div className={cs(st.textimonial_box_title)}>
                <h5> Tanay Vidhate </h5>
                <h6> Student </h6>
              </div>
            </div>
            <p> It is effortless and very easy to use. As a student having no experience in writing resumes,
              I built a resume quickly.</p>
          </div>
          <div className={cs(st.textimonial_box)}>
            <div className={cs(st.textimonial_box_image)}>
              <div>
                <img alt="client" src={'/assets/images/avatar_boy.png'} />
              </div>
              <div className={cs(st.textimonial_box_title)}>
                <h5> Teja Srinivas </h5>
                <h6> Software Engineer </h6>
              </div>
            </div>
            <p> I was looking for a job, and I wanted to create a good resume
              had no idea what to do at that point until
              I came across Resumaker, searched template,
              and I jumped to Guided Tool, and I was happy at how my resume
              came out beautifully and received an interview call. Just amazing!</p>
          </div>
          <div className={cs(st.textimonial_box)}>
            <div className={cs(st.textimonial_box_image)}>
              <div>
                <img alt="client" src={'/assets/images/avatar_boy.png'} />
              </div>
              <div className={cs(st.textimonial_box_title)}>
                <h5> Manoj Kumar</h5>
                <h6>Assistant Manager </h6>
              </div>
            </div>
            <p> I spent less than 30 minutes turning my wordy
              resume into a professional and impressive resume.
              It is worth making an investment. THANKS!!! </p>
          </div>
        </Col>
      </Row>
      <div
        className={cs(st.button, st.btn_hover, st.btn_center)}
        onClick={this.showFullTestmonial}>
        {showFullTestmonial ? 'Show Less' : 'Load more' }
      </div>
    </Container>
  )
}
}
