import React from 'react'
import { Switch } from 'react-router-dom'

import { Authenticated } from '.'
import { USER_ADMIN } from './constants'
import { AppRoutes, APP_ROUTE_NAME } from './plugins'

const AppRouter = props => (
  <Switch>
    <Authenticated
      authorize={[USER_ADMIN]}
      componentRef={AppRoutes}
      name={APP_ROUTE_NAME}
      path="/"
    />
  </Switch>
)

export default AppRouter
