import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Samples from './Samples'
import UIDesigner from './Samples/UIDesigner'
import Fresher from './Samples/Fresher'
import BusinessDevelopment from './Samples/BusinessDevelopment'
import ITProfessional from './Samples/ITProfessional'
import Accounting from './Samples/Accounting'
import Bpo from './Samples/Bpo'
import Nurse from './Samples/Nurse'
import OperationsManager from './Samples/OperationsManager'
import HumanResource from './Samples/HumanResource'
import Supplychain from './Samples/Supplychain'
import BestResume2021 from './Samples/BestResume2022'

export default class ResumeListing extends Component {
  static propTypes = {
    gotoURL: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    loginWithRedirect: PropTypes.func.isRequired,
    selectedSubKey: PropTypes.string.isRequired,
  }

  getRenderComponent() {
    const { selectedSubKey } = this.props
    switch (selectedSubKey) {
      case 'ux-ui-designer-resume-templates':
        return <UIDesigner {...this.props} />
      case 'fresher-resume-templates':
        return <Fresher {...this.props} />
      case 'nurse-resume-templates':
        return <Nurse {...this.props} />
      case 'business-developmen-resume-templates':
        return <BusinessDevelopment {...this.props} />
      case 'software-engineer-resume-templates':
        return <ITProfessional {...this.props} />
      case 'accounting-executive-resume-templates':
        return <Accounting {...this.props} />
      case 'customer-service-representative-resume-templates':
        return <Bpo {...this.props} />
      case 'operations-manager-resume-templates':
        return <OperationsManager {...this.props} />
      case 'human-resource-manager-resume-templates':
        return <HumanResource {...this.props} />
      case 'supply-chain-manager-resume-templates':
        return <Supplychain {...this.props} />
      case 'best-resume-template-in-2022':
        return <BestResume2021 {...this.props} />
      default:
        return <Samples {...this.props} />
    }
  }

  render() {
    return (
      <div>
        { this.getRenderComponent() }
      </div>
    )
  }
}
