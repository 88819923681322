import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import MetaTags from 'react-helmet'
import classnames from 'classnames'
import { SEO } from '../../../constants/constants'
import styles from './style.scss'

export default class About extends Component {
  render() {
    return (
      <Fragment>
        <MetaTags>
          <title>About | Best Resume Builder | Export Resume | Resumaker</title>
          <meta content={SEO.DESCRIPTION} name="description" />
        </MetaTags>
        <div className={classnames(styles.about_img)}>
          <Container fluid>
            <Row>
              <Col lg={6} md={12} sm={12} xl={6} xs={12}>
                <div className={classnames(styles.about_content)}>
                  <h1><span className={classnames(styles.about_header)}>ABOUT</span> US</h1>
                  <div className={classnames(styles.about_inside_content)}>
                    <div className={classnames(styles.about_content_div_one)}>
                      <p>Resumaker is a professional resume writing company that offers
                        top-quality services to clients across the globe. All our writers possess a lot
                        of experience and relevant skills in Human Resources and Information Technology.
                        With our recruitment background, we understand what it takes to craft a resume
                        that will make you stand out and get shortlisted for an interview.</p>
                    </div>
                    <div className={classnames(styles.about_content_div_two)}>
                      <p>At Resumaker,we are mindful that a resume is the
                        first introduction you make to your prospective employer.
                        That is why we craft a unique CV that showcases your strengths,
                        skills, experiences, and achievements. Additionally,
                        we are aware that recruiters only scan the introductory part of
                        your CV to decide whether to consider it or not.
                        Therefore, we craft an intriguing resume summary that shows your
                        competencies and experiences.</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={12} sm={12} xl={6} xs={12}>
                <img
                  alt="card" className={classnames(styles.dd_990, styles.img_responsive)}
                  src="/assets/images/img_1_about.png" />
              </Col>
            </Row>
          </Container>
        </div>
        <Container className={classnames(styles.section_2)}>
          <Row>
            <div>
              <h2>WHY RESUMAKER ?</h2>
            </div>
          </Row>
          <Row className={classnames(styles.sub_Section)}>
            <Col lg={1} md={2} sm={3} xl={1} xs={12}>
              <img
                alt="card" className={classnames(styles.img_fluid_quote)}
                src="/assets/images/quote_about.png" />
            </Col>
            <Col lg={6} md={12} sm={12} xl={6} xs={12}>
              <p className={classnames(styles.section_2_content)}>
                At Resumaker, we provide top-notch resume writing services for all job levels.
                Whether you seek an entry-level position, professional,
                or executive job, we have got covered.
                Our “Expert Resume” service is a prime and ideal option,
                especially when you want personalized
                and professional resumes. Our highly skilled writers are
                adept at composing resumes that exceed
                our clients’ expectations. However, if you need standard resume services,
                you can opt for the “Guided Resume” service.
                This the commonly used option due to its cost-effectiveness.</p>
            </Col>
            <Col lg={5} md={12} sm={12} xl={5} xs={12}>
              <p className={classnames(styles.section_2_content)}>At Resumaker, we have in-depth
                knowledge of the
                recruitment process, and we believe that
                our top interview questions and answers will help
                you familiarize yourself with the interviewing process.
                The interview questions are relevant
                to various occupations, and you can use them to understand the hiring process.</p>
            </Col>
          </Row>
        </Container>
        <div className={classnames(styles.about_img_2)}>
          <Container>
            <Row className={classnames(styles.bottom_blocks)}>
              <Col lg={5} md={12} sm={12} xl={5} xs={12}>
                <img
                  alt="card"
                  className={classnames(styles.dd_990, styles.img_responsive, styles.img_responsive_2)}
                  src="/assets/images/img_2_about.png" />
              </Col>
              <Col lg={7} md={12} sm={12} xl={7} xs={12}>
                <div className={classnames(styles.about_block_content)}>
                  <p>
                    Resumaker is one of the best Resume writing companies you can rely on.
                    We provide hi-tech resume services that qualify you for interviews and
                    in the hiring process. We understand how a job
                    search can be difficult,
                    so we compose resumes that can accord you victory within a short time.</p>

                  <p className={classnames(styles.bottom_second_content)}>
                    Resumaker knows how modern recruiters use the applicant
                    tracking system (ATS) to select the right resumes.
                    That is why we enrich your resume with the right content and
                    relevant keywords to enhance your selection.</p>
                  <p>
                    We take pride in our resourceful blogs and interview questions,
                    and we believe that you will benefit from the insights provided.
                    At Resumaker, we are a one-stop-shop, and we ensure that you are well
                    prepared for the hiring process,
                    from resume creation to interview, recruitment, and working.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Fragment>
    )
  }
}
