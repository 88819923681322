import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Col, Button, Container, Row } from 'react-bootstrap'

import { validatePassword } from '../../constants'
import Footer from './Footer'
import styles from './style.scss'

class GeneratePassword extends Component {
  static propTypes = {
    email: PropTypes.string.isRequired,
    openURL: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    updatePassword: PropTypes.func.isRequired,
  }

  constructor(props){
    super(props)
    this.state = {
      password: '',
      confPassword: '',
      errorMessage: 'Please enter valid password !',
    }
  }

  updatePassword = () => {
    const { updatePassword, token, email } = this.props
    const { password } = this.state
    const isStrongPassword = validatePassword(password)
    if (isStrongPassword){
      updatePassword({ email, token, password })
    }
    else {
      this.setState({
        errorMessage: 'Password must contain minimum 8 characters including atleast 1 number and 1 capital letter.',
      })
    }
  }

  updateField = (key, value) => {
    this.setState({
      [key]: value,
    }, this.validatePassword)
  }

  validatePassword = () => {
    const { password, confPassword, disableReset } = this.state
    let disable = true
    if (password === confPassword) {
      disable = false
    }

    if (disableReset !== disable) {
      this.setState({
        disableReset: disable,
        errorMessage: disable ? 'Passwords did not match, please check and re-enter.' : '',
      })
    }
  }

  render(){
    const { errorMessage, password, confPassword, disableReset } = this.state
    const { openURL } = this.props
    return (
      <Fragment>
        <Container fluid>
          <Row>
            <Col lg={6} md={6} sm={12} xl={6} xs={12}>
              <div className={classnames(styles.welcome_bg, styles.password_reset_screen)} />
            </Col>
            <Col lg={6} md={6} sm={12} xl={6} xs={12}>
              <div className={classnames(styles.welcome_next_box)}>
                <div className={classnames(styles.welcome)}>
                  <div className={classnames(styles.login_title)}>
                    <div
                      className={classnames(styles.show_cursor)}
                      onClick={() => openURL('/')}>
                      <img
                        alt={'logo'} className={classnames(styles.logo_img_login)}
                        src={'assets/images/logo.svg'} />
                    </div>
                    <span> Change password ! </span>
                  </div>
                  <div className={classnames(styles.welcome_back_title)}>
                    Please Enter your password here
                  </div>
                  <div>
                    <input
                      className={classnames(styles.login_input_forgot)} id="password" name="password"
                      onChange={(e) => this.updateField('password', e.target.value)}
                      placeholder="Enter your new password here" type="password"
                      value={password} />
                    <input
                      className={classnames(styles.login_input_forgot)} id="password" name="password"
                      onChange={(e) => this.updateField('confPassword', e.target.value)}
                      placeholder="Re Enter your new password here" type="password"
                      value={confPassword} />
                    {errorMessage !== '' && (
                      <div className={classnames(styles.alert_password)}>
                        { errorMessage }
                      </div>
                    )}
                    <Button
                      className={classnames(styles.btn_loginpage_submit)}
                      disabled={disableReset}
                      onClick={this.updatePassword} type="submit"> Submit </Button>
                  </div>
                </div>
              </div>
            </Col>
            <Footer openURL={openURL} />
          </Row>
        </Container>
      </Fragment>
    )
  }
}
export default withRouter(GeneratePassword)
