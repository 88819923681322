import React, { Component } from 'react'
import cs from 'classnames'
import st from './style.scss'
import { Icon, IconType } from '../../common'

export default class TickIcon extends Component {
  render() {
    const { TICK } = IconType.BUILDER
    return (
      <Icon
        className={cs(st.img_fluid)} color={'#28a745'} height={'12'}
        names={[TICK]} viewHeight={'24'}
        viewWidth={'24'} width={'12'} />
    )
  }
}
