import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import cs from 'classnames'
import { get } from 'lodash'
import { Row, Col, Container } from 'react-bootstrap'
import { getInteviewImageUrl } from '../../constants'
import st from './style.scss'

class RelatedQuestions extends Component {
  static propTypes = {
    gotoQuestion: PropTypes.func.isRequired,
    relatedQs: PropTypes.array.isRequired,
  }

  render() {
    const { gotoQuestion, relatedQs } = this.props
    return (
      <Container>
        <Row>
          {relatedQs && relatedQs.length > 0 && relatedQs.map((question, index) => (
            <Col lg={4} md={4} sm={4} xl={4} xs={12}>
              <div className={cs(st.more_iq_card)} onClick={() => gotoQuestion(get(question, 'qId'))}>
                <img
                  alt="Interview Alt Text" className={cs('card-img-top')}
                  src={getInteviewImageUrl(get(question, 'media.url'))} />
                <div className={cs(st.content)}>
                  <h3>{get(question, 'title')}</h3>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    )
  }
}

export default withRouter(RelatedQuestions)
