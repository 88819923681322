import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Col, Button, Container, Row } from 'react-bootstrap'
import Footer from './Footer'
import styles from './style.scss'
import { validateOtp } from '../../constants'

class SubmitOtp extends Component {
  static propTypes = {
    openURL: PropTypes.func.isRequired,
    submitOTP: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
  }

  constructor(props){
    super(props)
    this.state = {
      otp: '',
      otpError: '',
    }
  }

  submitOTP = () => {
    const { submitOTP, token } = this.props
    const { otp, otpError } = this.state
    let errormsg = ''
    const isValidOtp = validateOtp(otp)
    if (!isValidOtp){
      errormsg = 'OTP code should be 6 digits'
    }
    else if (otp.length !== 6){
      errormsg = 'OTP code should be 6 digits'
    }

    if (otpError !== errormsg){
      this.setState({
        otpError: errormsg,
      })
    }
    if (isValidOtp){
      submitOTP({ token, otp })
    }
  }

  render(){
    const { otp, otpError } = this.state
    const { openURL } = this.props
    return (
      <Fragment>
        <Container fluid>
          <Row>
            <Col lg={6} md={6} sm={12} xl={6} xs={12}>
              <div className={classnames(styles.welcome_bg, styles.forgot_password_otp)} />
            </Col>
            <Col lg={6} md={6} sm={12} xl={6} xs={12}>
              <div className={classnames(styles.welcome_next_box)}>
                <div className={classnames(styles.welcome)}>
                  <div className={classnames(styles.login_title)}>
                    <div
                      className={classnames(styles.show_cursor)}
                      onClick={() => openURL('/')}>
                      <img
                        alt={'logo'} className={classnames(styles.logo_img_login)}
                        src={'assets/images/logo.svg'} />
                    </div>
                    <span> OTP Generated </span>
                  </div>
                  <div className={classnames(styles.welcome_back_title)}>
                    Please enter OTP sent to your email
                  </div>
                  <div>
                    <input
                      className={classnames(styles.login_input_forgot)} id="text"
                      name="text"
                      onChange={(e) => this.setState({ otp: e.target.value })}
                      placeholder="Enter OTP here"
                      type="text"
                      value={otp} />
                    <div className={classnames(styles.alert_email)}>
                      {otpError}
                    </div>
                    <Button
                      className={classnames(styles.btn_loginpage_submit)}
                      onClick={this.submitOTP}
                      type="submit"> Submit </Button>
                  </div>
                </div>
              </div>
            </Col>
            <Footer openURL={openURL} />
          </Row>
        </Container>
      </Fragment>
    )
  }
}
export default withRouter(SubmitOtp)
