import React, { Component } from 'react'
import { Container, Row, Col, Accordion, Card } from 'react-bootstrap'
import cs from 'classnames'
import st from './style.scss'
import { Icon, IconType } from '../../common'

export default class Fqa extends Component {
  render() {
    const { CHEVRONDOWN } = IconType.DIRECTION
    return (
      <div className={cs(st.section, st.faq, st.options_items_align)}>
        <Container className={cs(st.options_items_align)}>
          <Row>
            <Col lg={6} md={6} sm={12} xl={5} xs={12}>
              <div className={cs(st.section_col, st.section_title, st.faq_title)}>
                <h4>Frequently Asked Questions</h4>
                <img alt="faq" src="/assets/images/faq_bg.png" />
              </div>
            </Col>
            <Col className={cs(st.faq_title_margin)} lg={6} md={6} sm={12} xl={7} xs={12}>
              <div className={cs(st.section_col)}>
                <Accordion>
                  <Card className={cs(st.faq_border)}>
                    <Accordion.Toggle eventKey="1">
                      <Card.Header className={cs(st.faq_card_header)}>
                        How Is Resumaker Different From Other Services ?
                        <Icon
                          color={'#212121'}
                          height={'30'}
                          names={[CHEVRONDOWN]}
                          viewHeight={'30'}
                          viewWidth={'30'} width={'30'} />
                      </Card.Header>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                        The backbone of Resumaker team from fortune 100 companies.
                        Resumaker principles guide our team
                        providing users with quality. Our primary motto is identifying the potential capabilities of
                        users, project those into eye-catching content.
                        We try to present one&#39;s profile on a single
                        page or two so that the employer can go through
                        a resume as complete as possible. This will help
                        the user convey about him/her effectively. When it comes
                        to our info graphic resumes we present
                        multi-dimensional attributes into impressive graphical content.
                        This will simplify reader&#39;s
                        ability to catch buzz words with facts so the resume will stand out.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Accordion>
                  <Card className={cs(st.faq_border)}>
                    <Accordion.Toggle eventKey="2">
                      <Card.Header className={cs(st.faq_card_header)}>
                        How To Order For Resume Writing Service?
                        <Icon
                          color={'#212121'}
                          height={'30'}
                          names={[CHEVRONDOWN]}
                          viewHeight={'30'}
                          viewWidth={'30'} width={'30'} />
                      </Card.Header>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2">
                      <Card.Body>
                        As soon as you place the order, our support team will send you
                        a welcome email confirming your order upon receiving your request.
                        In this mail, they ask for your professional details to match the
                        Expert Resume writer who is most suitable for your career path.
                        The executive will then assign your
                        Resume to the most qualified writer based on your background.
                        Expect to be contacted by your Professional Writer within 1 business
                        day after signing up to schedule your branding and strategy call.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Accordion>
                  <Card className={cs(st.faq_border)}>
                    <Accordion.Toggle eventKey="3">
                      <Card.Header className={cs(st.faq_card_header)}>
                        How Expert Resume Writing Service Works?
                        <Icon
                          color={'#212121'}
                          height={'30'}
                          names={[CHEVRONDOWN]}
                          viewHeight={'30'}
                          viewWidth={'30'} width={'30'} />
                      </Card.Header>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="3">
                      <Card.Body>
                        <div>
                          <b>STEP 1</b> Purchase the service category you fall under.
                          Expert Resume writer gets assigned and calls you to understand your expectations,
                          profile, strengths, and key points highlighted in your Resume.
                        </div>
						&nbsp;
                        <div>
                          <b> STEP 2</b> Draft of your Resume will be shared within 2 – 3 days.
                          You can review and provide your review comments.
                        </div>
						&nbsp;
                        <div>
                          <b>STEP 3</b> In case of any comments, the Resume writer will incorporate
                          changes in your Resume and deliver the final Resume in MS Word and PDF format.
                          After 5 working days of the final delivery, your order will be closed.
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Accordion>
                  <Card className={cs(st.faq_border)}>
                    <Accordion.Toggle eventKey="7">
                      <Card.Header className={cs(st.faq_card_header)}>
                        Why Use Our Resume Builder Tool?
                        <Icon
                          color={'#212121'}
                          height={'30'}
                          names={[CHEVRONDOWN]}
                          viewHeight={'30'}
                          viewWidth={'30'} width={'30'} />
                      </Card.Header>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="7">
                      <Card.Body>
                        Resumaker Builder Tool is effortless and provides professionally
                        designs templates, recruiter friendly outlays, built-in editor, multiple
                        colours, font and formatting options, Pre-written work history suggestions
                        by real-world applicants and recruiters experts and lastly ATS friendly.
                        You can easily download it in PDF and MS Word.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Accordion>
                  <Card className={cs(st.faq_border)}>
                    <Accordion.Toggle eventKey="8">
                      <Card.Header className={cs(st.faq_card_header)}>
                        Is My Payment Transaction Is Secure?
                        <Icon
                          color={'#212121'}
                          height={'30'}
                          names={[CHEVRONDOWN]}
                          viewHeight={'30'}
                          viewWidth={'30'} width={'30'} />
                      </Card.Header>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="8">
                      <Card.Body>
                        All transactions are 100% secure, with the option to pay with our trusted payment partners,
                        both having the highest security measures available.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Accordion>
                  <Card className={cs(st.faq_border)}>
                    <Accordion.Toggle eventKey="9">
                      <Card.Header className={cs(st.faq_card_header)}>
                        Is My Personal Data Secure?
                        <Icon
                          color={'#212121'}
                          height={'30'}
                          names={[CHEVRONDOWN]}
                          viewHeight={'30'}
                          viewWidth={'30'} width={'30'} />
                      </Card.Header>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="9">
                      <Card.Body>
                        Keeping your information safe and secure is the top priority of Resumaker.
                        This is the promise we make to our every individual customer.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Accordion>
                  <Card className={cs(st.faq_border)}>
                    <Accordion.Toggle eventKey="9">
                      <Card.Header className={cs(st.faq_card_header)}>
                        Are There Any Hidden Charges?
                        <Icon
                          color={'#212121'}
                          height={'30'}
                          names={[CHEVRONDOWN]}
                          viewHeight={'30'}
                          viewWidth={'30'} width={'30'} />
                      </Card.Header>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="9">
                      <Card.Body>
                        There are no hidden charges.
                        After selecting the plan, the total price you will be charged can be
                        seen before introducing any payment details.
                        (This excludes the charges required by the bank transaction.)
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>

              </div>
            </Col>
          </Row>
        </Container>
      </div>

    )
  }
}
