import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cs from 'classnames'
import { get } from 'lodash'

import st from './style.scss'

export default class InterviewFilters extends Component {
  static propTypes = {
    categories: PropTypes.object.isRequired,
    filterByCategory: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
  }

  constructor(props){
    super(props)
    this.state = {
      showMore: false,
    }
  }

  render() {
    const { categories, filterByCategory } = this.props
    const { showMore } = this.state
    return (
      <div className={cs(st.filters_sticky)}>
        <div className={cs('d-flex', st.filters_buttons)} />
        <ul className={cs('list-unstyled', 'mb-0', 'pl-0', 'mt-4', st.filters_checkboxes)}>
          {categories && categories.length > 0 && categories.map((category, index) => (
            (showMore || index < 2) && (
              <li>
                <input
                  className={cs(st.styled_checkbox)}
                  id={`filterOptions${get(category, 'catId')}`} name="selectedCategories[]"
                  onClick={() => filterByCategory(get(category, 'catName'))}
                  type="checkbox" />
                <label
                  className={cs('align-items-center', 'd-flex')}
                  htmlFor={`filterOptions${get(category, 'catId')}`}>{get(category, 'catName')}</label>
              </li>
            )
          ))}
        </ul>
        <div
          className={cs('mt-4', st.filters_showmore)}
          data-target="#showMoreOptions" data-toggle="collapse">
          {!showMore && (
            <span
              className={cs(st.more)}
              onClick={() => this.setState({ showMore: true })}>Show More</span>
          )}
          {showMore && (
            <span
              className={cs(st.more)}
              onClick={() => this.setState({ showMore: false })}>Show Less</span>
          )}
          <i className="fas ml-1 fa-angle-down" /></div>
      </div>

    )
  }
}
