import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import MetaTags from 'react-helmet'
import classnames from 'classnames'
import styles from '../style.scss'
import { SEO } from '../../../constants/constants'
import appstyle from '../../../containers/AppContainer/appmain.scss'

class PrivacyPolicy extends Component {
  render() {
    return (
      <Fragment>
        <MetaTags>
          <title>Resumaker Privacy Policy | Resumaker</title>
          <meta content={SEO.DESCRIPTION} name="description" />
        </MetaTags>
        <div className={classnames(styles.notice, styles.notice_conditions)}>
          <Container className={classnames(styles.box_notice)}>
            <h2 className={classnames(styles.notice_title)}>Privacy Policy</h2>
            <div>
              <h4>Introduction</h4>
              <p />
              <p className={classnames(appstyle.mt_10)}>We hold the right to adjust
                this user agreement and privacy policy document at any time for any
                reason.
                Any
                changes will be highlighted by indicating the Last Updated date.
                Changes take effect
                immediately
                when the
                document is updated on the site and therefore, forfeit the right
                to be given a specific notice
                of such
                changes or
                modification.</p>
              <p className={classnames(appstyle.mt_10)}>We encourage you to be
                reviewing this document to be up to date with new changes. Your continued
                use of
                our
                Website is an express indication that you are aware, subject to
                and deemed as an act of
                acceptance of
                the revised
                changes</p>
              <p className={classnames(appstyle.mt_10)}>
                Anytime you visit our Website <span
                  className={classnames(styles.link)}
                  onClick={() => this.props.gotoURL('/')}>https://www.resumaker.co.in</span> and use any
                of our services, we hope that you read through carefully because
                we regard this document to be
                important. If
                there is any privacy policy that you are not in agreement with,
                please do not use our Website or
                services.
              </p>
              <h4>Our Promise</h4>
              <p className={classnames(appstyle.mt_10)}>Whatever information we
                collect as you seek our services gives us the ability to offer better
                services.
                Keeping your information safe and secure is the top priority of Resumaker.
                This is the promise
                we make
                to every
                individual customer</p>
              <p className={classnames(appstyle.mt_10)}>Any information shared with us
                will be undergoing strict security standards that define
                confidentiality.
              </p>
              <p className={classnames(appstyle.mt_10)}>We collect minimal information
                from our clients which is the only information needed for service
                delivery
                and customer advisory about our products, opportunities and other services.</p>
              <p className={classnames(appstyle.mt_10)}>Our employees have appropriate
                training on handling personal information; they know when to
                access and
                use
                them as needed. Violation of our privacy promise is subject to a disciplinary action.</p>
              <p className={classnames(appstyle.mt_10)}>Unless previously informed
                we do not share user data with external organizations unless required
                by law.
              </p>
              <p className={classnames(appstyle.mt_10)}>We will at all times be in control
                of customer information and only share such information with
                other companies when a customer shows interest in their products or services.
                It is important to note
                that this agreement policy does not cover the other companies use of
                shared customer information.</p>
              <p className={classnames(appstyle.mt_10)}>Any organization operating under
                our name will be required to abide by our agreement and privacy
                policy.
              </p>
              <p className={classnames(appstyle.mt_10)}>All our customer data are periodically updated,
                and accuracy maintained. Unless prohibited by
                law, we inform our clients where and how to access their information.
                The customer can use the same
                access methods provided to send notifications on errors and any changes to be made.</p>
              <h4>Information Resumaker Collects from You</h4>
              <p className={classnames(appstyle.mt_10)}>Your privacy is of value to us, we,
                therefore, make an effort to collect as little information as
                possible
                to make sure our website and related services run smoothly. Some functionalities on our website
                cannot
                function
                without such information. The information is collected only when you register to use our
                services.</p>
              <p className={classnames(appstyle.mt_10)}>
                Information related to you includes:<br />
              </p>
              <ul className={classnames(styles.notice_list_privacy)}>
                <li>Your name(s)</li>
                <li>Telephone number</li>
                <li>E-Mail address</li>
                <li>Company or Home address</li>
                <li>Billing address</li>
              </ul>
              <p className={classnames(appstyle.mt_10)}>All customers have a way of accessing
                and registering to our website through personal, social
                media and
                E-Mail accounts such as LinkedIn, Google, Twitter, or Facebook. Registration via social media
                accounts
                gives us
                the same information collected when registering manually. We also use the same image and
                location.</p>
              <p className={classnames(appstyle.mt_10)}>
                The information collected is used to create your Resume. Such other information may identify you
                when
                putting
                together.</p>
              <p className={classnames(appstyle.mt_10)}> They include:</p>
              <ul className={classnames(styles.notice_list_privacy)}>
                <li>Professional experience</li>
                <li>Educational background</li>
                <li>Employment history</li>
                <li>Professional skills</li>
                <li>Credentials and licenses</li>
                <li>Endorsements, awards, and honours</li>
                <li>Publications and presentations</li>
              </ul>
              <p className={classnames(appstyle.mt_10)}>Any other information that a user
                may see appropriate and can be added to their cover letters and
                resume
                is
                also collected but not expressly requested from the users.</p>
              <h4>Miscellaneous Information</h4>
              <p>Other information from the users such as age, town, gender, interests,
                or any activity related to
                the
                user
                platform may be collected.</p>
              <p>What Our Servers Collect Automatically</p>
              <p className={classnames(appstyle.mt_10)}>By accessing our website,
                our servers automatically retrieve the following information:</p>
              <ul className={classnames(styles.notice_list_privacy)}>
                <li>Type of browser</li>
                <li>Operating system being used</li>
                <li>IP address</li>
                <li>Device name</li>
                <li>Access, login and logout time</li>
                <li>Search history</li>
              </ul>
              <p>This information helps improve our services and functionality as well as marketing and analysis.
              </p>

              <h4>Financial Information</h4>
              <p className={classnames(appstyle.mt_10)}>Processing payments are done
                by collecting a small amount of required information relating to
                financial
                and
                security transfer. Such information is securely shared with third-party payment processors who
                finalize
                the
                transactions. All information stored on our servers complies with the Payment Card Industry Data
                Security
                Standards (PCI DSS)</p>
              <h4>How will Resumaker Use Your Information ?</h4>
              <p className={classnames(appstyle.mt_10)}>For smooth, efficient,
                and personalized experience, we use information about you for the
                following
                purposes:</p>
              <h5 className={classnames(appstyle.mt_20, styles.h5_title_policy)}>Legal</h5>
              <p className={classnames(appstyle.mt_10)}>Help enforce court orders
                (subpoenas).Prevent fraudsters to use our website as well as monitor
                and track
                any criminal activity</p>
              <h5 className={classnames(appstyle.mt_20, styles.h5_title_policy)}>Business</h5>
              <ul className={classnames(styles.notice_list_privacy)}>
                <li>Compile statistical data to be used anonymously internally or by third parties</li>
                <li>Design targeted advertisements, newsletters, coupons, and promotions</li>
                <li>Managing orders, payments, and refunds</li>
                <li>Enhancing User Experience</li>
                <li>Running promotions and contests</li>
                <li>Creating a user personal profile</li>
                <li>Increase user efficiency when using the site</li>
                <li>Offer recommendations to you based on personal profile</li>
                <li>Creating and managing your account</li>
              </ul>
              <h5 className={classnames(appstyle.mt_20, styles.h5_title_policy)}>Communication</h5>
              <ul className={classnames(styles.notice_list_privacy)}>
                <li>Share information regarding your order</li>
                <li>Facilitate user-to-user communication</li>
                <li>Respond to customer requests</li>
                <li>Request feedback</li>
              </ul>
              <h4>Disclosing Information to Third Parties</h4>
              <p>From time to time, we may share your information only under special circumstances and for the
                following
                reasons.</p>
              <h4>Complying with Laws Protecting Rights of Users</h4>
              <p className={classnames(appstyle.mt_10)}>If at any given time we
                have reason to believe that you are supposed to respond to an impending
                legal
                process needed to protect rights, property or remedy violation of this policy document, we will
                only
                share your information as permitted by law</p>
              <h4>Help Third Party Service Providers</h4>
              <p className={classnames(appstyle.mt_10)}>Your information will be
                shared with third party companies that handle other services on our
                behalf. Such
                services may not be limited to payments, analysis, hosting services and marketing.</p>
              <h4>Integrating Social Media Accounts</h4>
              <p className={classnames(appstyle.mt_10)}>Registering on our site via
                social media accounts means that the account(s) used will have your
                name,
                picture, and information related to you.</p>
              <h4>Transfer Assets in a Company Sale, or Bankruptcy</h4>
              <p className={classnames(appstyle.mt_10)}>If we undergo a merger or are
                acquired by a third party, we may transfer your information to the
                successor. In the event that the successor refuses to honour commitments made in this document,
                you will
                be made aware. We are not responsible for actions conducted by third parties and if you no
                longer see
                the need to be in correspondence with them, you are at liberty to contact them directly.</p>
              <h4>Cookies and Other Tracking Technologies</h4>
              <p className={classnames(appstyle.mt_10)}>We use cookies to help you and other
                visitors to move faster when accessing our site. Upon
                registration,
                we may pass cookies to your computer or device. Cookies are strings of information sent by a
                website and
                stored on the hard drive temporarily. Cookies help us understand site usage and improve your
                user
                experience</p>
              <h4>Security</h4>
              <p className={classnames(appstyle.mt_10)}>Information that personally
                identifies you will be collected and stored in servers with limited
                access.
                The information is maintained and safeguarded to ensure server and information integrity.</p>
              <h4>Internet Based Transfers</h4>
              <p>The internet is a global space that involves the transmission of data internationally. Therefore,
                when
                browsing <span
                  className={classnames(styles.link)}
                  onClick={() => this.props.gotoURL('/')}>https://www.resumaker.co.in</span> and
                initiate a communication you have agreed and acknowledged processing of your data.</p>
              <h4>Control of Cookies</h4>
              <p className={classnames(appstyle.mt_10)}>Browsers are set to accept cookies by default.
                Most browsers give users an option of accepting or
                rejecting cookies. Any action taken to control the cookies may affect your interaction with the
                site.
                Other ways of opting out of third-party cookies are through third-party tools</p>
              <h4>Tracking Technologies</h4>
              <p className={classnames(appstyle.mt_10)}>Beacons, pixel tagging, and other
                tracking technologies can also be used to help customize the
                user
                experience. A beacon or a pixel tag could be an image, or a small object embedded within a web
                page or
                email. They help in tracking the number of visitors to a page via emails and other statistical
                data.
                They may have information about time and date of a page view, cookie number, and site
                description.
                Unlike cookies, web beacons and pixel tags cannot be declined, but their use can be limited by
                controlling cookies interacting with them.</p>
              <h4>Contact</h4>
              <p className={classnames(appstyle.mt_10)}>A person registered with the site may
                request the removal of personal data by contacting us using the contact information provided below.
                Your request should have all details associated with your account and any relevant information.
                Once the request is acknowledged, we will make sure the data is no longer available for public
                viewing on the site or on mobile. Please note that your data may not be completely
                removed from our systems immediately.</p>
              <p>Our privacy officer can be contacted via:</p>
              <p className={classnames(appstyle.mt_20)}>Head Office:
                <div
                  className={classnames(styles.link)}
                  onClick={() => this.props.gotoURL('/')}>www.resumaker.co.in</div>
                <span className={classnames(styles.span_block)}>AARWII TechSolutions Pvt Ltd,</span>
                <span className={classnames(styles.span_block)}>Plot #9, IT Park, Mangalagiri, PIN - 522503,</span>
                <span className={classnames(styles.span_block)}>Andhra Pradesh, India.
                  Email: support@resumaker.co.in</span>
                <span className={classnames(styles.span_block)}>
                  Phone: (+91) 9121 900 900 (available Monday to Friday, 9 AM - 6 PM)</span></p>
            </div>
          </Container>
        </div>
      </Fragment>
    )
  }
}

export default withRouter(PrivacyPolicy)
