import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { inject } from 'mobx-react'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'
import MetaTags from 'react-helmet'
import cs from 'classnames'
import { get } from 'lodash'
import { Container, Row, Col } from 'react-bootstrap'
import { getInteviewImageUrl } from '../../constants'

import RelatedQuestions from './RelatedQuestions'
import st from './style.scss'

@inject('mainModel')
class Questions extends Component {
  static propTypes = {
    gotoQuestion: PropTypes.func.isRequired,
    mainModel: PropTypes.object.isRequired,
    questionId: PropTypes.string.isRequired,
  }

  constructor(props){
    super(props)
    this.state = {
      questionId: props.questionId,
      section: {},
      relatedQs: [],
    }
  }

  componentDidMount(){
    this.fetchQuestionDetails()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.questionId !== this.state.questionId) {
      this.fetchQuestionDetails()
    }
  }

  static getDerivedStateFromProps(props, state) {
    const oldQuestionId = get(state, 'questionId')
    const newQuestionId = get(props, 'questionId')
    if (oldQuestionId !== newQuestionId) {
      return {
        questionId: newQuestionId,
      }
    }
    return null
  }

  fetchQuestionDetails = () => {
    const { mainModel } = this.props
    const { questionId } = this.state
    const cb = (section) => {
      const cb1 = (relatedQs) => {
        this.setState({
          relatedQs,
        })
      }
      mainModel.getRelatedInterviewQuestions(get(section, 'catName'), 3, cb1)
      this.setState({
        section,
      })
    }
    mainModel.getInterviewQuestionsDetails(questionId, cb)
  }

  render() {
    const { gotoQuestion } = this.props
    const { section, relatedQs } = this.state
    const seo = get(section, 'seo')
    const media = get(section, 'media')
    const { cannonical } = `https://www.resumaker.co.in/interview/${get(section, 'blogId')}`
    const questionsList = get(section, 'contentList', [])

    console.log('description', get(seo, 'description'))
    return (
      <div>
        <MetaTags>
          <title>{get(seo, 'title')}</title>
          <meta content={get(seo, 'description')} data-react-helmet="true" data-rh="true" name="description" />
          <link href={cannonical} rel="canonical" />
          <meta content={get(seo, 'title')} property="og:title" />
          <meta content={getInteviewImageUrl(get(media, 'url'))} property="og:image" />
        </MetaTags>

        <Container fluid>
          <div className={cs('page-main-wrapper')}>
            <div className={cs(st.container_xl, st.single_interview, 'mt-5')}>
              <div className={cs('align-items-center', 'justify-content-center')}>
                <Row>
                  <Col lg={1} xl={1} />
                  <Col lg={10} md={12} sm={12} xl={10} xs={12}>
                    <div>
                      <img
                        alt="interview page"
                        className={cs(st.img_responsive)} src={getInteviewImageUrl(get(media, 'url'))} />
                    </div>
                    <div>
                      <div
                        className={cs('my-2')}>
                        <h1 className={cs(st.interview_inner_title)}>
                          {ReactHtmlParser(get(section, 'title'))}</h1>
                        <div className={cs(st.iterview_lists_type)}>
                          {ReactHtmlParser(get(section, 'description'))}</div>
                      </div>
                    </div>
                    {get(section, 'proTip') && get(section, 'proTip').length > 0 && (
                    <blockquote
                      className={cs(st.interview_blockquote, st.blockquote_bg)}>
                      {ReactHtmlParser(get(section, 'proTip'))}
                    </blockquote>
                    )}
                    {get(section, 'postDescription') && get(section, 'postDescription').length > 0 && (
                    <div
                      className={cs(st.iterview_lists_type, st.interview_postDesc)}>
                      {ReactHtmlParser(get(section, 'postDescription'))}
                    </div>
                    )}

                    <div
                      className={cs('text-center')}>
                      <a
                        className={cs('btn', st.btn_secondary_color, st.rounded_pill,
                          'py-2', 'px-4', 'py-lg-3')}
                        href="/sample-resume">Download Resume Template</a>
                    </div>
                    {questionsList && questionsList.length > 0 && questionsList.map((question, index) => (
                      <div
                        className={cs('mt-2', 'mb-lg-5', st.iq_ans)}>
                        <h2 className={cs(st.interview_question_title)}>
                          {ReactHtmlParser(get(question, 'title'))}</h2>
                        <div className={cs(st.iterview_lists_type)}>
                          {ReactHtmlParser(get(question, 'answer'))}</div>
                        {get(question, 'proTip') && get(question, 'proTip').length > 0 && (
                        <blockquote className={cs('mt-4', st.interview_blockquote)}>
                          {ReactHtmlParser(get(question, 'proTip'))}
                        </blockquote>
                        )}
                        {get(section, 'postAnswer') && get(section, 'postAnswer').length > 0 && (
                        <div
                          className={cs(st.iterview_lists_type, st.interview_postAnswer)}>
                          {ReactHtmlParser(get(section, 'postAnswer'))}
                        </div>
                        )}
                      </div>
                    ))}
                    <RelatedQuestions gotoQuestion={gotoQuestion} relatedQs={relatedQs} />
                  </Col>
                  <Col lg={1} xl={1} />
                </Row>
              </div>
            </div>
          </div>
        </Container>
      </div>
    )
  }
}

export default withRouter(Questions)
