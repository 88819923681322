import React, { Component } from 'react'
import classnames from 'classnames'
import { Container, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import PromoCard from './PromoCard'
import Download from '../../common/Download'
import GetStartedButton from '../../MainContent/Home/GetStartedButton'
import { getDocUrl } from '../../../constants'
import styles from './style.scss'

export default class Supplychain extends Component {
    static propTypes = {
      history: PropTypes.object.isRequired,
      loginWithRedirect: PropTypes.func.isRequired,
    }

    render() {
      const { loginWithRedirect, history } = this.props
      return (
        <div>
          <Container fluid>
            <div className={classnames('page-main-wrapper')}>
              <div className={classnames(styles.container_xl, 'mt-5', 'mb-5')}>
                <div className={classnames(styles.page_content_header, 'row', 'align-items-center')}>
                  <Col lg={12} md={12} xl={12} xs={12}>
                    <div className={classnames('text-center', 'mx-auto')}>
                      <img
                        alt="fresher resume" className={classnames(styles.resume_sample_vector, 'img-fluid', 'mx-auto')}
                        src="/assets/images/supplychain_vector.jpg" />
                      <div
                        className={classnames('mt-md-5', 'text-center', 'mb-md-4')}>
                        <h1 className={classnames('mt-4', styles.size_heading)}> Supply Chain  Resume </h1>
                        <p className={classnames('mb-0')}>Supply chain managers are responsible
                          for developing procurement strategies and supervising all
                          the supply chain life cycle stages. Employers are
                          interested in supply chain managers who are analytical,
                          detail-oriented, and outgoing. Your resume
                          should highlight your
                          ability to form reliable networks, use technology,
                          and handle multiple responsibilities.</p>
                      </div>
                      <div className={classnames(styles.btn_gs)}>
                        <GetStartedButton label="Build My Resume" loginWithRedirect={loginWithRedirect} size="lg" />
                      </div>
                    </div>
                  </Col>
                  <div className={classnames(styles.resume_sample_container, 'mt-5', 'pt-3', 'pt-lg-4')}>
                    <Row>
                      <Col lg={6}>
                        <div className={classnames('mb-5', 'p-xl-5', 'mb-xl-0')}>
                          <div className={classnames(styles.resume_sample)}>
                            <img
                              alt="Resume Sample" className={classnames(styles.resume_sample_img)}
                              src="/assets/images/supplychain_resume.jpg" />
                            <Download
                              downloadURL={getDocUrl('assets/docs/supplychain-res.docx')}
                              history={history} />
                          </div>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className={classnames('mb-5', 'p-xl-5', 'mb-xl-0')}>
                          <div className={classnames(styles.resume_sample)}>
                            <img
                              alt="Resume Sample" className={classnames(styles.resume_sample_img)}
                              src="/assets/images/supplychain_CL.jpg" />
                            <Download
                              downloadURL={getDocUrl('assets/docs/supplychain-cl.docx')}
                              history={history} />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className={classnames(styles.resume_sample_header, 'text-center')}>
                      <div className={classnames(styles.container_xl)}>
                        <h2 className={classnames(styles.section_heading)}>
                          Supply Chain Manager (Text Format)
                        </h2>
                        <p className={classnames('mt-2', styles.section_paragraph)}>
                          Make sure you choose the right resume
                          format to suit your unique experience and the job you are applying.</p>
                      </div>
                    </div>
                    <div className={classnames(styles.resume_sample_wrapper)}>
                      <div className={classnames(styles.container_xl)}>
                        <Row>
                          <Col lg={8} xl={8} xs={12}>
                            <div className={classnames(styles.resume_sample_main)}>
                              <div className={classnames(styles.tab_content)}>
                                Text View
                              </div>
                            </div>
                            <div className={classnames(styles.resume_content_card,
                              styles.dz_card, styles.shadow_1, 'px-3', 'px-lg-5', 'py-5', 'tab-pane active')}>
                              <Row>
                                <h2 className={classnames(styles.section_heading, 'ml-2')}>
                                  Supply Chain Manager Resume
                                </h2>
                              </Row>
                              <div className={classnames(styles.resume_content_title, 'mt-3')}>Profile</div>
                              <p className={classnames('mt-2', 'mb-2')}>
                                Industrious Supply Chain Manager skilled in directing and
                                training staff members to achieve high performance.
                                Expertise in conducting performance evaluations and
                                implementing continuous improvement strategies to
                                foster organizational success. Excellent
                                communicator with logistics coordination expertise.
                              </p>
                              <div className={classnames(styles.resume_content_title)}>
                                Work Experience</div>
                              <ul className={classnames(styles.interview_points, 'list-unstyled',
                                styles.content_title, 'mt-4')}>
                                <li className={classnames('mb-0')}>Supply Chain Manager</li>
                                <li>XYZ Solutions, Belas, US Dec 2019- till date</li>
                              </ul>
                              <ul className={classnames(styles.interview_points, 'mt-2')}>
                                <li>Erected supplier relationships to mitigate risk and
                                  establish effective purchasing
                                  arrangements with direct, indirect and logistics customers.</li>
                                <li>Oriented management change initiatives and
                                  designed functional area infrastructure
                                  to support demand planning and supply strategy.</li>
                                <li>Developed effective supply
                                  chain planning systems
                                  while improving sales and operations
                                  planning processes for the distribution network.</li>
                                <li>Drove vital performance
                                  indicators and continuous improvements
                                  throughout logistics and supplier operations.</li>
                                <li>Stabilized inventory targets, stock level
                                  and risk mitigation targets and
                                  managed flexibility procedure to optimize inventory.</li>
                                <li>Optimized supply chain infrastructure by
                                  estimating and initiating targeted
                                  modifications to reduce
                                  operating costs by 20%.</li>
                                <li>Facilitated change management
                                  strategies and incorporated
                                  functional area infrastructure to
                                  support demand planning and supply initiatives.</li>
                              </ul>
                              <ul className={classnames(styles.interview_points, 'list-unstyled',
                                styles.content_title, 'mt-4')}>
                                <li className={classnames('mb-0')}>Supply Chain Specialist</li>
                                <li>ABC Solutions, Belas, US Jan 2014 - Nov 2019</li>
                              </ul>
                              <ul className={classnames(styles.interview_points, 'mt-2')}>
                                <li>Aided change management strategies and
                                  incorporated functional
                                  area infrastructure to support
                                  demand planning and supply initiatives.</li>
                                <li>Applied insights on global economics
                                  and marketing to implement
                                  pricing strategies and channels.</li>
                                <li>Built supplier relationships to mitigate risk
                                  and establish enough purchasing
                                  arrangements with indirect
                                  and logistics customers.</li>
                                <li>Supervised distribution network,
                                  including logistics, trade compliance
                                  and warehouse management.</li>
                                <li>Secured inventory targets, stock
                                  level and risk mitigation
                                  targets and managed flexibility
                                  strategy to optimize inventory.</li>
                              </ul>
                              <div className={classnames(styles.resume_content_title)}>
                                Skills
                              </div>
                              <ul className={classnames(styles.interview_points, 'mt-4')}>
                                <li>Supply Chain Management</li>
                                <li>Negotiating</li>
                                <li>Multi-plant experience</li>
                                <li> Federal contracting</li>
                                <li>Staff Management</li>
                                <li>Relationship building </li>
                                <li> Analytical skills</li>
                                <li>Critical thinking</li>
                                <li>Cultural awareness</li>
                                <li>Excellent work ethic</li>
                              </ul>
                              <div className={classnames(styles.resume_content_title, 'mt-3')}>
                                Education summary</div>
                              <ul className={classnames(styles.interview_points, 'list-unstyled', 'mt-4')}>
                                <li>BBA In Supply Chain Management (Sir Andrew College, Belas)</li>
                              </ul>
                              <div className={classnames(styles.resume_content_title, 'mt-3')}>Certifications</div>
                              <ul className={classnames(styles.interview_points, 'list-unstyled', 'mt-4')}>
                                <li>Certified Professional of American Production
                                  and Inventory Control Society (APICS)</li>
                              </ul>
                              <div className={classnames(styles.resume_content_title)}>
                                Achievements
                              </div>
                              <ul className={classnames(styles.interview_points, 'mt-4')}>
                                <li>Resolved product issue through consumer testing.</li>
                                <li>Used Microsoft Excel to develop
                                  inventory tracking spreadsheets.</li>
                              </ul>
                              <div className={classnames(styles.resume_content_title, 'mt-3')}>Hobbies</div>
                              <ul className={classnames(styles.interview_points, 'mt-4')}>
                                <li>Travelling</li>
                                <li>Bungee Jumping</li>
                                <li>Reading</li>
                              </ul>
                            </div>
                            <div className={classnames(styles.resume_sample_header, 'pb-5')}>
                              <h2 className={classnames(styles.section_heading, 'mb-3')}>
                                How to Write Supply Chain Resume</h2>
                              <p className={classnames('mb-2')}>Typical jobs highlighted on a Supply Chain
                                resume includes procuring supplies,
                                analyzing the company&#8242;s supply needs,
                                scheduling purchases, and predicting
                                material requirements. To compose a
                                perfect resume, you have to showcase
                                qualifications such as management expertise,
                                leadership, communication skills, attention
                                to detail, critical thinking, and
                                problem-solving. Computer literacy is also essential.
                                Successful candidates for this position
                                must have at least a Bachelor&#8242;s Degree in a relevant area.</p>
                              <p>In this guide, you get detailed
                                information on how to write
                                the supply manager&#8242;s resume.</p>
                            </div>
                            <div
                              aria-labelledby="resumeContentTab"
                              className={classnames(styles.resume_content_card,
                                styles.dz_card, styles.shadow_1, 'px-0', 'px-lg-5', 'py-5', 'tab-pane active')}
                              id="resumeContent" role="tabpanel">
                              <h3 className={classnames(styles.resume_content_heading, 'mt-0')}>
                                How to Write Supply Chain  Resume Summary</h3>
                              <p className={classnames('mb-2')}>A resume summary is placed at
                                the top of your CV where the hiring
                                manager can&#8242;t miss to see it.
                                Ensure to equip your summary with the crucial
                                points of your work experience and education.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>
                                Here is how to write a resume summary</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Begin with descriptive words, such as surpassed or efficient.</li>
                                <li>Highlight your title e.g., supply chain manager</li>
                                <li>Include the number of years you have worked</li>
                                <li> Share your goal for the role
                                  (e.g., Seeking to create productive partnerships...)</li>
                                <li>Indicate the new company’s name.</li>
                                <li>Highlight your best accomplishments (reduced cost by 30%.)</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold', 'mt-3', 'mt-2')}>
                                Examples of Supply Chain Manager Resume Summary</h5>
                              <p>Here are examples of the supply chain manager&#8242;s resume summary.</p>
                              <h5 className={classnames('ml-3', 'font-weight-bold', 'mt-3')}>Good Example</h5>
                              <blockquote><p className={classnames(styles.resume_rank, 'ml-0')}>
                                Professional supply chain manager with more than five
                                years of experience reducing cost
                                and enhancing productivity.
                                Seeking to create successful
                                partnerships for Mackinnon Ltd.
                                At Taton Ltd, reduced material costs
                                by 40% via packaging redesign.
                              </p></blockquote>
                              <h5 className={classnames('ml-3', 'font-weight-bold')}>Bad Example</h5>
                              <blockquote><p className={classnames(styles.resume_rank, 'ml-0', 'mb-0')}>
                                Organized supply chain manager with vast experience in
                                reducing costs and enhancing productivity.
                                Experienced in creating
                                productive and long-lasting partnerships.
                                Highly committed with excellent communication skills.
                              </p>
                                <p className={classnames(styles.resume_rank, 'ml-0', 'mb-2')}>
                                  The wrong example has no factual information. To improve it
                                  into an excellent summary, you have to
                                  add more details, such as years of
                                  experience and achievement.
                                </p>
                                <p className={classnames(styles.resume_rank, 'ml-0')}>
                                  An inexperienced supply chain manager requires
                                  career objectives. The summary and objective statements
                                  aren&#8242;t much different. They are the same in terms of
                                  structure but pick and pack your supply chain
                                  manager skills from unrelated jobs.
                                  If you were in procurement, find shining
                                  moments that fit the supply chain management role.
                                </p>

                              </blockquote>
                              <div className={classnames('card', 'p-3', 'px-lg-4', 'mt-5',
                                styles.ProTip, styles.interview_blockquote)}>
                                <h5 className={classnames('ml-5', 'font-weight-bold')}>Pro Tips </h5>
                                <p className={classnames('ml-5')}>
                                  In the summary section, list accomplishments
                                  relevant to the job you are applying for.
                                  Use a combination of hard and soft skills
                                  to make your resume stand out.</p>
                              </div>
                              <h3 className={classnames(styles.resume_content_heading, 'mt-5', 'mb-4')}>
                                How to Highlight Skills on a Supply Chain Manager Resume
                              </h3>
                              <img
                                alt="Nurseimage" className={classnames('mt-2', 'mb-3')}
                                src="/assets/images/supply_skill.jpg" />
                              <p className={classnames('mb-0')}>It is vital to highlight skills
                                on your resume based on the content of the job descriptions.
                                If it is your first time in a job search,
                                then you should familiarize yourself with resume samples.
                                Bear in mind that the most common supply
                                chain manager resume skills include
                                supply chain, procurement, and logistics.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2', styles.align)}>
                                When you&#8242;re writing your
                                skills section, you should consider the following.</h5>
                              <ul className={classnames(styles.interview_points, 'mt-2')}>
                                <li>Highlight between 6-12 skills</li>
                                <li>Use a mix of both hard and soft skills</li>
                                <li>Highlight your most impressive skills or achievements</li>
                                <li>Use bullet points to list skills</li>
                                <li>Before listing skills on your resume,
                                  analyze the job descriptions to
                                  familiarize yourself with the recruiter&#8242;s
                                  requirements regarding skills</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold', 'mt-2', 'mb-0', styles.align)}>
                                Supply Chain Manager&#8242;s
                                Hard and Soft Skills</h5>
                              <p className={classnames('mb-0')}>Hard skills include qualifications
                                and technical abilities required to accomplish a particular job.
                                On the other hand, soft skills refer to your personality or working style.
                                Note that unlike hard skills, soft skills are difficult to quantify.</p>
                              <p className={classnames('mb-1')}>Ensure that you list relevant skills that show your
                                potential to manage and serve clients and other stakeholders.</p>
                              <h5 className={classnames('font-weight-bold', 'ml-2', styles.align)}>
                                Example of Hard Skills</h5>
                              <ul className={classnames(styles.interview_points, 'mb-0', 'mt-2')}>
                                <li>Project Logistics</li>
                                <li>Process Improvement</li>
                                <li>Project Management</li>
                                <li>Supply Chain Management</li>
                                <li>Strategic Planning</li>
                                <li>Lean management</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold', 'ml-2', styles.align)}>
                                Example of Soft skills</h5>
                              <ul className={classnames(styles.interview_points, 'mt-2')}>
                                <li>Leadership skills</li>
                                <li>Interpersonal relationships</li>
                                <li>Communication skills</li>
                                <li>Teamwork</li>
                                <li>Critical thinking</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold', 'ml-2')}>Supply Chain Manager
                                Resume Example [Skills]</h5>
                              <h5 className={classnames('font-weight-bold', 'ml-2', 'mt-4')}>Right Example</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Reduced resupply turn-around time by 30%
                                  through vendors inclusion in our Lean programs.</li>
                                <li>Undertook ERP training for ten supply chain planners.</li>
                                <li>Enhanced supply efficiency by 20% through
                                  process improvement design and implementation.</li>
                                <li>Reduced direct material costs by 10% on metal products
                                  by collaborating with KYM operators
                                  on a scrap-reduction program.</li>
                              </ul>
                              <div className={classnames('card', 'p-3', 'px-lg-4', 'mt-5',
                                styles.ProTip, styles.interview_blockquote)}>
                                <h5 className={classnames('ml-5', 'font-weight-bold')}>Pro Tip </h5>
                                <p className={classnames('ml-5')}>
                                  Analyze the advert’s job descriptions and pick
                                  skills that match the employer&#8242;s need.
                                  Additionally, use the keywords in the job
                                  descriptions to list your skills on a resume.</p>
                              </div>
                              <h3 className={classnames(styles.resume_content_heading, 'mt-5')}>
                                How to Write Work Experience in Supply Chain Resume</h3>
                              <p className={classnames('mb-2')}>Knowing what to include in
                                the experience section of a resume can be challenging if you
                                don&#8242;t know how to go about it.
                                Before you highlight your experience,
                                you have to consider your qualifications
                                and the kind of job you are applying for.</p>
                              <p className={classnames('mb-2')}>Ensure that any experience
                                you highlight is relevant to the
                                position you are applying for. Also, avoid using
                                experience that is more than ten years.</p>
                              <p className={classnames('mb-2')}>When composing about responsibilities
                                in a supply chain manager position, keep each
                                experience detail-oriented.
                                If possible, include numbers to show the extent
                                of your achievements in that position.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>
                                Here is how to write your experience on a resume</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Start with your newest job title</li>
                                <li>Highlight the organization name, and the number of years you worked.</li>
                                <li>Include a short supply chain manager job description.</li>
                                <li>List up to six bullet points.</li>
                                <li>Use various action words such as “&#8243;achieved,&#8243;
                                  &#8243;met,&#8243; &#8243;delivered,&#8243; etc. More importantly,
                                  use present tense for continuous activities
                                  and past tense for those you are not involved in.</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>
                                Example of Supply Chain Manager Experience on a Resume</h5>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>Right sample</h5>
                              <ul className={classnames(styles.interview_points, 'list-unstyled', 'mt-3')}>
                                <li className={classnames('mb-2')}>Experience</li>
                                <li className={classnames('mb-2')}> Supply Chain Manager</li>
                                <li>Loctite Ltd</li>
                                <li>July 2015 to March 2019</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold', 'ml-2')}>
                                Key Qualifications &amp; Accomplishment</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Utilized planning knowledge to
                                  conduct supply chain activities
                                  through the creation and
                                  implementation of production plans.</li>
                                <li>Managed 300+ Stock Keeping Units (SKUs) for
                                  machinery parts and tools.</li>
                                <li>Created and launched a supplier scorecard and supplier survey.</li>
                                <li>Saved $100K by eradicating obstacles in the laminating process.</li>
                                <li>Reduced high-speed laminator setups by 15%
                                  through changing pack quantity of raw stock.</li>
                                <li>Facilitated an onboarding program for all 4,000
                                  incoming graduates across the country, which
                                  reduced their settling in the period from one month.</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold', 'ml-2', 'mt-3')}>Wrong Example</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Resolved customer complaints, and inquiries
                                  within the recommended timelines.</li>
                                <li>Responsible for enhancing operating procedures.</li>
                                <li>Supervised sales orders to ensure precise completion.</li>
                                <li>Accounted for the management of repair parts.</li>
                              </ul>
                              <div className={classnames('card', 'p-3', 'px-lg-4', 'mt-5',
                                styles.ProTip, styles.interview_blockquote)}>
                                <h5 className={classnames('ml-5', 'font-weight-bold')}>Pro Tip </h5>
                                <p className={classnames('ml-5')}>
                                  To make your work experience section more appealing,
                                  you should structure it with bullet
                                  points and key achievements.
                                  Example: Reduced inventory cost by
                                  20% through the identification of unnecessary tooling.</p>
                              </div>
                              <h3 className={classnames(styles.resume_content_heading, 'mt-5')}>
                                How to write Supply Chain Manager Achievements,
                                Hobbies, Training, and certifications on Resume</h3>

                              <h5 className={classnames('font-weight-bold', 'mt-4')}>
                                How to Highlight Achievement</h5>
                              <p className={classnames('mb-2')}>Highlight your key achievements in a way that
                                proves that you are fit for the position.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>Examples</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Awarded the company&#8242;s best supply
                                  chain manager for two consecutive years</li>
                                <li>Achieved 50% procurement efficiency,
                                  which was above the company&#8242;s benchmark</li>
                                <li>Managed more than 50 employees across
                                  different branch networks</li>
                              </ul>
                              <div className={classnames('card', 'p-3', 'px-lg-4', 'mt-5',
                                styles.ProTip, styles.interview_blockquote)}>
                                <h5 className={classnames('ml-5', 'font-weight-bold')}>Pro Tip </h5>
                                <p className={classnames('ml-5')}>
                                  Ensure to use metrics, such as percentages and
                                  numbers, to measure your achievements.
                                  Example: &#8243;Achieved 40% efficiency in
                                  procurement within my first year of employment.”</p>
                              </div>
                              <h5 className={classnames('font-weight-bold', 'mt-5')}>How to
                                highlight hobbies</h5>
                              <p className={classnames('mb-2')}>A hobby is any activity done for amusement.
                                Hobbies are not meant for payment, and you
                                can engage in them during off time.
                                Make sure to list hobbies that are relevant
                                to the job you are applying for.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>Here are the tips on how
                                to list hobbies on your resume</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>List hobbies relevant to the position you are applying for</li>
                                <li>Limit yourself to a maximum of three hobbies</li>
                                <li>Highlight hobbies as per the skills
                                  listed in the job description</li>
                                <li>List hobbies at the bottom of your resume</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>Examples of hobbies to
                                list on supply chain manager&#8242;s resume</h5>
                              <ul className={classnames(styles.interview_points, 'mt-3')}>
                                <li>Socializing</li>
                                <li>Traveling</li>
                                <li>Reading</li>
                                <li>Blogging</li>
                              </ul>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>How to Highlight
                                Certification on a Resume</h5>
                              <p className={classnames('mb-0')}>Highlighting certifications
                                on your resume can enhance your competitiveness
                                and set you apart from other applicants.
                                If you have certifications, it is crucial
                                to know how to add them to your resume.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>Here is how to list
                                certifications on your resume.</h5>
                              <h5 className={classnames('font-weight-bold', 'mt-3')}>
                                Highlight the certification’s title
                              </h5>
                              <p className={classnames('mb-2')}>Ensure to highlight the full title
                                of certification under the title &#8243;Certifications.&#8243;
                                Make sure you type the name of the certification well,
                                and spell out any abbreviations.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>
                                List the certifying authority
                              </h5>
                              <p className={classnames('mb-2')}>It is vital to list the certifying
                                authority to allow for the verification of your certifications.
                                Always include the name of the organization/association
                                that awarded it to you.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>
                                List the date earned
                              </h5>
                              <p className={classnames('mb-3')}>Listing the date earned is
                                crucial because it informs your recruiter of how long
                                it has taken since you earned the certification.
                                Also, it provides the hiring manager
                                with the information they need to
                                call the certifying authority and confirm
                                that you earned the certification in question.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>
                                Provide details on the associated skills
                              </h5>
                              <p className={classnames('mb-2')}>It is critical to include a list of
                                skills associated with the certification.
                                This is meant to explain the certification&#8242;s
                                relevance and answer any questions
                                your recruiter may have.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2')}>
                                Certification example
                              </h5>
                              <ul className={classnames(styles.interview_points, 'list-unstyled',
                                styles.content_list, 'mt-3', 'ml-0')}>
                                <li className={classnames('mb-0')}>Supply Chain Management Certification</li>
                                <li className={classnames('mb-0')}>New Delhi Institute, January 2015</li>
                                <li className={classnames('mb-0')}>Expires: January 2030</li>
                                <li className={classnames('mb-0')}>Relevant skills: Logistics</li>
                              </ul>

                              <div className={classnames('card', 'p-3', 'px-lg-4', 'mt-5',
                                styles.ProTip, styles.interview_blockquote)}>
                                <h5 className={classnames('ml-5', 'font-weight-bold')}>Pro Tips </h5>
                                <ul className={classnames(styles.interview_points, 'mt-4', 'ml-5')}>
                                  <li>List the name of the certifications,
                                    date, and the certifying authority</li>
                                  <li>Highlight your certifications
                                    using a reverse-chronological order</li>
                                </ul>
                              </div>
                              <h3 className={classnames(styles.resume_content_heading, 'mt-5')}>
                                Top Professional Tips for Writing Supply Chain Resume</h3>
                              <h5 className={classnames('font-weight-bold', 'mt-4')}>
                                Here are the tips for writing the supply chain  resume</h5>
                              <h5 className={classnames('font-weight-bold', 'mt-4', 'mb-2')}>
                                Add your current contact information
                              </h5>
                              <p className={classnames('mb-2')}>Add your personal details, such as your
                                name and contact details, at the top of your resume.
                                Make sure you highlight your full name, address,
                                and telephone number, email, and LinkedIn profile.
                                You may also include your current title.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2', 'mb-2')}>
                                Write a summary
                              </h5>
                              <p className={classnames('mb-2')}>Write a brief paragraph
                                describing what makes you the perfect candidate for the
                                supply chain manager position. Highlight your qualifications,
                                and use metrics to quantify your accomplishments.
                                List the skills that are relevant to the job you are applying for.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2', 'mb-2')}>
                                List any achievements or highlights</h5>
                              <p className={classnames('mb-2')}>Highlight any awards or achievements
                                you have received in supply chain department. For instance,
                                if you used your strategies to reduce 50% of the
                                transport costs, let the hiring managers know about it.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2', 'mb-2')}>Highlight your experience
                                in procurement or logistics</h5>
                              <p className={classnames('mb-2')}>Identify your most recent
                                roles and list the aspects
                                of each job that relate to supply chain management.
                                If you have any customer service experience, purchasing,
                                inventory management, don&#8242;t hesitate to list them here.
                                Include jobs that support your teamwork, management, and negotiation skills.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2', 'mb-2')}>
                                Add your qualifications</h5>
                              <p className={classnames('mb-2')}>Qualifications matter a lot when
                                it comes to applying for the supply chain manager position.
                                List your qualifications, starting from the highest
                                to the lowest. Some of the relevant
                                qualifications for the role include logistics, supply
                                chain management, and business management.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2', 'mb-2')}>
                                Include important certifications</h5>
                              <p className={classnames('mb-2')}>
                                Having relevant certifications enhances your
                                chances of recruitment. Ensure to add your certifications
                                in reverse-chronological order, starting from the current ones.
                                Additionally, highlight the certifying
                                authority and the date when it was earned.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2', 'mb-2')}>
                                Add your skills</h5>
                              <p className={classnames('mb-2')}>Highlight both hard and soft skills on your resume.
                                Note that recruiters look for skills, such as
                                critical thinking, project management, negotiation skills, etc.
                                Anything you highlight in the skills section
                                should be backed supported by experience and qualifications.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2', 'mb-2')}>
                                Quantify your accomplishments</h5>
                              <p className={classnames('mb-2')}>Use metrics to quantify your accomplishments.
                                Recruiters are less concerned about your responsibilities
                                but are more interested in your impact on their businesses.
                                While highlighting your experience, make sure
                                to incorporate numbers or rates where conceivable.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2', 'mb-2')}>
                                Highlight your technical experience</h5>
                              <p className={classnames('mb-2')}>As a supply chain manager, you should
                                have technical knowledge. Almost all organizations
                                need a tech-savvy person to work for them.
                                Recruiters will be intrigued to check whether
                                you have any experience in computer or management system.</p>
                              <p className={classnames('mb-2')}>Commit a section of your resume
                                to your technical experience.
                                Here, you can take note of your Office Suite
                                capability and related knowledge.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2', 'mb-2')}>
                                Highlight your management experience</h5>
                              <p className={classnames('mb-2')}>A supply chain manager&#8242;s
                                work entails administrative functions. This can be dealing
                                with clients or motivating staff.
                                Listing your management experience confirms
                                to the recruiters that you are fit for the role.</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2', 'mb-2')}>
                                Add keywords</h5>
                              <p className={classnames('mb-2')}>Keywords are vital in making your resume
                                to get selected during the electronic search.
                                The supply chain manager keywords include supply management,
                                procurement, logistics, and business development.</p>
                              <p className={classnames('mb-2')}>It is crucial to optimize
                                your resume for the keywords search.
                                This will make your resume to be easily found whenever
                                it is subjected to ATS (Applicant Tracking Systems).</p>
                              <h5 className={classnames('font-weight-bold', 'mt-2', 'mb-2')}>
                                List hobbies and interests</h5>
                              <p className={classnames('mb-2')}>Although many contend that hobbies
                                and interests aren&#8242;t essential to your job, listing them
                                can set you apart, especially in stiff competition.
                                Adding hobbies, such as socializing,
                                shows your ability to hold discussions with
                                clients and make them feel appreciated.</p>
                            </div>
                          </Col>
                          <Col lg={4} xl={4} xs={12}>
                            <PromoCard loginWithRedirect={loginWithRedirect} />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      )
    }
}
