import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { AppRouter } from '../../router'

class PrivateContainer extends Component {
  render = () => {
    const { isValidRedirection } = this.props
    return (
      <Fragment>
        <AppRouter isValidRedirection={isValidRedirection} />
      </Fragment>
    )
  }
}

PrivateContainer.propTypes = {
  history: PropTypes.object.isRequired,
  isValidRedirection: PropTypes.bool.isRequired,
}

export const Private = withRouter(PrivateContainer)
