import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { OAuthContainer } from '../../containers'
import { PageNotFound } from '../../components'
import { OAUTH_REDIRECT_ROUTE } from '../../constants'

export const OAUTH_ROUTE = '/oauth2'

export const OAuthRoutes = () => (
  <Switch>
    <Route
      exact path={[OAUTH_REDIRECT_ROUTE]}
      render={props => <OAuthContainer {...props} type={'redirect'} />} />
    <Route component={PageNotFound} path="*" />
  </Switch>
)
