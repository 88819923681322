import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { inject } from 'mobx-react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'react-bootstrap'
import MetaTags from 'react-helmet'
import cs from 'classnames'
import { get, replace } from 'lodash'
import ReactHtmlParser from 'react-html-parser'
import { INTERVIEW_KEY_ROUTE } from '../../router'
import { Icon, IconType } from '../common'
import Questions from './Questions'
import InterviewFilters from './InterviewFilters'
import UserCompany from '../MainContent/Home/UserCompany'
import { getInteviewImageUrl } from '../../constants'
import st from './style.scss'

@inject('mainModel')
class Interview extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    mainModel: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  }

  constructor(props){
    super(props)
    this.state = {
      categories: [],
      interviewQuestions: [],
      tag: '',
      filter: '',
      page: 1,
      expandIndex: [],
    }
  }

  componentDidMount(){
    const { mainModel, match } = this.props
    const { page } = this.state
    const selectedSubKey = get(match, 'params.key', '')
    if (selectedSubKey === ''){
      const cb = (categories) => {
        this.setState({
          categories,
        })
      }
      mainModel.getInterviewQuestionsCategories(1, cb)
      this.getInterviewQuestionsList({ page })
    }
  }

  getInterviewQuestionsList = (payload) => {
    const { mainModel } = this.props
    const cb = (interviewQuestions) => {
      this.setState({
        interviewQuestions,
        expandIndex: Array(interviewQuestions.length).fill(false),
      })
    }
    mainModel.getInterviewQuestionsList(payload, cb)
  }

  gotoInterviewSection = (sectionId) => {
    const { history } = this.props
    const url = `${replace(INTERVIEW_KEY_ROUTE, ':key', sectionId)}`
    history.push(url)
    window.scrollTo(0, 0)
  }

  filterByCategory = (category) => {
    const { tag, filter, page } = this.state
    this.getInterviewQuestionsList({ category, tag, filter, page })
  }

  toggleExpandQuestion = (index) => {
    const { expandIndex } = this.state
    expandIndex[index] = !expandIndex[index]
    this.setState({
      expandIndex,
    })
  }

  renderInterviewQuestions= (questions, index) => {
    const { expandIndex } = this.state
    const contentList = get(questions, 'contentList')
    const media = get(questions, 'media')
    const { ARROW_DOWN } = IconType.DIRECTION
    return (
      <div>
        <MetaTags>
          <title>{'Top Interview Questions | Freshers job interview Tips | Resumaker'}</title>
          <meta
            // eslint-disable-next-line max-len
            content={'Find out most valuable interview tips. Browse here Resumaker blog post about How to prepare for an Interview - Fresher’s and give your best in interview. '}
            name="description" />
          <link href={'https://www.resumaker.co.in/interview'} rel="canonical" />
        </MetaTags>
        <div className={cs('mb-5', 'card', st.dz_card, st.shadow_1,
          st.interview_card)}>
          <img
            alt="Interview Alt Text" className={cs('card-img-top')}
            src={getInteviewImageUrl(get(media, 'url'))} />
          <div className={cs('card-body', 'mt-3')}>
            <h2 className={cs(st.card_title, 'mb-4')}>{get(questions, 'title')} </h2>
            <p className={cs(st.card_text)}>  {ReactHtmlParser(get(questions, 'description'))}</p>
            <div>
              <div className={cs(st.card_collapse_header)}>
                <span className={cs(st.interview_question_span)}>
                  Questions that you will find answers in this article </span>
                <div
                  className={cs(st.btn, st.btn_grey)}
                  data-target="#collapseContentExample3"
                  data-toggle="collapse" onClick={() => this.toggleExpandQuestion(index)}>
                  <i className={cs({ [st.drop_img]: !expandIndex[index],
                    [st.up_img]: expandIndex[index] })}>
                    <Icon
                      color={'#ffffff'} height={'20'}
                      names={[ARROW_DOWN]} viewHeight={'1024'}
                      viewWidth={'1024'} width={'20'} />
                  </i>
                </div>
              </div>
              {expandIndex[index] && (
              <div
                className={cs(st.collapse, st.card_collapse_content)}
                id="collapseContentExample3">
                <ul className={cs(st.interview_points)}>
                  {contentList && contentList.length > 0 && contentList.map((content, inIndex) => (
                    <li>{get(content, 'title')}</li>))}
                </ul>
              </div>
              )}
            </div>
            <div className={cs('mt-4', 'mb-4')}>
              <div
                className={cs('btn', st.btn_readmore, 'btn-primary')}
                onClick={() => this.gotoInterviewSection(get(questions, 'qId'))}>Read More</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderLandingPage(){
    const { categories, interviewQuestions } = this.state
    return (
      <Container>
        <div className={cs(st.container_xl, 'mt-5', st.page_content_header, 'align-items-center')}>
          <Row>
            <Col className={cs('mx-auto', st.content_header)} lg={6} md={12} sm={12} xl={6} xs={12}>
              <div className={cs('mb-5', 'pb-mb-3')}>
                <h1 className={cs('mb-4')}>Job Interviews </h1>
                <p className={cs('mb-0')}>Learn how to prepare for an interview and ace it.
                  Find out what questions recruiters ask and how to answer them.
                  Follow expert strategies and tips, go through the interviewing stage stress-free,
                  and land that job. Each guide comes
                  with real-life examples and pro tips from HR professionals.</p>
              </div>
            </Col>
            <Col className={cs('mx-auto')} lg={6} md={12} sm={12} xl={6} xs={12}>
              <img
                alt="interview page" className={cs('image-fluid', st.img_responsive, st.img_center)}
                src="/assets/images/ilp-header.png" />
            </Col>
          </Row>
        </div>
        <div className={cs('mt-5')} />
        <UserCompany />
        <div className={cs('mb-5')} />
        <div className={cs(st.container_xl, 'interview-page')}>
          <Row>
            <Col
              className={cs('d-lg-block', 'interview-page-left', 'sticky-effect')}
              lg={3} xl={3} xs={12}>
              <InterviewFilters categories={categories} filterByCategory={this.filterByCategory} />
            </Col>
            <Col lg={9} xs={12}>
              <div className={cs('pl-lg-0')}>
                {/* <div className={cs('pt-4', 'pb-4', 'pb-md-5', 'd-flex',
                  'justify-content-end', st.interview_total)}>
                  10000 Results Shown
                </div> */}
                {interviewQuestions && interviewQuestions.length > 0
                  && interviewQuestions.map((questions, index) => this.renderInterviewQuestions(questions, index))}
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    )
  }

  render() {
    const { match } = this.props
    const selectedSubKey = get(match, 'params.key', '')
    return (
      <Fragment>
        {selectedSubKey === '' && this.renderLandingPage()}
        {selectedSubKey !== '' && <Questions gotoQuestion={this.gotoInterviewSection} questionId={selectedSubKey} />}
      </Fragment>
    )
  }
}

export default withRouter(Interview)
