import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Button, Container, Row } from 'react-bootstrap'
import { get } from 'lodash'
import Footer from './Footer'
import { validateEmail, handleReturn } from '../../constants'
import styles from './style.scss'

class LoginwithEmail extends Component {
  static propTypes = {
    login: PropTypes.func.isRequired,
    openURL: PropTypes.func.isRequired,
  }

  constructor(props){
    super(props)
    this.state = {
      userName: '',
      password: '',
    }
  }

  updateField = (key, value) => {
    this.setState({
      [key]: value,
    }, this.validateValues)
  }

  validateValues = () => {
    let isValid = true
    const { password, userName } = this.state
    const emailValid = validateEmail(userName)
    let errorMessage = ''
    if (!emailValid){
      errorMessage = 'Please Enter Valid Email'
      isValid = false
    }
    else if (password === '') {
      errorMessage = 'Please Enter Valid Password'
      isValid = false
    }
    if (errorMessage !== get(this.state, 'errorMessage')) {
      this.setState({
        errorMessage,
      })
    }
    return isValid
  }

  login = () => {
    const { login } = this.props
    const { userName, password } = this.state
    if (this.validateValues()){
      login({ userName, password })
    }
  }

  render(){
    const { userName, password, errorMessage } = this.state
    const { openURL } = this.props
    return (
      <Fragment>
        <Container fluid>
          <Row>
            <div className={classnames(styles.pp_0, styles.section_one)}>
              <div className={classnames(styles.welcome_bg, styles.login_with_email_bg)} />
            </div>
            <div className={classnames(styles.section_two)}>
              <div className={classnames(styles.welcome_next_box)}>
                <div className={classnames(styles.welcome)}>
                  <div className={classnames(styles.login_title)}>
                    <div
                      className={classnames(styles.show_cursor)}
                      onClick={() => openURL('/')}><img
                        alt={'logo'} className={classnames(styles.logo_img_login)}
                        src={'assets/images/logo.svg'} /></div>
                    <span> Login </span>
                  </div>
                  <div className={classnames(styles.welcome_back_title)}>
                    Welcome ! Please login to your Account
                  </div>
                  <div>
                    <input
                      className={classnames(styles.login_input_forgot)} id="userName" name="userName"
                      onChange={(e) => this.setState({ userName: e.target.value })}
                      onKeyUp={(e) => handleReturn(e, this.login)}
                      placeholder=" Enter Your Email" type="email" value={userName} />
                    <input
                      className={classnames(styles.login_input_forgot)} id="password" name="password"
                      onChange={(e) => this.setState({ password: e.target.value })}
                      onKeyUp={(e) => handleReturn(e, this.login)}
                      placeholder="Enter your password" type="password" value={password} />
                    <div className={classnames(styles.alert_email)}>
                      {errorMessage}
                    </div>
                    <div className={classnames(styles.logged_in)}>
                      <div className={classnames(styles.keep_logged_in)} />
                      <div className={classnames(styles.forgot_password, styles.show_cursor)}>
                        <div onClick={() => openURL('submit-email')}>Forgot Password ? </div>
                      </div>
                    </div>
                    <div className={classnames(styles.login_btn)}>
                      <Button
                        className={classnames(styles.btn_loginpage_submit, styles.button_effect)}
                        onClick={this.login} type="submit"> Login </Button>
                    </div>
                  </div>
                  <div className={classnames(styles.dont_have_account,
                    styles.login_create_new_one, styles.create_account_inline)}>
                    Don&#39;t have an Account ? <span>
                      <div
                        className={classnames(styles.show_cursor)}
                        onClick={() => openURL('signup')}>
                        Create Account </div> </span>
                  </div>
                </div>
              </div>
            </div>
            <Footer openURL={openURL} />
          </Row>
        </Container>
      </Fragment>
    )
  }
}
export default withRouter(LoginwithEmail)
